import React, { Component } from 'react';
import image1 from '../images/UMEDME_logo_white.png';
import image2 from '../images/UMEDME_new.png';
import Logo from '../images/umedmeweblogo.png';
import { Link } from "react-router-dom";
import { readSessionData } from '../config/MyFunctions';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import MyConfig from '../config/MyConfig';
import ReactTooltip from 'react-tooltip'


class SideManuBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageAccess: [],
            routeAccess: [],
            session: "",
            mUserId: "",
            location: "",
            getRoleType: "",
            iframePageUrl: "",
            random: 0
        }
    }


    async componentDidMount() {
        let { getRoleType } = this.state;
        let resData = await readSessionData()
        let { manage_session, manage_mUserId } = resData
        let login_RoleTypeData = localStorage.getItem("login_RoleType")
        if (login_RoleTypeData) {
            getRoleType = login_RoleTypeData;
        }
        this.setState({
            session: manage_session,
            mUserId: manage_mUserId,
            location: this.props.location.pathname,
            getRoleType
        }, () => {
            this.getMappingPage()
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                location: this.props.location.pathname
            })
        }
        if (this.props.pageUrl !== prevProps.pageUrl) {
            let copyUrl = this.props.pageUrl;
            if (copyUrl && copyUrl !== "") {
                copyUrl = copyUrl + "?demoPassword=GK&IYwjv";
            }
            this.setState({ iframePageUrl: copyUrl, random: this.state.random + 1 })
        }
    }

    getMappingPage() {
        let { grpcClient } = this.props
        let { mUserId, session, routeAccess } = this.state
        let postJson = { mUserId, session }
        // console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain("adminPages");
        request.setEvent("getAccess");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response == "Success") {
                    let result = responseData.result;
                    let pageAccess = [];
                    Object.keys(result).map((pageId) => {
                        pageAccess.push(result[pageId].title)
                        routeAccess.push(result[pageId].route)
                    })
                    //console.log("routeAccess", routeAccess, result)
                    // console.log("pageAccess", pageAccess)
                    this.setState({ pageAccess, routeAccess })
                    ReactTooltip.rebuild();
                }
            }
        });
    }

    render() {
        let { menuActive, typeofPage, navigation } = this.props
        let { pageAccess, routeAccess, location, getRoleType, iframePageUrl } = this.state
        let serachMenuConditions = routeAccess.includes("/medicationsearch") || routeAccess.includes("/food-search") || routeAccess.includes("/doctorsearch") || routeAccess.includes("/dashboard")
        let listMenuConditons = routeAccess.includes("/doctorlist") || routeAccess.includes("/invitelist")
        let speakupMenuConditons = routeAccess.includes("/speakup-dashboard") || routeAccess.includes("/speakup-subscribe-list") || routeAccess.includes("/speakup-unsubscribe-list") || routeAccess.includes("/speakup-submission")
        let manageMenuCondtions = routeAccess.includes("/userslist") || routeAccess.includes("/userCreate") || routeAccess.includes("/roletype") || routeAccess.includes("/pagecreate")
        let uiMenuCondtions = routeAccess.includes("/uilist") || routeAccess.includes("/uistructure") || routeAccess.includes("/uilang") || routeAccess.includes("/uilangmaster")
        let articleMenuCoditons = routeAccess.includes("/articlecategory") || routeAccess.includes("/articlesubcategory") || routeAccess.includes("/article") || routeAccess.includes("/forwho") || routeAccess.includes("/articleusertype")

        let feedbackRouting = routeAccess.includes("/admin-feedback") || routeAccess.includes("/admin-feedbacklist") || routeAccess.includes("/issueslist")

        let techMenuCheck = routeAccess.includes("/create-student") || routeAccess.includes("/create-teacher") || routeAccess.includes("/addresource-category") || routeAccess.includes("/add-resource") || routeAccess.includes("/resource-subcategory")


        let classNameSideMenu = getRoleType === "TranslatorAdmin" ? "translator-side-menu" : ""
        return (
            <nav id="sidebar" className={menuActive + " " + classNameSideMenu}  >
                <div className="sidebar-logo">
                    <img className={"img-fluid " + (getRoleType === "TranslatorAdmin" ? "translator-side-bar-images" : "")} src={Logo} />
                    <span className='sidebar-close' onClick={() => {
                        this.props.setMenuActive("");
                    }}><i className='fa fa-times' /></span>
                </div>

                {/* <div className="sidebar-header" style={{ borderBottom: "none" }}>
                </div> */}

                <ul className="list-unstyled components">
                    <ReactTooltip place="bottom" type="dark" effect="float" />
                    {serachMenuConditions &&
                        <h4 style={{ paddingLeft: 10, paddingTop: 10, textAlign: "start", fontWeight: "bold" }}>SEARCH</h4>
                    }

                    {routeAccess.includes("/medicationsearch") &&
                        <li className={location === "/admin/medication" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/medication")
                        }}>
                            <a className='left-align-txt' data-tip={"Search here for medication"}>Medication Search</a>
                        </li>}

                    {routeAccess.includes("/food-search") &&
                        <li className={location === "/admin/food-search" === "foodsearch" ? "activeClass" : ""} onClick={() => {
                            navigation.push("/admin/food-search")
                        }} data-tip={"Search here for food information"}>
                            <a className='left-align-txt'>Food Search</a>
                        </li>
                    }

                    {routeAccess.includes("/doctorsearch") &&
                        <li className={location === "/admin/doctorsearch" ? "activeClass" : ""} onClick={() => {
                            navigation.push("/admin/doctorsearch")
                        }} data-tip={"Find a doctor here"}>
                            <a className='left-align-txt'>Doctors Search</a>
                        </li>
                    }

                    {routeAccess.includes("/icdsearch") &&
                        <li className={location === "/admin/icdsearch" ? "activeClass" : ""} onClick={() => {
                            navigation.push("/admin/icdsearch")
                        }} data-tip={"ICD Search"}>
                            <a className='left-align-txt'>ICD Search</a>
                        </li>
                    }

                    {routeAccess.includes("/dashboard") && <React.Fragment>
                        <h4 style={{ paddingLeft: 10, paddingTop: 10, textAlign: "start", fontWeight: "bold" }}>UmedMe</h4>
                        <li className={location === "/admin/dashboard" ? "activeClass" : ""} onClick={() => {
                            navigation.push("/admin/dashboard")
                        }} data-tip={"Click to view Umedme Dashboard"}>
                            <a className='left-align-txt'>Dashboard</a>
                        </li>
                    </React.Fragment>
                    
                    }

                    {speakupMenuConditons &&
                        <h4 style={{ paddingLeft: 10, paddingTop: 10, textAlign: "start", fontWeight: "bold" }}>SpeakUp</h4>
                    }

                    {routeAccess.includes("/speakup-dashboard") &&
                        <li className={location === "/admin/speakup-dashboard" ? "activeClass" : ""} onClick={() => {
                            navigation.push("/admin/speakup-dashboard")
                        }} data-tip={"Click to view Umedme Dashboard"}>
                            <a className='left-align-txt'>Dashboard</a>
                        </li>
                    }
                    {routeAccess.includes("/speakup-subscribe-list") &&
                        <li className={location === "/admin/speakup-subscribe-list" ? "activeClass" : ""} onClick={() => {
                            navigation.push("/admin/speakup-subscribe-list")
                        }} data-tip={"Subscribe List"}>
                            <a className='left-align-txt'>Subscribe List</a>
                        </li>
                    }
                    {routeAccess.includes("/speakup-unsubscribe-list") &&
                        <li className={location === "/admin/speakup-unsubscribe-list" ? "activeClass" : ""} onClick={() => {
                            navigation.push("/admin/speakup-unsubscribe-list")
                        }} data-tip={"Unsubscribe List"}>
                            <a className='left-align-txt'>Unsubscribe List</a>
                        </li>
                    }
                    {routeAccess.includes("/speakup-submission") &&
                        <li className={location === "/admin/speakup-submission" ? "activeClass" : ""} onClick={() => {
                            navigation.push("/admin/speakup-submission")
                        }} data-tip={"Speakup Story"}>
                            <a className='left-align-txt'>Speakup Story</a>
                        </li>
                    }


                    {listMenuConditons &&
                        <h4 style={{ paddingLeft: 10, paddingTop: 10, textAlign: "start", fontWeight: "bold" }}>LIST</h4>
                    }

                    {routeAccess.includes("/doctorlist") &&
                        <li className={location === "/admin/doctorlist" ? "activeClass" : ""} onClick={() => {
                            navigation.push("/admin/doctorlist")
                        }} data-tip={"Find the added Doctors List"}>
                            <a className='left-align-txt'>Doctors List</a>
                        </li>
                    }



                    {routeAccess.includes("/invitelist") &&
                        <li className={location === "/admin/invitelist" ? "activeClass" : ""} onClick={() => {
                            navigation.push("/admin/invitelist")
                        }} data-tip={"Find the invited users list"}>
                            <a className='left-align-txt'>Invite List</a>
                        </li>
                    }

                    {manageMenuCondtions &&
                        <h4 style={{ paddingLeft: 10, paddingTop: 10, textAlign: "start", fontWeight: "bold" }}>MANAGE</h4>
                    }


                    {routeAccess.includes("/userCreate") && <>
                        <li className={location === "/admin/userCreate" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/userCreate")
                        }} data-tip={"To create Admin Users"}>
                            <a className='left-align-txt'> Admin Users</a>
                        </li>

                    </>}
                    {
                        routeAccess.includes("/userslist") && <>
                            <li className={location === "/admin/userslist" ? "activeClass li-active" : ""} onClick={() => {
                                navigation.push("/admin/userslist")
                            }} data-tip={"Find the registered users list of umedme app"}>
                                <a className='left-align-txt'>User List</a>
                            </li>
                        </>
                    }


                    {routeAccess.includes("/roletype") && <>
                        <li className={location === "/admin/roletype" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/roletype")
                        }} data-tip={"Admin Role Type"}>
                            <a className='left-align-txt'>Admin Role Type</a>
                        </li>
                    </>}

                    {routeAccess.includes("/pagecreate") && <>
                        <li className={location === "/admin/pagecreate" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/pagecreate")
                        }} data-tip={"Admin Pages"}>
                            <a className='left-align-txt'> Admin Pages</a>
                        </li>

                    </>}
                    {routeAccess.includes("/infra-apps") && <>
                        <li className={location === "/admin/infra-apps" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/infra-apps")
                        }} data-tip={"Infra Apps"}>
                            <a className='left-align-txt'> Infra Apps</a>
                        </li>

                    </>}

                    {uiMenuCondtions &&
                        <h4 style={{ paddingLeft: 10, paddingTop: 10, textAlign: "start", fontWeight: "bold" }}>UI</h4>
                    }
                    {routeAccess.includes("/uilangmaster") && <>
                        <li className={location === "/admin/uilangmaster" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/uilangmaster")
                        }} data-tip={"Language Registry is used to add languages"}>
                            <a className='left-align-txt'>Language Registry</a>
                        </li>

                    </>}
                    {routeAccess.includes("/uilist") && <>
                        <li className={location === "/admin/uilist" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/uilist")
                        }} data-tip={"User can create pages in UI List"}>
                            <a className='left-align-txt'> UI List</a>
                        </li>

                    </>}

                    {routeAccess.includes("/uistructure") && <>
                        <li className={location === "/admin/uistructure" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/uistructure")
                        }} data-tip={"Language Mapping is used to add text contents for pages"}>
                            <a className='left-align-txt'>Language Structure</a>
                        </li>
                    </>}

                    {routeAccess.includes("/uilang") && <>
                        <li className={location === "/admin/uilang" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/uilang")
                        }} data-tip={"Language Mapping is used to add text contents for pages"}>
                            <a className='left-align-txt'>Language Mapping</a>
                        </li>
                    </>}

                    {articleMenuCoditons &&
                        <h4 style={{ paddingLeft: 10, paddingTop: 10, textAlign: "start", fontWeight: "bold" }}>CONTENT</h4>
                    }

                    {routeAccess.includes("/articleusertype") &&
                        < li className={location === "/admin/articleusertype" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/articleusertype")
                        }} data-tip={"Article User Type"}>
                            <a className='left-align-txt'>Article User Type</a>
                        </li>
                    }

                    {routeAccess.includes("/articlecategory") &&
                        < li className={location === "/admin/articlecategory" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/articlecategory")
                        }} data-tip={"Category"}>
                            <a className='left-align-txt'>Category</a>
                        </li>
                    }

                    {routeAccess.includes("/articlesubcategory") &&
                        <li className={location === "/admin/articlesubcategory" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/articlesubcategory")
                        }} data-tip={"Sub Category"}>
                            <a className='left-align-txt'>Sub Category</a>
                        </li>
                    }

                    {routeAccess.includes("/forwho") &&
                        <li className={location === "/admin/forwho" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/forwho")
                        }} data-tip={"For Who?"}>
                            <a className='left-align-txt'>For Who?</a>
                        </li>
                    }

                    {routeAccess.includes("/article") && <li className={location === "/admin/article" ? "activeClass li-active" : ""} onClick={() => {
                        navigation.push("/admin/article")
                    }} data-tip={"Article Manager"}>
                        <a className='left-align-txt'> Article Manager</a>
                    </li>}

                    {routeAccess.includes("/resource-interest") && <li className={location === "/admin/resource-interest" ? "activeClass li-active" : ""} onClick={() => {
                        navigation.push("/admin/resource-interest")
                    }} data-tip={"Resource interest"}>
                        <a className='left-align-txt'> Resource interest</a>
                    </li>}



                    {feedbackRouting && <h4 style={{ paddingLeft: 10, paddingTop: 10, textAlign: "start", fontWeight: "bold" }}>Feedback</h4>}


                    {routeAccess.includes("/issueslist") &&
                        <li className={location === "/admin/feedbacklist" ? "activeClass" : ""} onClick={() => {
                            navigation.push("/admin/feedbacklist")
                        }} data-tip={"We can get the users added feedbacks here"}>
                            <a className='left-align-txt'>User Feedback</a>
                        </li>
                    }

                    {
                        routeAccess.includes("/admin-feedback") &&
                        <li className={location === "/admin/admin-feedback" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/admin-feedback")
                        }} data-tip={"We can get the users added feedbacks here"}>
                            <a className='left-align-txt'> Feedback</a>
                        </li>
                    }

                    {
                        routeAccess.includes("/admin-feedbacklist") &&
                        <li className={location === "/admin/admin-feedbacklist" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/admin-feedbacklist")
                        }} data-tip={"Admin Feedback"}>
                            <a className='left-align-txt'>Admin Feedback</a>
                        </li>
                    }

                    {getRoleType === "TranslatorAdmin" && <>
                        <div style={{ position: "relative" }}>
                            <div style={{ position: "absolute", right: 50, top: 18, cursor: "pointer" }} onClick={() => {
                                this.setState({ random: this.state.random + 1 })
                            }}>
                                <i className="fas fa-sync" ></i>
                            </div>
                            <div className='smartphone'>
                                <div style={{ position: "absolute", }}>
                                    <iframe key={this.state.random} src={MyConfig.umedmeWebAppUrl + iframePageUrl} border={0} style={{ height: 640, width: 320, background: '#fff' }} ></iframe>
                                </div>
                            </div>
                        </div>


                    </>}

                    {(techMenuCheck) &&
                        <h4 style={{ paddingLeft: 10, paddingTop: 10, textAlign: "start", fontWeight: "bold" }}>TECH CONTENT</h4>
                    }

                    {(routeAccess.includes("/create-teacher")) &&
                        < li className={location === "/admin/create-teacher" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/create-teacher")
                        }} data-tip={"Teacher"}>
                            <a className='left-align-txt'>Teacher</a>
                        </li>
                    }
                    {(routeAccess.includes("/create-student")) &&
                        < li className={location === "/admin/create-student" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/create-student")
                        }} data-tip={"Student"}>
                            <a className='left-align-txt'>Student</a>
                        </li>
                    }
                    {(routeAccess.includes("/addresource-category")) &&
                        < li className={location === "/admin/addresource-category" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/addresource-category")
                        }} data-tip={"Resource Category"}>
                            <a className='left-align-txt'>Resource Category</a>
                        </li>
                    }

                    {(routeAccess.includes("/resource-subcategory")) &&
                        < li className={location === "/admin/resource-subcategory" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/resource-subcategory")
                        }} data-tip={"Resource Sub Category"}>
                            <a className='left-align-txt'>Resource Sub Category</a>
                        </li>
                    }

                    {(routeAccess.includes("/add-resource")) &&
                        < li className={location === "/admin/add-resource" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/add-resource")
                        }} data-tip={"Add Resource"}>
                            <a className='left-align-txt'>Resource</a>
                        </li>
                    }

                    {routeAccess.includes("/createworkspace") &&
                        <h4 style={{ paddingLeft: 10, paddingTop: 10, textAlign: "start", fontWeight: "bold" }}>WORKSPACE</h4>
                    }
                    {routeAccess.includes("/createworkspace") &&
                        < li className={location === "/admin/createworkspace" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/createworkspace")
                        }} data-tip={"Users can create workspace here"}>
                            <a className='left-align-txt'>Create</a>
                        </li>
                    }
                    {routeAccess.includes("/continuityofcarelist") &&
                        < li className={location === "/admin/continuityofcarelist" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/continuityofcarelist")
                        }} data-tip={"View the details of continuity of care"}>
                            <a className='left-align-txt'>Continuity Of Care</a>
                        </li>
                    }

                    {
                        (routeAccess.includes("/community-email") || routeAccess.includes("/app-users") || routeAccess.includes("/case-summary") || routeAccess.includes("/case-summary-share")) && <h4 style={{ paddingLeft: 10, paddingTop: 10, textAlign: "start", fontWeight: "bold" }}>Umedme Migration</h4>
                    }

                    {routeAccess.includes("/community-email") &&
                        <li className={location === "/admin/community-email" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/community-email")
                        }} data-tip={"Map the email to community"}>
                            <a className='left-align-txt'>Community Email</a>
                        </li>
                    }
                    {routeAccess.includes("/community") &&
                        <li className={location === "/admin/community" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/community")
                        }}>
                            <a className='left-align-txt'>Community</a>
                        </li>
                    }
                    {routeAccess.includes("/app-users") &&
                        <li className={location === "/admin/app-users" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/app-users")
                        }} data-tip={"Users"}>
                            <a className='left-align-txt'>Users</a>
                        </li>
                    }
                    {
                        routeAccess.includes("/case-summary") &&
                        <li className={location === "/admin/case-summary" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/case-summary")
                        }} data-tip={"Case Summary"}>
                            <a className='left-align-txt'>Case Summary</a>
                        </li>
                    }
                    {
                        routeAccess.includes("/case-summary-share") &&
                        <li className={location === "/admin/case-summary-share" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/case-summary-share")
                        }} data-tip={"Find the Case Summary details "}>
                            <a className='left-align-txt'>Case Summary Share</a>
                        </li>
                    }
                    {
                        routeAccess.includes("/create-community") &&
                        <li className={location === "/admin/create-community" ? "activeClass li-active" : ""} onClick={() => {
                            navigation.push("/admin/create-community")
                        }} data-tip={"Create Community "}>
                            <a className='left-align-txt'>Create Community</a>
                        </li>
                    }
                </ul>
            </nav >
        )
    }
}

export default SideManuBar;