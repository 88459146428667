import React, { Component } from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import SideManuBar from './SideMenuBar';
import image3 from '../images/Teqbahn_logo1.png';
import MyConfig from '../config/MyConfig';
import { toast, ToastContainer } from "react-toastify";
// import ReactJson from 'react-json-view'





class ArticleHierarchyData extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuActive: "",
            getJsonData: {},
            articleHierData: [],
            subcatagoryName: [],
            link: "",
            categoryIndex: "",
            subcategoryIndex: ""
        }
    }
    componentDidMount() {
        this.getArticle_Catagory()

    }

    getArticle_Catagory() {
        let { articleHierData, } = this.state
        let postJson = { auth: "Teqbahn@321#", }
        //auth: String, 
        const request = new ZiFetchRequest();
        request.setDomain("admin");
        request.setEvent("getArticleHierarchyData");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                // console.log("json", json)
                let overalldata = json.result
                if (Object.keys(overalldata).length != 0) {
                    Object.keys(overalldata).map((key) => {
                        var ovarallDataKey = overalldata[key]
                        var catdata = overalldata[key].catData
                        var categoryName = catdata.categoryName
                        var subCatMap = overalldata[key].subCatMap
                        var Link = ""
                        var subcatagoryMapping = []
                        if (Object.keys(subCatMap).length != 0) {
                            Object.keys(subCatMap).map((subCatMapkey) => {
                                var subCatMapkey = subCatMap[subCatMapkey]
                                var subCatData = subCatMapkey.subCatData
                                var subCategoryName = subCatData.subCategoryName
                                var articleData = subCatMapkey.articleData
                                if (Object.keys(articleData).length != 0) {
                                    Object.keys(articleData).map((articleDatakey) => {
                                        var article = articleData[articleDatakey]
                                        var articleDataLink = article.articleData
                                        if (articleDataLink) {
                                            var articleDataLinkTxt = JSON.parse(articleDataLink)
                                            Link = articleDataLinkTxt.link

                                        }
                                    })
                                }
                                subcatagoryMapping.push({ subcatagoryName: subCategoryName, link: Link },)

                            })
                        }
                        articleHierData.push({ catagoryName: categoryName, subcatagoryMapping })
                        this.setState({ articleHierData: articleHierData, })
                    })
                }
            }
        });
    }


    render() {
        let { menuActive, getJsonData, articleHierData, subcatagoryName, link, subcategoryIndex, categoryIndex } = this.state
        import('../admin/style.css');
        let that = this


        return (
            <>
                <div className="wrapper">
                    <ToastContainer />
                    <SideManuBar menuActive={menuActive} typeofPage={"articlehierarchydata"} navigation={this.props.history} />
                    <div id="content">

                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <div className="container-fluid">
                                <button type="button" id="sidebarCollapse" className="btn btn-info" onClick={() => {

                                    if (!menuActive) {
                                        menuActive = "active"
                                    }
                                    else {
                                        menuActive = ""
                                    }

                                    this.setState({ menuActive })
                                }}>
                                    <i className="fas fa-align-left"></i>
                                    <span> {menuActive ? "Expand" : "Hide"} </span>
                                </button>

                                <img className="img-Teqlogo" src={image3} width="250px" height="70" />
                            </div>
                        </nav>
                        <div className="body-content left-rigt-padding">

                            <main className="app-main">
                                <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>
                                    <div className='col-12 col-sm-12 col-md-12'>
                                        <nav class="tabbable">
                                            <ul class="nav nav-tabs" style={{ width: "100%", height: "45px", backgroundColor: "#e54a33", borderRadius: "2px" }}>
                                                {
                                                    articleHierData.map((datas, index) => {
                                                        return (
                                                            <li class="active scroll" style={{ padding: "10px" }}><a data-toggle="tab" href="#home" onClick={() => {
                                                                this.setState({ subcatagoryName: datas.subcatagoryMapping, categoryIndex: index })

                                                            }}><p style={{ color: "#fff", fontSize: "14px", borderBottom: categoryIndex == index ? "5px solid #e9e8e9" : "", fontWeight: "bold" }}>{datas.catagoryName}</p></a></li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </nav>
                                        <div class="tab-content">
                                            <div id="home" class="tab-pane fade in active">
                                                <ul class="nav nav-tabs" style={{ width: "100%", height: "30px", backgroundColor: "#e9e8e9" }}>
                                                    {
                                                        subcatagoryName.map((subcat, j) => {
                                                            return (
                                                                <li style={{ padding: "5px", borderBottom: j == subcategoryIndex ? "5px solid #e54a33" : "" }} onClick={() => {
                                                                    this.setState({ link: subcat.link, subcategoryIndex: j })
                                                                }}><a data-toggle="tab" href="#icona"><p style={{ color: "#e54a33", fontSize: "12px", fontWeight: "bold" }}>{subcat.subcatagoryName}</p></a></li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="tab-content bg" style={{ backgroundColor: "red", }}>
                                            <div class="tab-pane" id="icona" style={{ border: "1px solid #cfc9c9", width: "100%", height: "120px" }}>
                                                <div className='card-body' style={{ backgroundColor: "#fff", height: "80%", width: "98%", marginLeft: "1%", marginTop: "11px", border: "1px solid #ccc9cd", borderRadius: "5px" }}>
                                                    <p>Link:{link}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div >
            </>
        )
    }
}



export default ArticleHierarchyData;