import React, { Component } from 'react';
import image1 from '../images/UMEDME_logo_white.png';
import image2 from '../images/UMEDME_new.png';
import Logo from '../images/umedmeweblogo.png';
import { Link } from "react-router-dom";


class SideManuBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let { menuActive, typeofPage, navigation } = this.props

        let activeUiRoute = ["uilist", "uistructure", "uilang"]
        let activecatagoryRoute = ["addarticlecatagory", "addarticlesubcatagory", "addarticle"]

        return (
            <nav id="sidebar" className={menuActive}  >
                <div className="sidebar-logo">
                    <img className="img-fluid" src={Logo} />
                </div>
                <div className="sidebar-header">
                    <h3 >Admin</h3>
                </div>

                <ul className="list-unstyled components">

                    {/* <li className="active">
                    <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Home</a>
                    <ul className="collapse list-unstyled" id="homeSubmenu">
                        <li>
                            <a href="#">Home 1</a>
                        </li>
                        <li>
                            <a href="#">Home 2</a>
                        </li>
                        <li>
                            <a href="#">Home 3</a>
                        </li>
                    </ul>
                </li> */}

                    {/* <li className={typeofPage === "reviewlist" ? "activeClass" : ""} onClick={() => {
                        navigation.push("/admin/reviewlist")
                    }}>
                        <a>Review List</a>
                    </li> */}
                    <li className={typeofPage === "medicationsearch" ? "activeClass" : ""} onClick={() => {
                        navigation.push("/admin/medication")
                    }}>
                        <a>Medication Search</a>
                    </li>
                    <li className={typeofPage === "foodsearch" ? "activeClass" : ""} onClick={() => {
                        navigation.push("/admin/food-search")
                    }}>
                        <a>Food Search</a>
                    </li>
                    <li className={typeofPage === "doctorlist" ? "activeClass" : ""} onClick={() => {
                        navigation.push("/admin/doctorlist")
                    }}>
                        <a>Doctors List</a>
                    </li>
                    <li className={typeofPage === "doctorsearch" ? "activeClass" : ""} onClick={() => {
                        navigation.push("/admin/doctorsearch")
                    }}>
                        <a>Doctors Search</a>
                    </li>
                    {/* <li className={typeofPage === "userlist" ? "activeClass" : ""} onClick={() => {
                        navigation.push("/admin/userslist")
                    }}>
                        <a>User List</a>
                    </li>
                </li> */}
                    <li className={typeofPage === "dashboard" ? "activeClass" : ""} onClick={() => {
                        navigation.push("/admin/dashboard")
                    }}>
                        <a>Dashboard</a>
                    </li>
                    <li className={typeofPage === "issueslist" ? "activeClass" : ""} onClick={() => {
                        navigation.push("/admin/feedbacklist")
                    }}>
                        <a>Feedback List</a>
                    </li>
                    <li className={typeofPage === "invitelist" ? "activeClass" : ""} onClick={() => {
                        navigation.push("/admin/invitelist")
                    }}>
                        <a>Invite List</a>
                    </li>
                    <li className={typeofPage === "workspace" ? "activeClass" : ""} onClick={() => {
                        navigation.push("/admin/workspace")
                    }}>
                        <a>Document Workspace</a>
                    </li>
                    <li className={typeofPage === "userCreate" ? "activeClass" : ""} onClick={() => {
                        navigation.push("/admin/userCreate")
                    }}>
                        <a>Manage Admin User</a>
                    </li>

                    <li className={""}>
                        <a href="#uiSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">UI</a>
                        <ul className={activeUiRoute.includes(typeofPage) ? "collapse list-unstyled show" : " collapse list-unstyled "} id="uiSubmenu">
                            <li>
                                <Link className={typeofPage === "uilist" ? "activeClass font-weigh-bold" : "submenufontColor"} to={"/admin/uilist"}>UI List</Link>
                            </li>
                            <li >
                                <Link className={typeofPage === "uistructure" ? "activeClass font-weigh-bold" : "submenufontColor"} to={"/admin/uistructure"}>Language Structure</Link>
                            </li>
                            <li>
                                <Link className={typeofPage === "uilang" ? "activeClass font-weigh-bold" : "submenufontColor"} to={"/admin/uilang"}>Language Mapping</Link>
                            </li>
                        </ul>
                    </li>

                    {/* <li className={""}>
                        <a href="#catSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Content</a>
                        <ul className={activecatagoryRoute.includes(typeofPage) ? "collapse list-unstyled show" : " collapse list-unstyled "} id="catSubmenu">
                            <li>
                                <Link className={typeofPage === "addarticlecatagory" ? "activeClass font-weigh-bold" : "submenufontColor"} to={"/admin/addarticlecategory"}>Category</Link>
                            </li>
                            <li >
                                <Link className={typeofPage === "addarticlesubcatagory" ? "activeClass font-weigh-bold" : "submenufontColor"} to={"/admin/addarticlesubcategory"}>Sub Category</Link>
                            </li>
                            <li>
                                <Link className={typeofPage === "addarticle" ? "activeClass font-weigh-bold" : "submenufontColor"} to={"/admin/addarticle"}>Article Manager</Link>
                            </li>
                        </ul>
                    </li> */}


                    {/* <li onClick={() => {
                        // window.location.href = "/admin/login"
                        localStorage.removeItem("logedin");
                        navigation.push("/admin/login")
                    }}>
                        <a >Logout</a>
                    </li> */}
                </ul>

            </nav>
        )
    }
}

export default SideManuBar;