import React, { Component } from 'react';
import Footer from '../common/Footer';
import Header from '../common/Header';
import image1 from '../images/umedme-1.png';
import image2 from '../images/umedme-2.png';
import image3 from '../images/umedme-3.png';
import image4 from '../images/umedme-4.jpg';
import image5 from '../images/umedme-5.jpg';
import image6 from '../images/umedme-6.jpg';
import image7 from '../images/umedme-7.png';
import image8 from '../images/umedme-8.png';
import home_1 from '../images/home-1.png';
import home_2 from '../images/home-2.png';
import home_3 from '../images/home-3.png';
import home_4 from '../images/home-4.png';
import home_7 from '../images/home-7.png';

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="top-body">
                <Header page="home" />
                <main>
                    <div className="container px-0">
                        <div className="row my-5">
                            <div className="col-sm-12 text-center">
                                <p className="it-s-all">It's all about me</p>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-sm-12 text-center">
                                <img className="img-fluid" src={image1} />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-sm-12 text-center p-text">
                                <p>We are inspired by women and their role in the family.</p>
                                <p>Mothers, sisters, daughters, wives and partners who are working to achieve optimal health and wellness for themselves and their families.</p>
                                <br />
                                <p>We celebrate their leadership by delivering privacy, security, and easy access to health information.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-12 text-center">
                            <table id="u-med-me-mean">
                                <tr>
                                    <td colspan="3">
                                        <img className="img-fluid" src={home_1} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img className="img-fluid" src={home_2} />
                                    </td>
                                    <td>
                                        <img className="img-fluid" src={home_3} />
                                    </td>
                                    <td>
                                        <img className="img-fluid" src={home_4} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                        <img className="img-fluid" src={home_7} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-12 px-0 grey-bg">
                            <div className="care-givers text-right px-5 pt-5">
                                <p className="">
                                    Close to 70% of caregivers in the <br />
                                    United States are women.
                                </p>
                            </div>
                            <div>
                                <img className="umedme-3" src={image3} />
                            </div>
                        </div>
                    </div>
                    <div className="row vertical-center">
                        <div className="col-sm-12 text-center p-text">
                            Women<br/>
                            Bear greater responsibility for their families<br/>
                            Face structural inequities in healthcare<br/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-12 px-0 grey-bg">
                            <div>
                                <img className="umedme-4" src={image4} />
                            </div>
                            <div className="more-than px-5 pb-5 navbar-container">
                                <p className="">
                                More than 25 million<br/>
                                women - provide care to family<br/>
                                members or friends with a chronic or disabling condition.<br/>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row my-5 v-center">
                        <div className="col-sm-5 text-center">
                                <img className="rounded-circle umedme-5" src={image5} /> 
                        </div>
                        <div className="col-sm-7 we-are-hurtling">
                            <p className="">
                            We are hurtling towards an inflection point<br/>
                            with the population aging and people living<br/>
                            longer. Caregiving responsibilities are increasing<br/>
                            rapidly and family caregivers are often compelled<br/>
                            to do complex tasks that would typically be done<br/>
                            by a healthcare professional in a formal setting.
                            </p>
                        </div>
                    </div>
                    <div className="row my-5 v-center">
                        <div className="col-sm-7 in-august-2020 text-right">
                        <p className="">“In August 2020 my father suffered a heart attack and<br/>
                        underwent quadruple bypass surgery. He is also<br/>
                        diabetic and hypertensive. I needed to know how to<br/>
                        assess his surgical wound, understand his lab<br/>
                        results, make sure he followed restrictions, monitor<br/>
                        his vital signs, understand all the medications<br/>
                        he was on, make sure he took his medications<br/>
                        correctly, and followed his diet!!”</p>
                        <p><b>Kathleen A.</b> MSN, RN, ONC, CMSRN</p>
                        </div>
                        <div className="col-sm-5 text-center">
                                <img className="rounded-circle umedme-5" src={image6} /> 
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-sm-12 px-0">
                            <img className="umedme-7" src={image7} />
                        </div>
                    </div>
                    <div className="row my-5">
                        <div className="col-sm-12 text-center p-text">
                            <p>
                                Umed.Me is a medical, health, and wellness app that secures <br/>
                                personal health information and puts it in the palm of your hands. <br/> <br/> 
                                Umed.Me uses multi-level encryption and HIPPA guidelines <br/>
                                to protect your health data and ensure that only you access it.
                            </p>
                        </div>
                    </div>
                    <div className="row grey-bg">
                        <div className="col-sm-12 px-0">
                            <div className="">
                                <img className="umedme-8" src={image8} />
                            </div>
                        </div>
                    </div>
                    <div class="row vertical-center-home-launch grey-bg">
                        <div class="col-sm-12 text-center red-text">
                                <b>Umed.Me Beta App</b><br/>
                                Coming Soon
                        </div>
                    </div>
                </main>
                <Footer />
                </div>
            </React.Fragment>
        )
    }
}