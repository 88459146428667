import React from 'react';
import Select from 'react-select';


export default (props) => {

  const customStyles = (hasError) => ({
    control: (styles) => ({
      ...styles,
      background: props.background !== undefined ? props.background : "#fff",
      ...(hasError && { borderColor: 'red' }),
    }),
    menu: (base) => ({
      ...base,
      background: "#FFF",
      color: "#000"
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  });

  return (
    <Select
      styles={customStyles(props.error ? props.error : false)}
      value={props.value ? props.value : props.selectedOption}
      onChange={props.onChange}
      options={props.options}
      isDisabled={props.isDisabled ? true : false}
      isMulti={props.isMulti ? props.isMulti : false}
      placeholder={props.placeholder}
      menuPortalTarget={document.body} 
      {...props}
    />
  )
}
