import React, { Component } from 'react';
import SideManuBar from '../admin/SideMenuBar';
import image3 from '../images/Teqbahn_logo1.png';
import Dropzone from 'react-dropzone';
import MyConstant from '../config/MyConstant';
import { v4 as uuid } from 'uuid';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import { loginStatusCheck, checkLogin } from '../config/MyFunctions';
import AdminLogout from "../admin/AdminLogout"
import ManageSideMenu from '../manage/ManageSideMenu';
import ManageLogout from "../manage/ManageLogout"








class UploadBatchMultipleFile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            successMsg: false,
            viewMode: "upload",
            documentFiles: [],
            ruleId: "",
            basedocsId: "",
            document_Json: [],
            selectTypeVlaue: "",
        }
    }
    backPage() {
        this.props.history.push(MyConstant.keyList.projectUrl + "/admin/rules-list/" + this.state.basedocsId)
    }

    async componentDidMount() {

        let login_status = await checkLogin(this.props)
        var docsId = this.props.match.params['docsId'];
        var ruleId = this.props.match.params['ruleId'];
        this.setState({ basedocsId: docsId, ruleId: ruleId })

    }

    async documentOnDrop(newFiles) {
        var documentFiles = this.state.documentFiles;
        var document_Json = this.state.document_Json;

        for (var i = 0; i < newFiles.length; i++) {
            documentFiles.push(newFiles[i]);
            document_Json.push({
                name: this.state.selectTypeVlaue,
                path: newFiles[i],
                status: 'active',
                progress: '0'
            })
        }
        this.setState({
            documentFiles: documentFiles, document_Json: document_Json
        });
    }

    async documentOnRemove(loopIndex) {
        var document_Json = this.state.document_Json;
        var documentFiles = this.state.documentFiles;
        document_Json.splice(loopIndex, 1);
        documentFiles.splice(loopIndex, 1);
        this.setState({
            document_Json: document_Json, documentFiles: documentFiles
        });

    }
    async submitDocuments(alertKey) {
        var that = this;
        this.setState({ viewMode: "progress" })
        var documentFiles = this.state.documentFiles;
        var docsId = this.props.match.params['docsId'];
        var ruleId = this.props.match.params['ruleId'];
        for (var i = 0; i < documentFiles.length; i++) {
            await that.sendPostJson(documentFiles[i], i)
            if (parseInt(i) + 1 == documentFiles.length) {
                this.setState({ successMsg: true, document_Json: [], documentFiles: [] })
                setTimeout(() => this.props.history.push(MyConstant.keyList.projectUrl + "/admin/viewuploadmultipletestfile/" + docsId + "/" + ruleId), 3000)
            }
        }
    }

    async sendPostJson(documentArray, i) {
        var uid = uuid();
        var that = this;
        var document_Json = this.state.document_Json;
        document_Json[i].progress = 50;
        that.setState({ document_Json: document_Json })
        //console.log(JSON.stringify(document_Json))      
        var documentArrayString = [];
        var newDocumentArrayString = [];
        var file = documentArray;
        var fileName = file.name;
        var path_splitted = fileName.split('.');
        var extension = path_splitted.pop();
        var getUidString = uid + "." + extension;
        documentArrayString.push(getUidString)
        newDocumentArrayString.push({
            name: document_Json[i]['name'],
            path: getUidString,
            status: document_Json[i]['status']
        })

        if (document_Json.length > 0) {
            var uploadDocumentFiles = await that.uploadDocumentFiles(documentArrayString, documentArray, i, uid);
            document_Json[i].progress = 100;
            that.setState({ document_Json: document_Json })

        }

    }

    async uploadDocumentFiles(documentArrayString, documentArray, i, uid) {

        var that = this
        var userId = "admin";
        var sessionId = "1234";
        var ruleId = this.props.match.params['ruleId'];
        var baseDocsId = this.props.match.params['docsId'];
        var files = this.state.documentFiles;
        const data = new FormData();
        var processedRuleJson = this.state.processedRuleJson;
        var projectName = MyConstant.keyList.projectName;
        data.append('projectName', projectName);
        data.append('uploadingFileType', "pdf");
        var file = documentArray;
        var fileName = file.name;
        var path_splitted = fileName.split('.');
        var extension = path_splitted.pop();
        data.append('file' + (i + 1), files[i]);
        data.append('fileName' + (i + 1), fileName);
        data.append('docsTitle', this.state.docsTitle);
        data.append('userId', userId);
        data.append('sessionId', sessionId);
        data.append('processType', "testDoc");
        data.append('ruleId', ruleId);
        data.append('baseDocsId', baseDocsId);
        data.append('namesArrayString', JSON.stringify(documentArrayString));
        console.log("this data", [...data])
        // var resData = await doFileConnectS3("/doconoai/uploads", "POST", data);
        // return JSON.stringify(documentArrayString);
        let dirPath = "testDoc"
        var subPath = "admin"
        var res = await fetch(MyConstant.keyList.apiURL + "uploadDocs/" + dirPath + "/" + uid + "/" + subPath, {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: data,
        }).then((response) => response.json())
            .then((responseData) => {
                return responseData.response;
            })
            .catch(error => console.warn(error));

        console.log("res", res)

        if (res == "Success") {
            // sessionId:String,userId: String,baseDocsId:String, docsName: String,docsId:String,documentParserName:String,noOfPages:String,rulesId:String
            let postJson = { sessionId: sessionId, userId: userId, baseDocsId: baseDocsId, docsName: fileName, docsId: uid, documentParserName: this.state.docsTitle, noOfPages: "", rulesId: ruleId }
            console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain('admin');
            request.setEvent('multipleTestDocumentParser');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.log("response", response)
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    console.log("response", responseMsg)
                }
            });

        }

    }

    render() {
        let { menuActive, } = this.state
        import('../admin/style.css');
        var that = this

        var manageIsLogin = localStorage.getItem("managelogedin")
        let returnMenu = <SideManuBar menuActive={menuActive} typeofPage={"workspace"} navigation={this.props.history} />
        let returnLogout = <AdminLogout navigation={this.props.history} />
        if (manageIsLogin == "manage") {
            returnMenu = <ManageSideMenu menuActive={menuActive} typeofPage={"workspace"} navigation={this.props.history} grpcClient={this.props.grpcClient} />
            returnLogout = <ManageLogout navigation={this.props.history} />
        }

        return (
            <>
                <div className={`wrapper ${manageIsLogin ? "manageBgColor" : ""}`}>
                    {returnMenu}
                    <div id="content">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <div className="container-fluid">

                                <button type="button" id="sidebarCollapse" className="btn btn-info" onClick={() => {

                                    if (!menuActive) {
                                        menuActive = "active"
                                    }
                                    else {
                                        menuActive = ""
                                    }

                                    this.setState({ menuActive })
                                }}>
                                    <i className="fas fa-align-left"></i>
                                    <span> {menuActive ? "Expand" : "Hide"} </span>
                                </button>
                                {returnLogout}
                            </div>
                        </nav>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="col-sm-2">
                                        <button className={"btn btn-primary btn-primary btn-bg-styl"} onClick={(e) => that.backPage()}>BACK</button>
                                    </div>
                                    <div className={"col-lg-12"}>
                                        <br />
                                    </div>
                                    {
                                        that.state.successMsg ?
                                            <div className="col-sm-12" style={{ textAlign: "center" }}>
                                                <h5 className="success"> Document Upload Successfully Processing your document!...</h5>
                                            </div>
                                            : null
                                    }
                                    {
                                        that.state.viewMode == "upload" ?
                                            <div>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-sm-3"></div>
                                                        <div className="col-sm-2">
                                                            <label class="multibatchlablestyle">Batch Name</label>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <input type="text" className="hs-input"
                                                                value={that.state.docsTitle}
                                                                onChange={(e) => this.setState({ docsTitle: e.target.value })} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-sm-3"></div>
                                                        <div className="col-sm-2">
                                                            <label class="multibatchlablestyle">Upload Batch Documents</label>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <Dropzone accept=".pdf" multiple={true}
                                                                onDrop={acceptedFiles => that.documentOnDrop(acceptedFiles)}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section>
                                                                        <div {...getRootProps({ className: 'dropzone' })}>
                                                                            <input {...getInputProps()} />
                                                                            <p>Try dropping some files here, or click to
                                                                                select files to upload.</p>
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }
                                    <div className="row">
                                        {
                                            this.state.documentFiles.map((f, loopIndex) =>
                                                <div className="col-sm-6" key={loopIndex}>

                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <span key={loopIndex}>
                                                                <img src="https://cdn1.iconfinder.com/data/icons/adobe-acrobat-pdf/154/adobe-acrobat-pdf-file-512.png" alt="pdf" width={50} height={50} />
                                                            </span>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <div className="progress" key={loopIndex}>
                                                                <div className="progress-bar bg-danger wow animated progress-animated"
                                                                    style={{ width: that.state.document_Json[loopIndex].progress + "%", height: 10 }} role="progressbar"></div>
                                                            </div>
                                                            {f.name}
                                                        </div>
                                                        <div className="col-sm-1">
                                                            <a href="javascript:void(0)" style={{ color: 'red', float: 'right', padding: 5 }} onClick={() => that.documentOnRemove(loopIndex)}>X</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="form-group">
                                        {
                                            this.state.documentFiles.length > 0 ?
                                                <div className="col-sm-12 text-center" >
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={() => that.submitDocuments(true)}>Submit
                                                    </button>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default UploadBatchMultipleFile;