import React from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import image3 from '../images/Teqbahn_logo1.png';
import MyConfig from '../config/MyConfig';
import { dateConverter } from '../config/MyFunctions';
import { toast, } from "react-toastify";
import { pageNumbers, checkLogin, readSessionData, resetLocalData } from '../config/MyFunctions';
import Pagination from "./Pagination"
import whiteLogo from '../images/whiteLogo.png';
import Select from './component/Select';
import MyConstant from '../config/MyConstant';
import { v4 as uuidv4 } from 'uuid';


class AddResourceSubCategory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuActive: "",
            action: "Add",
            subCategoryName: "",
            subCategoryNameValidate: "",
            processing: false,
            prev: false,
            next: false,
            noPagesLimit: 1,
            pageLimit: 10,
            subCatagoryJson: {},
            pagination: [],
            visibleClass: "",
            totalResultsCount: 0,
            mUserId: "",
            session: "",
            displayShow: true,
            articleUserTypeOptions: [],
            selectArticleType: "",
            workSpaceOptions: [],
            workSpaceSelect: "",
            documentFiles: [],
            document_Json: [],
            editImageName: "",
            catagoryOptions: [],
            catagorySelect: "",
            errors: {}
        }
        this.myRef = React.createRef()
    }
    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        if (login_status) {
            document.body.style.backgroundColor = "#f6f7fb";
            let resData = await readSessionData()
            let { manage_session, manage_mUserId } = resData
            this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
                this.getResourceSubCatagoryListAPI()
                this.getWorkSpaceList()
            })
        }

    }

    getCatagoryBasedOnWorkSpace() {
        let { mUserId, workSpaceSelect, session } = this.state
        let postJson = { auth: MyConfig.authKey, mUserId, session, workspaceId: workSpaceSelect }
        //auth: String, 
        const request = new ZiFetchRequest();
        request.setDomain("resource");
        request.setEvent("getResourceCategoryListBasedOnWorkspace");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                // console.log(json)
                let response_Msg = json.response
                if (response_Msg == "Success") {
                    let responseData = json.result
                    let catagoryOptions = [];
                    if (Object.keys(responseData).length > 0) {
                        Object.keys(responseData).map(key => {
                            let categoryName = responseData[key].categoryName
                            catagoryOptions.push({ label: categoryName, value: key })
                        })
                    }
                    this.setState({
                        catagoryOptions
                    });
                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }

    getResourceSubCatagoryListAPI() {
        let { noPagesLimit, pageLimit, mUserId, session } = this.state
        let postJson = { auth: MyConfig.authKey, pageLimit: pageLimit, noOfPage: noPagesLimit, mUserId, session }
        //auth: String, pageLimit: Int, noOfPage:Int       
        const request = new ZiFetchRequest();
        request.setDomain("resource");
        request.setEvent("getResourceSubCategoryList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response == "Success") {
                    //  console.log(json.result)
                    let next = false;
                    let prev = false;
                    let totalResult = json.totalResult
                    let currentResult = noPagesLimit * 10;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noPagesLimit > 1) {
                        prev = true;
                    }

                    let pageCount = Math.ceil(totalResult / 10);
                    let pagination = pageNumbers(pageCount, noPagesLimit, 5);
                    this.setState({
                        prev,
                        next,
                        pagination: pagination,
                        processing: false,
                        subCatagoryJson: json.result,
                        totalResultsCount: totalResult
                    });
                }
                else if (json.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }

    async submit() {

        let { subCategoryName, mUserId, session, workSpaceSelect, document_Json, catagorySelect } = this.state
        let errors = {};
        if (!subCategoryName) {
            errors.subCategoryName = "Enter subcategory Name"
        }
        if (document_Json.length === 0) {
            errors.resourceCategoryPhotoValidate = "Please choose file"
        }
        if (workSpaceSelect === "") {
            errors.workSpaceSelect = "Please choose workspace"
        }
        if (catagorySelect === "") {
            errors.catagoryName = "Enter category Name"
        }

        this.setState({ errors })
        if (Object.keys(errors).length > 0) {
            return false
        }

        let fileList = { fileName: "" };
        for (var i = 0; i < document_Json.length; i++) {
            await this.sendPostJson(document_Json[i], i);
            let fileDetails = document_Json[i];
            fileList = {
                origFileName: fileDetails.origFileName,
                fileName: fileDetails.fileName,
                docsId: fileDetails.docsId,
                processType: fileDetails.processType,
                fileType: fileDetails.fileType
            };

        }

        let postJson = { workspaceId: workSpaceSelect, catId: catagorySelect, subCategoryName, photo: fileList.fileName, mUserId, session, };

        console.log("postJson", postJson)
        //subCategoryName: String, workspaceId: String, photo:String,mUserId: String, session: String
        const request = new ZiFetchRequest();
        request.setDomain('resource');
        request.setEvent('createResourceSubCategory');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Resource Sub Category Added successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({
                        documentFiles: [],
                        document_Json: [],
                        workSpaceSelect: "",
                        errors: {},
                        catagorySelect: "",
                        subCategoryName: ""
                    }, () => {
                        this.getResourceSubCatagoryListAPI()
                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }

    async Update() {
        let { subCategoryName, mUserId, session, document_Json, editImageName, catagorySelect, workSpaceSelect } = this.state

        let errors = {};
        if (!subCategoryName) {
            errors.subCategoryName = "Enter Sub Category Name"
        }
        if (document_Json.length === 0) {
            errors.resourceCategoryPhotoValidate = "Please choose file"
        }
        if (workSpaceSelect === "") {
            errors.workSpaceSelect = "Please choose workspace"
        }
        if (catagorySelect === "") {
            errors.catagoryName = "Enter Category Name"
        }
        this.setState({ errors })

        if (Object.keys(errors).length > 0) {
            return false
        }
        let fileList = { fileName: editImageName };
        for (var i = 0; i < document_Json.length; i++) {
            if (document_Json[i].path) {
                await this.sendPostJson(document_Json[i], i);
                let fileDetails = document_Json[i];
                fileList = {
                    origFileName: fileDetails.origFileName,
                    fileName: fileDetails.fileName,
                    docsId: fileDetails.docsId,
                    processType: fileDetails.processType,
                    fileType: fileDetails.fileType
                };
            }

        }

        let postJson = {
            id: this.state.id, photo: fileList.fileName,
            subCategoryName: subCategoryName,
            mUserId, session
        };
        console.log("postJson", postJson)

        //id: String, subCategoryName: String,photo:String, mUserId: String, session: String
        const request = new ZiFetchRequest();
        request.setDomain('resource');
        request.setEvent('updateResourceSubCategory');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Resource Sub Category Updated successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({
                        action: "Add",
                        documentFiles: [],
                        document_Json: [],
                        workSpaceSelect: "",
                        errors: {},
                        catagorySelect: "",
                        subCategoryName: ""
                    }, () => {
                        this.getResourceSubCatagoryListAPI()
                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });

    }
    async edit(data) {
        console.log(data)
        this.scrollToTop()
        let { workspaceId, photo } = data
        let split = photo.split(".");
        let documentFiles = [];
        let document_Json = [];
        documentFiles.push({ processType: "resources", fileType: split[1], docsId: split[0] })
        document_Json.push({ processType: "resources", fileType: split[1], docsId: split[0] })
        this.setState({
            action: "Edit", id: data["id"], catagorySelect: data["catId"],
            subCategoryName: data["subCategoryName"], visibleClass: "bgClrChg", displayShow: false, workSpaceSelect: workspaceId, editImageName: photo, documentFiles, document_Json
        })
        setTimeout(() => {
            this.setState({ visibleClass: "" })
        }, 1000);
    }

    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }
        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getResourceSubCatagoryListAPI()
            })
        }
    }

    delete(data) {
        let { mUserId, session } = this.state

        let confirmMessage = window.confirm("Are You Sure Delete Sub Catagory?")
        //id: String
        let postJson = {
            id: data["id"],
            mUserId, session,
            catId: data["catId"],
            subCatId: data["id"],
            workspaceId: data["workspaceId"]
        };

        if (confirmMessage) {
            console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain('resource');
            request.setEvent('deleteResourceSubCategory');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.log("response", response)
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    // console.log(responseMsg)
                    if (responseMsg.response == "Success") {
                        toast.success("Resource Sub Category Data Deleted.", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        this.getResourceSubCatagoryListAPI()
                    }
                    else if (responseMsg.response == "exist") {
                        toast.error("Please remove under the sub catagory mapping !", {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    else if (responseMsg.response == "Invalid Session Request") {
                        toast.warning("Your session got expired!", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        resetLocalData(this.props)
                    }
                }
            });
        }

    }

    scrollToTop() {
        this.myRef.current.scrollTo(0, 0);
    }
    getWorkSpaceList() {
        let postJson = { auth: MyConfig.authKey }
        //auth: String, pageLimit: Int, noOfPage:Int       
        const request = new ZiFetchRequest();
        request.setDomain("workspace");
        request.setEvent("getWorkspaceList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.result && Object.keys(json.result).length != 0) {
                    let workSpaceOptions = [];
                    Object.keys(json.result).map((e) => {
                        let { name } = json.result[e]
                        workSpaceOptions.push({ label: name, value: e })
                    })
                    this.setState({
                        workSpaceOptions,
                    });
                }
                else if (json.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }

    documentOnDrop(newFiles) {
        var documentFiles = [];
        var document_Json = [];

        let file_Name = ""
        for (var i = 0; i < newFiles.length; i++) {
            let file = newFiles[i];
            let filename = file.name;
            let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
            let uid = uuidv4();

            documentFiles.push(newFiles[i]);
            document_Json.push({
                name: this.state.selectTypeVlaue,
                path: newFiles[i],
                status: 'active',
                progress: '0',
                origFileName: filename,
                fileName: uid + "." + ext,
                docsId: uid,
                processType: "resources",
                fileType: ext
            })
            file_Name = uid + "." + ext
        }

        this.setState({
            documentFiles: documentFiles,
            document_Json: document_Json,
            user_profilePhoto: file_Name
        });
    }



    documentOnRemove(loopIndex) {
        var document_Json = this.state.document_Json;
        var documentFiles = this.state.documentFiles;
        document_Json.splice(loopIndex, 1);
        documentFiles.splice(loopIndex, 1);
        this.setState({
            document_Json: document_Json, documentFiles: documentFiles,
            user_profilePhoto: ""
        });

    }


    async sendPostJson(documentArray, i) {
        var that = this;
        var document_Json = this.state.document_Json;
        document_Json[i].progress = 50;
        that.setState({
            document_Json: document_Json
        })
        if (document_Json.length > 0) {
            if (documentArray.path) {
                await that.uploadDocumentFiles(documentArray, i);
            }
            document_Json[i].progress = 100;
            that.setState({
                document_Json: document_Json
            })
        }
    }

    async uploadDocumentFiles(documentArray, i) {
        const postFileUpload = new FormData();
        postFileUpload.append('file' + (i + 1), documentArray.path);
        postFileUpload.append('fileName' + (i + 1), documentArray.fileName);
        postFileUpload.append('processType' + (i + 1), documentArray.processType);
        postFileUpload.append('docsId' + (i + 1), documentArray.docsId);
        let result = await fetch(MyConstant.keyList.apiURL + "uploads/" + documentArray.processType + "/" + documentArray.docsId + "/resourceSubCategory", {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        }).then(
            function (response) {
                return response.json();
            }
        ).then(json => {
            return json.response;
        }).catch(error => console.warn(error));

        return result
    }

    render() {
        let { subCatagoryJson, subCategoryName, pagination, next, prev, noPagesLimit, visibleClass, displayShow, workSpaceSelect, workSpaceOptions, documentFiles, catagorySelect, catagoryOptions, errors } = this.state
        import('../admin/style.css');
        let that = this
        var manageIsLogin = localStorage.getItem("managelogedin")

        let compnayLogo = image3
        if (manageIsLogin) {
            compnayLogo = whiteLogo
        }


        let tableData = []
        Object.keys(subCatagoryJson).forEach(function (key, index) {
            let fullData = subCatagoryJson[key]
            let info = fullData.info
            let photo = fullData.photo;
            let imageId = photo.split(".")[0];
            let ext = photo.split(".")[1];

            let buildUrl = MyConstant.keyList.apiURL + "vp?action=resources&key=" + imageId + "." + ext + "&id=resourceSubCategory"

            tableData.push(
                <tr>
                    <td style={{ width: "10%" }} className='tabletxt' >{fullData.workspaceName}</td>
                    <td style={{ width: "10%" }} className='tabletxt'>{fullData['categoryName']}</td>
                    <td style={{ width: "10%" }} className='tabletxt'>{fullData['subCategoryName']}</td>
                    <td style={{ width: "10%" }} className='tabletxt' ><img src={buildUrl} alt="no img" style={{ width: 80, height: 80, }} /></td>
                    <td style={{ width: "10%" }} className='tabletxt'>
                        {
                            info != null ? <p>{info.mUserName} - {info.email}</p> : null
                        }
                    </td>
                    <td style={{ width: "10%" }} className='tabletxt' >{dateConverter(fullData['createdAt'])}</td>
                    <td style={{ width: "10%" }} className='fofamily' ><div className={" editBtnstyle"} onClick={() => that.edit(fullData)}>Edit</div></td>
                    <td style={{ width: "10%" }} className='fofamily'><div className={"deleteBtnstyle"} onClick={() => that.delete(fullData)}>Delete</div></td>
                </tr>
            )
        });
        return (
            <div className="body-content left-rigt-padding cat-style" ref={this.myRef}>
                <main className="app-main">
                    <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>

                        <div className="container">
                            <div className={(visibleClass ? visibleClass : "") + " card box-shadow"} style={{ padding: 0 }}>
                                <div className="card-header border-btm-style cursor-style" onClick={() => {
                                    this.setState({ displayShow: !displayShow })
                                }}>
                                    <div className='row'>
                                        <div className='col-10 col-sm-11 col-md-11'>
                                            <h5 className='header-style' >{this.state.action} Resource Sub Category</h5>
                                        </div>
                                        <div className='col-2 col-sm-1 col-md-1'>
                                            <span style={{ float: "right", color: "#fff", }}>
                                                {displayShow ? <i class="fa fa-plus"></i> : <i class="fa fa-minus"></i>}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body" style={displayShow ? { display: "none" } : {}}>

                                    <div className="row my-2">
                                        <div className="col-md-3">
                                            <label className='fw'>Workspace</label>
                                        </div>
                                        <br />
                                        <div className="col-md-6">
                                            <Select
                                                options={workSpaceOptions}
                                                isSearchable={true}
                                                placeholder="Select WorkSpace"
                                                onChange={(e) => {
                                                    this.setState({ workSpaceSelect: e.value }, () => {
                                                        this.getCatagoryBasedOnWorkSpace()
                                                    })
                                                }}
                                                value={workSpaceOptions.filter((e => { return e.value === workSpaceSelect }))}
                                                isDisabled={this.state.action == "Add" ? false : true}
                                            />
                                            <span className='error-txt'>{errors.workSpaceSelect} </span>
                                        </div>
                                    </div>
                                    <br />

                                    <div className="row my-2">
                                        <div className="col-md-3">
                                            <label className='fw'>Category Name</label>
                                        </div>
                                        <br />
                                        <div className="col-md-6">
                                            <Select
                                                options={catagoryOptions}
                                                isSearchable={true}
                                                placeholder="Select WorkSpace"
                                                onChange={(e) => {
                                                    this.setState({ catagorySelect: e.value })
                                                }}
                                                value={catagoryOptions.filter((e => { return e.value === catagorySelect }))}
                                                isDisabled={this.state.action == "Add" ? false : true}
                                            />
                                            <span className='error-txt'>{errors.catagoryName} </span>
                                        </div>
                                    </div>
                                    <br />

                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Sub Category Name</label></div>
                                        <div className={"col-md-6"}>
                                            <input type="text" placeholder={"Enter Category "} className={"form-control inputRadius"} name={"label"} value={subCategoryName} onChange={e => {
                                                this.setState({ subCategoryName: e.target.value })
                                            }} />
                                            <span className='error-txt'>{errors.subCategoryName} </span>
                                        </div>
                                    </div>
                                    <br />

                                    <div className={"row mt-2"}>
                                        <div className={"col-md-3"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Image</label></div>
                                        <div className={"col-md-6"}>

                                            {
                                                this.state.action !== "view" && <div className={"col-md-4"} style={{}}>
                                                    <div className="image-upload-div inputRadius">
                                                        <span style={{ padding: 5 }}>Upload</span>
                                                        <input type="file" placeholder={"Upload"} className={"form-control inputRadius"} onChange={(event) => {
                                                            var files = event.target.files;
                                                            this.documentOnDrop(files)
                                                        }} />

                                                    </div>
                                                    <br />
                                                    <span className='error-txt'>{errors.resourceCategoryPhotoValidate} </span>
                                                </div>
                                            }

                                            <div className='row my-2'>
                                                {
                                                    documentFiles.map((f, loopIndex) => {
                                                        let filePath = ""
                                                        let fileName = ""
                                                        let fileType = ""
                                                        if (f instanceof File) {
                                                            filePath = URL.createObjectURL(f)
                                                            fileName = f.name
                                                            fileType = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
                                                        } else {
                                                            let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + f.processType + "&key=" + f.docsId + "." + f.fileType + "&id=resourceSubCategory"
                                                            filePath = buildUrl
                                                            fileType = f.fileType
                                                        }


                                                        let returnData = <img src={filePath} alt="no img" style={{
                                                            width: 100, height: 100,
                                                            // cursor: "pointer" 
                                                        }}
                                                        // onClick={() => {
                                                        //     let imagePreview = { filePath, fileType }
                                                        //     this.setState({ imagePreview, modalVisible: true })
                                                        // }}
                                                        />

                                                        return (<>
                                                            <div className='col-sm-4 col-md-3 file-box'>
                                                                {returnData}
                                                                {
                                                                    this.state.action !== "view" && <i className="fa fa-trash delete-upload-file" aria-hidden="true"
                                                                        onClick={() => that.documentOnRemove(loopIndex)}
                                                                    ></i>
                                                                }
                                                            </div>
                                                        </>)
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className={"col-md-2"}>
                                            {
                                                this.state.action == "Add" ? <input type="button" className="btn mb-2  ml-auto addbuttonsty" value="Add" onClick={() => this.submit()} /> : <input type="button" className="btn  mb-2 ml-auto addbuttonsty" value="Update" onClick={() => this.Update()} />
                                            }
                                            {this.state.action == "Edit" && <input type="button" className="btn mb-1 ml-auto btn-cancel-sty" value="Cancel" onClick={() => {
                                                this.setState({
                                                    action: "Add",
                                                    documentFiles: [],
                                                    document_Json: [],
                                                    workSpaceSelect: "",
                                                    errors: {},
                                                    catagorySelect: "",
                                                    subCategoryName: ""
                                                })
                                            }} />}

                                        </div>
                                    </div>
                                    <br />


                                </div>
                            </div>
                        </div>

                        <div className="container mt-5">
                            <div className="card box-shadow" style={{ padding: 0 }}>
                                <div className="card-header border-btm-style" style={{ borderBottom: "none" }}>
                                    <h5 className='header-style' > Resource Sub Category List</h5>
                                </div>
                                <div className="card-body table-responsive" style={{ padding: 2 }}>
                                    <table className="table table-style light-border-clr" style={{ width: "100%", textAlign: "center" }} border={1} cellPadding={7}>
                                        <tr className='upperCase'>
                                            <th scope="col fw">Workspace</th>
                                            <th scope="col fw">Category Name</th>
                                            <th scope="col fw">Sub Category Name</th>
                                            <th scope="col fw">Image</th>
                                            <th scope="col fw">Created By <br /> UserName - Email</th>
                                            <th scope="col fw">Published</th>
                                            <th scope="col fw">Edit</th>
                                            <th scope="col fw">Delete</th>
                                        </tr>
                                        <tbody>
                                            {tableData}
                                        </tbody>
                                    </table>

                                    <Pagination
                                        pagination={pagination}
                                        currentPage={noPagesLimit}
                                        prev={prev}
                                        next={next}
                                        movePage={(type) => {
                                            this.movePage(type)
                                        }} />

                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </div>
        )
    }
}

export default AddResourceSubCategory;