import React from 'react';
import MyConstant from '../../config/MyConstant'
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'
import Slide1 from '../../images/techoverview/1.png';
import Slide2 from '../../images/techoverview/2.png';
import Slide3 from '../../images/techoverview/3.png';
import Slide4 from '../../images/techoverview/4.png';
import Slide5 from '../../images/techoverview/5.png';
import Slide6 from '../../images/techoverview/6.png';
import Slide7 from '../../images/techoverview/7.png';
import Slide8 from '../../images/techoverview/8.png';
import Slide9 from '../../images/techoverview/9.png';
import Slide10 from '../../images/techoverview/10.png';
import Slide11 from '../../images/techoverview/11.png';
import Slide12 from '../../images/techoverview/12.png';
import Slide13 from '../../images/techoverview/13.png';
import Slide14 from '../../images/techoverview/14.png';
import techlogo from '../../images/v3/techlogo.png';
import Footer from '../../common/Footer3';
import { userTrack } from '../../config/MyFunctions';


let imageList = [{ imagePath: Slide1 }, { imagePath: Slide2 }, { imagePath: Slide3 }, { imagePath: Slide4 }, { imagePath: Slide5 }, { imagePath: Slide6 }, { imagePath: Slide7 }, { imagePath: Slide8 }, { imagePath: Slide9 }, { imagePath: Slide10 }, { imagePath: Slide11 }, { imagePath: Slide12 }, { imagePath: Slide13 }, { imagePath: Slide14 }]




class MigrationOverView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            holdImage: true,
            oneTimeSet: false,
        }
    }
    componentDidMount() {
        userTrack("Tech Overview Page", "Landing");
    }

    changeStatus(type) {
        this.setState({ holdImage: type })
    }
    render() {
        let { holdImage } = this.state
        return <>
            <div className="umedme-version-3 tech">
                <div className='header'>
                    <div className='logo-container'>
                        <Link to={MyConstant.keyList.projectUrl + "/tech"}>
                            <img src={techlogo} className="logo" />
                        </Link>
                    </div>
                    <div className='menu-link'>
                        <Link to={MyConstant.keyList.projectUrl + "/"}>
                            Home
                        </Link>
                        <Link to={MyConstant.keyList.projectUrl + "/tech-overview"}>
                            Overview
                        </Link>
                    </div>
                </div>
            </div>
            <div className="umedme-over-view-slider responsive">
                <Carousel autoPlay={holdImage} interval={5000} width={"100%"} infiniteLoop={true} stopOnHover={false} showArrows={true} showStatus={false}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                            <span onClick={onClickHandler}>
                                <a href="javascript:void(0)" onClick={() => {
                                    this.changeStatus(true)
                                }} className='btn btn-sm  prevbtn'>
                                    <i className='fa fa-chevron-left' style={{ fontSize: "26px", color: "#e2915b" }} />
                                </a>
                            </span>
                        )
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                            <span onClick={onClickHandler}>
                                <a href="javascript:void(0)" onClick={() => {
                                    this.changeStatus(true)
                                }} title={label} className='btn btn-sm nextbtn' >
                                    <i className='fa fa-chevron-right' style={{ fontSize: "26px", color: "#e2915b" }} />
                                </a>
                            </span>
                        )
                    }
                    showThumbs={false}
                    animationHandler="fade"

                >
                    {imageList.map((e, i) => {
                        return <div className='' key={i} onClick={() => {
                            this.changeStatus(false)
                        }} >
                            <img src={e.imagePath} className='carosal_img' style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 100) : "auto", }}
                            />
                        </div>
                    })}


                </Carousel>
            </div>
            <Footer />
        </>
    }
}
export default MigrationOverView