import React, { Component } from 'react';
import Footer from '../common/Footer3';
// import about1 from '../images/about-1.png';
// import about1 from '../images/about1bg.jpg';
import about1_collage from '../images/about-collage.jpg';
import HeaderVTwo from "./HeaderVTwo";
import Download from '../../src/Download';



import { userTrack } from '../config/MyFunctions';

export default class OurServies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            intervalId: "",
            sliderTransform: 0,
            sliderHover: false,
            NavResClass: "",
            pageLogin: false,
            showDownload: false,
            showMedia: false
        }
        this.aboutImg = React.createRef()
        this.aboutImgParent = React.createRef()
    }

    async componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });


    }


    render() {
        let { NavResClass, showDownload, showMedia } = this.state
        return (
            <React.Fragment>
                <div className="panel panel-default homevtwo footerwhitebg">
                    <div className="panel-heading">
                        <HeaderVTwo history={this.props.history} hamburgerOnclick={(value) => {
                            this.setState({ NavResClass: value })
                        }} pageType="blog" clickServices={() => { }} />
                    </div>

                    <div className={"homevtwo-about " + (NavResClass ? "about-display" : "")}>
                        <div className="about-section">
                            <div className="mt-2 mb-2">
                                <div className="res-hamb-txt KeplerStd-font-family" onClick={() => {
                                    this.props.history.push("/about")
                                }}>About Us</div>
                            </div>
                            <div className="mt-2 mb-2">
                                <div className="res-hamb-txt KeplerStd-font-family"
                                    onClick={() => {
                                        this.setState({ showMedia: !showMedia })
                                    }}>Media
                                    <i class="fa fa-angle-down pl-2" aria-hidden="true"></i>
                                </div>
                                <div className='mob-service-txt' style={showMedia ? { display: "block" } : { display: "none" }}>
                                    <div onClick={() => {
                                        this.props.history.push("/videos")
                                    }}>Videos</div>
                                    <div onClick={() => {
                                        this.props.history.push("/research")
                                    }}>Research</div>
                                    <div onClick={() => {
                                        this.props.history.push("/press")
                                    }}>Press</div>
                                </div>
                            </div>
                            <div className="mt-2 mb-2">
                                <div className="res-hamb-txt KeplerStd-font-family" onClick={() => {
                                    this.setState({ showDownload: !showDownload })
                                }}>
                                    Beta
                                    <i class="fa fa-angle-down pl-2" aria-hidden="true"></i>
                                </div>

                                <div className='mob-service-txt' style={showDownload ? { display: "block" } : { display: "none" }}>
                                    <div onClick={() => {
                                        this.props.history.push("/downloads")
                                    }}>Downloads</div>
                                </div>
                            </div>
                            <div className="mt-2 mb-2">
                                <div className="res-hamb-txt KeplerStd-font-family" onClick={() => {
                                    this.props.history.push("/migration")
                                }}>Migration</div>
                            </div>
                        </div>

                        <div className="res-hum-more-bottom-div">
                            <div className="hum-more-btn-div">
                                <div className="more-btn-txt">

                                    <span className='dropdown' style={{ borderLeft: "none" }}>
                                        More
                                        <div class="dropdown-content dropdown-left ">
                                            <div onClick={() => {
                                                this.props.history.push("/app-feedback")
                                            }}>Feedback</div>
                                            <div>FAQ</div>
                                        </div>
                                    </span>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"top-body v_two_about " + (NavResClass ? "showdiv" : "")} >
                        <main>
                            <Download grpcClient={this.props.grpcClient} history={this.props.history} page="blog"/>
                        </main>
                        <Footer />
                    </div>
                </div>

            </React.Fragment >
        )
    }
}