import { useEffect, useState } from 'react';
import MyConstant from '../../config/MyConstant';
import techlogo from '../../images/v3/techlogo.png'
import techLogoIntro from '../../images/v3/tech.jpeg'
import mobileAppImg from '../../images/v3/mobileApp.jpeg'
import ledEditionbg from '../../images/v3/ledEditionbg.jpeg'
// import umedmelogofull from '../../images/v3/umedmelogofull.png'
import { Link } from 'react-router-dom';
import Footer from '../../common/Footer3';
import { userTrack } from '../../config/MyFunctions';

function Tech2(props) {
    let [mobileMenu, setMobileMenu] = useState(false);

    useEffect(() => {
        userTrack("Tech Page", "Landing");
    }, []);

    return <div className="umedme-version-3 tech">
        <div className="bg">
            <div className='header'>
                <div className='logo-container'>
                    <Link to={MyConstant.keyList.projectUrl + "/tech"}>
                        <img src={techlogo} className="logo" />
                    </Link>
                </div>
                <div className='menu-link'>
                    <Link to={MyConstant.keyList.projectUrl + "/"}>
                        Home
                    </Link>
                    <Link to={MyConstant.keyList.projectUrl + "/tech-overview"}>
                        Overview
                    </Link>
                </div>
                <div className='mobile-link' onClick={() => {
                    setMobileMenu(true)
                }}>
                    <i className="fa fa-bars"></i>
                </div>
                {
                    mobileMenu && <div className='mobile-menu'>
                        <div className='header d-flex'>
                            <div className='main-logo'>
                                <Link to={MyConstant.keyList.projectUrl + "/tech"}>
                                    <img alt="UmedMe" className='logo' src={techlogo} />
                                </Link>
                            </div>
                            <div className='menu-close' onClick={() => {
                                setMobileMenu(false)
                            }}>
                                <i className='fa fa-times' />
                            </div>
                        </div>
                        <div className='mobile-menu-link'>
                            <Link to={MyConstant.keyList.projectUrl + "/"}>
                                Home
                            </Link>
                            <Link to={MyConstant.keyList.projectUrl + "/tech-overview"}>
                                Overview
                            </Link>
                        </div>
                    </div>
                }
            </div>
            <div className='background'>
                <div className='text'>
                A Carbon Sensitive Medical EdTech tool to help nursing students practice and master fundamental skills including:
                <ul>
                    <li>
                        <p>Medication administration</p>
                    </li>
                    <li>
                        <p>Diet & nutrition management</p>
                    </li>
                    <li>
                        <p>Health document storage & tracking</p>
                    </li>
                </ul>
                <br/>
                This tool includes a live polling survey that:
                <ul>
                    <li>
                        <p>Delivers pre and post learning assessments</p>
                    </li>
                    <li>
                        <p>Deepens learner engagement</p>
                    </li>
                    <li>
                        <p>Advances high quality learning outcomes</p>
                    </li>
                </ul>
                </div>
                <div className='img'>
                    <img src={techLogoIntro} />
                </div>
                <div className='py-5'>
                    <div className='problem'>
                        Problem
                    </div>
                    <div className='pt-5'>
                        <h4>Enrollment in nursing has declined</h4>
                        <br />
                        <h4>20% drop out rate in nursing</h4>
                        <br />
                        <h4>Shortage of 450,000 nurses in next two years</h4>
                    </div>
                </div>
            </div>
        </div>
        <div className="why-block d-flex justify-content-center py-5">
            <div className="w-75 d-flex flex-direction-column align-items-center p-2 text-center">
                <div className="why-button">Why</div>
                <div className="pt-5">
                    <h5>For the first time in 20 years, enrollment in entry-level baccalaureate nursing programs decreased by 1.4%, and declines continue to occur in master’s and PhD programs, according to new data from the American Association of Colleges of Nursing (AACN). </h5>
                    <br />
                    <h5>In April 2022, Dr. David Auerbach and colleagues published a nursing workforce analysis in Health Affairs, which found that total supply of RNs decreased by more than 100,000 from 2020 to 2021 – the largest drop than ever observed over the past four decades.</h5>
                    <br />
                    <h5>The nursing profession continues to face shortages due to a lack of potential educators, high turnover, and inequitable workforce distribution.</h5>
                    <br />
                    <h5>Violence in the healthcare setting plays a role in the nursing shortage, the ever-present threat of emotional or physical abuse, adding to an already stressful environment. A study conducted in Poland between 2008 to 2009 concluded that nurses represent the profession most vulnerable to aggression in the workplace regarding a healthcare setting.</h5>
                </div>
            </div>
        </div>
        {/* <div className="problem-block py-5">
            <div className='w-75'>
                <div className='py-5'>
                    <div className='problem'>
                        Problem
                    </div>
                    <div className='pt-5'>
                        <h3>People are dying due to this lack of understanding. </h3>
                        <br />
                        <h2>The Joint Commission on Accreditation of Healthcare Organizations describes</h2>
                        <br />
                        <h3>"..communication error as the cause of 60% to 70% of preventable hospital deaths."</h3>
                    </div>
                </div>
            </div>
        </div> */}
        <div className="community-led-block text-center py-5" style={{ backgroundImage: `url(${ledEditionbg})` }}>
            <div className='py-5'>
                <h3>Community Led Solution</h3>
            </div>
            <div className='img pb-5' >
                <img src={mobileAppImg} />
            </div>
            {/* <div className='pb-5' >
                <p>Data security with end to end encryption.</p>
                <br />
                <p>Android, iOS, Desktop, and Web.</p>
                <br />
                <p>Integration into existing systems</p>
            </div> */}
        </div>
        <div className='containeer'>
            <div className='row mx-0 pb-5'>
                <div className='col-sm-6' style={{ backgroundColor: "#253552"}}>
                    <div className='d-flex h-100 align-items-center justify-content-center'>
                        <img className='img-fluid' src={techlogo} style={{ maxWidth: 250 }} />
                    </div>
                </div>
                <div className='col-sm-6 p-5'>
                    <ul className='usage-block'>
                        <li>
                            <p>Built for nurses by nurses and nurse educators.</p>
                        </li>
                        <li>
                            <p>Data security with end to end encryption.</p>
                        </li>
                        <li>
                            <p>Android, iOS, Desktop, and Web.</p>
                        </li>
                        <li>
                            <p>Integration into existing systems.</p>
                        </li>
                        <li>
                            <p>Develops nursing education priorities below.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className='circle-main-block d-flex flex-direction-column align-items-center py-5'>
            <div className='first-row d-flex'>
                <div className='circle-block'>
                    <div className='circle'>
                        Critical Thinking
                    </div>
                </div>
                <div className='circle-block'>
                    <div className='circle'>
                        Nursing Knowledge Base
                    </div>
                </div>
                <div className='circle-block'>
                    <div className='circle'>
                        Nursing Process Diagnosis
                    </div>
                </div>
            </div>
            <div className='second-row d-flex'>
                <div className='circle-block'>
                    <div className='circle'>
                        Nursing Process Planning
                    </div>
                </div>
                <div className='circle-block'>
                    <div className='circle'>
                        Nursing Process Assessment
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>;
}
export default Tech2