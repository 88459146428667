import React, { Component } from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import Logo from '../images/umedmeweblogo.png';
import Teqbahn_Logo from '../images/teqbahn-white.png';
import { Link } from 'react-router-dom';
import MyConstant from '../config/MyConstant';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default class ManageForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            userId: "",
            errors: {
                email: ""
            },
            otp: "",
            password: "",
            errorsReset: {
                otp: "",
                password: "",
            },
        }
    }
    componentDidMount() {
    }

    submit() {
        let { email, otp, password, userId } = this.state;
        if (userId) {
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            let passwordReg = /^(?!.* ).{6,15}$/;

            let errorsReset = {};
            if (otp.trim() === '') {
                errorsReset["otp"] = "Please enter PIN";
            } else if (otp.length !== 6) {
                errorsReset["otp"] = "Please enter valid PIN";
            }

            if (password.trim() === '') {
                errorsReset["password"] = "Please enter password.";
            } else if (passwordReg.test(password) === false) {
                errorsReset["password"] = "Please enter minimum 6 characters and max 15 characters";
            }

            if (Object.keys(errorsReset).length > 0) {
                this.setState({
                    errorsReset
                })
            } else {
                this.setState({
                    errorsReset: {
                        otp: "",
                        password: "",
                    }
                })
                let postJson = { userId, otp, password };
                console.log("postJson", postJson)
                const request = new ZiFetchRequest();
                request.setDomain('adminUserReq');
                request.setEvent('adminUserUpdateForgotPassword');
                request.setMessage(JSON.stringify(postJson));
                request.setDatetime(new Date().toString());
                this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
                    if (response == null || response.getMessage() == 'Invalid Request') {
                        console.warn('Invalid Request. Please try again later');
                    } else {
                        let responseData = JSON.parse(response.getMessage());
                        var response = responseData.response;
                        if (response == "Success") {
                            this.setState({
                                userId: ""
                            })
                            toast.success("Password updated successfully.", {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } else if (response === "NotExist") {
                            toast.error("Please enter valid OTP.", {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                });
            }
        } else {
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            let errors = {};
            if (email.trim() === '') {
                errors["email"] = "Please enter username.";
            }
            if (Object.keys(errors).length > 0) {
                this.setState({
                    errors
                })
            } else {
                this.setState({
                    errors: {
                        email: ""
                    }
                })
                let postJson = { userName: email };
                console.log("postJson", postJson)
                const request = new ZiFetchRequest();
                request.setDomain('adminUserReq');
                request.setEvent('adminUserForgotPasswordSend');
                request.setMessage(JSON.stringify(postJson));
                request.setDatetime(new Date().toString());
                this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
                    if (response == null || response.getMessage() == 'Invalid Request') {
                        console.warn('Invalid Request. Please try again later');
                    } else {
                        let responseData = JSON.parse(response.getMessage());
                        var response = responseData.responseMessage;
                        if (response == "Success") {
                            this.setState({
                                userId: responseData.userId
                            })
                            toast.success("Please check your email", {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } else if (response === "NotExist") {
                            toast.error("Username does not exist.", {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                });
            }
        }
    };

    render() {
        let { email, errors, userId, otp, password, errorsReset, pageText } = this.state;
        import('../admin/style.css');
        return <React.Fragment>
            <ToastContainer />
            <main className="login-body d-flex justify-content-center align-items-center layout-green-login">
                <div className='login-box' style={{ color: "#fff" }}>
                    <div className='d-flex align-items-center'>
                        <div style={{ flex: 1, padding: 10, borderRight: "1px solid #ddd" }}>
                            <img src={Logo} className="img-fluid" />
                        </div>
                        <div style={{ flex: 1, padding: 10 }}>
                            <img src={Teqbahn_Logo} className="img-fluid" />
                        </div>
                    </div>
                    <div className='text-center pt-5 pb-2' style={{ color: "#fff" }}>
                        <h4>Forgot Password</h4>
                    </div>
                    <div>
                        <form action="">
                            <div className='row'>
                                <div className='offset-sm-2 col-sm-8'>
                                    <div className='form-group text-right'>
                                        <Link className='forgot-password-link' to={MyConstant.keyList.projectUrl + "/manage"}>Back to login</Link>
                                    </div>
                                    {
                                        userId === "" ? <div className="form-group mb-3">
                                            <label>Username</label>
                                            <input type="email" value={email} className={`form-control form-control-solid form-control-lg ${errors['email'] ? "is-invalid" : ""}`} placeholder={"Please enter username"}
                                                onChange={(e) => {
                                                    this.setState({
                                                        email: e.target.value
                                                    })
                                                }} />
                                            {
                                                errors['email'] && <div className="invalid-feedback">{errors['email']}</div>
                                            }
                                        </div> : <React.Fragment>
                                            <div className="form-group mb-3">
                                                <label>Username</label>
                                                <label className="form-label"> : {email}</label>
                                            </div>
                                            <div className="form-group mb-3">
                                                <label>PIN</label>
                                                <input type="text" value={otp} className={`form-control form-control-solid form-control-lg ${errorsReset['otp'] ? "is-invalid" : ""}`} placeholder={"Please enter PIN"}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            otp: e.target.value
                                                        })
                                                    }} />
                                                {
                                                    errorsReset['otp'] && <div className="invalid-feedback">{errorsReset['otp']}</div>
                                                }
                                            </div>
                                            <div className="form-group mb-3">
                                                <label className="form-label fw-bolder">Password</label>
                                                <input type="password" value={password} className={`form-control form-control-solid form-control-lg ${errorsReset['password'] ? "is-invalid" : ""}`} placeholder={"Please enter password"}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            password: e.target.value
                                                        })
                                                    }} />
                                                {
                                                    errorsReset['password'] && <div className="invalid-feedback">{errorsReset['password']}</div>
                                                }
                                            </div>
                                        </React.Fragment>
                                    }
                                    <button type="button" className="btn btn-block login-btn mb-4" onClick={() => this.submit()}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </React.Fragment>
    }
}