import migracionLogo from '../../images/v3/migracionLogo.png'
import migracionBg from '../../images/v3/migracionBg.jpeg'
import MyConstant from '../../config/MyConstant'
import { Link } from 'react-router-dom';
import aboutus1 from '../../images/v3/aboutus1.jpg'
import aboutus2 from '../../images/v3/aboutus2.jpg'
import aboutus3 from '../../images/v3/aboutus3.jpg'
import aboutus4 from '../../images/v3/aboutus4.jpg'
import aboutus5 from '../../images/v3/aboutus5.jpg'
import aboutus6 from '../../images/v3/aboutus6.jpg'
import translator1 from '../../images/v3/translator1.png'
import translator2 from '../../images/v3/translator2.jpg'
import translator3 from '../../images/v3/translator3.jpg'
import translator4 from '../../images/v3/translator4.jpg'
import advisor1 from '../../images/v3/advisor1.jpeg'
import advisor2 from '../../images/v3/advisor2.jpeg'
import advisor3 from '../../images/v3/advisor3.jpeg'
import advisor4 from '../../images/v3/advisor4.jpeg'
import box2bg from '../../images/v3/box2bg.jpeg'
import carousel1 from '../../images/v3/carousel1.jpeg'
import carousel2 from '../../images/v3/carousel2.jpeg'
import carousel3 from '../../images/v3/carousel3.jpeg'
import carousel4 from '../../images/v3/carousel4.jpeg'
import carousel5 from '../../images/v3/carousel5.jpeg'
import carousel6 from '../../images/v3/carousel6.jpeg'
import carousel7 from '../../images/v3/carousel7.jpeg'
import afterComunitybg1 from '../../images/v3/afterComunitybg1.png'
import afterComunitybg2 from '../../images/v3/afterComunitybg2.png'
import afterComunitybg3 from '../../images/v3/afterComunitybg3.jpeg'
import howitworks from '../../images/v3/how-it-works.jpeg'
import howitworks2 from '../../images/v3/how-it-works2.jpeg'
import download1 from '../../images/v3/download1.jpeg'
import download2 from '../../images/v3/download2.jpeg'
import download3 from '../../images/v3/download3.jpeg'
import partner1 from '../../images/v3/partner1.jpg'
import partner2 from '../../images/v3/partner2.png'
import { useEffect, useRef, useState } from 'react';
import appleIcon from '../../images/AppleIconLink.png';
import androidIcon from '../../images/android.png';
import UmedMeLite from '../../images/umedmeLink.png';
import web from '../../images/web.png';
import Footer from '../../common/Footer3';
import { getPageText, getValidLabels, userTrack } from '../../config/MyFunctions';

export default function MigrationTest(props) {
    let [slidewidth, setSlidewidth] = useState(0);
    let [sliderWidth, setSliderWidth] = useState(0);
    let [sliderActive, setSliderActive] = useState(0);
    let [sliderTransform, setSliderTransform] = useState(0);
    let [bioInfo, setBioInfo] = useState([]);
    let [mobileMenu, setMobileMenu] = useState(false);
    let [slideContent, setSlideContent] = useState([]);
    let [language, setLanguage] = useState("");
    const [pageText, setPageText] = useState({})

    useEffect(() => {
        userTrack("Migration Page", "Landing");
        let language = localStorage.getItem("language");

        setLanguage(language ? language : "eng")
        let focusId = props.match.params.focusId;
        const element = document.getElementById(focusId)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
    }, []);


    useEffect(() => {
        if(language) {
            let pageText = getPageText('migration');
            setPageText(pageText)
    
            let slideContent = [
                {
                    image: carousel1,
                    content: getValidLabels(pageText, 'section2MigrantTxt')
                },
                {
                    image: carousel2,
                    content: getValidLabels(pageText, 'section2WithDrEmmanuelTxt')
                },
                {
                    image: carousel3,
                    content: getValidLabels(pageText, 'section2WithGrisTxt')
                },
                {
                    image: carousel4,
                    content: getValidLabels(pageText, 'section2VidyaAndMariaTxt')
                },
                {
                    image: carousel5,
                    content: getValidLabels(pageText, 'section2WithJudithTxt')
                },
                {
                    image: carousel6,
                    content: getValidLabels(pageText, 'section2LearningTxt')
                },
                {
                    image: carousel7,
                    content: getValidLabels(pageText, 'section2WithJaninaTxt')
                },
            ]
            setSlideContent(slideContent);
        }
    }, [language])

    useEffect(() => {
        if(slideContent.length > 0) {
            updateDimensions()
        }
    }, [slideContent])

    function updateDimensions() {
        let deviceWidth = document.body.clientWidth;
        var width = deviceWidth / 3;
        if (deviceWidth < 900) {
            width = deviceWidth
        }

        let sliderWidth = slideContent.length * width;
        var sliderTransform = sliderActive * width;

        setSliderActive(0)
        setSlidewidth(width)
        setSliderWidth(sliderWidth)
        setSliderTransform(0)
    }

    function moveSlide(action) {
        let length = slideContent.length;
        let changeSlide = false;
        let currentSliderActive;
        if (action === "next") {
            currentSliderActive = sliderActive + 1;
            if ((currentSliderActive + 1) < length) {
                changeSlide = true;
            }
        } else {
            currentSliderActive = sliderActive - 1;
            if (currentSliderActive >= 0) {
                changeSlide = true;
            }
        }
        if (changeSlide) {
            let deviceWidth = document.body.clientWidth;
            var width = deviceWidth / 2;
            if (deviceWidth < 900) {
                width = deviceWidth
            }

            var sliderTransform = -(currentSliderActive * slidewidth);

            setSliderActive(currentSliderActive)
            setSliderTransform(sliderTransform)
        }

    }

    function toggleInfo(value) {
        let obj = [...bioInfo]

        if (obj.includes(value)) {
            let index = obj.indexOf(value);
            obj.splice(index, 1)
        } else {
            obj.push(value)
        }

        setBioInfo(obj)
    }

    return <div className="umedme-version-3 migration">
        <div className="bg">
            <div className='header'>
                <div className='logo-container'>
                    <Link to={MyConstant.keyList.projectUrl + "/migration"}>
                        <img src={migracionLogo} className="logo" />
                    </Link>
                </div>
                <div className='menu-link'>
                    <Link to={MyConstant.keyList.projectUrl + "/"}>
                        {getValidLabels(pageText, 'headerHomeTxt')}
                    </Link>
                    <Link to={MyConstant.keyList.projectUrl + "/migration-apps"}>
                        {getValidLabels(pageText, 'headerAppsTxt')}
                    </Link>
                    <Link to={MyConstant.keyList.projectUrl + "/migration-media"}>
                        {getValidLabels(pageText, 'headerMediaTxt')}
                    </Link>
                    <Link to={MyConstant.keyList.projectUrl + "/migration-overview"}>
                        {getValidLabels(pageText, 'headerOverviewTxt')}
                    </Link>
                    <div className={`nav-dropdown-menu`}>
                        <span>{getValidLabels(pageText, 'headerLanguageTxt')}</span>
                        <div className="nav-dropdown-content">
                            <div onClick={() => {
                                setLanguage("eng")
                                localStorage.setItem("language", "eng")
                            }} className={`lang ${language === "eng" ? "active" : ""}`}>{getValidLabels(pageText, 'headerEnglishTxt')}</div>
                            <div onClick={() => {
                                setLanguage("esp")
                                localStorage.setItem("language", "esp")
                            }} className={`lang ${language === "esp" ? "active" : ""}`}>{getValidLabels(pageText, 'headerSpanishTxt')}</div>
                        </div>
                    </div>
                </div>
                <div className='mobile-link' onClick={() => {
                    setMobileMenu(true)
                }}>
                    <i className="fa fa-bars"></i>
                </div>
                {
                    mobileMenu && <div className='mobile-menu'>
                        <div className='header d-flex'>
                            <div className='main-logo'>
                                <Link to={MyConstant.keyList.projectUrl + "/migration"}>
                                    <img alt="UmedMe" className='logo' src={migracionLogo} />
                                </Link>
                            </div>
                            <div className='menu-close' onClick={() => {
                                setMobileMenu(false)
                            }}>
                                <i className='fa fa-times' />
                            </div>
                        </div>
                        <div className='mobile-menu-link'>
                            <Link to={MyConstant.keyList.projectUrl + "/"}>
                                {getValidLabels(pageText, 'headerHomeTxt')}
                            </Link>
                            <Link to={MyConstant.keyList.projectUrl + "/migration-apps"}>
                                {getValidLabels(pageText, 'headerAppsTxt')}
                            </Link>
                            <Link to={MyConstant.keyList.projectUrl + "/migration-media"}>
                                {getValidLabels(pageText, 'headerMediaTxt')}
                            </Link>
                            <Link to={MyConstant.keyList.projectUrl + "/migration-overview"}>
                                {getValidLabels(pageText, 'headerOverviewTxt')}
                            </Link>
                            <div className={`nav-dropdown-menu`}>
                                <span>{getValidLabels(pageText, 'headerLanguageTxt')}</span>
                                <div className="nav-dropdown-content">
                                    <div onClick={() => {
                                        setLanguage("eng");
                                        localStorage.setItem("language", "eng");
                                        setMobileMenu(false)
                                    }} className={`lang ${language === "eng" ? "active" : ""}`}>{getValidLabels(pageText, 'headerEnglishTxt')}</div>
                                    <div onClick={() => {
                                        setLanguage("esp");
                                        localStorage.setItem("language", "esp");
                                        setMobileMenu(false);
                                    }} className={`lang ${language === "esp" ? "active" : ""}`}>{getValidLabels(pageText, 'headerSpanishTxt')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <img src={migracionBg} className="background" />
            <div className='bg-setup'></div>
        </div>

        <div className='my-3'>
            <div className='text-center'>
                <h2>{getValidLabels(pageText, 'partnersSection')}</h2>
            </div>
            <div className='container'>
                <div className='pt-3 d-flex justify-content-center'>
                    <div className='box-partner'>
                        <img className='img-fluid' src={partner1}/>
                    </div>
                    <div className='box-partner'>
                        <img className='img-fluid' src={partner2}/>
                    </div>
                </div>
            </div>
        </div>
        <div className='my-5'>
            <div className='text-center'>
                <h2>{getValidLabels(pageText, 'section1BasicRightTxt')}*</h2>
            </div>
            <div className='container box-setup pt-5 d-flex justify-content-center'>
                <div className='box1'>
                    {getValidLabels(pageText, 'section1ProblemTxt')}
                </div>
                <div className='box2' style={{ backgroundImage: `url(${box2bg})` }}>
                    {getValidLabels(pageText, 'section1SolutionTxt')}
                </div>
            </div>
        </div>
        <div className='mb-5'>
            <div className='text-center'>
                <h2>The Community</h2>
            </div>
            <div className="slider-outer mb-3">
                {
                    sliderActive > 0 && <div className="move-slide-migration prev">
                        <div className="button" onClick={() => moveSlide('prev')}>
                            <i className="fa fa-arrow-left"></i>
                        </div>
                    </div>
                }
                {
                    (sliderActive + 2) < slideContent.length && <div className="move-slide-migration next">
                        <div className="button" onClick={() => moveSlide('next')}>
                            <i className="fa fa-arrow-right"></i>
                        </div>
                    </div>
                }

                <div className="slider" style={{ width: sliderWidth, transform: `translate3d(${sliderTransform}px, 0px, 0px)` }}>
                    {slideContent.map((value, index) => {
                        return <div key={index} className="slide" style={{ width: slidewidth }}>
                            <div className="slide-inner">
                                <img className='img-fluid' src={value.image} alt={"Umedme"} />
                                <p className="content mt-2" style={{ fontSize: 14 }}>{value.content}</p>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
        <div className='mb-5'>
            <div className='after-community-bg'>
                <img src={afterComunitybg3} className="background" />
                <div className='bg-setup'></div>
                <div className='after-community-bg1'>
                    <img src={afterComunitybg1} />
                </div>
                <div className='after-community-bg2'>
                    <img src={afterComunitybg2} />
                </div>
            </div>
        </div>
        <div className='container mb-5'>
            <div className='row'>
                <div className='col-sm-6'>
                    <img className='img-fluid' src={howitworks} alt={"umedme"} />
                </div>
                <div className='col-sm-6 d-flex px-5 flex-direction-column justify-content-center'>
                    <h2>{getValidLabels(pageText, 'section3HowItWorksTxt')}</h2>
                    <h4>{getValidLabels(pageText, 'section3SecureDocumentTxt')}</h4>
                    <ul>
                        <li>
                            <p>{getValidLabels(pageText, 'section3OfficialDocumentsTxt')}</p>
                        </li>
                        <li>
                            <p>{getValidLabels(pageText, 'section3SafelyStoreTxt')}</p>
                        </li>
                        <li>
                            <p>{getValidLabels(pageText, 'section3ForAnIndividualTxt')}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='row py-5'>
                <div className='col-sm-6 d-flex px-5 flex-direction-column justify-content-center'>
                    <h2>{getValidLabels(pageText, 'section3HowItWorks2Txt')}</h2>
                    <h4>{getValidLabels(pageText, 'section3ContinuityOfCareTxt')}</h4>
                    <ul>
                        <li>
                            <p>{getValidLabels(pageText, 'section3DoctorsMayHesitateTxt')}</p>
                        </li>
                        <li>
                            <p>{getValidLabels(pageText, 'section3QuicklySaveTxt')}</p>
                        </li>
                        <li>
                            <p>{getValidLabels(pageText, 'section3BetterOutcomesTxt')}</p>
                        </li>
                    </ul>
                </div>
                <div className='col-sm-6'>
                    <img className='img-fluid' src={howitworks2} alt={"umedme"} />
                </div>
            </div>
        </div>
        <div className='download-box pb-5' id={"download"}>
            <div className='img1'>
                <img src={download2} alt={"Umedme"} />
            </div>
            <div className='download-image d-flex'>
                <div className='img2'>
                    <img src={download1} alt={"Umedme"} />
                </div>
                <div className='img3'>
                    <img src={download3} alt={"Umedme"} />
                </div>
            </div>
            <div className='link-image d-flex'>
                <div className='img'>
                    <a href='https://umed.me/app/' target='_blank'>
                        <img src={web} alt={"Umedme"} />
                        <div>Aplicación Web</div>
                    </a>
                </div>
                <div className='img'>
                    <a href='https://play.google.com/store/apps/details?id=com.teqbahn.umedme' target='_blank'>
                        <img src={androidIcon} alt={"Umedme"} />
                        <div>Androide</div>
                    </a>
                </div>
                <div className='img'>
                    <a href='https://apps.apple.com/in/app/umedme/id1631817085' target='_blank'>
                        <img src={appleIcon} alt={"Umedme"} />
                        <div>iPhone</div>
                    </a>
                </div>
                <div className='img'>
                    <a href='https://umed.me/lite/' target='_blank'>
                        <img src={UmedMeLite} alt={"Umedme"} />
                        <div>Umed.me lite</div>
                    </a>
                </div>
            </div>
        </div>
        <div style={{ border: "2px solid #253552" }} className='my-5' />
        <div className='about-us px-5 py-5'>
            <div className='text-center'>
                <h2>ABOUT US</h2>
            </div>
            <div className='row'>
                <div className='col-sm-4'>
                    <div className='circle d-flex justify-content-center'>
                        <img className='img-fluid' src={aboutus1} alt="UmedMe" />
                    </div>
                    <div className='text-center mt-2'>
                        <p className='mb-1'><b>Vidya Sri</b></p>
                        <p>MBA</p>
                        <p>
                            Harvard Fellow, Founders Institute, Co-Founder Everywoman Treaty, Social Impact Entrepreneur, 15+ Years Fortune 100
                        </p>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className='circle d-flex justify-content-center'>
                        <img className='img-fluid' src={aboutus2} alt="UmedMe" />
                    </div>
                    <div className='text-center mt-2'>
                        <p className='mb-1'><b>Maria Caterina Gargano</b></p>
                        <p>Global Migration Lead</p>
                        <p>
                            Expert in migration and mental health<br />
                            Trauma informed interventions <br />
                            <a style={{ color: '#007bff' }} href="https://www.mdpi.com/1660-4601/19/6/3476" target='_blank'>Mental Health in the Transit Context: Evidence from 10 Countries</a>
                        </p>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className='circle d-flex justify-content-center'>
                        <img className='img-fluid' src={aboutus3} alt="UmedMe" />
                    </div>
                    <div className='text-center mt-2'>
                        <p className='mb-1'><b>Kathleen Ansay</b></p>
                        <p>R&D</p>
                        <p>
                            Nurse Educator, Expertise across medical specialities at a level 2 trauma hospital
                        </p>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-4'>
                    <div className='circle d-flex justify-content-center'>
                        <img className='img-fluid' src={aboutus4} alt="UmedMe" />
                    </div>
                    <div className='text-center mt-2'>
                        <p className='mb-1'><b>Mark Ansay</b></p>
                        <p>Head of Product</p>
                        <p>
                            Dialysis Nurse, Expertise in hospital and clinical settings, patient care, compliance, training and operations
                        </p>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className='circle d-flex justify-content-center'>
                        <img className='img-fluid' src={aboutus5} alt="UmedMe" />
                    </div>
                    <div className='text-center mt-2'>
                        <p className='mb-1'><b>Ravi Madhusudhan</b></p>
                        <p>CTO</p>
                        <p>
                            Aeronautical Engineer, Founders Institute, 25+ Years Fortune 100
                        </p>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className='circle d-flex justify-content-center'>
                        <img className='img-fluid' src={aboutus6} alt="UmedMe" />
                    </div>
                    <div className='text-center mt-2'>
                        <p className='mb-1'><b>Ruben Barcenilla Jr.</b></p>
                        <p>Head of Creative</p>
                        <p>
                            Multi-disciplinary creative, Successful brand design for Fortune 100
                        </p>
                    </div>
                </div>
            </div>

            <div className='row pt-5'>
                <div className='col-sm-3'>
                    <div className='adivisor-box'>
                        <div className='img'>
                            <img className='img-fluid' src={translator1} alt={"Umedme"} />
                        </div>
                        <div className='text-center'>
                            <p><b>Rawand Faeq</b></p>
                            {
                                bioInfo.includes("RawandFaeq") && <p className={"bio-info"}>
                                    Rawand Faeq is an open-source research analyst. 
                                    He has conducted open-source intelligence and human intelligence research for various stabilization projects. 
                                    Rawand developed his analytical skills and political insight by working at Rudaw Media Network, one of the. 
                                    Kurdistan Region of Iraq’s media outlets. 
                                    His latest research project focused on understanding the information environment of the 2021 Iraqi National Election. As a <a style={{ color: '#007bff' }} target='_blank' href='https://keough.nd.edu/master-of-global-affairs/'>master of global affairs</a> student, Rawand is the recipient of a <a style={{ color: '#007bff' }} target='_blank' href='https://kroc.nd.edu/'>Kroc Institute</a> Fellowship.
                                </p>
                            }
                        </div>
                        <div className='text-center'>
                            <button className='btn btn-read-bio' onClick={() => {
                                toggleInfo("RawandFaeq")
                            }}>Read Bio</button>
                        </div>
                    </div>
                </div>
                <div className='col-sm-3'>
                    <div className='adivisor-box'>
                        <div className='img'>
                            <img className='img-fluid' src={translator2} alt={"Umedme"} />
                        </div>
                        <div className='text-center'>
                            <p><b>Nilofer Hashimi</b></p>
                            {
                                bioInfo.includes("NiloferHashimi") && <p className={"bio-info"}>
                                    Nilofer Hashimi is a marketing graduate and media studies student from Afghanistan where Dari is her native language. 
                                    She works with Chadari, a storytelling platform for Afghan women and girls.
                                    Chadari is a platform that gives an opportunity for Afghan women to express their opinions and perspectives, talk about their achievements, the stories of bravery. 
                                </p>
                            }
                        </div>
                        <div className='text-center'>
                            <button className='btn btn-read-bio' onClick={() => {
                                toggleInfo("NiloferHashimi")
                            }}>Read Bio</button>
                        </div>
                    </div>
                </div>
                <div className='col-sm-3'>
                    <div className='adivisor-box'>
                        <div className='img'>
                            <img className='img-fluid' src={translator4} alt={"Umedme"} />
                        </div>
                        <div className='text-center'>
                            <p><b>Farangis Azizhojaeva</b></p>
                            {
                                bioInfo.includes("FarangisAzizhojaeva") && <p className={"bio-info"}>
                                    Farangis Azizhojaeva is a Canadian citizen born and raised in Tajikistan. Fluent in Russian, Farsi, and English. Business Sales and Marketing completed in Niagara, Canada. 10+ years in Business Administration. Mother of Three.
                                </p>
                            }
                        </div>
                        <div className='text-center'>
                            <button className='btn btn-read-bio' onClick={() => {
                                toggleInfo("FarangisAzizhojaeva")
                            }}>Read Bio</button>
                        </div>
                    </div>
                </div>
                <div className='col-sm-3'>
                    <div className='adivisor-box'>
                        <div className='img'>
                            <img className='img-fluid' src={translator3} alt={"Umedme"} />
                        </div>
                        <div className='text-center'>
                            <p><b>Jocie Philistin</b></p>
                            {
                                bioInfo.includes("JociePhilistin") && <p className={"bio-info"}>
                                </p>
                            }
                        </div>
                        {/* <div className='text-center'>
                            <button className='btn btn-read-bio' onClick={() => {
                                toggleInfo("JociePhilistin")
                            }}>Read Bio</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        <div className='advisors px-5 py-5'>
            <div className='text-center'>
                <h2>ADVISORS</h2>
            </div>
            <div className='row pt-5'>
                <div className='col-sm-3'>
                    <div className='adivisor-box'>
                        <div className='img'>
                            <img className='img-fluid' src={advisor1} alt={"Advisor"} />
                        </div>
                        <div className='text-center'>
                            <p><b>Charlie Clements</b></p>
                            <p>MD, MPH</p>
                            {
                                bioInfo.includes("Charlie") && <p className={"bio-info"}>
                                    Charlie Clements is a retired American physician and a human rights activist. He served as
                                    the executive director of the Carr Center for Human Rights Policy at the John F. Kennedy
                                    School of Government at Harvard University from 2010 to 2016. From 2003 to 2010, Dr.
                                    Clements served as president and CEO of the Unitarian Universalist Service Committee, and
                                    before that he was the president of Physicians for Human Rights.

                                    Dr. Clements wrote Witness to War, which was published in 1983 and became the subject of
                                    a 1985 Academy Award-winning short documentary of the same name. This book chronicles
                                    his experience as a distinguished graduate of the Air Force Academy who had flown more
                                    than 50 missions in the Vietnam War.

                                    Afterwards, Clements entered medical school and came to realize that exploitation, poverty,
                                    and injustice were often the origin of illness and injury. Later, as a newly trained physician,
                                    he heard the U.S. was sending military aid to the Salvadoran government, he chose to work
                                    in the midst of El Salvador's civil war in an area controlled by the Salvadoran guerrilla
                                    resistance group, FMLN. There, the villages he served were bombed, rocketed, or strafed by
                                    some of the same aircraft in which he had previously trained.

                                    Upon returning to the U.S., Clements testified in Congress and led congressional delegations
                                    to the region. At the conclusion of the civil war in 1992, he was invited as a special guest to
                                    the signing of the Peace Accords in Mexico City and in 2009 to the inauguration of the first
                                    FMLN president in El Salvador, Mauricio Funes. In 1997 Clements represented Physicians for
                                    Human Rights in the signing of the treaty to ban landmines and later at the Nobel Prize
                                    ceremony for the International Campaign to Ban Landmines.
                                </p>
                            }
                        </div>
                        <div className='text-center'>
                            <button className='btn btn-read-bio' onClick={() => {
                                toggleInfo("Charlie")
                            }}>Read Bio</button>
                        </div>
                    </div>
                </div>
                <div className='col-sm-3'>
                    <div className='adivisor-box'>
                        <div className='img'>
                            <img className='img-fluid' src={advisor2} alt={"Advisor"} />
                        </div>
                        <div className='text-center'>
                            <p><b>Christina Bradic</b></p>
                            <p>MPH</p>
                            {
                                bioInfo.includes("Christina") && <p className={"bio-info"}>
                                    Christina Bradic, MPH, has extensive experience working at the intersection of global health and human rights, with a focus on forced displacement, the right to migrate, and the resulting impact on the human experience. Her passion is using data to influence policy and she has worked on five pieces of U.S. legislation, including the International Violence Against Women Act and the U.S. Trafficking Victims Protection Act, advocating for a provision for unaccompanied minors at the U.S. border. She has completed a data analysis of the effects of natural climate phenomena on refugee migration across the Mediterranean Sea, a comprehensive examination resulting in changes to policies about how humanitarian aid is distributed in the region. Christina's body of work is largely centered on humanitarian work with an emphasis on fragile contexts. She has created and implemented programs in refugee camps in Jordan, Iraq, and Kenya, building child safe spaces where children have psychosocial support and parents are provided economic opportunity. Additionally, she has served as a subject matter expert on statelessness for children of Haitian migrants living in the Dominican Republic and kamlari, girls in indentured servitude in Nepal. With experience across more than 15 countries, her focus on gender, ensures that programs have a strong equity and justice component. Christina holds a Masters of Public Health from The George Washington University, A post-graduate Certificate in Global Mental Health from the Harvard Program on Refugee Trauma, credentials from the UN GenderPro Alliance, and is currently a JD candidate at Syracuse Law.
                                </p>
                            }
                        </div>
                        <div className='text-center'>
                            <button className='btn btn-read-bio' onClick={() => {
                                toggleInfo("Christina")
                            }}>Read Bio</button>
                        </div>
                    </div>
                </div>
                <div className='col-sm-3'>
                    <div className='adivisor-box'>
                        <div className='img'>
                            <img className='img-fluid' src={advisor3} alt={"Advisor"} />
                        </div>
                        <div className='text-center'>
                            <p><b>Eleanor Nwadinobi</b></p>
                            <p>MBBS, EMA, FAAC</p>
                            {
                                bioInfo.includes("Eleanor") && <p className={"bio-info"}>
                                    Dr Nwadinobi is a medical doctor and international health, women peace and security, gender and human rights expert.
                                    She holds a European Union masters in Human rights and Democratisation (EMA) from Venice, Italy. As President of the
                                    Widows Development Organisation, Dr Nwadinobi joined in advocating for the law protecting widows in Enugu State,
                                    the Violence Against Persons Prohibition(VAPP) Act at Federal level and most recently Abia State VAPP act. She is the
                                    International President of the Medical Women's International Association (MWIA) and first Nigerian to assume this
                                    position. Dr Nwadinobi sits on the boards of several National and International Organisations. She has authored several
                                    publications and received numerous awards of merit. Her personal profile is featured in Friedrich Ebert Stiftung
                                    publication, “The hands that build Nigeria: Nigerian women role models”.
                                </p>
                            }
                        </div>
                        <div className='text-center'>
                            <button className='btn btn-read-bio' onClick={() => {
                                toggleInfo("Eleanor")
                            }}>Read Bio</button>
                        </div>
                    </div>
                </div>
                <div className='col-sm-3'>
                    <div className='adivisor-box'>
                        <div className='img'>
                            <img className='img-fluid' src={advisor4} alt={"Advisor"} />
                        </div>
                        <div className='text-center'>
                            <p><b>Dr. Emmanuel Mejorada</b></p>
                            <p>MD</p>
                            {
                                bioInfo.includes("Emmanuel") && <p className={"bio-info"}>
                                    Emmanuel Mejorada is a passionate and dedicated doctor committed to improving the quality of life for migrants
                                    and refugees in Mexico. His focus is the provision of comprehensive and humanitarian medical care to those seeking
                                    a better life, particularly in the border city of Tijuana, Baja California. He offers culturally sensitive medical care to
                                    migrants, addressing their medical and emotional needs with empathy and understanding. Emmanuel collaborates
                                    with multidisciplinary teams to ensure appropriate patient care. He develops preventive health programs and
                                    education to empower the migrant population to make informed decisions about their well-being. His active
                                    participation in community initiatives advocates for equitable medical care and the inclusion of migrant individuals
                                    in society. His commitment to the health and well being of migrants drives his passion to create a positive and
                                    lasting impact in their lives, while also fostering a more inclusive and compassionate society.
                                </p>
                            }
                        </div>
                        <div className='text-center'>
                            <button className='btn btn-read-bio' onClick={() => {
                                toggleInfo("Emmanuel")
                            }}>Read Bio</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
}
