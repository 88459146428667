import React from 'react'
import { withRouter } from 'react-router-dom';
import MyConstant from "../../config/MyConstant";

class Call extends React.Component {

    domain = MyConstant.keyList.jitsiDomain;
    api = {};

    constructor(props) {
        super(props);
        this.state = {
            room: "",
            user: {
                name: 'Teqbahn'
            },
            isAudioMuted: false,
            isVideoMuted: false,
            callEnded: false,
            missedCall: false,
            thanksWindow: false
        }
    }

    componentDidMount() {
        let { emailid, roomId, userId, name } = this.props.match.params;
        this.setState({
            room: roomId,
            user: {
                name: emailid
            }
        }, () => {
            if (window.JitsiMeetExternalAPI) {
                this.startMeet();
            } else {
                alert('JitsiMeetExternalAPI not loaded');
            }
        })
    }


    startMeet = () => {
        const options = {
            roomName: this.state.room,
            width: '100%',
            height: window.innerHeight,
            configOverwrite: {
                prejoinPageEnabled: false,
                startWithVideoMuted: true,
                disableDeepLinking: true
            },
            interfaceConfigOverwrite: {
                // overwrite interface properties
                JITSI_WATERMARK_LINK: 'http://www.google.com',
            },
            parentNode: document.querySelector('#jitsi-iframe'),
            userInfo: {
                displayName: this.state.user.name
            }
        }
        this.api = new window.JitsiMeetExternalAPI(this.domain, options);

        this.api.addEventListeners({
            readyToClose: this.handleClose,
            participantLeft: this.handleParticipantLeft,
            participantJoined: this.handleParticipantJoined,
            videoConferenceJoined: this.handleVideoConferenceJoined,
            videoConferenceLeft: this.handleVideoConferenceLeft,
            audioMuteStatusChanged: this.handleMuteStatus,
            videoMuteStatusChanged: this.handleVideoStatus,
        });
    }

    handleClose = () => {
        setTimeout(() => {
            if (window.ReactNativeWebView)
                window.ReactNativeWebView.postMessage('meeting close');
        }, 1000)
    }


    handleParticipantLeft = async (participant) => {
        this.participantLeft();
    }

    async participantLeft() {
        const data = await this.getParticipants();
        console.log("data", data);

        if (data.length === 0) {
            let { activeChannelId, messageId, userId } = this.props.match.params;
            let { missedCall } = this.state;

            if (!missedCall) {
                this.setState({
                    callEnded: true
                }, () => {

                })
            }
        }
    }
    handleParticipantJoined = async (participant) => {
        console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
    }

    handleVideoConferenceJoined = async (participant) => {
        console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
    }

    handleVideoConferenceLeft = () => {
        console.log("handleVideoConferenceLeft");
        // return this.props.history.push('/client/workspace');
        // return window.close();
        this.participantLeft();
    }

    handleMuteStatus = (audio) => {
        console.log("handleMuteStatus", audio); // { muted: true }
    }

    handleVideoStatus = (video) => {
        console.log("handleVideoStatus", video); // { muted: true }
    }

    getParticipants() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(this.api.getParticipantsInfo()); // get all participants
            }, 500)
        });
    }

    // custom events
    executeCommand(command) {
        this.api.executeCommand(command);;
        if (command == 'hangup') {
            return this.props.history.push('/thank-you');
        }

        if (command == 'toggleAudio') {
            this.setState({ isAudioMuted: !this.state.isAudioMuted });
        }

        if (command == 'toggleVideo') {
            this.setState({ isVideoMuted: !this.state.isVideoMuted });
        }
    }
    render() {
        return (
            <div id="jitsi-iframe"></div>
        );
    }
}
export default withRouter(Call);
