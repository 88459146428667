import React, { Component } from 'react';
import Footer from '../common/Footer';
import Header from '../common/Header';
import about1 from '../images/about-1.png';
import screen1 from '../images/screen-1.png';
import screen2 from '../images/screen-2.png';
import screen3 from '../images/screen-3.png';
import screen4 from '../images/screen-4.png';
import screen5 from '../images/screen-5.png';
import screen6 from '../images/screen-6.png';
import screen7 from '../images/screen-7.png';
import screen8 from '../images/screen-8.png';
import screen9 from '../images/screen-9.png';
import screen10 from '../images/screen-10.png';
export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="top-body">
                <Header page="about"/>
                <main className="about">
                    <div className="row">
                        <div className="col-sm-12 text-center px-0">
                            <img className="about-1" src={about1} />
                        </div>
                    </div>
                    <div className="container px-0">
                        <div className="row mt-5">
                            <div className="col-sm-12">
                                <h3>What we do</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8">
                                <p className="what-we-do-p">
                                We honor the role of women and their family caregiving
                                responsibilities by delivering convenience and peace of mind
                                while enabling secure access and control of protected health
                                information with edge computing.
                                </p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-sm-12">
                                <h3>How we Work</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8">
                                <p className="how-we-work-p">
                                We are inspired and motivated by women and their role in
                                the family. We support their leadership by delivering privacy,
                                security and easy access to their health information so they
                                can achieve optimal health and wellness for themselves and
                                their families.
                                </p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-sm-12">
                                <h3>Our Goal</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8">
                                <p className="our-goal">
                                To become the personal medical experience that women
                                choose to achieve optimal health and wellness for
                                themselves and their families.
                                </p>
                            </div>
                        </div>
                        <div class="row vertical-center-about-launch">
                            <div class="col-sm-12 text-center red-text">
                                    <b>Umed.Me Beta App</b><br/>
                                    Coming Soon
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-sm-4 my-3">
                                <img className="img-fluid" src={screen1} />
                            </div>
                            <div className="col-sm-4 my-3">
                                <img className="img-fluid" src={screen2} />
                            </div>
                            <div className="col-sm-4 my-3">
                                <img className="img-fluid" src={screen3} />
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-sm-4 my-3">
                                <img className="img-fluid" src={screen4} />
                            </div>
                            <div className="col-sm-4 my-3">
                                <img className="img-fluid" src={screen5} />
                            </div>
                            <div className="col-sm-4 my-3">
                                <img className="img-fluid" src={screen6} />
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-sm-3 my-3">
                                <img className="img-fluid" src={screen7} />
                            </div>
                            <div className="col-sm-3 my-3">
                                <img className="img-fluid" src={screen8} />
                            </div>
                            <div className="col-sm-3 my-3">
                                <img className="img-fluid" src={screen9} />
                            </div>
                            <div className="col-sm-3 my-3">
                                <img className="img-fluid" src={screen10} />
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
                </div>
            </React.Fragment>
        )
    }
}