import React from 'react';
import { Link } from 'react-router-dom';
import ReadMore from '../admin/component/ReadMore';
import Pagination from '../admin/Pagination';
import MyConstant from '../config/MyConstant';
import { getAdminIssueId } from '../config/MyFunctions';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import Modal from './Modal';
import MyConfig from '../config/MyConfig';
import { readSessionData, checkLogin,resetLocalData } from '../config/MyFunctions';
import { toast, ToastContainer } from "react-toastify";


const issueBeingTested = MyConfig.issueBeingTested
class IssuesList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pagination: [],
            noPagesLimit: 1,
            issueList: {},
            pageLimit: 10,
            viewFileModal: false,
            viewModal: false,
            viewType: "",
            viewDetails: {},
            mUserId: "",
        }
    }

    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        if (login_status) {
            let resData = await readSessionData()
            let { manage_session, manage_mUserId } = resData
            this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
                this.getIssueList()
            })
        }


    }
    getIssueList() {
        let { noPagesLimit, pageLimit, session, mUserId } = this.state;
        let userId = this.state.mUserId
        let postJson = { userId, pageLimit: pageLimit, noOfPage: noPagesLimit, session, mUserId }
        const request = new ZiFetchRequest();
        request.setDomain('adminIssues');
        request.setEvent('getAdminIssuesReportListForUser');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid Request");
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg.msg == "Success") {
                    var responseData = responseMsg.response;
                    let totalPagesResults = responseMsg.totalResult;
                    this.setState({
                        totalPagesResults,
                        issueList: responseData
                    })
                }
                else if (responseMsg.msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }

    movePage(type) {
        let { noPagesLimit } = this.state
        if (type == "next") {
            noPagesLimit = noPagesLimit + 1
        } else if (type == "prev") {
            noPagesLimit = noPagesLimit - 1
        }
        this.setState({
            noPagesLimit,
            processing: true
        }, () => {
            this.getIssueList()
        })
    }

    viewFiles(files) {
        this.setState({
            currentViewFiles: files,
            viewFileModal: true
        })
    }

    humanDateFormat(time) {
        return new Date(time).toLocaleString()
    }

    viewContent() {
        let { viewType, viewDetails, historyData } = this.state;
        let dlist = viewDetails;
        let metaInfo = dlist.metaInfo ? JSON.parse(dlist.metaInfo) : ""
        let deviceInfo = dlist.deviceInfo ? JSON.parse(dlist.deviceInfo) : {}

        let images = ["jpg", "png"];
        let videos = ["mp4", "3gp", "ogg", "mov"]
        let pdf = ["pdf"]

        let buildContent;
        switch (viewType) {
            case "refNo":
                buildContent = <div>{getAdminIssueId(dlist.issuesNo)}</div>
                break;
            case "title":
                buildContent = <div>{dlist.title}</div>
                break;
            case "description":
                buildContent = <div style={{ whiteSpace: 'pre-wrap' }}>{dlist.descrition}</div>
                break;
            case "device":
                buildContent = metaInfo ? ((metaInfo.issueFrom === undefined || metaInfo.issueFrom === "App") ? metaInfo.deviceOs + `(${metaInfo.deviceOsVersion} ${metaInfo.deviceModel ? "- " + metaInfo.deviceModel : ""})` : "Web") : "-"
                break;
            case "osVersion":
                buildContent = <div>{metaInfo ? `${metaInfo.deviceOsVersion} ${metaInfo.deviceModel ? "- " + metaInfo.deviceModel : ""}` : "-"}</div>
                break;
            case "version":
                buildContent = <div>{deviceInfo.appVersion}</div>
                break;
            case "imageVideo":
                let currentViewFiles = dlist.fileList;
                let userId = dlist.userId;
                buildContent = <div className="row " >
                    {
                        currentViewFiles.map((fileStr, fileIndex) => {
                            let file = JSON.parse(fileStr)
                            let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + file.processType + "&key=" + file.docsId + "." + file.fileType + "&id=issues"
                            return <div className="col-6 my-2 px-2" key={fileIndex} >
                                <div style={{ border: "1px solid #ddd", textAlign: "center", background: "#9e9e9e" }}>
                                    {
                                        images.includes(file.fileType.toLowerCase()) && <img src={buildUrl} className="img-fluid" style={{ maxHeight: 400 }} />
                                    }
                                    {
                                        videos.includes(file.fileType.toLowerCase()) && <video width="100%" controls style={{ maxHeight: 400 }}>
                                            <source src={buildUrl} />
                                        </video>
                                    }
                                    {
                                        pdf.includes(file.fileType.toLowerCase()) && <React.Fragment>
                                            <object data={buildUrl} type="application/pdf" width="100%" height="250px"></object>
                                            <a className="btn btn-sm btn-success my-2" href={buildUrl} target="_blank">Preview</a>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
                break;
            case "status":
                //Issue Fixed
                buildContent = dlist.status === issueBeingTested ? <div>Fixed - We will update next release.</div> : <div>
                    {`${dlist.status} `}
                    <br />
                    <b>{dlist.statusDescription}</b>
                </div>
                break;
            case "history":
                buildContent = <React.Fragment>
                    <ul className="timeline">
                        <li>
                            <span>Feedback created</span>
                            <span className="float-right">{this.humanDateFormat(dlist.createdAt)}</span>
                        </li>
                        {
                            historyData.map((history, index) => {
                                history = JSON.parse(history)
                                return <li key={index} className={history.status.toLowerCase()}>
                                    <span>{history.status}</span>
                                    <span className="float-right">{this.humanDateFormat(history.createdAt)}</span>
                                    <p className="status-description">{history.statusDescription}</p>
                                </li>
                            })
                        }
                    </ul>
                </React.Fragment>
                break;
            case "createdAt":
                buildContent = <div>{this.humanDateFormat(dlist.createdAt)}</div>
                break;
            case "completedAt":
                buildContent = <div>{dlist.completedAt > 0 ? this.humanDateFormat(dlist.completedAt) : " - "}</div>
                break;
        }

        return buildContent;
    }

    viewModal(viewType, issueId) {
        let { issueList } = this.state
        console.log(issueList[issueId])
        this.setState({
            viewType,
            viewDetails: issueList[issueId],
            viewModal: true,
            historyData: [],
            issueId,
        })
    }

    render() {
        let { pagination, noPagesLimit, issueList, totalPagesResults, viewModal, viewDetails, pageLimit, viewType } = this.state
        let pageCount = Math.ceil((totalPagesResults / pageLimit))

        return <React.Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card box-shadow" style={{ padding: 0 }}>
                        <div className="card-header">
                            Feedback
                            <div className="float-right">
                                <button className="btn btn-sm btn-primary" onClick={() => {
                                    this.props.setPage("issue-post")
                                }}>Create Feedback</button>
                            </div>
                        </div>
                        <div className="card-body" style={{ padding: 0}}>
                            <div className="row my-3">
                                <div className="col-12 ">
                                    <div className="table-responsive">

                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Ref No</th>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Device</th>
                                                    <th scope="col">Image/Video/PDF</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Created At</th>
                                                    <th scope="col">Edit</th>
                                                    <th scope="col">Completed At</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Object.keys(issueList).length > 0 ? Object.keys(issueList).map((key, index) => {
                                                        let dlist = issueList[key];
                                                        let metaInfo = dlist.metaInfo ? JSON.parse(dlist.metaInfo) : ""
                                                        return <tr key={index}>
                                                            <td>{getAdminIssueId(dlist.issuesNo)}</td>
                                                            <td>{dlist.title}</td>
                                                            <td>
                                                                <span>{dlist.descrition.substring(0, 50)} {dlist.descrition.length > 50 && <span>...<a className="read-more-comp" onClick={() => this.viewModal("description", key)}>Read More</a></span>}</span>
                                                            </td>
                                                            <td>{metaInfo ? ((metaInfo.issueFrom === undefined || metaInfo.issueFrom === "App") ? metaInfo.deviceOs + `(${metaInfo.deviceOsVersion} ${metaInfo.deviceModel ? "- " + metaInfo.deviceModel : ""})` : "Web") : "-"}</td>
                                                            <td><button className="btn btn-sm btn-info" onClick={() => this.viewModal("imageVideo", key)}>View</button></td>
                                                            <td>
                                                                {//"Issue Fixed"
                                                                    dlist.status === issueBeingTested ? <div>Fixed - We will update next release.</div> : <div>
                                                                        {`${dlist.status} `}
                                                                        <br />
                                                                        <b>{dlist.statusDescription.substring(0, 50)} {dlist.statusDescription.length > 50 && <span>...<a className="read-more-comp" onClick={() => this.viewModal("status", key)}>Read More</a></span>}</b>
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>{this.humanDateFormat(dlist.createdAt)}</td>
                                                            <td><Link className="btn btn-sm btn-success" to={MyConstant.keyList.projectUrl + "/admin/admin-editfeedback/" + key}>Edit</Link></td>
                                                            <td>{dlist.completedAt > 0 ? this.humanDateFormat(dlist.completedAt) : " - "}</td>
                                                        </tr>
                                                    }) : <tr>
                                                        <td colSpan="9" className="text-center">No record(s) found.</td>
                                                    </tr>
                                                }
                                            </tbody>

                                        </table>
                                    </div>
                                    <Pagination
                                        pagination={pagination}
                                        prev={noPagesLimit > 1 ? true : false}
                                        next={noPagesLimit != pageCount ? true : false} movePage={(type) => {
                                            this.movePage(type)
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                viewModal && <Modal
                    visible={viewModal}
                    size={"modal-xl"}
                    closeModal={() => this.setState({ viewModal: false })}
                    heading={"Feedback - " + viewDetails.title}
                    body={<React.Fragment>
                        <div className="row">
                            <div className="col-8">
                                {
                                    this.viewContent()
                                }
                            </div>
                            <div className="col-4">
                                <div className="tabs-vertical">
                                    <div className={`tab ${viewType === "refNo" ? "active" : ""}`} onClick={() => this.setState({ viewType: "refNo" })}>Ref No</div>
                                    <div className={`tab ${viewType === "title" ? "active" : ""}`} onClick={() => this.setState({ viewType: "title" })}>Title</div>
                                    <div className={`tab ${viewType === "description" ? "active" : ""}`} onClick={() => this.setState({ viewType: "description" })}>Description</div>
                                    <div className={`tab ${viewType === "device" ? "active" : ""}`} onClick={() => this.setState({ viewType: "device" })}>Device</div>
                                    {/* <div className={`tab ${viewType === "osVersion" ? "active" : ""}`} onClick={() => this.setState({ viewType: "osVersion" })}>OS version</div>
                                    <div className={`tab ${viewType === "version" ? "active" : ""}`} onClick={() => this.setState({ viewType: "version" })}>Version</div> */}
                                    <div className={`tab ${viewType === "imageVideo" ? "active" : ""}`} onClick={() => this.setState({ viewType: "imageVideo" })}>Image/Video/PDF</div>
                                    <div className={`tab ${viewType === "status" ? "active" : ""}`} onClick={() => this.setState({ viewType: "status" })}>Status</div>
                                    <div className={`tab ${viewType === "createdAt" ? "active" : ""}`} onClick={() => this.setState({ viewType: "createdAt" })}>Created At</div>
                                    <div className={`tab ${viewType === "completedAt" ? "active" : ""}`} onClick={() => this.setState({ viewType: "completedAt" })}>Completed At</div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    }
                />
            }
        </React.Fragment>;
    }
}

export default IssuesList;