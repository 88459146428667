import React, { useState } from 'react';

import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useLocation,
    withRouter
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ManageLogout from "./manage/ManageLogout"
import ManageSideMenu from './manage/ManageSideMenu';

import ReviewList from './admin/ReviewList';
import MedicationSearch from "./admin/MedicationSearch";
import FoodSearch from "./admin/FoodSearch";
import DoctorList from './admin/DoctorList';
import IssuesList from './admin/IssuesList';
import UsersList from './admin/UsersList';
import DoctorSearch from './admin/DoctorSearch';
import MedicationSearchNew from './admin/MedicationSearchNew';

import WorkSpace from './FileUpload/WorkSpace';
import DocumentUpload from './FileUpload/DocumentUpload';
import RulesList from './FileUpload/RulesList';
import UploadBatchMultipleFile from './FileUpload/UploadBatchMultipleFile';
import ViewUploadMultipleTestFile from './FileUpload/ViewUploadMultipleTestFile';
import ViewMultipleBatchSheet from './FileUpload/ViewMultipleBatchSheet';
import TableSheet from './FileUpload/TableSheet';
import Dashboard from './admin/Dashboard';
import UiList from './admin/UiList';
import UiManager from './admin/UiManager';
import UILanguage from './admin/UILanguage';
import ManageUserCreate from './admin/ManageUserCreate';
import RoleType from './admin/RoleType';
import PageCreated from './admin/PageCreated';
import MyProfile from './admin/MyProfile';
import InviteList from './admin/InviteList';
import FeedbackStatus from './admin/FeedbackStatus';
import ArticleHierarchyData from './admin/ArticleHierarchyData';

import AddArticleCategory from './admin/AddArticleCategory';
import AddArticleSubCategory from './admin/AddArticleSubCategory';
import AddArticle from './admin/AddArticle';
import InfraApps from './admin/InfraApps';
import NoMatch from './pages/NoMatch';
import ForWho from './admin/ForWho';
import AdminAppIssues from './adminIssues/AdminAppIssues';
import AdminEditIssues from './adminIssues/AdminEditIssues';
import FeedBackList from './adminIssues/FeedBackList';
import AdminFeedbackStatus from './adminIssues/AdminFeedbackStatus';
import ArticleUserType from './admin/ArticleUserType';
import SpeakupSubscribeList from './admin/SpeakupSubscribeList';
import SpeakupUnsubscribeList from './admin/SpeakupUnsubscribeList';
import SpeakupSubmit from './admin/SpeakupSubmit';
import SpeakupDashboard from './admin/SpeakupDashboard';
import UiLanguageMaster from './admin/UiLanguageMaster'
import CreteWorkSpace from './admin/CreteWorkSpace';
import ResourceInterest from './admin/ResourceInterest';
import ICDSearch from './admin/ICDSearch';
import ContinuityOfCareList from './admin/ContinuityOfCareList';
import CommunityEmail from './admin/CommunityEmail';
import AppUsers from './admin/AppUsers';
import CaseSummary from './admin/CaseSummary';
import ResetPassword from './admin/ResetPassword';
import CaseSummaryShare from './admin/CaseSummaryShare';
import Community from './admin/Community';

import AddResource from './admin/AddResource';
import AddResourceCategory from './admin/AddResourceCategory';
import AddTeacher from './admin/AddTeacher';
import AddStudent from './admin/AddStudent';
import AddResourceSubCategory from './admin/AddResourceSubCategory';







import('./admin/style.css');

function AdminRoute(props) {
    let { path, url } = useRouteMatch();
    let location = useLocation();
    let [menuActive, setMenuActive] = useState("");

    var manageIsLogin = localStorage.getItem("managelogedin")
    var userProfilePhoto = localStorage.getItem("manage_profile")
    let [profilePhoto, setProfilePhoto] = useState(userProfilePhoto);
    let [pageUrl, setPageUrl] = useState("");

    var getRoleType = localStorage.getItem("login_RoleType")
    let translatorLogin = false;
    if (getRoleType && getRoleType === "TranslatorAdmin") {
        translatorLogin = true;
    }

    let returnMenu = ""
    let returnLogout = ""
    if (manageIsLogin == "manage") {
        returnMenu = <ManageSideMenu setMenuActive={setMenuActive} menuActive={menuActive} typeofPage={"medicationsearch"} navigation={props.history}
            grpcClient={props.grpcClient} location={location} pageUrl={pageUrl} />
        returnLogout = <ManageLogout navigation={props.history} userProfilePhoto={profilePhoto} />
    }
    let routerArray = [`${path}/medication`, `${path}/reviewlist`, `${path}/medicationSearch`, `${path}/food-search`, `${path}/doctorlist`, `${path}/feedbacklist`, `${path}/invitelist`, `${path}/dashboard`,
    `${path}/userslist`, `${path}/doctorsearch`, `${path}/medication`, `${path}/medication`, `${path}/documentUpload`, `${path}/rules-list/:docsId`, `${path}/uploadbatchmultiplefile/:docsId/:ruleId`, `${path}/viewuploadmultipletestfile/:docsId/:ruleId`, `${path}/viewmultiplebatchsheet/:docsId/:ruleId`, `${path}/tablesheet/:docsId`, `${path}/feedbackBasedStatus`, `${path}/userCreate`, `${path}/roletype`, `${path}/pagecreate`, `${path}/myprofile`, `${path}/articlehierarchydata`, `${path}/uilist`, `${path}/uistructure`, `${path}/uilang`, `${path}/articlecategory`, `${path}/articlesubcategory`, `${path}/article`, `${path}/forwho`, `${path}/admin-feedback`, `${path}/admin-editfeedback/`,
    `${path}/admin-feedbacklist`, `${path}/admin-feedbackBasedStatus`, `${path}/articleusertype`, `${path}/speakup-dashboard`, `${path}/speakup-subscribe-list`, `${path}/speakup-unsubscribe-list`, `${path}/speakup-submission`, `${path}/infra-apps`, `${path}/uilangmaster`, `${path}/createworkspace`, `${path}/resource-interest`, `${path}/icdsearch`, `${path}/continuityofcarelist`, `${path}/community-email`, `${path}/app-users`, `${path}/case-summary`, `${path}/case-summary-share`, `${path}/reset-password`, `${path}/community`, `${path}/add-resource`, `${path}/addresource-category`, `${path}/create-teacher`, `${path}/create-student`, `${path}/resource-subcategory`]

    var currentPath = routerArray.includes(location.pathname)
    var dashboardPage = ""
    if (location.pathname == `${path}/dashboard` || location.pathname == `${path}/feedbacklist`) {
        dashboardPage = "umedme"
    }

    let urlPath = location.pathname
    if (urlPath) {
        urlPath = urlPath.split("/")
        if (urlPath.length > 0) {
            urlPath.map((key) => {
                if (key == "admin-editfeedback") {
                    currentPath = true
                }
            })
        }

    }

    return (
        <div className={`wrapper ${dashboardPage} ${manageIsLogin ? "manageBgColor" : ""}`}>
            <ToastContainer />
            {currentPath && returnMenu}
            <div id="content">
                {currentPath && <nav className={`navbar navbar-expand-lg navbar-light bg-light menu ${menuActive ? "closed" : ""} ${translatorLogin ? "traslator-menu" : ""}`}>
                    <div className="container-fluid">
                        <button type="button" id="sidebarCollapse" className="btn btn-info" onClick={() => {
                            if (!menuActive) {
                                menuActive = "active"
                            }
                            else {
                                menuActive = ""
                            }
                            setMenuActive(menuActive)
                        }}>
                            <i className="fas fa-align-left"></i>
                            <span> {menuActive ? "Expand" : "Hide"} </span>
                        </button>
                        {returnLogout}
                    </div>
                </nav>}
                <div className='main-route-content'>
                    <Switch>
                        <Route exact path={`${path}/medication`}>
                            <MedicationSearchNew grpcClient={props.grpcClient} history={props.history} />
                        </Route>
                        <Route exact path={`${path}/reviewlist`} history={props.history}>
                            <ReviewList grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/medicationSearch`} history={props.history} >
                            <MedicationSearch grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/food-search`} history={props.history} >
                            <FoodSearch grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/doctorlist`} history={props.history} >
                            <DoctorList grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/feedbacklist`} history={props.history} >
                            <IssuesList grpcClient={props.grpcClient} speakupGrpcClient={props.speakupGrpcClient} {...props} />
                        </Route>
                        <Route exact path={`${path}/invitelist`} history={props.history} >
                            <InviteList grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/dashboard`} history={props.history} >
                            <Dashboard grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/userslist`} history={props.history} >
                            <UsersList grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/doctorsearch`} history={props.history} >
                            <DoctorSearch grpcClient={props.grpcClient}  {...props} />
                        </Route>

                        <Route exact path={`${path}/medication`} history={props.history} >
                            <MedicationSearchNew grpcClient={props.grpcClient}  {...props} />
                        </Route>

                        <Route exact path={`${path}/workspace`} history={props.history} >
                            <WorkSpace grpcClient={props.grpcClient}  {...props} />
                        </Route>

                        <Route exact path={`${path}/documentUpload`} history={props.history} >
                            <DocumentUpload grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/rules-list/:docsId`} history={props.history} >
                            <RulesList grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/uploadbatchmultiplefile/:docsId/:ruleId`} history={props.history} >
                            <UploadBatchMultipleFile grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/viewuploadmultipletestfile/:docsId/:ruleId`} history={props.history} >
                            <ViewUploadMultipleTestFile grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/viewmultiplebatchsheet/:docsId/:ruleId`} history={props.history} >
                            <ViewMultipleBatchSheet grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/tablesheet/:docsId`} history={props.history} >
                            <TableSheet grpcClient={props.grpcClient}  {...props} />
                        </Route>

                        <Route exact path={`${path}/feedbackBasedStatus`} search={"?status="} history={props.history} >
                            <FeedbackStatus grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/userCreate`} history={props.history} >
                            <ManageUserCreate grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/roletype`} history={props.history} >
                            <RoleType grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/pagecreate`} history={props.history} >
                            <PageCreated grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/myprofile`} history={props.history} >
                            <MyProfile grpcClient={props.grpcClient}  {...props} logoChange={(fileName) => {
                                setProfilePhoto(fileName)
                            }} />
                        </Route>
                        <Route exact path={`${path}/articlehierarchydata`} history={props.history} >
                            <ArticleHierarchyData grpcClient={props.grpcClient}  {...props} />
                        </Route>

                        {/* <Route exact path={`${path}/login`} history={props.history} >
                            <AdminLogin grpcClient={props.grpcClient}  {...props} />
                        </Route> */}
                        <Route exact path={`${path}/uilist`} history={props.history} >
                            <UiList grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/uistructure`} history={props.history} >
                            <UiManager grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/uilang`} history={props.history} >
                            <UILanguage grpcClient={props.grpcClient}  {...props} onChangePage={(e) => {
                                if (e) {
                                    let routeValue = e.routing ? e.routing : "user/home";
                                    setPageUrl(routeValue)
                                }
                            }} />
                        </Route>
                        <Route exact path={`${path}/articlecategory`} history={props.history} >
                            <AddArticleCategory grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/articlesubcategory`} history={props.history} >
                            <AddArticleSubCategory grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/article`} history={props.history} >
                            <AddArticle grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/forwho`} history={props.history} >
                            <ForWho grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/admin-feedback`} history={props.history} >
                            <AdminAppIssues grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/admin-editfeedback/:editId`} history={props.history} >
                            <AdminEditIssues grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/admin-feedbacklist`} history={props.history} >
                            <FeedBackList grpcClient={props.grpcClient}  {...props} />
                        </Route>

                        <Route exact path={`${path}/admin-feedbackBasedStatus`} search={"?status="} history={props.history} >
                            <AdminFeedbackStatus grpcClient={props.grpcClient}  {...props} />
                        </Route>

                        <Route exact path={`${path}/articleusertype`} history={props.history} >
                            <ArticleUserType grpcClient={props.grpcClient}  {...props} />
                        </Route>

                        <Route exact path={`${path}/speakup-dashboard`} history={props.history}>
                            <SpeakupDashboard {...props} />
                        </Route>
                        <Route exact path={`${path}/speakup-subscribe-list`} history={props.history}>
                            <SpeakupSubscribeList {...props} />
                        </Route>
                        <Route exact path={`${path}/speakup-unsubscribe-list`} history={props.history} >
                            <SpeakupUnsubscribeList {...props} />
                        </Route>
                        <Route exact path={`${path}/speakup-submission`} history={props.history} >
                            <SpeakupSubmit {...props} />
                        </Route>
                        <Route exact path={`${path}/infra-apps`} history={props.history} >
                            <InfraApps {...props} />
                        </Route>
                        <Route exact path={`${path}/uilangmaster`} history={props.history} >
                            <UiLanguageMaster {...props} />
                        </Route>
                        <Route exact path={`${path}/createworkspace`} history={props.history} >
                            <CreteWorkSpace grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/resource-interest`} history={props.history} >
                            <ResourceInterest grpcClient={props.grpcClient}  {...props} />
                        </Route>

                        <Route exact path={`${path}/icdsearch`} history={props.history} >
                            <ICDSearch grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/continuityofcarelist`} history={props.history} >
                            <ContinuityOfCareList grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/community-email`} history={props.history} >
                            <CommunityEmail grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/app-users`} history={props.history} >
                            <AppUsers grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/case-summary`} history={props.history} >
                            <CaseSummary grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/case-summary-share`} history={props.history} >
                            <CaseSummaryShare grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/community`} history={props.history} >
                            <Community grpcClient={props.grpcClient}  {...props} />
                        </Route>

                        <Route exact path={`${path}/reset-password`} history={props.history} >
                            <ResetPassword grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/add-resource`} history={props.history} >
                            <AddResource grpcClient={props.grpcClient}  {...props} />
                        </Route>

                        <Route exact path={`${path}/addresource-category`} history={props.history} >
                            <AddResourceCategory grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/create-teacher`} history={props.history} >
                            <AddTeacher grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/create-student`} history={props.history} >
                            <AddStudent grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/resource-subcategory`} history={props.history} >
                            <AddResourceSubCategory grpcClient={props.grpcClient}  {...props} />
                        </Route>

                        <Route path="*">
                            <NoMatch />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}
export default withRouter(AdminRoute);