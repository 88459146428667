import React, { Component } from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import SideManuBar from './SideMenuBar';
import image3 from '../images/Teqbahn_logo1.png';
import MyConfig from '../config/MyConfig';
import { dateConverter } from '../config/MyFunctions';
import { toast, ToastContainer } from "react-toastify";
import { pageNumbers, checkLogin, readSessionData, resetLocalData } from '../config/MyFunctions';
import Pagination from "./Pagination"
import ManageSideMenu from '../manage/ManageSideMenu';
import whiteLogo from '../images/whiteLogo.png';
import ManageLogout from "../manage/ManageLogout"
import AdminLogout from "./AdminLogout"






class PageCreated extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuActive: "",
            action: "Add",
            title_Name: "",
            title_Validate: "",
            processing: false,
            prev: false,
            next: false,
            noPagesLimit: 1,
            pageLimit: 10,
            pageCreate_Json: {},
            pagination: [],
            visibleClass: "",
            totalResultsCount: 0,
            mUserId: "",
            session: "",
            routingName: "",
            displayShow: true
        }
        this.myRef = React.createRef()
    }
    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        if (login_status) {
            document.body.style.backgroundColor = "#f6f7fb";
            let resData = await readSessionData()
            let { manage_session, manage_mUserId } = resData
            this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
                this.getPageList()
            })
        }

    }

    getPageList() {
        let { noPagesLimit, pageLimit, mUserId, session } = this.state
        let postJson = { pageLimit: pageLimit, noOfPage: noPagesLimit, mUserId, session }
        const request = new ZiFetchRequest();
        request.setDomain("adminPages");
        request.setEvent("list");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response == "Success") {
                    let next = false;
                    let prev = false;
                    let totalResult = json.totalResult
                    let currentResult = noPagesLimit * 10;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noPagesLimit > 1) {
                        prev = true;
                    }

                    let pageCount = Math.ceil(totalResult / 10);
                    let pagination = pageNumbers(pageCount, noPagesLimit, 5);
                    this.setState({
                        prev,
                        next,
                        pagination: pagination,
                        processing: false,
                        pageCreate_Json: json.result,
                        totalResultsCount: totalResult
                    });
                }
                else if (json.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }

    submit() {

        let { title_Name, routingName, mUserId, session } = this.state
        let postJson = { title: title_Name, route: routingName, mUserId, session };
        let title_Validate = ""
        let routingNameValidate = ""

        if (!title_Name) {
            title_Validate = "Enter Title Name"
        }

        if (!routingName) {
            routingNameValidate = "Enter Route Name"
        }

        this.setState({ title_Validate, routingNameValidate })
        if (!title_Name || !routingName) {
            return false
        }
        console.log("postJson", postJson)
        ///title, route, mUserId, session
        const request = new ZiFetchRequest();
        request.setDomain('adminPages');
        request.setEvent('create');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Page Created successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({ title_Name: "", routingName: "" }, () => {
                        this.getPageList()
                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }



    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }
        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getPageList()
            })
        }
    }


    scrollToTop() {
        this.myRef.current.scrollTo(0, 0);
    }

    async edit(data) {
        this.scrollToTop()
        this.setState({ action: "Edit", id: data["pageId"], title_Name: data["title"], routingName: data["route"], visibleClass: "bgClrChg" ,displayShow:false})
        setTimeout(() => {
            this.setState({ visibleClass: "" })
        }, 1000);
    }

    delete(data) {
        let { mUserId, session } = this.state
        let confirmMessage = window.confirm("Are You Sure Delete Page?")
        //pageId, mUserId, session
        let postJson = {
            mUserId: mUserId,
            pageId: data["pageId"],
            session, session
        };

        if (confirmMessage) {
            console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain('adminPages');
            request.setEvent('delete');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.log("response", response)
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    // console.log(responseMsg)
                    if (responseMsg.response == "Success") {
                        toast.success("Page  Deleted.", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        this.getPageList()
                    }
                    else if (responseMsg.response == "exist") {
                        toast.error("Please remove under the role mapping !", {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    else if (responseMsg.response == "Invalid Session Request") {
                        toast.warning("Your session got expired!", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        resetLocalData(this.props)
                    }
                }
            });
        }

    }

    Update() {
        let { mUserId, session, title_Name, routingName } = this.state
        //pageId, title, route, mUserId, session
        let postJson = {
            pageId: this.state.id,
            mUserId, session,
            title: title_Name,
            route: routingName
        };

        let title_Validate = ""
        let routingNameValidate = ""

        if (!title_Name) {
            title_Validate = "Enter Title Name"
        }

        if (!routingName) {
            routingNameValidate = "Enter Route Name"
        }

        this.setState({ title_Validate, routingNameValidate })
        if (!title_Name || !routingName) {
            return false
        }

        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('adminPages');
        request.setEvent('update');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Category Name Updated successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({ action: "Add", title_Name: "", routingName: "" }, () => {
                        this.getPageList()
                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });

    }

    render() {
        let { menuActive, title_Validate, pageCreate_Json, title_Name, pagination, next, prev, noPagesLimit, visibleClass, routingName, routingNameValidate, displayShow } = this.state
        import('../admin/style.css');
        let that = this
        var manageIsLogin = localStorage.getItem("managelogedin")


        let compnayLogo = image3
        if (manageIsLogin) {
            compnayLogo = whiteLogo
        }



        let tableData = []
        Object.keys(pageCreate_Json).forEach(function (key, index) {
            let data = pageCreate_Json[key]
            tableData.push(
                <tr >
                    <td style={{ width: "25%" }} className='tabletxt'>{data['title']}</td>
                    <td style={{ width: "25%" }} className='tabletxt'>{data['route']}</td>
                    <td style={{ width: "20%" }} className='tabletxt' >{dateConverter(data['createdAt'])}</td>
                    <td style={{ width: "10%" }} className='fofamily' ><div className={" editBtnstyle"} onClick={() => that.edit(data)}>Edit</div></td>
                    <td style={{ width: "10%" }} className='fofamily'><div className={"deleteBtnstyle"} onClick={() => that.delete(data)}>Delete</div></td>
                </tr>
            )
        });

        return (

            <div className="body-content left-rigt-padding cat-style" ref={this.myRef}>
                <main className="app-main">
                    <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>

                        <div className="container">
                            <div className={(visibleClass ? visibleClass : "") + " card box-shadow"} style={{ padding: 0 }}>
                                <div className="card-header border-btm-style cursor-style" onClick={() => {

                                    this.setState({ displayShow: !displayShow })
                                }}>
                                    <div className='row'>
                                        <div className='col-10 col-sm-11 col-md-11'>
                                            <h5 className='header-style' >{this.state.action} Pages</h5>
                                        </div>
                                        <div className='col-2 col-sm-1 col-md-1'>
                                            <span style={{ float: "right", color: "#fff", }}>
                                                {/* {displayShow ? <i class="fa fa-chevron-up"></i> : <i class="fa fa-chevron-down"></i>} */}
                                                {displayShow ? <i class="fa fa-plus"></i> : <i class="fa fa-minus"></i>}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body" style={displayShow ? { display: "none" } : {}}>
                                    <div className={"row"}>
                                        <div className={"col-md-2"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Title Name</label></div>
                                        <div className={"col-md-7"}>
                                            <input type="text" placeholder={"Enter Title "} className={"form-control inputRadius"} name={"label"} value={title_Name} onChange={e => {
                                                this.setState({ title_Name: e.target.value })
                                            }} />
                                            <span className='error-txt'>{title_Validate} </span>
                                        </div>
                                    </div>
                                    <br />

                                    <div className={"row"}>
                                        <div className={"col-md-2"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Routing Name</label></div>
                                        <div className={"col-md-7"}>
                                            <input type="text" placeholder={"Enter Routing "} className={"form-control inputRadius"} name={"label"} value={routingName} onChange={e => {
                                                this.setState({ routingName: e.target.value })
                                            }} />
                                            <span className='error-txt'>{routingNameValidate} </span>
                                        </div>
                                    </div>

                                    <div className={"row mt-3"}>
                                        <div className={"col-sm-4"}></div>
                                        <div className={"col-sm-4"}>
                                            {
                                                this.state.action == "Add" ? <input type="button" className="btn btn-primary ml-auto" value="Create" onClick={() => this.submit()} /> : <input type="button" className="btn btn-primary ml-auto" value="Update" onClick={() => this.Update()} />
                                            }
                                            {this.state.action == "Edit" && <input type="button" className="btn btn-danger ml-3" value="Cancel" onClick={() => {
                                                this.setState({ action: "Add", title_Name: "", title_Validate: "", routingName: "", routingNameValidate: "" })
                                            }} />}
                                        </div>
                                        <div className={"col-sm-4"}></div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="container mt-2">
                            <div className="card box-shadow" style={{ padding: 0 }}>
                                <div className="card-header border-btm-style" style={{ borderBottom: "none" }}>
                                    <h5 className='header-style' > Page List</h5>
                                </div>
                                <div className="card-body table-responsive" style={{ padding: 2 }}>
                                    <table className="table table-style light-border-clr" style={{ width: "100%", textAlign: "center" }} border={1} cellPadding={7}>
                                        <tr className='upperCase'>
                                            <th scope="col fw">Title</th>
                                            <th scope="col fw">Route</th>
                                            <th scope="col fw">Created At</th>
                                            <th scope="col fw">Edit</th>
                                            <th scope="col fw">Delete</th>

                                        </tr>
                                        <tbody>
                                            {tableData}
                                        </tbody>
                                    </table>

                                    <Pagination
                                        pagination={pagination}
                                        currentPage={noPagesLimit}
                                        prev={prev}
                                        next={next}
                                        movePage={(type) => {
                                            this.movePage(type)
                                        }} />

                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </div>
        )
    }
}

export default PageCreated;