import React, { Component, useRef, useEffect } from 'react';
// import Footer3 from './common/Footer3';
// import Header3 from './common/Header3';
import UmedMe_Show_Reel_Poster from './video/poster/UmedMe_Show_Reel.jpg'
import UmedMe_App_Demo_Poster from './video/poster/UmedMe_App_Demo.jpg'
import Private_Launch_Event_Poster from './video/poster/Private_Launch_Event.jpg'
import { userTrack } from './config/MyFunctions';


function VideoPlayer({ src, videoStretch }) {

    return (
        <video
            style={{ objectFit: videoStretch === "remove" ? "contain" : "fill" }}
            controls="controls"
            preload="auto"
            autobuffer="true"
        >
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    );
}

export default class VideosPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            slideContent: [{
                title: "Umed.Me Show Reel",
                url: "https://umedme-vid.s3.amazonaws.com/release/UmedMe_Show_Reel.mp4",
                poster: UmedMe_Show_Reel_Poster,
            }, {
                title: "Umed.Me App Demo",
                url: "https://umedme-vid.s3.amazonaws.com/release/UmedMe_App_Demo.mp4",
                poster: UmedMe_App_Demo_Poster
            }, {
                title: "Private Launch Event",
                url: "https://umedme-vid.s3.amazonaws.com/release/umedme-private-launch.mp4",
                poster: Private_Launch_Event_Poster
            }],
            slidewidth: 0,
            sliderWidth: 0,
            sliderTransform: 0,
            sliderActive: 0,
            playVideo: false,
            touchStart: 0,
            touchEnd: 0,
            moveEventStart: false
        }
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        userTrack("Videos Page", "Landing");
        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        let { slideContent, sliderActive } = this.state;
        let deviceWidth = document.body.clientWidth;
        var sliderTransform = sliderActive * deviceWidth;
        this.setState({
            slidewidth: deviceWidth,
            sliderWidth: deviceWidth * slideContent.length,
            sliderTransform: sliderTransform === 0 ? 0 : -sliderTransform
        });
    }

    setSliderActive(index, from) {
        let { slidewidth } = this.state;
        let sliderTransform = index * slidewidth;
        this.setState({
            sliderTransform: sliderTransform === 0 ? 0 : -sliderTransform,
            sliderActive: index,
            playVideo: false
        }, () => {
            if (from === "latest-videos") {
                window.scrollTo(0, 0)
            }
        })
    }
    playVideo() {
        this.setState({
            playVideo: true
        })
    }

    handleTouchStart(e) {
        let clientX = e.type == 'touchstart' ? e.targetTouches[0].clientX : e.clientX;
        this.setState({
            touchStart: clientX,
            moveEventStart: true
        })
    }

    handleTouchMove(e) {
        let { sliderActive, slidewidth, touchStart, sliderTransform, moveEventStart } = this.state;
        if (moveEventStart) {
            let clientX = e.type == 'touchmove' ? e.targetTouches[0].clientX : e.clientX;
            let currentWidth = sliderActive * slidewidth;
            let diff = clientX - touchStart;
            sliderTransform = currentWidth - diff

            this.setState({
                touchEnd: clientX,
                sliderTransform: -sliderTransform,
            })
        }
    }

    handleTouchEnd() {
        let { touchStart, touchEnd, sliderActive, slideContent } = this.state;
        this.setState({
            moveEventStart: false,
            touchStart: 0,
            touchEnd: 0,
        }, () => {
            if (touchEnd > 0) {
                if (touchStart - touchEnd > 50 && sliderActive < (slideContent.length - 1)) {
                    this.setSliderActive(sliderActive + 1, "slider");
                } else if (touchStart - touchEnd < -50 && sliderActive !== 0) {
                    this.setSliderActive(sliderActive - 1, "slider");
                } else {
                    this.setSliderActive(sliderActive, "slider");
                }
            }
        })
    }

    render() {
        let { slideContent, slidewidth, sliderWidth, sliderTransform, sliderActive, playVideo } = this.state;
        let { videoStretch } = this.props;
        return (<React.Fragment>
            <div>
                {/* <Header3 page="videos" /> */}
                <main>
                    <div className="video-container-outer">
                        <div
                            className={"video-container"}
                            style={{ width: sliderWidth, transform: `translate3d(${sliderTransform}px, 0px, 0px)` }}
                            onTouchStart={touchStartEvent => this.handleTouchStart(touchStartEvent)}
                            onTouchMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}
                            onTouchEnd={() => this.handleTouchEnd()}
                            onMouseDown={touchStartEvent => this.handleTouchStart(touchStartEvent)}
                            onMouseMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}
                            onMouseUp={() => this.handleTouchEnd()}
                        >
                            {
                                slideContent.slice(0, 3).map((video, videoIndex) => {
                                    return <React.Fragment key={videoIndex}>
                                        <div className="video-poster" key={videoIndex} style={{ width: slidewidth, position: "relative", maxHeight: "60vh", minHeight: 250, textAlign: "center" }}>
                                            {
                                                playVideo ? <div className="video-player">
                                                    <VideoPlayer src={video.url} videoStretch={videoStretch} />
                                                </div> : <React.Fragment>
                                                    <div className="play-icon-with-bg"></div>
                                                    <div className="play-icon" onClick={() => this.playVideo()}><i className="fas fa-play"></i></div>
                                                    <div className="video-title">{video.title}</div>
                                                    <img src={video.poster} style={{ width: "auto", height: "100%" }} />
                                                </React.Fragment>
                                            }
                                        </div>
                                    </React.Fragment>
                                })
                            }
                        </div>
                        {
                            <div className="circle-container">
                                <div className={`circle ${sliderActive === 0 ? "active" : ""}`} onClick={() => this.setSliderActive(0, "slider")}></div>
                                <div className={`circle ${sliderActive === 1 ? "active" : ""}`} onClick={() => this.setSliderActive(1, "slider")}></div>
                                <div className={`circle ${sliderActive === 2 ? "active" : ""}`} onClick={() => this.setSliderActive(2, "slider")}></div>
                            </div>
                        }
                    </div>
                    <div className="container my-5">
                        <div className="row">
                            <div className="col-12">
                                <h3>Latest Videos</h3>
                            </div>
                        </div>
                        <div className="row">
                            {
                                slideContent.map((video, videoIndex) => {
                                    return <div className={`col-sm-12 col-md-4 p-2`} key={videoIndex} onClick={() => this.setSliderActive(videoIndex, "latest-videos")}>
                                        <div className={`thumbnail-box ${sliderActive === videoIndex ? "active" : ""}`} style={{ position: "relative" }}>
                                            <img src={video.poster} style={{ width: "100%" }} />
                                            <div className="thumbnail-content">{video.title}</div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </main>
                {/* <Footer3 /> */}
            </div>
        </React.Fragment>
        );
    }
}
