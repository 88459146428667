import React, { useEffect, useState } from "react";
import { getUuidv4, readSessionData } from "../config/MyFunctions";
import PaginationDatatable from "./component/PaginationDatatable";
import Modal from "./component/Modal";
import CountryCodeSelect from "./component/CountryCodeSelect";
import DropDown from "./component/DropDown";
import { ZiFetchRequest } from "../protobuf/generated/ZiFetchService_grpc_web_pb";
import { toast, ToastContainer } from "react-toastify";
import countries from "../json/countries.json"

function CommunityEmail(props) {
    let [refresh, setRefresh] = useState(false);
    let [formModal, setFormModal] = useState(false);
    let [countryCode, setCountryCode] = useState("");
    let [communityName, setCommunityName] = useState("");
    let [emailId, setEmailId] = useState("");
    let [communityOption, setCommunityOption] = useState([]);
    let resData = readSessionData();
    let { manage_session, manage_mUserId } = resData

    useEffect(() => {
        getCommunity()
    }, [])
    
    useEffect(() => {
        setRefresh(!refresh)
    }, [communityOption])

    function getCommunity(){
        let postJson = { reqId: getUuidv4() }
        const request = new ZiFetchRequest();
        request.setDomain("adminUserManage");
        request.setEvent("getCommunityList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request') {
                console.warn('error', 'Invalid Request. Please try again later', "");
            } else {
                let json = JSON.parse(response.getMessage());
                let communityOption = [];
                let data = json.result;
                let keys = Object.keys(data);
                for (let i = 0; i < keys.length; i++) {
                    let key = keys[i];
                    communityOption.push({
                        label: data[key].communityName,
                        value: key
                    })
                }
                setCommunityOption(communityOption)
            }
        })

    }

    function updateStatus() {
        let postJson = { mUserId: manage_mUserId,session: manage_session,communityId: communityName, countryCode,emailId} 
        const request = new ZiFetchRequest();
        request.setDomain('adminUserManage');
        request.setEvent('updateCommunityEmailMapping');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
            }
            else {
                let responseMsg = JSON.parse(response.getMessage());
                if(responseMsg.response === "Success") {
                    setRefresh(!refresh)
                    setFormModal(false)
                    setCountryCode("")
                    setCommunityName("")
                    setEmailId("")
                    toast.success('Updated successfully..', {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            }
        });
    }
    return <div className="body-content left-rigt-padding cat-style" style={{ height: "auto" }}>
        <ToastContainer />
        <main className="app-main">
            <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>
                <div className="container">
                    <div className="card box-shadow" style={{ padding: 0 }}>
                        <div className="card-header border-btm-style" style={{ borderBottom: "none" }}>
                            <div className="row">
                                <div className='col-10 col-sm-11 col-md-11'>
                                    <h5 className='header-style' >Community Email</h5>
                                </div>
                                <div className='col-2 col-sm-1 col-md-1' onClick={() => {
                                    setFormModal(true)
                                }}>
                                    <span style={{ float: "right", color: "#fff", }}>
                                        <i class="fa fa-plus"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <PaginationDatatable
                                grpcClient={props.grpcClient}
                                domain={"adminUserManage"}
                                event={"getCommunityEmailMappingList"}
                                data={[
                                    {
                                        column: "Community Name",
                                        cell: (row, key) => {
                                            let communityName = communityOption.filter(option => option.value === row.communityId)
                                            return communityName .length > 0 ? communityName[0].label : "-"
                                        }
                                    },
                                    {
                                        column: "Country",
                                        cell: (row, key) => {
                                            let country = countries.countries.filter(option => option.value === row.countryCode)
                                            console.log("country", country)
                                            return country.length > 0 ? country[0].label : "-"
                                        }
                                    },
                                    {
                                        column: "Email",
                                        cell: (row, key) => {
                                            return row.emailId
                                        }
                                    },
                                    {
                                        column: "Edit",
                                        cell: (row, key) => {
                                            return <button className="btn btn-sm btn-success" onClick={() => {
                                                setCommunityName(row.communityId)
                                                setCountryCode(row.countryCode)
                                                setEmailId(row.emailId)
                                                setFormModal(true)
                                            }}>Edit</button>
                                        }
                                    }
                                ]}
                                postJsonInfo={{ mUserId: manage_mUserId, session: manage_session }}
                                refresh={refresh}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </main>

        {
            formModal && <Modal
                visible={formModal}
                closeModal={() => setFormModal(false)}
                heading={`Update Community Email`}
                body={<React.Fragment>

                    <div className="form-group">
                        <label>Community Name</label>
                        <DropDown
                            selectedOption={communityOption.filter(option => option.value === communityName)}
                            onChange={(e) => setCommunityName(e.value)}
                            options={communityOption}
                            isDisabled={false}
                            isMulti={false}
                            placeholder={"Select Community name"}
                        />
                    </div>
                    <div className="form-group">
                        <label>Country</label>
                        <CountryCodeSelect
                            countryCode={countryCode}
                            onChange={(obj) => {
                                setCountryCode(obj.value)
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input className="form-control" value={emailId} onChange={(e) => {
                            setEmailId(e.target.value)
                        }} />
                    </div>
                </React.Fragment>
                }
                footer={<React.Fragment>
                    <button type="button" className="btn theme-bg-manage white-bold" onClick={() => {
                        updateStatus()
                    }}>Save changes</button>
                </React.Fragment>}
            />
        }
    </div>;
}

export default CommunityEmail;