import React, { Component } from 'react';
// import Pagination from "./Pagination"
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import image3 from '../images/Teqbahn_logo1.png';
import SideManuBar from '../admin/SideMenuBar';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { v4 as uuid } from 'uuid';
import MyConstant from '../config/MyConstant';
import { loginStatusCheck, checkLogin } from '../config/MyFunctions';
import AdminLogout from "../admin/AdminLogout"
import ManageSideMenu from '../manage/ManageSideMenu';
import ManageLogout from "../manage/ManageLogout"





class DocumentUpload extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            documentFiles: [],
            menuActive: "",
            document_Json: [],
            docsTitle: "",
            successMsg: false
        }
        this.documentOnDrop = this.documentOnDrop.bind(this);
    }
    async componentDidMount() {
        await checkLogin(this.props)
    }



    async documentOnDrop(newFiles) {
        var documentFiles = [];
        var document_Json = [];
        for (var i = 0; i < newFiles.length; i++) {
            documentFiles.push(newFiles[i]);
            document_Json.push({
                name: this.state.selectTypeVlaue,
                path: newFiles[i],
                status: 'active'
            })
        }
        this.setState({
            documentFiles: documentFiles, document_Json: document_Json
        });
    }


    async documentOnRemove(loopIndex) {
        var document_Json = this.state.document_Json;
        var documentFiles = this.state.documentFiles;
        document_Json.splice(loopIndex, 1);
        documentFiles.splice(loopIndex, 1);
        this.setState({
            document_Json: document_Json, documentFiles: documentFiles
        });

    }


    async submitDocuments(alertKey) {
        let uid = uuid();
        var that = this;
        var document_Json = this.state.document_Json;
        var documentFiles = this.state.documentFiles;
        var documentArrayString = [];
        var newDocumentArrayString = [];
        for (var i = 0; i < documentFiles.length; i++) {
            var file = documentFiles[i];
            var fileName = file.name;
            var path_splitted = fileName.split('.');
            var extension = path_splitted.pop();
            var getUidString = uid + "." + extension;
            documentArrayString.push(getUidString)
            newDocumentArrayString.push({
                name: document_Json[i]['name'],
                path: getUidString,
                status: document_Json[i]['status']
            })
        }
        if (document_Json.length > 0) {
            let docsName = documentArrayString[0]
            var files = this.state.documentFiles;
            var file = ""
            var fileName = ""
            for (var i = 0; i < files.length; i++) {
                file = files[i];
                fileName = file.name;
                var path_splitted = fileName.split('.');
                var extension = path_splitted.pop();
            }
            this.UploadPost(uid, fileName, file)

        }
    }


    async UploadPost(uid, docsName, file) {
        var that = this
        let dirPath = "base"
        let eventType = "doCamelotProcess"
        var newFileName = docsName
        var subPath = "admin"
        var uri = ""
        var fileSubDir = dirPath + "/" + subPath
        let fileData = new FormData();
        fileData.append('fileName1', newFileName);
        fileData.append('processType', dirPath);
        fileData.append('docsId', uid);
        fileData.append('userId', subPath);
        fileData.append('file1', file);

        var res = await fetch(MyConstant.keyList.apiURL + "uploadDocs/" + dirPath + "/" + uid + "/" + subPath, {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: fileData,
        }).then((response) => response.json())
            .then((responseData) => {
                return responseData.response;
            })
            .catch(error => console.warn(error));

        console.log("res", res)

        if (res == "Success") {
            let postJson = {
                sessionId: "1234", userId: "admin", docsName: docsName, docsId: uid,
                documentParserName: this.state.docsTitle, noOfPages: "", reqId: ""
            }
            console.log("post createDoPar", postJson)
            const request = new ZiFetchRequest();
            request.setDomain('admin');
            request.setEvent('createDocumentParser');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.log("response", response)
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    console.log("response", responseMsg)
                    this.setState({ successMsg: true })

                    setTimeout(function () {
                        that.props.history.push(MyConstant.keyList.projectUrl + "/admin/workspace")
                    }, 1000)
                }
            });
        }

    }



    render() {
        let { menuActive, successMsg } = this.state
        import('../admin/style.css');
        var that = this

        var manageIsLogin = localStorage.getItem("managelogedin")
        let returnMenu = <SideManuBar menuActive={menuActive} typeofPage={"workspace"} navigation={this.props.history} />
        let returnLogout = <AdminLogout navigation={this.props.history} />
        if (manageIsLogin == "manage") {
            returnMenu = <ManageSideMenu menuActive={menuActive} typeofPage={"workspace"} navigation={this.props.history} grpcClient={this.props.grpcClient} />
            returnLogout = <ManageLogout navigation={this.props.history} />
        }


        return (
            <>
                <div className={`wrapper ${manageIsLogin ? "manageBgColor" : ""}`}>
                    {returnMenu}
                    <div id="content">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <div className="container-fluid">

                                <button type="button" id="sidebarCollapse" className="btn btn-info" onClick={() => {

                                    if (!menuActive) {
                                        menuActive = "active"
                                    }
                                    else {
                                        menuActive = ""
                                    }
                                    this.setState({ menuActive })
                                }}>
                                    <i className="fas fa-align-left"></i>
                                    <span> {menuActive ? "Expand" : "Hide"} </span>
                                </button>
                                {returnLogout}
                            </div>
                        </nav>
                        <div className="body-content" style={{ paddingLeft: 20 }}>

                            <div className='card card-table'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='col-sm-2'>
                                            <Link to='/admin/workspace'>
                                                <a class="btn btn-primary btn-bg-clr"> Back</a>
                                            </Link>
                                        </div>
                                        <h4 class="panel-title text-center"><u>Documents Upload</u></h4>
                                    </div>
                                    <div class="col-lg-12"><hr className='hr-bg-color' /><br /></div>
                                </div>

                                {
                                    successMsg ?
                                        <div className="row">
                                            <div className="col-sm-5"></div>
                                            <div className="col-sm-5">
                                                <h5 className="success"> Document Upload Successfully!...</h5>
                                            </div>
                                        </div>
                                        : null
                                }

                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-sm-3"></div>
                                        <div className="col-sm-2">
                                            <label>Documents Name</label>
                                        </div>
                                        <div className="col-sm-4">
                                            <input type="text" className="hs-input" value={that.state.docsTitle} onChange={(e) => this.setState({ docsTitle: e.target.value })} />
                                        </div>
                                    </div>
                                </div>


                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-sm-3"></div>
                                        <div className="col-sm-2">
                                            <label>Upload Documents</label>
                                        </div>
                                        <div className="col-sm-2">
                                            <Dropzone accept=".pdf" multiple={false} onDrop={acceptedFiles => that.documentOnDrop(acceptedFiles)}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                        <div {...getRootProps({ className: 'dropzone' })}>
                                                            <input {...getInputProps()} />
                                                            <p>Try dropping some files here, or click to select files to upload.</p>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                        <div className="col-sm-3">
                                            <ol>
                                                {
                                                    this.state.documentFiles.map((f, loopIndex) =>
                                                        <div className="row">
                                                            <br />
                                                            <li key={loopIndex} style={{ padding: 5, border: '5px solid', margin: "auto" }}>
                                                                <div className="col-sm-12">
                                                                    <a href="javascript:void(0)" style={{ color: 'red', float: 'right', padding: 5 }} onClick={() => that.documentOnRemove(loopIndex)}>X</a>
                                                                    {f.name}
                                                                    <br />
                                                                    <b>File Size :</b>{f.size} bytes
                                                                </div>

                                                            </li>
                                                        </div>
                                                    )
                                                }
                                            </ol>
                                        </div>
                                    </div>
                                </div>


                                <div className="form-group">
                                    {
                                        this.state.documentFiles.length > 0 ?
                                            <div className="col-sm-12 text-center" >
                                                <button type="button" className="btn btn-primary"
                                                    onClick={() => that.submitDocuments(true)}>Submit
                                                </button>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default DocumentUpload;