import React, { Component } from 'react';
import Pagination from "./Pagination"
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import image1 from '../images/UMEDME_logo_white.png';
import image2 from '../images/UMEDME_new.png';
import image3 from '../images/Teqbahn_logo1.png';
import SideManuBar from './SideMenuBar';
import { pageNumbers } from '../config/MyFunctions';
import AdminLogout from "./AdminLogout"
import ManageLogout from "../manage/ManageLogout"
import ManageSideMenu from '../manage/ManageSideMenu';
import { checkLogin } from '../config/MyFunctions';


class DoctorList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            DoctorList: [],
            menuActive: "",
            search: "",
            resultArray: [],
            noPagesLimit: 1,
            totalPagesResults: "",
            processing: true,
            prev: false,
            next: false,
            pagination: [],
        }
    }
    async componentDidMount() {
        var loginincheck = await await checkLogin(this.props)
        this.getAllDoctorsList()
    }

    getAllDoctorsList() {
        // pageLimit: Int, noOfPage:Int, totalResult: Long
        let { tableData, totalPagesResults, noPagesLimit } = this.state
        let postJson = { auth: 'Teqbahn@321#', pageLimit: 10, noOfPage: noPagesLimit, totalResult: 10 }
        const request = new ZiFetchRequest();
        request.setDomain('admin');
        request.setEvent('getDoctorsList');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {

            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg && responseMsg.result) {
                    let responseData = responseMsg.result
                    let next = false;
                    let prev = false;
                    let totalResult = responseMsg.totalResult
                    let currentResult = noPagesLimit * 10;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noPagesLimit > 1) {
                        prev = true;
                    }

                    let pageCount = Math.ceil(totalResult / 10);
                    let pagination = pageNumbers(pageCount, noPagesLimit, 5);
                    this.setState({
                        issueList: responseData,
                        prev,
                        next,
                        pagination: pagination,
                        processing: false
                    });

                    console.log("responseData", responseData)

                    this.setState({
                        search: "",
                        DoctorList: responseData,
                        prev,
                        next,
                        pagination: pagination,
                        processing: false
                    })
                }


            }
        });
    }

    onSearch = async (e) => {
        let { DoctorList } = this.state;
        let search = e.target.value.toString().toLowerCase();
        let resultArray = [];

        DoctorList.map(ival => {
            let isThere = false;
            Object.values(ival).map(jval => {
                if (jval !== null) {
                    if (jval.toString().toLowerCase().indexOf(search) !== -1) {
                        isThere = true;
                    }
                }
            })
            if (isThere) {
                resultArray.push(ival);
            }
        })

        await this.setState({ resultArray, search });


    }

    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }

        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getAllDoctorsList();
            })
        }
    }

    render() {
        let { menuActive, DoctorList, resultArray, search, pagination, noPagesLimit, totalPagesResults, processing, prev, next } = this.state
        import('./style.css');
        var SearchList = search.trim() || resultArray.length ? [...resultArray] : [...DoctorList];


        return (
            <div className="body-content p-top">
                <div className="row mb-2">
                    <div className="col-2 ml-auto">
                        <input type="text" placeholder="search" className="form-control" value={this.state.search} onChange={this.onSearch} />
                    </div>
                </div>

                <div className={`row ${processing && "table-processing"}`}>
                    {processing && <div className="processing"><span>Processing...</span></div>}
                    <div className="col-md-12">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Mobile Number</th>
                                    <th scope="col">Address</th>
                                </tr>
                            </thead>
                            <tbody >
                                {SearchList.map((dlist, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td >{dlist.email}</td>
                                            <td>{dlist.fullName}</td>
                                            <td>{dlist.MobileNum}</td>
                                            <td>{dlist.address ? dlist.address : "-"}</td>

                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>
                        <Pagination
                            pagination={pagination}
                            currentPage={noPagesLimit}
                            prev={prev}
                            next={next}
                            movePage={(type) => {
                                this.movePage(type)
                            }} />
                    </div>
                </div>
            </div>
        )
    }
}

export default DoctorList;