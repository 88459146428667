import React, { Component } from 'react';
import SideManuBar from '../admin/SideMenuBar';
import image3 from '../images/Teqbahn_logo1.png';
import MyConstant from '../config/MyConstant';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import { timeConverter, loginStatusCheck } from '../config/MyFunctions';
import ManageSideMenu from '../manage/ManageSideMenu';
import ManageLogout from "../manage/ManageLogout"
import AdminLogout from "../admin/AdminLogout"






class ViewUploadMultipleTestFile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ruleId: "",
            userId: "admin",
            docsId: "",
            responseData: {}
        }
    }
    backPage() {
        this.props.history.push(MyConstant.keyList.projectUrl + "/admin/rules-list/" + this.state.docsId)
    }
    async componentDidMount() {
        let login_status = await loginStatusCheck(this.props)
        if (login_status) {
            let { userId } = this.state
            var docsId = this.props.match.params['docsId'];
            var ruleId = this.props.match.params['ruleId'];
            var sessionId = "1234"
            this.getTestDocumentList(userId, ruleId, sessionId, docsId);
            this.setState({ docsId: docsId, ruleId: ruleId })
        }

    }


    async getTestDocumentList(userId, ruleId, sessionId, docsId) {
        //getTestDocumentList
        //userId:String,rulesId: String, docsId: String
        let postJson = { userId: userId, rulesId: ruleId, docsId: docsId }
        const request = new ZiFetchRequest();
        request.setDomain('admin');
        request.setEvent('getTestDocumentList');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                console.log("response", responseMsg)
                this.setState({ responseData: responseMsg.response })

            }
        });

    }
    render() {
        let { menuActive, responseData } = this.state
        import('../admin/style.css');
        var that = this
        var docsProcessList = [];
        var docsUploadedList = [];
        var docsCompletedList = [];
        var jsonData = responseData
        var dirPath = "admin/test"
        Object.keys(jsonData).forEach(function (key) {

            var docjson = jsonData[key];
            var docs_id = docjson['docs_id'];
            if (docjson.status == "process") {
                docsProcessList.push(<tr>
                    <td>{docjson.document_parser_name} </td>
                    <td>{docjson.docs_name}</td>
                    <td>{docjson.no_of_pages}</td>
                    <td><a target={"_blank"} href={MyConstant.keyList.apiURL + "vp?action=" + dirPath + "&key=" + docjson.docs_name + "&id=" + docs_id} style={{ color: "blue" }} >View</a></td>
                    <td>{timeConverter(docjson.converted_at)}</td>
                </tr>)
            }
            if (docjson.status == "uploaded") {
                docsUploadedList.push(<tr>
                    <td>{docjson.document_parser_name} </td>
                    <td>{docjson.docs_name}</td>
                    <td>{docjson.no_of_pages}</td>
                    <td><a target={"_blank"} href={MyConstant.keyList.apiURL + "vp?action=" + dirPath + "&key=" + docjson.docs_name + "&id=" + docs_id} style={{ color: "blue" }} >View</a></td>
                    <td>{timeConverter(docjson.uploaded_at)}</td>
                </tr>)
            } if (docjson.status == "completed") {
                docsCompletedList.push(<tr>
                    <td>{docjson.document_parser_name} </td>
                    <td>{docjson.docs_name}</td>
                    <td>{docjson.no_of_pages}</td>
                    <td><a target={"_blank"} href={MyConstant.keyList.apiURL + "vp?action=" + dirPath + "&key=" + docjson.docs_name + "&id=" + docs_id} style={{ color: "blue" }} >View</a></td>
                    <td>{timeConverter(docjson.completed_at)}</td>
                </tr>)
            }
        })


        var manageIsLogin = localStorage.getItem("managelogedin")
        let returnMenu = <SideManuBar menuActive={menuActive} typeofPage={"workspace"} navigation={this.props.history} />
        let returnLogout = <AdminLogout navigation={this.props.history} />
        if (manageIsLogin == "manage") {
            returnMenu = <ManageSideMenu menuActive={menuActive} typeofPage={"workspace"} navigation={this.props.history} grpcClient={this.props.grpcClient} />
            returnLogout = <ManageLogout navigation={this.props.history} />
        }

        return (
            <>
                <div className={`wrapper ${manageIsLogin ? "manageBgColor" : ""}`}>
                    {returnMenu}
                    <div id="content">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <div className="container-fluid">

                                <button type="button" id="sidebarCollapse" className="btn btn-info" onClick={() => {

                                    if (!menuActive) {
                                        menuActive = "active"
                                    }
                                    else {
                                        menuActive = ""
                                    }

                                    this.setState({ menuActive })
                                }}>
                                    <i className="fas fa-align-left"></i>
                                    <span> {menuActive ? "Expand" : "Hide"} </span>
                                </button>
                                {returnLogout}
                            </div>
                        </nav>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="col-sm-2">
                                        <button className={"btn btn-primary btn-primary btn-bg-styl"} onClick={(e) => that.backPage()}>BACK</button>
                                    </div>
                                    <div className={"col-lg-12"}>
                                        <br />
                                    </div>
                                    <div className={"col-lg-12"}>
                                        <Tabs>
                                            <TabList>
                                                <Tab>Uploaded Queue</Tab>
                                                <Tab>Processing Queue</Tab>
                                                <Tab>Completed</Tab>
                                            </TabList>

                                            <TabPanel>
                                                <div className="bootstrap-data-table-panel">
                                                    <div className="table-responsive">
                                                        {
                                                            docsUploadedList.length > 0 ?
                                                                <table className="table table-striped table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Docs Parser</th>
                                                                            <th>Docs Name</th>
                                                                            <th>No Of Pages</th>
                                                                            <th>View</th>
                                                                            <th>Uploaded Date and Time</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {docsUploadedList}
                                                                    </tbody>
                                                                </table>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="bootstrap-data-table-panel">
                                                    <div className="table-responsive">
                                                        {
                                                            docsProcessList.length > 0 ?
                                                                <table className="table table-striped table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Docs Parser</th>
                                                                            <th>Docs Name</th>
                                                                            <th>No Of Pages</th>
                                                                            <th>View</th>
                                                                            <th>Converted Date and Time</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {docsProcessList}
                                                                    </tbody>
                                                                </table>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="bootstrap-data-table-panel">
                                                    <div className="table-responsive">
                                                        {
                                                            docsCompletedList.length > 0 ?
                                                                <table className="table table-striped table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Docs Parser</th>
                                                                            <th>Docs Name</th>
                                                                            <th>No Of Pages</th>
                                                                            <th>View</th>
                                                                            <th>Completed Date and Time</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {docsCompletedList}
                                                                    </tbody>
                                                                </table>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </Tabs>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ViewUploadMultipleTestFile;