import React, { useState } from "react";
import { readSessionData } from "../config/MyFunctions";
import PaginationDatatable from "./component/PaginationDatatable";

function AppUsers(props) {
    let [refresh, setRefresh] = useState(false);

    let resData = readSessionData();
    let { manage_session, manage_mUserId } = resData

    return <div className="body-content left-rigt-padding cat-style" style={{ height: "auto" }}>
        <main className="app-main">
            <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>
                <div className="container">
                    <div className="card box-shadow" style={{ padding: 0 }}>
                        <div className="card-header border-btm-style" style={{ borderBottom: "none" }}>
                            <div className="row">
                                <div className='col-10 col-sm-11 col-md-11'>
                                    <h5 className='header-style' >Users</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <PaginationDatatable
                                grpcClient={props.grpcClient}
                                domain={"adminUserManage"}
                                event={"getCommunityEmailMappingList"}
                                data={[
                                   
                                ]}
                                postJsonInfo={{ mUserId: manage_mUserId, session: manage_session }}
                                refresh={refresh}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>;
}

export default AppUsers;