import React, { useEffect, useState } from 'react';

import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useLocation,
    withRouter
} from "react-router-dom";
import SideManuBar from './admin/SideMenuBar';
import AdminLogout from "./admin/AdminLogout"
import ManageLogout from "./manage/ManageLogout"
import ManageSideMenu from './manage/ManageSideMenu';
import AddArticleCategory from './admin/AddArticleCategory';
import AddArticleSubCategory from './admin/AddArticleSubCategory';
import AddArticle from './admin/AddArticle';
import { toast, ToastContainer } from "react-toastify";
import NoMatch from './pages/NoMatch';

function ManageRoute(props) {
    let { path, url } = useRouteMatch();
    let location = useLocation();
    let [menuActive, setMenuActive] = useState("");

    var manageIsLogin = localStorage.getItem("managelogedin")
    let returnMenu = ""
    let returnLogout = ""
    if (manageIsLogin == "manage") {
        returnMenu = <ManageSideMenu setMenuActive={setMenuActive} menuActive={menuActive} typeofPage={"medicationsearch"} navigation={props.history}
            grpcClient={props.grpcClient} location={location} />
        returnLogout = <ManageLogout navigation={props.history} />
    }
    let routerArray = [`${path}/articlecategory`, `${path}/articlesubcategory`, `${path}/article`]
    var currentPath = routerArray.includes(location.pathname)

    return (
        <div className={`wrapper ${manageIsLogin ? "manageBgColor" : ""}`}>
            <ToastContainer />
            {currentPath && returnMenu}
            <div id="content">
                {currentPath && <nav className="navbar navbar-expand-lg navbar-light bg-light menu">
                    <div className="container-fluid">
                        <button type="button" id="sidebarCollapse" className="btn btn-info" onClick={() => {
                            if (!menuActive) {
                                menuActive = "active"
                            }
                            else {
                                menuActive = ""
                            }
                            setMenuActive(menuActive)
                        }}>
                            <i className="fas fa-align-left"></i>
                            <span> {menuActive ? "Expand" : "Hide"} </span>
                        </button>
                        {returnLogout}
                    </div>
                </nav>}
                <div className='main-route-content'>
                    <Switch>
                        <Route exact path={`${path}/articlecategory`} history={props.history} >
                            <AddArticleCategory grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/articlesubcategory`} history={props.history} >
                            <AddArticleSubCategory grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route exact path={`${path}/article`} history={props.history} >
                            <AddArticle grpcClient={props.grpcClient}  {...props} />
                        </Route>
                        <Route path="*">
                            <NoMatch />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}
export default withRouter(ManageRoute);