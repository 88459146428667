import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Logo from '../images/UMEDME_logo_wht.png';
import MyConstant from '../config/MyConstant';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    menuClick() {
        window.scrollTo(0, 0);
    }
    render() {
        let {page} = this.props;
        return (
            <React.Fragment>
                <div id="header" className="fixed-top">
                    <nav className="navbar navbar-expand-md navbar-dark bg-dark navbar-container" >
                        <Link className="navbar-brand" to={MyConstant.keyList.projectUrl+"/umedme/home"} >
                            <img src={Logo} />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <ul className="navbar-nav ml-auto">
                                <li className={page === "home" ? "nav-item px-2 active" : "nav-item px-2"} >
                                    <Link to={MyConstant.keyList.projectUrl+"/umedme/home"} className="nav-link" onClick={ () => this.menuClick() }>HOME</Link>
                                </li>
                                <li className={page === "about" ? "nav-item px-2 active" : "nav-item px-2"} >
                                    <Link to={MyConstant.keyList.projectUrl+"/umedme/about"} className="nav-link" onClick={ () => this.menuClick() }>ABOUT</Link>
                                </li>
                                {/* <li className={page === "contact" ? "nav-item px-2 active" : "nav-item px-2"} >
                                    <a className="nav-link" href="#">CONTACT</a>
                                </li>
                                <li className={page === "signup" ? "nav-item px-2 active" : "nav-item px-2"}>
                                    <a className="nav-link" href="#">SIGNUP</a>
                                </li> */}
                            </ul>
                        </div>
                    </nav>
                </div>
            </React.Fragment>
        )
    }
}