import React, { Component } from 'react';
// import './style.css';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import image3 from '../images/Teqbahn_logo1.png';
import SideManuBar from './SideMenuBar';
import Pagination from "./Pagination"
import Tabs from './component/Tabs';
import { checkLogin } from '../config/MyFunctions';
import AdminLogout from "./AdminLogout"
import ManageLogout from "../manage/ManageLogout"
import ManageSideMenu from '../manage/ManageSideMenu';


class MedicationSearchList extends Component {
    render() {
        let { searchData, pagination, noOfPage, prev, next, movePage, getDetails } = this.props;
        return searchData.length > 0 && <div className="result">
            {
                searchData.map((data, index) => {
                    return <div className="result-label" key={index} onClick={() => getDetails(data.value, data.label)}>{data.label}</div>
                })
            }
            <div className="py-5">
                <Pagination pagination={pagination} currentPage={noOfPage} prev={prev} next={next} movePage={movePage} />
            </div>
        </div>
    }
}
class MedicationSearchData extends Component {

    async componentDidMount() {
        let login_status = await checkLogin(this.props)

    }
    render() {
        let { loading, medicationOveralldata } = this.props;
        var keysData = Object.keys(medicationOveralldata)
        var valuesData = Object.values(medicationOveralldata)
        return <div>
            {loading && <div className="loader lodercenter"></div>}
            {
                keysData.length > 0 && <div className="p-5"><table className="table table-bordered">
                    {
                        keysData.map((kval, k) => {
                            return <tr>
                                <td className="table-first-column-unique">{kval.replace(/_/g, ' ')}</td>
                                <td>{typeof valuesData[k] === 'string' ? valuesData[k] : <React.Fragment>
                                    {
                                        Array.isArray(valuesData[k]) ? valuesData[k].map((value, valueIndex) => {
                                            return kval === "active_ingredients" ? <React.Fragment><div className="row mx-0">
                                                <div className="col-4">Name</div>
                                                <div className="col-8">{value.name}</div>
                                            </div>
                                                <hr />
                                                <div className="row mx-0">
                                                    <div className="col-4">Strength</div>
                                                    <div className="col-8">{value.strength}</div>
                                                </div>
                                            </React.Fragment> : <div dangerouslySetInnerHTML={{ __html: value }} key={valueIndex} />
                                        }) : <table> {
                                            Object.keys(valuesData[k]).map((innerData, innerDataIndex) => {
                                                return <tr className="" key={innerDataIndex}>
                                                    <td className="table-first-column-unique">
                                                        {innerData.replace(/_/g, ' ')}
                                                    </td>
                                                    <td>
                                                        {valuesData[k][innerData]}
                                                    </td>
                                                </tr>
                                            })
                                        }</table>

                                    }
                                </React.Fragment>
                                }</td>
                            </tr>
                        })
                    }
                </table></div>
            }
        </div>
    }
}

export default class FoodSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuActive: "",
            searchData: [],
            searchText: "",
            totalResult: 0,
            view: "list",
            foodListData: [],
            loading: false,
            otherData: {},
            noOfPage: 1,
            pageLimit: 10,
            next: false,
            prev: false,
            pagination: [],
            medicationOveralldata: [],
            tabs: [],
            activeIndex: 0
        }
        this.movePage = this.movePage.bind(this)
        this.getDetails = this.getDetails.bind(this)
        this.setActiveTab = this.setActiveTab.bind(this)
        this.removeTab = this.removeTab.bind(this)
    }
    async componentDidMount() {
        var loginincheck = await checkLogin(this.props)

    }
    onFoodSearch = async (e) => {
        let searchText = e.target.value;
        this.setState({
            searchText
        })
    }

    getSearchData() {
        let { searchText, noOfPage, pageLimit, tabs } = this.state;
        let uniqId = localStorage.getItem("uniqId");
        if (!uniqId) {
            uniqId = 'id-' + (new Date()).getTime() + Math.random().toString(36).substr(2);
            localStorage.setItem("uniqId", uniqId)
        }

        let postJson = { sessionId: uniqId, searchText: searchText, searchType: "drug", pageLimit, noOfPage }
        const request = new ZiFetchRequest();
        request.setDomain('admin');
        request.setEvent('doSearch');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid request")
            } else {
                let data = JSON.parse(response.getMessage());
                if (data && data.resultList) {
                    let list = data.resultList;
                    let searchData = [];
                    list.map((key, keyIndex) => {
                        var dataArr = key.split("|")
                        let label = dataArr[0] + " - " + dataArr[1] + " - " + dataArr[2]
                        searchData.push({ "label": label, "value": key })
                    })

                    let totalResult = data.totalResult;
                    let currentResult = noOfPage * pageLimit;

                    let next = false;
                    let prev = false;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noOfPage > 1) {
                        prev = true;
                    }
                    let pageCount = Math.ceil(totalResult / pageLimit);
                    let pagination = this.pageNumbers(pageCount, noOfPage, 5);

                    let comp = <MedicationSearchList searchData={searchData} pagination={pagination} noOfPage={noOfPage} prev={prev} next={next} movePage={this.movePage} getDetails={this.getDetails} />
                    tabs[0] = {
                        title: "Search",
                        comp: comp
                    }
                    this.setState({
                        tabs,
                        activeIndex: 0,
                        next,
                        prev
                    });
                }

            }
        });
    }

    pageNumbers(totalPages, page, maxLength) {
        if (maxLength < 5) throw "maxLength must be at least 5";
        function range(start, end) {
            return Array.from(Array(end - start + 1), (_, i) => i + start);
        }
        let pagination = [];
        var sideWidth = maxLength < 9 ? 1 : 2;
        var leftWidth = (maxLength - sideWidth * 2 - 3) >> 1;
        var rightWidth = (maxLength - sideWidth * 2 - 2) >> 1;
        if (totalPages <= maxLength) {
            // no breaks in list
            pagination = range(1, totalPages);
        } else if (page <= maxLength - sideWidth - 1 - rightWidth) {
            // no break on left of page
            pagination = range(1, maxLength - sideWidth - 1)
                .concat(0, range(totalPages - sideWidth + 1, totalPages));
        } else if (page >= totalPages - sideWidth - 1 - rightWidth) {
            // no break on right of page
            pagination = range(1, sideWidth)
                .concat(0, range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages));
        } else {
            // Breaks on both sides
            pagination = range(1, sideWidth)
                .concat(0, range(page - leftWidth, page + rightWidth),
                    0, range(totalPages - sideWidth + 1, totalPages));
        }

        return pagination;
    }

    search() {
        this.setState({
            noOfPage: 1
        }, () => {
            this.getSearchData();
        })
    }
    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.search()
        }
    }

    getDetails(key, label) {
        let tabs = [...this.state.tabs];
        let index = tabs.length;
        tabs[index] = {
            title: label,
            comp: <MedicationSearchData loading={true} medicationOveralldata={[]} activeIndex={index} />
        }
        this.setState({
            tabs,
            activeIndex: index
        }, () => {
            let postJson = { userId: "10", key: key }
            const request = new ZiFetchRequest();
            request.setDomain('admin');
            request.setEvent('getMedicationFullData');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    tabs[index] = {
                        title: label,
                        comp: <MedicationSearchData loading={false} medicationOveralldata={responseMsg.response[key]} />
                    }
                    this.setState({
                        tabs
                    })
                }
            });
        })
    }

    movePage(page) {
        let pageNo = page

        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noOfPage + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noOfPage - 1;
            }
        }
        console.log("pageNo", pageNo)

        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noOfPage: pageNo,
                processing: true
            }, () => {
                this.getSearchData();
            })
        }
    }

    setActiveTab(index) {
        this.setState({
            activeIndex: index
        })
    }

    removeTab(e, index) {
        e.stopPropagation();
        let { tabs } = this.state;
        tabs.splice(index, 1);

        this.setState({
            tabs,
            activeIndex: index > 0 ? index - 1 : 0
        })
    }
    render() {
        let { menuActive, searchText, tabs, activeIndex } = this.state;
        import('./style.css');

        var manageIsLogin = localStorage.getItem("managelogedin")
        let returnMenu = <SideManuBar menuActive={menuActive} typeofPage={"medicationsearch"} navigation={this.props.history} />
        let returnLogout = <AdminLogout navigation={this.props.history} />
        if (manageIsLogin == "manage") {
            returnMenu = <ManageSideMenu menuActive={menuActive} typeofPage={"medicationsearch"} navigation={this.props.history}
                grpcClient={this.props.grpcClient} />
            returnLogout = <ManageLogout navigation={this.props.history} />
        }
        return (
            <div className="body-content p-top">
                <div className="row">
                    <div className="col-sm-2" />
                    <div className="col-sm-8">
                        <div className="input-group mb-3">
                            <input type="text" placeholder="name | brand | dosage | manufacturer" className="form-control"
                                value={searchText}
                                onKeyDown={(e) => this.handleKeyDown(e)}
                                onChange={(e) => {
                                    this.onFoodSearch(e)
                                }} />
                            <div className="input-group-append">
                                <button className="btn btn-outline-primary" type="button" onClick={() => this.search()}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    tabs.length > 0 && <div className="p-3">
                        <Tabs tabs={tabs} activeIndex={activeIndex} setActiveTab={this.setActiveTab} removeTab={this.removeTab} />
                    </div>
                }
            </div>
        )
    }
}
