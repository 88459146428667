import React from 'react';
import MyConstant from '../config/MyConstant';
import { contentAdminLoginCheck, readSessionData } from '../config/MyFunctions';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';


class UpdateAdminIssueData extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageText: "Waiting for Redirect ...",
            mUserId: "", session: ""
        }
    }

    async componentDidMount() {
        let login_status = await contentAdminLoginCheck(this.props)
        if (login_status) {

            let resData = await readSessionData()
            let { manage_session, manage_mUserId } = resData
            this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
                this.getIssueDetails()
            })

            setTimeout(() => {
                window.location = MyConstant.keyList.projectUrl + "/admin/admin-feedback"
            }, 1000);
        }
    }


    getIssueDetails() {
        // let editId = ""
        let editId = this.props.match.params['editId'];
        // let currentPath = this.props.location.pathname
        // editId = currentPath.split("/")[currentPath.split("/").length - 1]

        let userId = this.state.mUserId
        let postJson = { userId, issuesId: editId }
        console.log("**", postJson)
        return false
        const request = new ZiFetchRequest();
        request.setDomain('adminIssues');
        request.setEvent('getAdminIssuesReportDetails');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid Request")
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                console.log("**", responseMsg)
                if (responseMsg && responseMsg.response && responseMsg.response != null) {

                }


            }
        });
    }

    render() {
        import('../admin/style.css');
        return <React.Fragment>
            <div className="wrapper umedme">
                <div id="content" style={{ backgroundColor: "#ddd" }}>
                    <h4 className='text-center' style={{ color: "#000", fontSize: 40, fontFamily: "system-ui", position: "relative", float: "left", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}> {this.state.pageText}</h4>
                </div>
            </div>
        </React.Fragment>;
    }
}

export default UpdateAdminIssueData;
