import React, { Component } from 'react';
import { getUuidv4, isSuperadmin, readSessionData } from '../config/MyFunctions';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import { toast, ToastContainer } from "react-toastify";


class UiLanguageMaster extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuActive: "",
            action: "Add",
            languageTextName: "",
            languageTextFieldValidate: "",
            processing: false,
            prev: false,
            next: false,
            noPagesLimit: 1,
            pageLimit: 10,
            langCreate_Json: {},
            pagination: [],
            visibleClass: "",
            totalResultsCount: 0,
            mUserId: "",
            session: "",
            keyData: "",
            languageSortName: "",
            sortNameValidate: "",
            totalPageSize: 0,
            translatedFlagMap: {}
        }
        this.myRef = React.createRef()
    }

    componentDidMount() {
        let resData = readSessionData()
        let { manage_session, manage_mUserId } = resData
        this.setState({
            session: manage_session,
            mUserId: manage_mUserId
        })
        this.getLanguageList()
    }

    getLanguageList() {
        let { grpcClient } = this.props
        let postJson = { reqId: getUuidv4(), getTranslatedInfo: true };
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("getLanguagesData");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let { uiMap, translatedFlagMap, totalPageSize } = json
                this.setState({
                    langCreate_Json: uiMap,
                    translatedFlagMap,
                    totalPageSize: parseInt(totalPageSize)
                });

            }
        });
    }


    release(languageKey, type) {
        let { mUserId, session } = this.state;
        let postJson = { mUserId, session, langCode: languageKey, releaseStatus: type };
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("makeLanguagesDataZipRelease");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response === "Success") {
                    toast.success("Language Released successfully!", {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.getLanguageList()
                }
            }
        });
    }

    delete(languageKey) {
        let { mUserId, session } = this.state;
        let postJson = { mUserId, session, languageKey };
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("deleteLanguage");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response === "Success") {
                    toast.success("Language deleted successfully!", {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.getLanguageList()
                }
            }
        });
    }

    submit() {

        let { languageTextName, keyData, action, languageSortName, } = this.state
        let { grpcClient } = this.props
        let languageTextFieldValidate = ""
        let sortNameValidate = ""
        if (!languageTextName) {
            languageTextFieldValidate = "Enter Language Name"
        }
        if (!languageSortName) {
            sortNameValidate = "Enter Language Code"
        }
        // else if (languageSortName.trim("").length < 3 || languageSortName.trim("").length > 3) {
        //     sortNameValidate = "Enter Mininum Three Letters!"
        // }
        this.setState({ languageTextFieldValidate, sortNameValidate })
        // if (!languageTextName || languageSortName.trim("").length < 3 || languageSortName.trim("").length > 3) {
        //     return false
        // }
        if (!languageTextName) {
            return false
        }
        if (action === "Add") {
            keyData = languageSortName.toLowerCase();
        }
        this.setState({ submitLoader: true })
        let postJson = { key: keyData, language: languageTextName, reqId: getUuidv4(), };
        console.log("postJson", postJson)
        //key: String, language: String
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent(action === "Add" ? "createLanguagesJson" : "updateLanguagesJson");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                console.log(responseMsg)
                this.setState({ submitLoader: false })
                // console.log(responseMsg)
                if (responseMsg.response === "Success") {
                    toast.success("Language Name Created successfully!", {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({ languageTextName: "", routingName: "", keyData: "", action: "Add", languageSortName: "" }, () => {
                        this.getLanguageList()
                    })

                }
                else if (responseMsg.response === "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        });
    }

    humanDateFormat(time) {
        return new Date(time).toLocaleString()
    }


    scrollToTop() {
        this.myRef.current.scrollTo(0, 0);
    }

    async edit(key, data) {
        console.log(data, key)
        this.scrollToTop()
        this.setState({ action: "Edit", id: key, languageSortName: key, keyData: key, languageTextName: data, visibleClass: "bgClrChg" })
        setTimeout(() => {
            this.setState({ visibleClass: "" })
        }, 1000);
        this.getLanguageList()
    }
    render() {
        let { totalPageSize, translatedFlagMap, languageTextFieldValidate, langCreate_Json, languageTextName, visibleClass, languageSortName, sortNameValidate, submitLoader } = this.state
        let that = this

        let tableData = []
        Object.keys(langCreate_Json).forEach(function (key, index) {
            let data = langCreate_Json[key]
            var obj = JSON.parse(data)
            let name = obj.name
            let releasedStatus = obj.releasedStatus
            let releasedDate = obj.releasedDate
            let rDt = ""
            if (releasedDate != "" && releasedDate != null) {
                rDt = that.humanDateFormat(releasedDate)
            }
            let completedCount = translatedFlagMap[key] ? parseInt(translatedFlagMap[key]) : 0

            tableData.push(
                <tr key={index} >
                    <td style={{ width: "25%" }} className='tabletxt'>{name}</td>
                    <td style={{ width: "25%" }} className='tabletxt'>{key}</td>
                    <td style={{ width: "10%" }} className='fofamily' ><div className={" btn btn-sm btn-primary ml-auto"} onClick={() => that.edit(key, name)}>Edit</div></td>
                    {
                        isSuperadmin() && <React.Fragment>
                            <td scope="col fw">
                                <button className={"btn btn-sm btn-danger"} onClick={() => {
                                    if (window.confirm("Please confirm to delete?")) {
                                        that.delete(key)
                                    }
                                }}>Delete</button>
                            </td>
                            <td>
                                {completedCount + " / " + totalPageSize}
                            </td>
                            {/* <td scope="col fw">
                                <button className={`btn btn-sm btn-${completedCount === totalPageSize ? "success" : "secondary"}`} onClick={() => {
                                    if (window.confirm("Please confirm to release?")) {
                                        that.release(key, "Yes")
                                    }

                                }}
                                    disabled={completedCount !== totalPageSize}>Release</button>
                            </td> */}
                            <td scope="col fw">
                                <button className={"btn btn-sm btn-success"} onClick={() => {
                                    if (window.confirm("Please confirm to release?")) {
                                        that.release(key, "Yes")
                                    }

                                }}
                                >Release</button>
                            </td>
                            <td style={{ width: "20%" }} className='tabletxt'>
                                {releasedStatus.toLowerCase() === "yes" ?
                                    <button className={"btn btn-sm btn-danger ml-auto mb-2"} onClick={() => {
                                        if (window.confirm("Please confirm to stop?")) {
                                            that.release(key, "No")
                                        }
                                    }}>Stop</button> : releasedStatus
                                }
                                <br />
                                {
                                    rDt
                                }
                            </td>
                        </React.Fragment>
                    }

                </tr>
            )
        });


        return (
            <div className="body-content left-rigt-padding cat-style" ref={this.myRef} style={{ width: "100%" }}>
                <main className="app-main">
                    <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>

                        <div className="container">
                            <div className={"row"}>
                                <div className='col-md-2'></div>
                                <div className='col-md-8'>
                                    <div className={"card box-shadow"} style={{ padding: 0 }}>
                                        <div className="card-header border-btm-style">
                                            <h5 className='header-style' >{this.state.action} Language</h5>
                                        </div>
                                        <div className={(visibleClass ? visibleClass : "") + " card-body"}>
                                            <div className={"row"}>
                                                <div className={"col-md-1"}></div>
                                                <div className={"col-md-2"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Language Name</label></div>
                                                <div className={"col-md-7"}>
                                                    <input type="text" placeholder={"Enter Language Name "} className={"form-control inputRadius"} name={"label"} value={languageTextName} onChange={e => {
                                                        this.setState({ languageTextName: e.target.value })
                                                    }} />
                                                    <span className='error-txt'>{languageTextFieldValidate} </span>
                                                </div>
                                            </div>

                                            <br />
                                            <div className={"row"}>
                                                <div className={"col-md-1"}></div>
                                                <div className={"col-md-2"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Language Code</label></div>
                                                <div className={"col-md-7"}>
                                                    <input type="text" placeholder={"Enter Language Code "} className={"form-control inputRadius " + (this.state.action === "Edit" ? "text-disable" : "")} name={"label"} value={languageSortName} onChange={e => {
                                                        this.setState({ languageSortName: e.target.value.trim("").toLowerCase() })
                                                    }}
                                                        disabled={this.state.action === "Edit" ? true : false}
                                                    />
                                                    <span className='error-txt'>{sortNameValidate} </span>
                                                </div>
                                            </div>
                                            <br />
                                            <div className={"row mt-3"}>
                                                <div className={"col-sm-3"}></div>
                                                <div className={"col-sm-5"}>

                                                    {
                                                        <button type="button" className="btn btn-primary ml-auto" onClick={() => this.submit()} disabled={submitLoader}>{submitLoader && <i className="fa fa-spinner fa-spin"></i>}<span className={`${submitLoader && "pl-1"}`}>{this.state.action === "Add" ? "Create" : 'update'}</span></button>
                                                    }
                                                    {this.state.action === "Edit" && <input type="button" className="btn btn-danger ml-3" value="Cancel" onClick={() => {
                                                        this.setState({ action: "Add", languageTextName: "", languageTextFieldValidate: "", keyData: "", languageSortName: "" })
                                                    }} />}
                                                </div>
                                                <div className={"col-sm-4"}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-2'></div>
                            </div>
                        </div>
                        <div className="container mt-5">
                            <div className='row'>
                                <div className='col-md-2'>
                                </div>
                                <div className='col-md-8'>
                                    <div className="card box-shadow" style={{ padding: 0 }}>
                                        <div className="card-header border-btm-style" style={{ borderBottom: "none" }}>
                                            <h5 className='header-style' > Language List</h5>
                                        </div>
                                        <div className="card-body table-responsive" style={{ padding: 2 }}>
                                            <table className="table table-bordered table-striped" style={{ width: "100%", textAlign: "center" }} border={1} cellPadding={7}>
                                                <tr className='upperCase'>
                                                    <th scope="col fw">Name</th>
                                                    <th scope="col fw">Language Code</th>
                                                    <th scope="col fw">Edit</th>
                                                    {
                                                        isSuperadmin() && <React.Fragment>
                                                            <th scope="col fw">Delete</th>
                                                            <th scope="col fw">Completed Pages</th>
                                                            <th scope="col fw">Released Button</th>
                                                            <th scope="col fw">Released Status</th>
                                                        </React.Fragment>
                                                    }
                                                </tr>
                                                <tbody>
                                                    {tableData}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        )
    }
}

export default UiLanguageMaster;