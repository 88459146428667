import React from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { getValidLabels } from "../../config/MyFunctions";


function MedicationForm(props) {
    let {pageText} = props;

    return <div className="py-2" style={{ borderBottom: "1px dashed #ddd", position: "relative" }}>
        <div className="delete-medical-history" onClick={() => props.delete()}>
            <i className="fa fa-trash"></i>
        </div>
        <div>
            <div><b>{getValidLabels(pageText, 'nameOfMedicationLabelTxt')}</b></div>
            <div>
                <input className="form-control" value={props.nameOfMedication} onChange={(e) => {
                    props.onChange("nameOfMedication", e.target.value)
                }}></input>
            </div>
        </div>
        <div>
            <div><b>{getValidLabels(pageText, 'dosageLabelTxt')}</b></div>
            <div>
                <input className="form-control" value={props.dosage} onChange={(e) => {
                    props.onChange("dosage", e.target.value)
                }}></input>
            </div>
        </div>
        <div>
            <div><b>{getValidLabels(pageText, 'startDateLabelTxt')}</b></div>
            <div>
                <DatePicker
                    className={`form-control`}
                    dateFormat={"MM/dd/yyyy"}
                    selected={props.startDate ? new Date(props.startDate) : ""}
                    onChange={(date) => {
                        props.onChange("startDate", date)
                    }}
                />
            </div>
        </div>
        <div>
            <div><b>{getValidLabels(pageText, 'endDateLabelTxt')}</b></div>
            <div>
                <DatePicker
                    className={`form-control`}
                    dateFormat={"MM/dd/yyyy"}
                    selected={props.endDate ? new Date(props.endDate) : ""}
                    onChange={(date) => {
                        props.onChange("endDate", date)
                    }}
                />
            </div>
            <div className="">
                <input type="checkbox" checked={props.currently} onChange={(e) => {
                    props.onChange("currently", e.target.checked)
                }}/>{getValidLabels(pageText, 'currentlyLabelTxt')}
            </div>
        </div>
    </div>;
}

export default MedicationForm;