module.exports = {
  keyList: {
    projectName: 'UmedMe',
    projectUrl: '',
    // apiURL: 'https://teqbahn.com/umedme-api/',
    // grpcURL: 'https://teqbahn.com',
    /*  apiURL: 'https://api.teqbahn.com:9443/umedme-api/',
      grpcURL: 'https://api.teqbahn.com:9443',*/
    // apiURL: 'http://aa8c3ef462b82428d87abf11ca072cf1-51c9b7a7c971fbed.elb.us-east-1.amazonaws.com/umedme-api/',
    // grpcURL: 'http://aa8c3ef462b82428d87abf11ca072cf1-51c9b7a7c971fbed.elb.us-east-1.amazonaws.com',
    // apiURL: 'http://192.168.43.110:8093/umedme-api/',
    // grpcURL: 'http://192.168.43.110:8000',
    apiURL: 'https://api.teqbahn.com/umedme-api/',
    grpcURL: 'https://api.teqbahn.com',
    speakupApiURL: "https://api.teqbahn.com/speakup-api/",
    speakupGrpcURL: "https://api.teqbahn.com",
    ipURL: 'https://apis.teqbahn.com/teqe-api/',
    jitsiDomain: "meet.teqbahn.com:9443",
  },
};
