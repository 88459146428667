import React, { Component } from 'react';
// import './style.css';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import Pagination from "./Pagination"
import Tabs from './component/Tabs';
import { checkLogin } from '../config/MyFunctions';


class ICDSearchList extends Component {
    render() {
        let { searchData, pagination, noOfPage, prev, next, movePage, getDetails } = this.props;
        return searchData.length > 0 && <div className="result">
            {
                searchData.map((data, index) => {
                    return <div className="result-label" key={index} onClick={() => getDetails(data, data.label)}>{data.label}</div>
                })
            }
            <div className="py-5">
                <Pagination pagination={pagination} currentPage={noOfPage} prev={prev} next={next} movePage={movePage} />
            </div>
        </div>
    }
}
class ICDSearchData extends Component {
    render() {
        let { loading, selectedfulldata } = this.props;

        var dataArr = [];
        let codeName = "";
        let descriptionDetails = "";

        if (selectedfulldata) {
            dataArr = selectedfulldata.split("|");
            let cobine = dataArr[0];
            let stringLength = cobine.length;
            let sub = cobine.substring(0, 3)
            let sub_2 = cobine.substring(3, stringLength)
            codeName = sub + "." + sub_2
            descriptionDetails = dataArr[1];
        }

        return <div>
            {loading && <div className="loader lodercenter"></div>}
            {
                <div className="row" style={{ marginTop: "30px" }}>
                    <div className="col-sm-12">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Code</th>
                                    <th scope="col">Description</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td >{codeName}</td>
                                    <td >{descriptionDetails}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    }
}

export default class FoodSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuActive: "",
            searchData: [],
            searchText: "",
            totalResult: 0,
            view: "list",
            foodListData: [],
            loading: false,
            otherData: {},
            noOfPage: 1,
            pageLimit: 10,
            next: false,
            prev: false,
            pagination: [],
            tabs: [],
            activeIndex: 0
        }
        this.movePage = this.movePage.bind(this)
        this.getDetails = this.getDetails.bind(this)
        this.setActiveTab = this.setActiveTab.bind(this)
        this.removeTab = this.removeTab.bind(this)
    }
    async componentDidMount() {
        await checkLogin(this.props)

    }
    onICDSearch = async (e) => {
        let searchText = e.target.value;
        this.setState({
            searchText
        })
    }

    getSearchData() {
        let { searchText, noOfPage, pageLimit, tabs } = this.state;
        let uniqId = localStorage.getItem("uniqId");
        let removeDot = searchText.replace('.', "");
        if (!uniqId) {
            uniqId = 'id-' + (new Date()).getTime() + Math.random().toString(36).substr(2);
            localStorage.setItem("uniqId", uniqId)
        }

        let postJson = { sessionId: uniqId, searchText: removeDot, searchType: "icd", pageLimit, noOfPage }
        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('admin');
        request.setEvent('doSearch');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid request")
            } else {
                let data = JSON.parse(response.getMessage());
                console.log(data);
                if (data && data.resultList) {
                    let list = data.resultList;
                    console.log(list);
                    let searchData = [];

                    ////     val keyString = dac.frst_nm + "|" +dac.mid_nm + "|" +dac.lst_nm+ "|" + dac.gndr+ "|" +dac.adr_ln_1+ "|" +dac.adr_ln_2+ "|" +dac.cty+ "|" +dac.st+ "|" +zip+ "|" +dac.phn_numbr
                    list.map((key, keyIndex) => {
                        var dataArr = key.split("|");

                        //let cobine = key.frst_nm + " " + key.mid_nm + " " + key.lst_nm + ", " + key.adr_ln_1 + " " + key.adr_ln_2 + ", " + key.cty + "," + key.st + " - " + key.zip + ". " + key.phn_numbr
                        let cobine = dataArr[0];
                        let stringLength = cobine.length;
                        let sub = cobine.substring(0, 3)
                        let sub_2 = cobine.substring(3, stringLength)
                        let stringConvert = sub + "." + sub_2 + " " + dataArr[1]

                        searchData.push({ "label": stringConvert, "value": stringConvert, "fulldata": key })
                    })

                    let totalResult = data.totalResult;
                    let currentResult = noOfPage * pageLimit;

                    let next = false;
                    let prev = false;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noOfPage > 1) {
                        prev = true;
                    }
                    let pageCount = Math.ceil(totalResult / pageLimit);
                    let pagination = this.pageNumbers(pageCount, noOfPage, 5);

                    let comp = <ICDSearchList searchData={searchData} pagination={pagination} noOfPage={noOfPage} prev={prev} next={next} movePage={this.movePage} getDetails={this.getDetails} />
                    tabs[0] = {
                        title: "Search",
                        comp: comp
                    }
                    this.setState({
                        tabs,
                        activeIndex: 0,
                        next,
                        prev
                    });
                }

            }
        });
    }

    pageNumbers(totalPages, page, maxLength) {
        if (maxLength < 5) throw "maxLength must be at least 5";
        function range(start, end) {
            return Array.from(Array(end - start + 1), (_, i) => i + start);
        }
        let pagination = [];
        var sideWidth = maxLength < 9 ? 1 : 2;
        var leftWidth = (maxLength - sideWidth * 2 - 3) >> 1;
        var rightWidth = (maxLength - sideWidth * 2 - 2) >> 1;
        if (totalPages <= maxLength) {
            // no breaks in list
            pagination = range(1, totalPages);
        } else if (page <= maxLength - sideWidth - 1 - rightWidth) {
            // no break on left of page
            pagination = range(1, maxLength - sideWidth - 1)
                .concat(0, range(totalPages - sideWidth + 1, totalPages));
        } else if (page >= totalPages - sideWidth - 1 - rightWidth) {
            // no break on right of page
            pagination = range(1, sideWidth)
                .concat(0, range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages));
        } else {
            // Breaks on both sides
            pagination = range(1, sideWidth)
                .concat(0, range(page - leftWidth, page + rightWidth),
                    0, range(totalPages - sideWidth + 1, totalPages));
        }

        return pagination;
    }

    search() {
        this.setState({
            noOfPage: 1
        }, () => {
            this.getSearchData();
        })
    }
    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.search()
        }
    }

    getDetails(ival, label) {
        let tabs = [...this.state.tabs];
        let index = tabs.length;

        tabs[index] = {
            title: label,
            comp: <ICDSearchData loading={false} selectedfulldata={ival.fulldata} />
        }
        this.setState({
            tabs,
            activeIndex: index
        })
    }

    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noOfPage + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noOfPage - 1;
            }
        }

        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noOfPage: pageNo,
                processing: true
            }, () => {
                this.getSearchData();
            })
        }
    }

    setActiveTab(index) {
        this.setState({
            activeIndex: index
        })
    }

    removeTab(e, index) {
        e.stopPropagation();
        let { tabs } = this.state;
        tabs.splice(index, 1);

        this.setState({
            tabs,
            activeIndex: index > 0 ? index - 1 : 0
        })
    }

    render() {
        let { searchText, tabs, activeIndex } = this.state;
        import('./style.css');
        return (
            <div className="body-content p-top">
                <div className="row">
                    <div className="col-sm-2" />
                    <div className="col-sm-8">
                        <div className="input-group mb-3">
                            <input type="text" placeholder="ICD code" className="form-control"
                                value={searchText}
                                onKeyDown={(e) => this.handleKeyDown(e)}
                                onChange={(e) => {
                                    this.onICDSearch(e)
                                }} />
                            <div className="input-group-append">
                                <button className="btn btn-outline-primary" type="button" onClick={() => this.search()}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    tabs.length > 0 && <div className="p-3">
                        <Tabs tabs={tabs} activeIndex={activeIndex} setActiveTab={this.setActiveTab} removeTab={this.removeTab} />
                    </div>
                }
            </div>
        )
    }
}
