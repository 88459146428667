import React, { Component } from 'react';
// import Pagination from "./Pagination"
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import image1 from '../images/UMEDME_logo_white.png';
import image2 from '../images/UMEDME_new.png';
import image3 from '../images/Teqbahn_logo1.png';
import SideManuBar from '../admin/SideMenuBar';
import { Link } from 'react-router-dom';
import MyConstant from '../config/MyConstant';
import { loginStatusCheck, checkLogin } from '../config/MyFunctions';
import AdminLogout from "../admin/AdminLogout"
import ManageSideMenu from '../manage/ManageSideMenu';
import ManageLogout from "../manage/ManageLogout"






class WorkSpace extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            menuActive: "", search: "", processing: true, docsList: [],
            docResponse: {}
        }
    }
    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        this.getDocumentList()
    }


    async getDocumentList() {
        //sessionId:String,userId: String, docsId: String,reqId:String
        let postJson = { sessionId: "1234", userId: "admin", docsId: "", reqId: "" }
        const request = new ZiFetchRequest();
        request.setDomain('admin');
        request.setEvent('getDocumentParserList');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                await this.setState({ docResponse: responseMsg })
            }
        });

    }


    render() {
        let { menuActive, docResponse } = this.state
        import('../admin/style.css');

        var jsonData = {}

        // jsonData = { "1367b19d-85bb-443e-9ec0-9823e26ac3c9": { "docs_id": "1367b19d-85bb-443e-9ec0-9823e26ac3c9", "user_id": "9a7d8f1c-addd-44e5-a18f-ffe73bfc01df", "document_parser_name": "test", "docs_name": "AdultOutpatientAssessment.pdf", "no_of_pages": "2" } }

        if (docResponse.response) {
            jsonData = docResponse.response
        }

        let docsList = []
        jsonData && Object.keys(jsonData).forEach(function (key) {
            var docjson = jsonData[key];
            var docs_id = docjson['docs_id'];
            docsList.push(<tr>
                <td>{docjson.document_parser_name} </td>
                <td>{docjson.docs_name}</td>
                <td>{docjson.no_of_pages}</td>
                <td>
                    <Link to={MyConstant.keyList.projectUrl + "/admin/tablesheet/" + docs_id}>
                        <a className="btn-flat m-b-10 m-l-5">
                            <i className="ti-pencil-alt color-success border-pink" style={{ fontSize: 30 }}></i>
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </a>
                    </Link>
                </td>
                <td>
                    <Link to={MyConstant.keyList.projectUrl + "/admin/rules-list/" + docs_id}>
                        <a className="btn-flat m-b-10 m-l-5">
                            <i className="ti-list color-warning border-pink" style={{ fontSize: 30 }}></i>
                        </a>
                    </Link>
                </td>
            </tr>)
        })


        var manageIsLogin = localStorage.getItem("managelogedin")
        let returnMenu = <SideManuBar menuActive={menuActive} typeofPage={"workspace"} navigation={this.props.history} />
        let returnLogout = <AdminLogout navigation={this.props.history} />
        if (manageIsLogin == "manage") {
            returnMenu = <ManageSideMenu menuActive={menuActive} typeofPage={"workspace"} navigation={this.props.history} grpcClient={this.props.grpcClient} />
            returnLogout = <ManageLogout navigation={this.props.history} />
        }

        return (
            <>
                <div className={`wrapper ${manageIsLogin ? "manageBgColor" : ""}`}>
                    {returnMenu}
                    <div id="content">

                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <div className="container-fluid">

                                <button type="button" id="sidebarCollapse" className="btn btn-info" onClick={() => {

                                    if (!menuActive) {
                                        menuActive = "active"
                                    }
                                    else {
                                        menuActive = ""
                                    }

                                    this.setState({ menuActive })
                                }}>
                                    <i className="fas fa-align-left"></i>
                                    <span> {menuActive ? "Expand" : "Hide"} </span>
                                </button>
                                {returnLogout}
                            </div>
                        </nav>
                        <div className="body-content left-rigt-padding">

                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="card card-table mb-4">
                                        <div className="stat-widget-one">
                                            <div className="stat-icon dib"><i
                                                className="ti-layout-grid2 color-pink border-pink"></i></div>
                                            <div className="stat-content dib">
                                                <div className="stat-text">Active Workspace</div>
                                                <div className="stat-digit">{docsList.length}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-lg-12'>
                                    <Link to='/admin/documentUpload'>
                                        <a class="btn btn-primary btn-bg-clr"> ADD AGENT</a>
                                    </Link>
                                </div>
                            </div>

                            <div className='card card-table'>

                                <div className="bootstrap-data-table-panel">
                                    <div className="table-responsive">
                                        {
                                            docsList.length > 0 ?

                                                <table className="table table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Agent</th>
                                                            <th>Doc Name</th>
                                                            <th>No Of Pages</th>
                                                            <th>Create Spreadsheet</th>
                                                            <th>Spreadsheet List</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {docsList}
                                                    </tbody>
                                                </table> : <div className="text-center"> No Workspace Available</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default WorkSpace;