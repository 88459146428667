import React, { Component } from 'react';
// import './style.css';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import image3 from '../images/Teqbahn_logo1.png';
import SideManuBar from './SideMenuBar';
import Pagination from "./Pagination"
import Tabs from './component/Tabs';
import AdminLogout from "./AdminLogout"
import ManageLogout from "../manage/ManageLogout"
import ManageSideMenu from '../manage/ManageSideMenu';
import { checkLogin } from '../config/MyFunctions';



class FoodSearchList extends Component {
    render() {
        let { searchData, pagination, noOfPage, prev, next, movePage, getFoodDetails } = this.props;
        return searchData.length > 0 && <div className="result">
            {
                searchData.map((data, index) => {
                    return <div className="result-label" key={index} onClick={() => getFoodDetails(data.value, data.label)}>{data.label}</div>
                })
            }
            <div className="py-5">
                <Pagination pagination={pagination} currentPage={noOfPage} prev={prev} next={next} movePage={movePage} />
            </div>
        </div>
    }
}
class FoodSearchData extends Component {
    render() {
        let { loading, foodListData, otherData } = this.props;

        var keysData = Object.keys(otherData)
        var valuesData = Object.values(otherData)
        return <div>
            {loading && <div className="loader lodercenter"></div>}
            {
                foodListData.length > 0 && <React.Fragment><div className="row " style={{ marginTop: "30px" }}>
                    <div className="col-md-4">
                        <table className="table table-bordered table-striped">
                            <thead>
                                {
                                    foodListData.length > 0 ?
                                        <tr>
                                            <th scope="col" style={{ backgroundColor: "#ed3833", color: "#FFF", fontWeight: "bold" }}>Name</th>
                                            <th scope="col" style={{ backgroundColor: "#ed3833", color: "#FFF", fontWeight: "bold" }}>Amount</th>
                                        </tr> : null}
                            </thead>
                            <tbody>
                                {
                                    foodListData.map((data, index) => {
                                        return (
                                            <tr key={index}>

                                                <td style={{ backgroundColor: "#ed3833", color: "#FFF" }} >{data.name}</td>
                                                <td >{data.amount}  {data.unit_name}</td>

                                            </tr>)
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-8">
                        <table className="table table-bordered table-striped">
                            <thead>
                                {
                                    foodListData.length > 0 ?
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Nutrient Nbr</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Max Daily Amount</th>
                                            <th scope="col">Percentage Daily Amount</th>
                                            <th scope="col">Unit Name</th>
                                        </tr> : null}
                            </thead>
                            <tbody>
                                {
                                    foodListData.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td >{data.name}</td>
                                                <td >{data.nutrient_nbr}</td>
                                                <td >{data.amount}</td>
                                                <td >{data.max_daily_amount}</td>
                                                <td >{data.percentage_daily_amount}</td>
                                                <td >{data.unit_name}</td>
                                            </tr>)
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="p-5"><table className="table table-bordered">
                                {
                                    keysData.map((kval, k) => {
                                        return kval !== "nutritientInfoList" && <tr key={k}>
                                            <td className="table-first-column-unique">{kval.replace(/_/g, ' ')}</td>
                                            <td>{typeof valuesData[k] === 'string' ? valuesData[k] : <React.Fragment>
                                                {
                                                    Array.isArray(valuesData[k]) ? valuesData[k].map((value, valueIndex) => {
                                                        return <div dangerouslySetInnerHTML={{ __html: value }} key={valueIndex} />
                                                    }) : <table> {
                                                        Object.keys(valuesData[k]).map((innerData, innerDataIndex) => {
                                                            return <tr className="" key={innerDataIndex}>
                                                                <td className="table-first-column-unique">
                                                                    {innerData.replace(/_/g, ' ')}
                                                                </td>
                                                                <td>
                                                                    {valuesData[k][innerData]}
                                                                </td>
                                                            </tr>
                                                        })
                                                    }</table>

                                                }
                                            </React.Fragment>
                                            }</td>
                                        </tr>
                                    })
                                }
                            </table></div>
                        </div>
                    </div>
                </React.Fragment>
            }
        </div>
    }
}

export default class FoodSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuActive: "",
            searchData: [],
            foodSearchText: "",
            totalResult: 0,
            view: "list",
            foodListData: [],
            loading: false,
            otherData: {},
            noOfPage: 1,
            pageLimit: 10,
            next: false,
            prev: false,
            pagination: [],
            tabs: [],
            activeIndex: 0
        }
        this.movePage = this.movePage.bind(this)
        this.getFoodDetails = this.getFoodDetails.bind(this)
        this.setActiveTab = this.setActiveTab.bind(this)
        this.removeTab = this.removeTab.bind(this)
    }
    async componentDidMount() {
        var loginincheck = await checkLogin(this.props)

    }
    onFoodSearch = async (e) => {
        let foodSearchText = e.target.value;
        this.setState({
            foodSearchText
        })
    }

    getSearchData() {
        let { foodSearchText, noOfPage, pageLimit, tabs } = this.state;
        let uniqId = localStorage.getItem("uniqId");
        if (!uniqId) {
            uniqId = 'id-' + (new Date()).getTime() + Math.random().toString(36).substr(2);
            localStorage.setItem("uniqId", uniqId)
        }

        let postJson = { sessionId: uniqId, searchText: foodSearchText, searchType: "food", pageLimit, noOfPage }
        const request = new ZiFetchRequest();
        request.setDomain('admin');
        request.setEvent('doSearch');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid request")
            } else {
                let data = JSON.parse(response.getMessage());
                console.log(data);
                if (data && data.resultList) {
                    let foodList = data.resultList;
                    let searchData = [];
                    foodList.map((food, foodIndex) => {
                        var dataArr = food.split("|");
                        let label = dataArr[2] + " - " + dataArr[10];
                        let foodSearchTextToLower = foodSearchText.toLowerCase();

                        if (dataArr[2].toLowerCase().search(foodSearchTextToLower) === -1 && dataArr[10].toLowerCase().search(foodSearchTextToLower) === -1) {
                            let labelFind = false;
                            for (var i = 0; i < dataArr.length; i++) {
                                if (i !== 2 && i !== 10) {
                                    let eachData = dataArr[i];
                                    if (eachData.search(foodSearchText) !== -1 && !labelFind) {
                                        let buildText = "";
                                        let eachDataArr = eachData.split(" ");
                                        let indexOfText = eachDataArr.findIndex(e => e.includes(foodSearchText))
                                        if (indexOfText < 2) {
                                            if (eachDataArr.length > 2) {
                                                buildText = eachDataArr[0] + " " + eachDataArr[1] + " " + eachDataArr[2];
                                            } else if (eachDataArr.length === 2) {
                                                buildText = eachDataArr[0] + " " + eachDataArr[1]
                                            } else if (eachDataArr.length === 1) {
                                                buildText = eachDataArr[0]
                                            }
                                        } else if (indexOfText === 2) {
                                            buildText = eachDataArr[0] + " " + eachDataArr[1] + " " + eachDataArr[2];

                                            if (typeof eachDataArr[3] !== "undefined") {
                                                buildText += " " + eachDataArr[3];
                                            }
                                            if (typeof eachDataArr[4] !== "undefined") {
                                                buildText += " " + eachDataArr[4];
                                            }

                                            if (eachDataArr.length > 5) {
                                                buildText += "...";
                                            }
                                        } else {
                                            buildText = "..." + eachDataArr[indexOfText - 2] + " " + eachDataArr[indexOfText - 1] + " " + eachDataArr[indexOfText];

                                            if (typeof eachDataArr[indexOfText + 1] !== "undefined") {
                                                buildText += " " + eachDataArr[indexOfText + 1]
                                            }
                                            if (typeof eachDataArr[indexOfText + 2] !== "undefined") {
                                                buildText += " " + eachDataArr[indexOfText + 2]
                                            }

                                            if (typeof eachDataArr[indexOfText + 3] !== "undefined") {
                                                buildText += "...";
                                            }


                                        }
                                        label += " | " + buildText;
                                        labelFind = true;
                                    }
                                }
                            }
                        }
                        searchData.push({
                            "label": label,
                            "value": food
                        })
                    })

                    let totalResult = data.totalResult;
                    let currentResult = noOfPage * pageLimit;

                    let next = false;
                    let prev = false;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noOfPage > 1) {
                        prev = true;
                    }
                    let pageCount = Math.ceil(totalResult / pageLimit);
                    let pagination = this.pageNumbers(pageCount, noOfPage, 5);

                    let comp = <FoodSearchList searchData={searchData} pagination={pagination} noOfPage={noOfPage} prev={prev} next={next} movePage={this.movePage} getFoodDetails={this.getFoodDetails} />
                    tabs[0] = {
                        title: "Search",
                        comp: comp
                    }
                    this.setState({
                        tabs,
                        activeIndex: 0,
                        next,
                        prev
                    });
                }

            }
        });
    }

    pageNumbers(totalPages, page, maxLength) {
        if (maxLength < 5) throw "maxLength must be at least 5";
        function range(start, end) {
            return Array.from(Array(end - start + 1), (_, i) => i + start);
        }
        let pagination = [];
        var sideWidth = maxLength < 9 ? 1 : 2;
        var leftWidth = (maxLength - sideWidth * 2 - 3) >> 1;
        var rightWidth = (maxLength - sideWidth * 2 - 2) >> 1;
        if (totalPages <= maxLength) {
            // no breaks in list
            pagination = range(1, totalPages);
        } else if (page <= maxLength - sideWidth - 1 - rightWidth) {
            // no break on left of page
            pagination = range(1, maxLength - sideWidth - 1)
                .concat(0, range(totalPages - sideWidth + 1, totalPages));
        } else if (page >= totalPages - sideWidth - 1 - rightWidth) {
            // no break on right of page
            pagination = range(1, sideWidth)
                .concat(0, range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages));
        } else {
            // Breaks on both sides
            pagination = range(1, sideWidth)
                .concat(0, range(page - leftWidth, page + rightWidth),
                    0, range(totalPages - sideWidth + 1, totalPages));
        }

        return pagination;
    }

    search() {
        this.setState({
            noOfPage: 1
        }, () => {
            this.getSearchData();
        })
    }
    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.search()
        }
    }

    getFoodDetails(key, label) {
        let tabs = [...this.state.tabs];
        let index = tabs.length;
        tabs[index] = {
            title: label,
            comp: <FoodSearchData loading={true} foodListData={[]} otherData={{}} activeIndex={index} />
        }
        this.setState({
            tabs,
            activeIndex: index
        }, () => {
            let postJson = { userId: 10, key }
            const request = new ZiFetchRequest();
            request.setDomain('admin');
            request.setEvent('getFoodDetailWithKey');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    let data = responseMsg.response;
                    let foodListData = [],
                        otherData = {};
                    let keys = Object.keys(data);
                    if (keys.length > 0) {
                        foodListData = data[keys[0]].nutritientInfoList;
                        otherData = data[keys[0]];
                    }
                    tabs[index] = {
                        title: label,
                        comp: <FoodSearchData loading={false} foodListData={foodListData} otherData={otherData} activeIndex={index} />
                    }
                    this.setState({
                        tabs
                    })
                }
            });
        })
    }

    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noOfPage + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noOfPage - 1;
            }
        }

        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noOfPage: pageNo,
                processing: true
            }, () => {
                this.getSearchData();
            })
        }
    }

    setActiveTab(index) {
        this.setState({
            activeIndex: index
        })
    }

    removeTab(e, index) {
        e.stopPropagation();
        let { tabs } = this.state;
        tabs.splice(index, 1);

        this.setState({
            tabs,
            activeIndex: index > 0 ? index - 1 : 0
        })
    }

    render() {
        let { menuActive, foodSearchText, tabs, activeIndex } = this.state;
        import('./style.css');

        return (
            <div className="body-content p-top">
                <div className="row">
                    <div className="col-sm-2" />
                    <div className="col-sm-8">
                        <div className="input-group mb-3">
                            <input type="text" placeholder="fdc id | brand | description" className="form-control"
                                value={foodSearchText}
                                onKeyDown={(e) => this.handleKeyDown(e)}
                                onChange={(e) => {
                                    this.onFoodSearch(e)
                                }} />
                            <div className="input-group-append">
                                <button className="btn btn-outline-primary" type="button" onClick={() => this.search()}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    tabs.length > 0 && <div className="p-3">
                        <Tabs tabs={tabs} activeIndex={activeIndex} setActiveTab={this.setActiveTab} removeTab={this.removeTab} />
                    </div>
                }
            </div>
        )
    }
}
