import React, { Component } from 'react';
import Pagination from "../Pagination"
import { ZiFetchRequest } from '../../protobuf/speakup/speakup_grpc_web_pb';
import Modal from '../../AppIssues/Modal';
import MyConstant from '../../config/MyConstant';
import Select from '../component/Select';
import MyConfig from '../../config/MyConfig';
import { toast } from "react-toastify";
import { getSpeakUpIssueId, pageNumbers } from '../../config/MyFunctions';
import { PDFViewer } from 'react-view-pdf';
import { checkLogin, readSessionData } from '../../config/MyFunctions';



class IssuesList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            issueList: [],
            menuActive: "",
            search: "",
            resultArray: [],
            noPagesLimit: 1,
            totalPagesResults: "",
            processing: true,
            pageLimit: 10,
            statusOption: MyConfig.status,
            selectedStatus: "",
            description: "",
            userId: "",
            viewModal: false,
            historyData: [],
            activeData: {},
            viewType: "",
            viewDetails: {},
            prev: false,
            next: false,
            pagination: [],
            editHistoryData: {},
            issueCountList: {},
            previewModal: false,
            previewIndex: "",
            previewTotalLength: 0,
            loaderSpinner: false,
            session: "", mUserId: ""
        }
        this.handleChange = this.handleChange.bind(this)
    }
    async componentDidMount() {
        var loginincheck = await checkLogin(this.props)
        let resData = await readSessionData()
        let { manage_session, manage_mUserId } = resData
        this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
            this.getIssueList()
            this.getIssueCount()
        })


    }

    getIssueList() {
        let { noPagesLimit, pageLimit } = this.state
        let postJson = { auth: 'Teqbahn@321#', pageLimit, noOfPage: noPagesLimit }
        const request = new ZiFetchRequest();
        request.setDomain('appIssues');
        request.setEvent('getAllAppIssuesReportList');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid Request")
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg && responseMsg.response) {
                    let responseData = responseMsg.response
                    let next = false;
                    let prev = false;
                    let totalResult = responseMsg.totalResult
                    let currentResult = noPagesLimit * 10;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noPagesLimit > 1) {
                        prev = true;
                    }

                    let pageCount = Math.ceil(totalResult / 10);
                    let pagination = pageNumbers(pageCount, noPagesLimit, 5);
                    this.setState({
                        issueList: responseData,
                        prev,
                        next,
                        pagination: pagination,
                        processing: false
                    });
                }
            }
        });
    }


    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }

        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getIssueList();
            })
        }
    }

    humanDateFormat(time) {
        return new Date(time).toLocaleString()
    }
    viewHistory(issueId) {
        let postJson = { issueId }
        const request = new ZiFetchRequest();
        request.setDomain('appIssues');
        request.setEvent('getAppIssuesReportLogsList');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid Request")
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                this.setState({
                    historyData: responseMsg.response.reverse()
                })
            }
        });
    }
    viewEditHistory(issueId) {
        let postJson = { issuesId: issueId }
        const request = new ZiFetchRequest();
        request.setDomain('appIssues');
        request.setEvent('getAppIssuesReportVersionData');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid Request")
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                this.setState({
                    editHistoryData: responseMsg.response
                })
            }
        });
    }

    updateStatus() {
        let { selectedStatus, description, issueId } = this.state;

        let postJson = { issuesId: issueId, status: selectedStatus, statusDescription: description }
        const request = new ZiFetchRequest();
        request.setDomain('appIssues');
        request.setEvent('updateAppIssuesReportStatus');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid Request")
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg.response === "Success") {
                    toast.success(responseMsg.response, {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    this.setState({
                        selectedStatus: "",
                        description: "",
                    }, () => {
                        this.getIssueList();
                        this.viewHistory(issueId)
                        this.getIssueCount()
                    })
                }
            }
        });
    }

    handleChange(e) {
        this.setState({
            selectedStatus: e.value
        })
    }

    viewModal(viewType, issueId) {
        let { issueList } = this.state
        this.setState({
            viewType,
            viewDetails: issueList[issueId],
            viewModal: true,
            historyData: [],
            editHistoryData: [],
            issueId,
            selectedStatus: issueList[issueId].appIssuesReportData.status
        }, () => {
            this.viewHistory(issueId)
            // this.viewEditHistory(issueId)
        })
    }

    viewContent() {
        let { viewType, viewDetails, statusOption, selectedStatus, description, historyData, editHistoryData } = this.state;
        let dlist = viewDetails.appIssuesReportData;
        let metaInfo = dlist.metaInfo ? JSON.parse(dlist.metaInfo) : ""
        let emailId = viewDetails.emailId;
        let deviceInfo = dlist.deviceInfo ? JSON.parse(dlist.deviceInfo) : {}

        let images = ["jpg", "png"];
        let videos = ["mp4", "3gp", "ogg", "mov"]
        let pdf = ["pdf"]

        let buildContent;
        switch (viewType) {
            case "refNo":
                buildContent = <div>{getSpeakUpIssueId(dlist.issuesNo)}</div>
                break;
            case "title":
                buildContent = <div>{dlist.title}</div>
                break;
            case "description":
                buildContent = <div style={{ whiteSpace: 'pre-wrap' }}>{dlist.descrition}</div>
                break;
            case "email":
                buildContent = <div>{emailId}</div>
                break;
            case "device":
                buildContent = <div>{metaInfo.issueFrom === "Web" ? "web" : deviceInfo.appPlatform}</div>
                break;
            case "osVersion":
                buildContent = <div>{metaInfo.issueFrom === "Web" ? "-" : (metaInfo ? `${metaInfo.deviceOsVersion} ${metaInfo.deviceModel ? "- " + metaInfo.deviceModel : ""}` : "-")}</div>
                break;
            case "version":
                buildContent = <div>{metaInfo.issueFrom === "Web" ? "-" : deviceInfo.appVersion}</div>
                break;
            case "imageVideo":
                let currentViewFiles = dlist.fileList;
                let userId = dlist.userId;
                buildContent = <div className="row " >
                    {
                        currentViewFiles.map((fileStr, fileIndex) => {
                            let file = JSON.parse(fileStr)
                            let buildUrl = MyConstant.keyList.speakupApiURL + "vp?action=" + file.processType + "&key=" + file.docsId + "." + file.fileType + "&id=" + userId
                            return <div className="col-6 my-2 px-2" key={fileIndex} >
                                <div style={{ border: "1px solid #ddd", textAlign: "center", background: "#9e9e9e" }}>
                                    <div style={{
                                        position: 'absolute',
                                        right: 10,
                                        top: 10,
                                        background: '#000',
                                        zIndex: 1,
                                        color: '#fff',
                                        borderRadius: 5,
                                        padding: '2px 10px',
                                        cursor: 'pointer'
                                    }} onClick={() => {
                                        this.setState({
                                            previewModal: true,
                                            previewIndex: fileIndex,
                                            previewTotalLength: currentViewFiles.length,
                                            viewModal: false
                                        })
                                    }}>Preview</div>
                                    {
                                        images.includes(file.fileType.toLowerCase()) && <img src={buildUrl} className="img-fluid" style={{ maxHeight: 400 }} />
                                    }
                                    {
                                        videos.includes(file.fileType.toLowerCase()) && <video width="100%" controls style={{ maxHeight: 400 }}>
                                            <source src={buildUrl} />
                                        </video>
                                    }
                                    {
                                        pdf.includes(file.fileType.toLowerCase()) && <React.Fragment>
                                            <object data={buildUrl} type="application/pdf" width="100%" height="250px"></object>
                                            <a className="btn btn-sm btn-success my-2" href={buildUrl} target="_blank">Preview</a>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
                break;
            case "status":
                buildContent = <div>{`${dlist.status} ${dlist.statusDescription ? " - " + dlist.statusDescription : ""}`}</div>
                break;
            case "action":
                buildContent = <React.Fragment>
                    <div className="form-group">
                        <label>Status</label>
                        <Select
                            options={statusOption}
                            isSearchable={true}
                            placeholder="Select"
                            onChange={this.handleChange}
                            value={statusOption.filter(option => option.value === selectedStatus)}
                        />
                    </div>
                    <div className="form-group">
                        <label>Description</label>
                        <textarea
                            className="form-control"
                            value={description}
                            onChange={(e) => this.setState({
                                description: e.target.value
                            })}
                        />
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary" onClick={() => this.updateStatus()}>Save</button>
                    </div>
                </React.Fragment>
                break;
            case "history":
                buildContent = <React.Fragment>
                    <ul className="timeline">
                        <li>
                            <span>Feedback created</span>
                            <span className="float-right">{this.humanDateFormat(dlist.createdAt)}</span>
                        </li>
                        {
                            historyData.map((history, index) => {
                                history = JSON.parse(history)
                                return <li key={index} className={history.status.toLowerCase()}>
                                    <span>{history.status}</span>
                                    <span className="float-right">{this.humanDateFormat(history.createdAt)}</span>
                                    <p className="status-description">{history.statusDescription}</p>
                                </li>
                            })
                        }
                    </ul>
                </React.Fragment>
                break;
            case "editHistory":
                buildContent = <React.Fragment>
                    {
                        Object.keys(editHistoryData).map((row, index) => {
                            return <div key={index}>{this.versionChanges(row)}</div>
                        })
                    }
                </React.Fragment>
                break;
            case "createdAt":
                buildContent = <div>{this.humanDateFormat(dlist.createdAt)}</div>
                break;
        }

        return buildContent;
    }

    versionChanges(version) {
        let { editHistoryData } = this.state;
        return editHistoryData[version]
    }
    getIssueCount() {
        let { session, mUserId } = this.state
        let postJson = { auth: "Teqbahn@321#", msg: "getcounter", mUserId: mUserId, session: session, statusList: MyConfig.statusList }
        const request = new ZiFetchRequest();
        request.setDomain('appIssues');
        request.setEvent('getAppIssuesStatusCounter');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() === "Invalid request") {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                this.setState({ issueCountList: responseData.response })
            }
        });
    }
    previewContent() {
        let { viewDetails, previewIndex } = this.state;
        viewDetails = viewDetails.appIssuesReportData;
        let file = JSON.parse(viewDetails.fileList[previewIndex])
        let userId = viewDetails.userId;
        let buildUrl = MyConstant.keyList.speakupApiURL + "vp?action=" + file.processType + "&key=" + file.docsId + "." + file.fileType + "&id=" + userId
        let images = ["jpg", "png"];
        let videos = ["mp4", "3gp", "ogg", "mov"]
        let pdf = ["pdf"]

        let content = <div style={{ textAlign: "center" }} >
            {
                images.includes(file.fileType.toLowerCase()) && <img src={buildUrl} className="img-fluid" />
            }
            {
                videos.includes(file.fileType.toLowerCase()) && <video width="100%" controls>
                    <source src={buildUrl} />
                </video>
            }
            {
                pdf.includes(file.fileType.toLowerCase()) && <PDFViewer url={buildUrl} />
            }
        </div>

        return content;
    }

    fileGenerateAPI() {
        this.setState({ loaderSpinner: true })
        let postJson = { auth: "Teqbahn@321#", }
        const request = new ZiFetchRequest();
        request.setDomain('appIssues');
        request.setEvent('generateAllAppIssuesReportExcel');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid Request")
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg.response == "Success") {
                    this.setState({ loaderSpinner: false }, () => {
                        this.downloadUrl()
                    })
                }
            }
        });
    }

    downloadUrl() {
        var link = document.createElement("a");
        link.download = "feedback.xls";
        link.href = MyConstant.keyList.speakupApiURL + "feedback.xls";
        link.click();
    }
    render() {
        let { issueList, pagination, prev, next, processing, pageLimit, viewModal, viewType, viewDetails, noPagesLimit, issueCountList, previewModal, previewTotalLength, previewIndex, loaderSpinner } = this.state


        return (
            <>
                <div className="body-content">
                    <div className='card' style={{ padding: 0 }}>
                        <div className="card-header theme-bg">
                            <div className='row'>
                                <div className='col-10'>
                                    <p style={{ fontFamily: "system-ui", color: "#fff", fontWeight: "bold" }}> Feedback Overview</p>
                                </div>
                                <div className='col-2' style={{ textAlign: "end" }}>

                                    <button className="btn btn-primary" onClick={() => {
                                        this.fileGenerateAPI()
                                    }}>Export
                                        {loaderSpinner && <div className="spinner-border" role="status" style={{ width: "1rem", height: "1rem", color: "#fff", marginLeft: 10 }}>
                                            <span className="sr-only"></span>
                                        </div>}

                                    </button>
                                </div>
                            </div>


                        </div>
                        <div className='card-body' style={{ backgroundColor: "#ddd" }}>
                            <div className='row' style={{ paddingLeft: 20, paddingBottom: 15 }}>
                                {Object.keys(issueCountList).length != 0 ? <>
                                    {
                                        Object.keys(issueCountList).map((key) => {
                                            let issueType = key.replace(/_/g, " ")
                                            let issueCount = issueCountList[key]
                                            let fontColor = ""
                                            if (issueType == "Pending") {
                                                fontColor = "red"
                                            }
                                            else if (issueType == "Completed") {
                                                fontColor = "green"
                                            }

                                            return (<>
                                                <div className='col-sm-4 col-4 p-2 '>
                                                    <p style={{ fontWeight: "bold", fontFamily: "system-ui", color: fontColor }}>
                                                        {issueType} - <span className='hover-issueCount' onClick={() => {
                                                            if (parseInt(issueCount) > 0) {

                                                                window.open(MyConstant.keyList.projectUrl + "/admin/feedbackBasedStatus?status=" + key);
                                                            }
                                                        }} style={{ cursor: "pointer" }}> {issueCount} </span>
                                                    </p>
                                                </div>
                                            </>)
                                        })

                                    }

                                </> : null}
                            </div>
                        </div>
                    </div>


                    <div className={`row ${processing && "table-processing"}`}>
                        {processing && <div className="processing"><span>Processing...</span></div>}
                        <div className="col-md-12">
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Ref No</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Device</th>
                                        <th scope="col">OS version</th>
                                        <th scope="col">Version</th>
                                        <th scope="col">Image/Video</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                        <th scope="col">History</th>
                                        {/* <th scope="col">Edit History</th> */}
                                        <th scope="col">Created At</th>
                                        <th scope="col">Modified At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.keys(issueList).map((key, index) => {
                                            let dlist = issueList[key].appIssuesReportData;
                                            let metaInfo = dlist.metaInfo ? JSON.parse(dlist.metaInfo) : ""
                                            let emailId = issueList[key].emailId;
                                            let deviceInfo = dlist.deviceInfo ? JSON.parse(dlist.deviceInfo) : {}
                                            return <tr key={index} className={`${dlist.status === "Feature Addition" ? "feature-addition" : ""}`}>
                                                <td>{getSpeakUpIssueId(dlist.issuesNo)}</td>
                                                {
                                                    dlist.status === "Duplicate" ? <td colSpan="9" className="text-center">
                                                        Duplicate
                                                    </td> : <React.Fragment>
                                                        <td>{dlist.title}</td>
                                                        <td>
                                                            <span>{dlist.descrition.substring(0, 50)} {dlist.descrition.length > 50 && <span>...<a className="read-more-comp" onClick={() => this.viewModal("description", key)}>Read More</a></span>}</span>
                                                        </td>
                                                        <td>{emailId}</td>
                                                        <td>{metaInfo.issueFrom === "Web" ? "web" : deviceInfo.appPlatform}</td>
                                                        <td>{metaInfo.issueFrom === "Web" ? "-" : (metaInfo ? `${metaInfo.deviceOsVersion} ${metaInfo.deviceModel ? "- " + metaInfo.deviceModel : ""}` : "-")}</td>
                                                        <td>{metaInfo.issueFrom === "Web" ? "-" : deviceInfo.appVersion}</td>
                                                        <td><button className="btn btn-sm btn-info" onClick={() => this.viewModal("imageVideo", key)}>View</button></td>
                                                        <td>
                                                            {`${dlist.status} `}
                                                            {/* <div>{dlist.statusDescription.substring(0, 50)} {dlist.statusDescription.length > 50 && <span>...<a className="read-more-comp" onClick={() => this.viewModal("status", key)}>Read More</a></span>}</div> */}
                                                        </td>
                                                        <td><button className="btn btn-sm btn-success" onClick={() => this.viewModal("action", key)}>Update</button></td>
                                                    </React.Fragment>
                                                }

                                                <td><button className="btn btn-sm btn-success" onClick={() => this.viewModal("history", key)}>View</button></td>
                                                {/* <td><button className="btn btn-sm btn-success" onClick={() => this.viewModal("editHistory", key)}>View</button></td> */}
                                                <td>{this.humanDateFormat(dlist.createdAt)}</td>
                                                <td>{dlist.lastUpdatedAt == 0 ? "-" : this.humanDateFormat(dlist.lastUpdatedAt)}</td>
                                            </tr>
                                        })}
                                    {Object.keys(issueList).length === 0 && <td colSpan="13" className="text-center">{"No records found."}</td>}
                                </tbody>

                            </table>
                            <Pagination
                                pagination={pagination}
                                currentPage={noPagesLimit}
                                prev={prev}
                                next={next}
                                movePage={(type) => {
                                    this.movePage(type)
                                }} />
                        </div>
                    </div>
                </div>
                {
                    viewModal && <Modal
                        visible={viewModal}
                        size={"modal-xl"}
                        closeModal={() => this.setState({ viewModal: false })}
                        heading={"Feedback - " + viewDetails.appIssuesReportData.title}
                        body={<React.Fragment>
                            <div className="row">
                                <div className="col-8">
                                    {
                                        this.viewContent()
                                    }
                                </div>
                                <div className="col-4">
                                    <div className="tabs-vertical">
                                        <div className={`tab ${viewType === "refNo" ? "active" : ""}`} onClick={() => this.setState({ viewType: "refNo" })}>Ref No</div>
                                        <div className={`tab ${viewType === "title" ? "active" : ""}`} onClick={() => this.setState({ viewType: "title" })}>Title</div>
                                        <div className={`tab ${viewType === "description" ? "active" : ""}`} onClick={() => this.setState({ viewType: "description" })}>Description</div>
                                        <div className={`tab ${viewType === "email" ? "active" : ""}`} onClick={() => this.setState({ viewType: "email" })}>Email</div>
                                        <div className={`tab ${viewType === "device" ? "active" : ""}`} onClick={() => this.setState({ viewType: "device" })}>Device</div>
                                        <div className={`tab ${viewType === "osVersion" ? "active" : ""}`} onClick={() => this.setState({ viewType: "osVersion" })}>OS version</div>
                                        <div className={`tab ${viewType === "version" ? "active" : ""}`} onClick={() => this.setState({ viewType: "version" })}>Version</div>
                                        <div className={`tab ${viewType === "imageVideo" ? "active" : ""}`} onClick={() => this.setState({ viewType: "imageVideo" })}>Image/Video</div>
                                        <div className={`tab ${viewType === "status" ? "active" : ""}`} onClick={() => this.setState({ viewType: "status" })}>Status</div>
                                        <div className={`tab ${viewType === "action" ? "active" : ""}`} onClick={() => this.setState({ viewType: "action" })}>Action</div>
                                        <div className={`tab ${viewType === "history" ? "active" : ""}`} onClick={() => this.setState({ viewType: "history" })}>History</div>
                                        {/* <div className={`tab ${viewType === "editHistory" ? "active" : ""}`} onClick={() => this.setState({ viewType: "editHistory" })}>Edit History</div> */}
                                        <div className={`tab ${viewType === "createdAt" ? "active" : ""}`} onClick={() => this.setState({ viewType: "createdAt" })}>Created At</div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                    />
                }

                {
                    previewModal && <Modal
                        visible={previewModal}
                        size={"modal-xl"}
                        closeModal={() => this.setState({
                            previewModal: false,
                            viewModal: true
                        })}
                        heading={"Preview"}
                        body={<div style={{ minHeight: "75vh" }}>
                            <div className="row">
                                <div className="col-1">
                                    <button className="btn btn-secondary" onClick={() => {
                                        let index = previewIndex - 1;
                                        this.setState({
                                            previewIndex: index <= 0 ? 0 : index
                                        })
                                    }}><i className="fa fa-chevron-left"></i></button>
                                </div>
                                <div className="col-10">
                                    {
                                        this.previewContent()
                                    }
                                </div>
                                <div className="col-1">
                                    <button className="btn btn-secondary" onClick={() => {
                                        let index = previewIndex + 1;
                                        this.setState({
                                            previewIndex: previewTotalLength > index ? index : previewTotalLength - 1
                                        })
                                    }}><i className="fa fa-chevron-right"></i></button>
                                </div>
                            </div>

                        </div>
                        }
                    />
                }
            </>
        )
    }
}

export default IssuesList;