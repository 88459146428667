import React, { Component } from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import SideManuBar from './SideMenuBar';
import image3 from '../images/Teqbahn_logo1.png';
import MyConfig from '../config/MyConfig';
import { dateConverter } from '../config/MyFunctions';
import { toast, ToastContainer } from "react-toastify";
import { checkLogin, readSessionData, resetLocalData } from '../config/MyFunctions';
import ManageSideMenu from '../manage/ManageSideMenu';
import whiteLogo from '../images/whiteLogo.png';
import ManageLogout from "../manage/ManageLogout"
import AdminLogout from "./AdminLogout"
import Modal from "./component/Modal";
import Select from './component/Select';






class RoleType extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuActive: "",
            action: "Add",
            roleTypeName: "",
            roleTypeName_Validate: "",
            redirectPageName: "",
            redirectPageName_Validate: "",
            processing: false,
            prev: false,
            next: false,
            noPagesLimit: 1,
            pageLimit: 10,
            roleTypeList_Json: {},
            pagination: [],
            visibleClass: "",
            totalResultsCount: 0,
            mUserId: "",
            session: "",
            selectedPageOption: [],
            pageOption: [],
            roleToPageModal: false,
            chooseRole: "",
            chooseRoleId: "",
            pageDetailsObj: {},
            displayShow: true

        }
        this.myRef = React.createRef()
    }
    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        if (login_status) {
            document.body.style.backgroundColor = "#f6f7fb";
            let resData = await readSessionData()
            let { manage_session, manage_mUserId } = resData
            this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
                this.getRoleTypeList()
                this.getPages()
            })
        }

    }

    getPages() {
        let { mUserId, session } = this.state
        let postJson = { mUserId, session, pageLimit: 100, noOfPage: 1 };
        const request = new ZiFetchRequest();
        request.setDomain("adminPages");
        request.setEvent("list");
        request.setMessage(JSON.stringify(postJson));
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage().toLowerCase() == 'invalid request') {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                // console.log("**",responseData)
                let result = responseData.result;
                let pageOption = [];
                Object.keys(result).map((option) => {
                    pageOption.push({ value: option, label: result[option].title })
                })

                this.setState({
                    pageOption, pageDetailsObj: result
                })
            }
        })
    }

    getRoleTypeList() {
        let { mUserId, session } = this.state
        let postJson = { mUserId, session }
        const request = new ZiFetchRequest();
        request.setDomain("adminRoleType");
        request.setEvent("list");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response == "Success") {
                    this.setState({
                        roleTypeList_Json: json.result,
                    });

                }
                else if (json.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }

    submit() {

        let { roleTypeName, mUserId, session, redirectPageName } = this.state
        let postJson = { roleType: roleTypeName, redirect: redirectPageName, mUserId, session };
        let roleTypeName_Validate = ""
        let redirectPageName_Validate = ""

        if (!roleTypeName) {
            roleTypeName_Validate = "Enter Role Type"
        }
        if (!redirectPageName) {
            redirectPageName_Validate = "Enter Redirect Page Name"
        }

        this.setState({ roleTypeName_Validate, redirectPageName_Validate })
        if (!roleTypeName || !redirectPageName) {
            return false
        }
        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('adminRoleType');
        request.setEvent('create');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Role Type Added successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({ roleTypeName: "", redirectPageName: "" }, () => {
                        this.getRoleTypeList()
                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }

    Update() {
        let { roleTypeName, mUserId, session, redirectPageName } = this.state

        let postJson = {
            roleType: roleTypeName, redirect: redirectPageName,
            id: this.state.id,
            mUserId, session
        };

        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('adminRoleType');
        request.setEvent('update');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Role Type  Updated successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({ action: "Add", roleTypeName: "", redirectPageName: "" }, () => {
                        this.getRoleTypeList()
                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });

    }
    async edit(data) {
        this.scrollToTop()
        this.setState({ action: "Edit", id: data["id"], roleTypeName: data["rollType"], redirectPageName: data["redirect"], visibleClass: "bgClrChg",displayShow:false })
        setTimeout(() => {
            this.setState({ visibleClass: "" })
        }, 1000);
    }

    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }
        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getRoleTypeList()
            })
        }
    }

    delete(data) {
        let { mUserId, session } = this.state
        let confirmMessage = window.confirm("Are You Sure Delete Role Type?")
        //id: String
        let postJson = {
            id: data["id"],
            mUserId, session
        };

        if (confirmMessage) {
            console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain('adminRoleType');
            request.setEvent('delete');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.log("response", response)
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    // console.log(responseMsg)
                    if (responseMsg.response == "Success") {
                        toast.success("Category Data Deleted.", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        this.getRoleTypeList()
                    }
                    else if (responseMsg.response == "exist") {
                        toast.error("Please remove under the page  mapping !", {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    else if (responseMsg.response == "Invalid Session Request") {
                        toast.warning("Your session got expired!", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        resetLocalData(this.props)
                    }
                }
            });
        }

    }

    scrollToTop() {
        this.myRef.current.scrollTo(0, 0);
    }

    saveMap() {
        let { chooseRoleId, selectedPageOption, mUserId, session } = this.state
        let pages = selectedPageOption.map(function (item) {
            return item.value;
        });
        let postJson = { rollTypeId: chooseRoleId, adminPages: pages, mUserId, session };

        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('adminPages');
        request.setEvent('mapToRollType');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Page Mapped successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({ title_Name: "", routingName: "" }, () => {

                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });

    }

    getMappingPage(roleData) {
        let { chooseRoleId, mUserId, session, pageDetailsObj } = this.state
        let postJson = { rollTypeId: roleData['id'], mUserId, session }
        const request = new ZiFetchRequest();
        request.setDomain("adminPages");
        request.setEvent("getMappedToRollType");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response == "Success") {
                    let selectedPageOption = []
                    let listOfPageIds = json.listOfPageIds
                    listOfPageIds.map((key) => {
                        let data = pageDetailsObj[key]
                        selectedPageOption.push({ value: data["pageId"], label: data["title"] })
                    })
                    this.setState({
                        selectedPageOption,
                        roleToPageModal: true, chooseRole: roleData['rollType'], chooseRoleId: roleData['id']
                    });

                }
                else if (json.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }

    render() {
        let { menuActive, roleTypeName_Validate, roleTypeList_Json, roleTypeName, visibleClass, redirectPageName, redirectPageName_Validate, roleToPageModal, selectedPageOption, pageOption, displayShow } = this.state
        import('../admin/style.css');
        let that = this
        var manageIsLogin = localStorage.getItem("managelogedin")


        let compnayLogo = image3
        if (manageIsLogin) {
            compnayLogo = whiteLogo
        }


        let tableData = []
        Object.keys(roleTypeList_Json).length != 0 && Object.keys(roleTypeList_Json).forEach(function (key, index) {
            let data = roleTypeList_Json[key]
            tableData.push(
                <tr >
                    <td style={{ width: "20%" }} className='tabletxt'>{data['rollType']}</td>
                    <td style={{ width: "10%" }} className='tabletxt'>{data['redirect']}</td>
                    <td style={{ width: "20%" }} className='tabletxt' >{dateConverter(data['createdAt'])}</td>
                    <td style={{ width: "15%" }} className='fofamily' ><div className={" editBtnstyle"} style={{
                        backgroundColor: "#007bff"
                    }} onClick={() => {

                        that.getMappingPage(data)


                    }}>Mapping Page</div></td>
                    <td style={{ width: "10%" }} className='fofamily' ><div className={" editBtnstyle"} onClick={() => that.edit(data)}>Edit</div></td>
                    <td style={{ width: "10%" }} className='fofamily'><div className={"deleteBtnstyle"} onClick={() => that.delete(data)}>Delete</div></td>
                </tr>
            )
        });

        return (
            <div className="body-content left-rigt-padding cat-style" ref={this.myRef}>
                <main className="app-main">
                    <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>

                        <div className="container">
                            <div className={(visibleClass ? visibleClass : "") + " card box-shadow"} style={{ padding: 0 }}>
                                <div className="card-header border-btm-style cursor-style" onClick={() => {
                                    this.setState({ displayShow: !displayShow })
                                }}>
                                    <div className='row'>
                                        <div className='col-10 col-sm-11 col-md-11'>
                                            <h5 className='header-style' >{this.state.action} Role Type</h5>
                                        </div>
                                        <div className='col-2 col-sm-1 col-md-1'>
                                            <span style={{ float: "right", color: "#fff", }}>
                                                {displayShow ? <i class="fa fa-plus"></i> : <i class="fa fa-minus"></i>}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div className="card-body" style={displayShow ? { display: "none" } : {}}>
                                    <div className={"row"}>
                                        <div className={"col-md-2"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Role Type</label></div>
                                        <div className={"col-md-7"}>
                                            <input type="text" placeholder={"Enter Role Type "} className={"form-control inputRadius"} name={"label"} value={roleTypeName} onChange={e => {
                                                this.setState({ roleTypeName: e.target.value })
                                            }} />
                                            <span className='error-txt'>{roleTypeName_Validate} </span>
                                        </div>
                                    </div>
                                    <br />
                                    <div className={"row"}>
                                        <div className={"col-md-2"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Redirect</label></div>
                                        <div className={"col-md-7"}>
                                            <input type="text" placeholder={"Enter Category "} className={"form-control inputRadius"} name={"label"} value={redirectPageName} onChange={e => {
                                                this.setState({ redirectPageName: e.target.value })
                                            }} />
                                            <span className='error-txt'>{redirectPageName_Validate} </span>
                                        </div>
                                    </div>

                                    <div className={"row mt-3"}>
                                        <div className={"col-sm-4"}></div>
                                        <div className={"col-sm-4"}>
                                            {
                                                this.state.action == "Add" ? <input type="button" className="btn btn-primary ml-auto" value="Create" onClick={() => this.submit()} /> : <input type="button" className="btn btn-primary ml-auto" value="Update" onClick={() => this.Update()} />
                                            }
                                            {this.state.action == "Edit" && <input type="button" className="btn btn-danger ml-3" value="Cancel" onClick={() => {
                                                this.setState({ action: "Add", roleTypeName: "", roleTypeName_Validate: "", redirectPageName: "" })
                                            }} />}


                                        </div>
                                        <div className={"col-sm-4"}></div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="container mt-2">
                            <div className="card box-shadow" style={{ padding: 0 }}>
                                <div className="card-header border-btm-style" style={{ borderBottom: "none" }}>
                                    <h5 className='header-style' > Role Type List</h5>
                                </div>
                                <div className="card-body table-responsive" style={{ padding: 2 }}>
                                    <table className="table table-style light-border-clr" style={{ width: "100%", textAlign: "center" }} border={1} cellPadding={7}>
                                        <tr className='upperCase'>
                                            <th scope="col fw">Role Type</th>
                                            <th scope="col fw">Redirect</th>
                                            <th scope="col fw">Created At</th>
                                            <th scope="col fw">Mapping Page</th>
                                            <th scope="col fw">Edit</th>
                                            <th scope="col fw">Delete</th>
                                        </tr>
                                        <tbody>
                                            {tableData}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>

                        {
                            roleToPageModal && <Modal
                                visible={roleToPageModal}
                                closeModal={() => this.setState({ roleToPageModal: false })}
                                heading={`Assign Page`}
                                body={<React.Fragment>

                                    <div className="form-group">
                                        <label>Role</label>
                                        <input type="text" placeholder={"Role Type"} className={"form-control inputRadius txt-disable-color"} name={"label"} value={this.state.chooseRole} onChange={e => {

                                        }}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Page</label>
                                        <Select
                                            value={selectedPageOption}
                                            onChange={(e) => {
                                                this.setState({
                                                    selectedPageOption: e
                                                })
                                            }}
                                            options={pageOption}
                                            isDisabled={false}
                                            isMulti={true}
                                        />
                                    </div>
                                </React.Fragment>
                                }
                                footer={<React.Fragment>
                                    <button type="button" className="btn theme-bg-manage white-bold" onClick={() => this.saveMap()}>Save changes</button>
                                </React.Fragment>}
                            />
                        }

                    </div>
                </main>
            </div>
        )
    }
}

export default RoleType;