import React from 'react';
import MyConstant from '../config/MyConstant';



class UpdateIssueData extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageText: "Waiting for Redirect ..."
        }
    }

    componentDidMount() {
        let { match: { params: { id } } } = this.props
        if (typeof (id) != undefined && id) {
            window.location = "umedmeapp://appIssueEdit/" + id
            // window.location = "https://www.google.com/"
            setTimeout(() => {
                this.setState({ pageText: "Please Install Umedme App" })
                window.location = MyConstant.keyList.projectUrl + "/app-feedback"
            }, 120000);
        }

    }
    render() {
        import('./style.css');
        return <React.Fragment>
            <div className="wrapper umedme">
                <div id="content" style={{ backgroundColor: "#ddd" }}>
                    <h4 className='text-center' style={{ color: "#000", fontSize: 40, fontFamily: "system-ui", position: "relative", float: "left", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}> {this.state.pageText}</h4>
                </div>
            </div>
        </React.Fragment>;
    }
}

export default UpdateIssueData;
