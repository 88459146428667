import React from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import SideManuBar from './SideMenuBar';
import image3 from '../images/Teqbahn_logo1.png';
import MyConfig from '../config/MyConfig';
import Select from './component/Select';
import { toast, ToastContainer } from "react-toastify";
import { loginStatusCheck, checkLogin } from '../config/MyFunctions';
import AdminLogout from "./AdminLogout"
import ManageLogout from "../manage/ManageLogout"
import ManageSideMenu from '../manage/ManageSideMenu';




class UiManager extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedStatus: "",
            menuActive: "",
            uiJson: {},
            ui_Json_Structure: [],
            ui_Json_Array: [],
            selectValue: {},
            errorPageName: "",
            isModified: false
        }
        this.handleChange = this.handleChange.bind(this)
    }
    handleChange(e) {
        this.setState({
            selectedStatus: e.value,
            selectValue: e, errorPageName: ""
        }, () => {
            this.getUi_Structure()
        })
    }

    async componentDidMount() {
        // const link = document.createElement("link");
        // link.href = "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";
        // link.rel = "stylesheet";
        // link.type = "text/css";
        // document.head.appendChild(link);
        let login_status = await checkLogin(this.props)
        this.getUiData()

    }

    getUiData() {
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("ui-list");
        request.setMessage("{}");
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                this.setState({ uiJson: json.uiMap })
            }
        });
    }

    submitData() {
        let { ui_Json_Array, selectedStatus, isModified } = this.state
        let ui_json = []
        let emptyLabel = false
        ui_Json_Array.map(ival => {
            ui_json.push(ival.textField)
            if (!ival.textField) {
                emptyLabel = true
                ival.errorTextField = "Please Enter Label"
            }
            else {
                ival.errorTextField = ""
            }
        })

        // console.log(selectedStatus)
        let errorPageName = ""
        if (!selectedStatus) {
            errorPageName = "Please Choose Page Name"
        }
        this.setState({ errorPageName, ui_Json_Array })

        if (!selectedStatus || emptyLabel) {
            return false
        }

        if (ui_Json_Array.length == 0) {
            console.log(ui_Json_Array.length, "-->wait")
            return false
        }

        let postJson = { key: selectedStatus, UiJson: JSON.stringify(ui_json), isModified }
        //key: String, UiJson :String
        console.log("postJson", postJson)


        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("updateUIStructureData");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                console.log("json", json)
                if (json.response == "Success") {
                    toast.success("UI Structrue Created!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    this.getUi_Structure()
                }
            }
        });

    }

    getUi_Structure() {
        let { selectedStatus } = this.state
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("getUIStructure");
        request.setMessage("{}");
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (selectedStatus && json.uiMap && json.uiMap[selectedStatus]) {
                    let parseData = JSON.parse(json.uiMap[selectedStatus])
                    if (typeof (parseData) != undefined) {
                        let ui_Json_Array = []
                        parseData.map((ival) => {
                            ui_Json_Array.push({ textField: ival })
                        })
                        this.setState({ ui_Json_Array: ui_Json_Array })
                    }
                }
                else {
                    this.setState({ ui_Json_Array: [] })
                }
            }
        });
    }

    addMore() {
        let { ui_Json_Array, selectedStatus } = this.state
        let errorPageName = ""
        if (!selectedStatus) {
            errorPageName = "Please Choose Page Name"
        }
        if (selectedStatus) {
            ui_Json_Array.push({ textField: "", errorTextField: "" })
        }
        this.setState({ 
            ui_Json_Array, 
            errorPageName,
            isModified: true
        })
    }


    deleteLabel(index) {

        let askConfirm = window.confirm("Are You Sure Delete Label !")
        let { ui_Json_Array, selectedStatus } = this.state
        if (askConfirm) {
            let removeLabel = ui_Json_Array.filter((data, j) => {
                return j != index
            })
            this.setState({ 
                ui_Json_Array: removeLabel,
                isModified: true
            }, () => {
                this.deletePostApi()
            })
        }

    }

    deletePostApi() {

        let { ui_Json_Array, selectedStatus } = this.state
        let ui_json = []
        ui_Json_Array.map(ival => {
            ui_json.push(ival.textField)
        })

        console.log(ui_Json_Array)
        let postJson = { key: selectedStatus, UiJson: JSON.stringify(ui_json) }
        //key: String, UiJson :String
        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("updateUIStructureData");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                console.log("json", json)
                if (json.response == "Success") {
                    toast.success("Deleted Label!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    this.getUi_Structure()
                }
            }
        });
    }



    render() {
        let { menuActive, selectedStatus, uiJson, ui_Json_Array, errorPageName } = this.state
        import('../admin/style.css');
        let that = this
        let uiOption = []
        Object.keys(uiJson).length != 0 && Object.keys(uiJson).forEach(function (key, index) {
            let data = uiJson[key]
            uiOption.push({ label: data['label'], value: data['value'] })
        });

        return (
            <div className="body-content left-rigt-padding">
                <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>
                    <div className='col-lg-12'>
                        <h3>Language Structure</h3>
                    </div>
                    <div className={"col-lg-1"} />
                    <div className={"col-lg-4"} >
                        <br />
                        <label className='label-style'> Page </label>
                        <Select
                            options={uiOption}
                            isSearchable={true}
                            placeholder="Select"
                            onChange={this.handleChange}
                            value={uiOption.filter(option => option.value === selectedStatus)}
                        />
                        <span className='error-txt'> {errorPageName} </span>
                    </div>
                    <div className={"col-lg-4"} />
                </div>

                <div className={"row mt-3 pl-3 pr-3 "}>

                    {ui_Json_Array.length != 0 && ui_Json_Array.map((ival, i) => {
                        return (<React.Fragment key={i.toString()}>
                            <div className={"col-lg-6 col-sm-6 col-6 pt-2"} >
                                <label className='label-style'> Label - {i + 1} </label>
                                <i class="fa fa-trash pt-2" aria-hidden="true" style={{ float: "right", color: "red", cursor: "pointer" }} onClick={() => {
                                    this.deleteLabel(i)
                                }} ></i>
                                <input className='form-control' type={"text"} placeholder={`Label - ${i + 1}`}
                                    value={ival.textField} onChange={(e) => {
                                        ui_Json_Array[i].textField = e.target.value.trim()
                                        this.setState({ ui_Json_Array })
                                    }} />
                                {ui_Json_Array[i].errorTextField && <span className='error-txt'> {ui_Json_Array[i].errorTextField} </span>}
                            </div>
                        </React.Fragment>)
                    })}
                </div>

                {selectedStatus && <div className={"row mt-3 pt-3 mb-5"} style={{}}>
                    <div className={"col-lg-1"} />
                    <div className={"col-lg-4"} >
                        <a href="javascript:void(0)" onClick={() => { this.submitData() }} className={"btn btn-success"}>Submit</a>
                    </div>
                    <div className={"col-lg-4"} >

                        <a href="javascript:void(0)" onClick={() => { this.addMore() }} className={"btn btn-info"}>Add more</a>
                    </div>
                </div>}

            </div>
        )
    }
}

export default UiManager;