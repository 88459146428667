import React from 'react';
import { ZiFetchRequest } from "../protobuf/speakup/speakup_grpc_web_pb";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Cell,
    LineChart,
    Line
} from "recharts";
import DataTable from "./component/DataTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class SpeakupDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sDate: new Date(),
            eDate: new Date(),
            dateBasedVisitData: [],
            dateBasedPageData: [],
            dateBasedIp: {},
            columns: [
                {
                    name: 'Date',
                    selector: row => row.date,
                },
                {
                    name: 'IP address',
                    selector: row => row.ipaddress,
                },
                {
                    name: 'Visit count',
                    selector: row => row.visits,
                },
            ],
            ipData: [],
            platformType: "web",
            appRegisterUserData: [],
            counterJsonData: {
                audioData: [],
                videoData: [],
                calendarData: [],
                dietData: [],
                medicationData: []
            },
            appUserLoginCount: [],
            issueCount: [],
            totalIssueCount: 0,
            totalUserCount: 0,
            counterMap: {}
        }
    }
    componentDidMount() {
        var d = new Date();
        d.setDate(d.getDate() - 5);
        let ds = new Date()
        let yesterday = d

        this.setState({
            sDate: yesterday,
            eDate: ds,
        }, () => {
            this.searchData();
            this.getUsersCountList()
        });
    }

    
    formatDate(date) {
        var year = date.getFullYear().toString();
        var month = (date.getMonth() + 101).toString().substring(1);
        var day = (date.getDate() + 100).toString().substring(1);
        return year + "-" + month + "-" + day;
    }

    searchData() {
        let { sDate, eDate } = this.state
        sDate = this.formatDate(sDate);
        eDate = this.formatDate(eDate);

        this.setState({ resLoaderView: true })
        let postJson = { sDate, eDate, id: "123" }
        const request = new ZiFetchRequest();
        request.setDomain('pageAnalytics');
        request.setEvent('fetchFilterUserAttemptAnalytics');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.speakupGrpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() === "Invalid request") {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response) {
                    let response = responseData.response;
                    let dateBasedVisitData = [],
                        dateBasedPageData = [],
                        ipData = [];
                    response.dateBasedUniqueIp.map(ival => {
                        dateBasedVisitData.push({
                            name: this.dateFormatChange(ival.x),
                            y: parseInt(ival.y)
                        });
                    })

                    let dateBasedIp = response.dateBasedIp;
                    let inc = 1;
                    Object.keys(dateBasedIp).map(date => {
                        let data = dateBasedIp[date];
                        Object.keys(data).map(ip => {
                            ipData.push({
                                id: inc,
                                date: this.dateFormatChange(date),
                                ipaddress: ip,
                                visits: dateBasedIp[date][ip]
                            })

                            inc++;
                        })
                    });

                    let dateBasedPageVist = response.dateBasedPageVist;
                    Object.keys(dateBasedPageVist).sort().map(ival => {
                        let data = dateBasedPageVist[ival]
                        dateBasedPageData.push({
                            name: this.dateFormatChange(ival),
                            speakUp: typeof data["SpeakUp"] !== "undefined" ? parseInt(data["SpeakUp"]) : 0,
                            speakUpSignIn: typeof data["SpeakUp:SignIn"] !== "undefined" ? parseInt(data["SpeakUp:SignIn"]) : 0,
                            speakUpStory: typeof data["SpeakUp Story"] !== "undefined" ? parseInt(data["SpeakUp Story"]) : 0,
                            speakUpStorySubmit: typeof data["SpeakUp Story:Submit"] !== "undefined" ? parseInt(data["SpeakUp Story:Submit"]) : 0
                        });
                    })

                    this.setState({
                        dateBasedVisitData,
                        dateBasedPageData,
                        ipData
                    })
                }
            }
        });
    }

    async typeChange(type) {
        this.setState({ platformType: type })
    }

    dateFormatChange(date) {
        date = new Date(date);
        var year = date.getFullYear().toString();
        var month = (date.getMonth() + 101).toString().substring(1);
        var day = (date.getDate() + 100).toString().substring(1);

        return month + "-" + day + "-" + year;
    }

    async getUsersCountList() {
        let postJson = { auth: "Teqbahn@321#" }
        const request = new ZiFetchRequest();
        request.setDomain('pageAnalytics');
        request.setEvent('getCounters');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.speakupGrpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() === "Invalid request") {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                let totalUserCount = responseData.totalResult
                let counterMap = responseData.counterMap
                this.setState({ 
                    totalUserCount,
                    counterMap
                })

            }
        });
    }

    render() {
        let { counterMap, sDate, eDate, dateBasedVisitData, dateBasedPageData, ipData, columns, platformType, appRegisterUserData, counterJsonData, issueCount, totalIssueCount } = this.state;
        return <div className='umedme'>
            <div className="mb-2" style={{ paddingTop: 10 }}>
                <div className="row">
                    <div className="col-12">
                        <div className="card mx-0">
                            <div className="card-header">
                                Total Users Count
                            </div>
                            <div className='row mx-0'>
                                <div className="col-md-4 counter-body">
                                    <div className="counter-box"> 
                                        <i className="fa fa-users"></i> 
                                        <span className="counter">
                                            SpeakUp Signin Count <br/>
                                            { counterMap.speakUpMailingCount ? counterMap.speakUpMailingCount : 0}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-4 counter-body">
                                    <div className="counter-box"> 
                                        <i className="fa fa-users"></i> 
                                        <span className="counter">
                                            SpeakUp Unsubscribe Count <br/>
                                            { counterMap.speakUpMailingRemovedCount ? counterMap.speakUpMailingRemovedCount : 0}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-4 counter-body">
                                    <div className="counter-box"> 
                                        <i className="fa fa-users"></i> 
                                        <span className="counter">
                                            SpeakUp Story Submission Count <br/>
                                            { counterMap.speakUpSubmissionCount ? counterMap.speakUpSubmissionCount : 0}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-header">
                    Analytics by Day
                </div>
                <div className="card-body">
                    <div className="row pt-2 pb-2">
                        <div className="col-sm-3 col-3">
                            <div className="form-group" style={{ textAlign: 'left' }}>
                                <label>Start Date</label>
                                <DatePicker
                                    className="form-control"
                                    dateFormat="MM/dd/yyyy"
                                    selected={sDate}
                                    onChange={(date) => {
                                        this.setState({ sDate: date })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-sm-3 col-3" style={{ textAlign: 'left' }}>
                            <div className="form-group">
                                <label>End Date</label>
                                <DatePicker
                                    className="form-control"
                                    dateFormat="MM/dd/yyyy"
                                    selected={eDate}
                                    onChange={(date) => {
                                        this.setState({ eDate: date })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-sm-3 col-3" style={{ textAlign: 'left' }}>
                            <div>&nbsp;</div>
                            <div className="mt-1">
                                <button className="btn btn-primary" onClick={() => {
                                    this.searchData()
                                }

                                }>Search Data</button>
                            </div>
                        </div>
                    </div>

                    <div className="tabs-container">
                        <div className="tabs-body">

                            {
                                platformType == "web" && <>
                                    <div className='row' style={{ width: "100%" }}>
                                        <div className='col-6'>
                                            <div className="card">
                                                <div className="card-header blue">
                                                    Visits analytics by Day
                                                </div>
                                                <div className="card-body">
                                                    <ResponsiveContainer width="100%" height={300}>
                                                        <LineChart
                                                            data={dateBasedVisitData}
                                                        >
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis dataKey="name" />
                                                            <YAxis allowDecimals={false} />
                                                            <Tooltip />
                                                            <Legend />
                                                            <Line type="linear" strokeWidth={3} dataKey="y" stroke="#ed3833" name={"Visits"} />
                                                        </LineChart>
                                                    </ResponsiveContainer>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='col-6'>
                                            <div className="card">
                                                <div className="card-header blue">
                                                    Page analytics by Day
                                                </div>
                                                <div className="card-body">
                                                    <ResponsiveContainer width="100%" height={300}>
                                                        <LineChart
                                                            data={dateBasedPageData}
                                                        >
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis dataKey="name" />
                                                            <YAxis allowDecimals={false} />
                                                            <Tooltip />
                                                            <Legend />
                                                            <Line type="linear" strokeWidth={3} dataKey="speakUp" stroke="#ed3833" name={"SpeakUp Page Landing"} />
                                                            <Line type="linear" strokeWidth={3} dataKey="speakUpSignIn" stroke="#9c3cb0" name={"SpeakUp SignIn Form Submit"} />
                                                            <Line type="linear" strokeWidth={3} dataKey="speakUpStory" stroke="#2196f3" name={"SpeakUp Story Page Landing"} />
                                                            <Line type="linear" strokeWidth={3} dataKey="speakUpStorySubmit" stroke="#8bc34a" name={"SpeakUp Story Form Submit"} />
                                                        </LineChart>
                                                    </ResponsiveContainer>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <div className="card ">
                                                <div className="card-header blue">
                                                    IP analytics by Day
                                                </div>
                                                <div className="card-body">
                                                    <DataTable
                                                        title=""
                                                        columns={columns}
                                                        data={ipData}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>;
    }
}

export default SpeakupDashboard;
