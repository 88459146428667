import React, { Component } from 'react';
import { Link } from "react-router-dom";
import footer1 from '../images/footer-1.png';
import footer2 from '../images/footer-2.png';
import MyConstant from '../config/MyConstant';

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        let {page} = this.props;
        return (
            <React.Fragment>
                <footer>
                    <div className="row grey-bg py-5">
                        <div className="col-sm-12 text-center" id="social-icons">
                            <div className="mx-2 d-inline ">
                                <a href="#">
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                            </div>
                            <div className="mx-2 d-inline ">
                                <a href="#">
                                <i class="fab fa-twitter"></i>
                                </a>
                            </div>
                            <div className="mx-2 d-inline ">
                                <a href="#">
                                <i class="fab fa-instagram"></i>
                                </a>
                            </div>
                            <div className="mx-2 d-inline ">
                                <a href="#">
                                    <i class="fas fa-envelope"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="black-bg">
                        <div className="row text-center">
                            <div className="col-sm-12">
                                <div className="">
                                    <img className="img-fluid footer-1" src={footer1} />
                                </div>
                            </div>
                        </div>
                        <div className="row text-center py-2">
                            <div className="col-sm-12">
                                <div className="">
                                    <img className="img-fluid footer-2" src={footer2} />
                                </div>
                            </div>
                        </div>
                        <div className="row text-center py-1 f-text">
                            <div className="col-sm-12">
                                <p>Umed.Me supports the Sustainable Development Goals</p>
                                <p>Umed.Me Targets SDG #3 Good Health and Well Being</p>
                            </div>
                        </div>
                        <div className="row text-center py-1 f-text-end">
                            <div className="col-sm-12">
                                <p>Umed.Me is a Teqbahn Company</p>
                                <p>11 creekside court,<br/>Secaucus,&nbsp; New&nbsp;Jersey&nbsp;07094</p>
                                <p>Email: Contact@teqbahn.com</p>
                                <p>&#169;2021 by Teqbahn | <Link to={MyConstant.keyList.projectUrl+"/privacy-policy"}>Privacy Policy</Link></p>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}