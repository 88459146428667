import React, { Component } from 'react';
import { Link } from "react-router-dom";
import footer1 from '../images/footer-1.png';
import footer2 from '../images/footer-2.png';
import MyConstant from '../config/MyConstant';

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        let { page } = this.props;
        let year = new Date().getFullYear();
        return (
            <React.Fragment>
                <footer>
                    <div className='grey-bg py-5'>
                        <div className="row ">
                            <div className="col-sm-12 text-center pb-3 share-text">
                                Share
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-sm-12 text-center" id="social-icons">
                                <div className="mx-2 d-inline ">
                                    <a target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location}>
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </div>
                                <div className="mx-2 d-inline ">
                                    <a target="_blank" href={"https://twitter.com/intent/tweet?text=UmedMe&url=" + window.location}>
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </div>
                                <div className="mx-2 d-inline ">
                                    <a target="_blank" href={"https://www.linkedin.com/sharing/share-offsite/?url=" + window.location}>
                                        <i className="fab fa-linkedin"></i>
                                    </a>
                                </div>
                                <div className="mx-2 d-inline ">
                                    <a href={"mailto:?subject=Umed.Me&body=" + window.location} >
                                        <i className="fas fa-envelope"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="black-bg py-5">
                        <div className="row text-center">
                            <div className="col-sm-12">
                                <img className="img-fluid footer-2" src={footer2} />
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-sm-12">
                                <img className="img-fluid footer-1" src={footer1} />
                            </div>
                        </div>
                        <div className="row text-center py-2 f-text">
                            <div className="col-sm-12">
                                <p>Umed.Me supports the Sustainable Development Goals</p>
                                <p>Umed.Me Targets SDG #3 Good Health and Well Being</p>
                            </div>
                        </div>
                        <div className="row text-center py-2 f-text-end">
                            <div className="col-sm-12">
                                <p>Umed.me is a <a href="https://teqbahn.com">Teqbahn</a> Collaboration</p>
                                <p>11 creekside court,<br/>Secaucus,&nbsp; New&nbsp;Jersey&nbsp;07094</p>
                                <p>Email: Contact@teqbahn.com</p>
                                <br />
                                <p>&#169;{year} by Teqbahn | <Link to={MyConstant.keyList.projectUrl + "/privacy-policy"}>Privacy Policy</Link></p>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}