import React from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import image3 from '../images/Teqbahn_logo1.png';
import MyConfig from '../config/MyConfig';
import { dateConverter } from '../config/MyFunctions';
import { toast, } from "react-toastify";
import { checkLogin, readSessionData, resetLocalData } from '../config/MyFunctions';
import whiteLogo from '../images/whiteLogo.png';




class CreteWorkSpace extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuActive: "",
            action: "Add",
            workSpaceName: "",
            workSpaceNameValidate: "",
            processing: false,
            prev: false,
            next: false,
            noPagesLimit: 1,
            pageLimit: 10,
            workSpaceListJson: {},
            pagination: [],
            visibleClass: "",
            totalResultsCount: 0,
            mUserId: "",
            session: "",
            displayShow: true,
            workSpaceDescription: "",
            workSpaceDescriptionValidate: "",
        }
        this.myRef = React.createRef()
    }
    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        if (login_status) {
            document.body.style.backgroundColor = "#f6f7fb";
            let resData = await readSessionData()
            let { manage_session, manage_mUserId } = resData
            this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
                this.getWorkSpaceList()
            })
        }

    }

    getWorkSpaceList() {
        let postJson = { auth: MyConfig.authKey }
        //auth: String, pageLimit: Int, noOfPage:Int       
        const request = new ZiFetchRequest();
        request.setDomain("workspace");
        request.setEvent("getWorkspaceList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.result && Object.keys(json.result).length != 0) {
                    console.log(json.result)
                    this.setState({
                        workSpaceListJson: json.result,
                    });
                }
                else if (json.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }

    submit() {

        let { workSpaceName, workSpaceDescription, mUserId, session } = this.state

        let workSpaceNameValidate = ""
        if (!workSpaceName) {
            workSpaceNameValidate = "Enter Workspace name"
        }
        // let workSpaceDescriptionValidate = ""
        // if (!workSpaceDescription) {
        //     workSpaceDescriptionValidate = "Enter Workspace Description"
        // }
        this.setState({ workSpaceNameValidate, })
        if (!workSpaceName) {
            return false
        }
        let postJson = { name: workSpaceName, description: workSpaceDescription, mUserId, session };
        console.log("postJson", postJson)
        //name: String, description: String, createdAt: Long,mUserId: String,session: String

        const request = new ZiFetchRequest();
        request.setDomain('workspace');
        request.setEvent('createWorkSpace');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Workspace name created successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({ workSpaceName: "", }, () => {
                        this.getWorkSpaceList()
                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }

    Update() {
        let { workSpaceName, workSpaceDescription, mUserId, session } = this.state
        //id:String,name: String, description: String,mUserId: String, session: String
        let workSpaceNameValidate = ""
        if (!workSpaceName) {
            workSpaceNameValidate = "Enter Workspace name"
        }
        this.setState({ workSpaceNameValidate, })
        if (!workSpaceName) {
            return false
        }
        let postJson = {
            id: this.state.id,
            name: workSpaceName, description: workSpaceDescription,
            mUserId, session
        };

        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('workspace');
        request.setEvent('updateWorkspace');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Workspace name updated successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({ action: "Add", workSpaceName: "", workSpaceDescription: "" }, () => {
                        this.getWorkSpaceList()
                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });

    }
    async edit(data) {
        this.scrollToTop()
        let { name, description, } = data
        this.setState({ action: "Edit", id: data["id"], workSpaceName: name, workSpaceDescription: description, visibleClass: "bgClrChg", displayShow: false })
        setTimeout(() => {
            this.setState({ visibleClass: "" })
        }, 1000);
    }

    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }
        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getWorkSpaceList()
            })
        }
    }


    scrollToTop() {
        this.myRef.current.scrollTo(0, 0);
    }
    render() {
        let { workSpaceNameValidate, workSpaceListJson, workSpaceName, visibleClass, displayShow, workSpaceDescriptionValidate, statusDescription } = this.state
        import('../admin/style.css');
        let that = this
        var manageIsLogin = localStorage.getItem("managelogedin")

        let compnayLogo = image3
        if (manageIsLogin) {
            compnayLogo = whiteLogo
        }
        let tableData = []
        Object.keys(workSpaceListJson).forEach(function (key, index) {
            let fullData = workSpaceListJson[key]
            let { createdAt, name, description } = fullData
            tableData.push(
                <tr >
                    <td style={{ width: "30%" }} className='tabletxt'>{name}</td>
                    <td style={{ width: "30%" }} className='tabletxt'>
                        {description}
                    </td>
                    <td style={{ width: "20%" }} className='tabletxt' >{dateConverter(createdAt)}</td>
                    <td style={{ width: "10%" }} className='fofamily' ><div className={" editBtnstyle"} onClick={() => that.edit(fullData)}>Edit</div></td>
                </tr>
            )
        });

        return (
            <div className="body-content left-rigt-padding cat-style" ref={this.myRef}>
                <main className="app-main">
                    <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>

                        <div className="container">
                            <div className={(visibleClass ? visibleClass : "") + " card box-shadow"} style={{ padding: 0 }}>
                                <div className="card-header border-btm-style cursor-style" onClick={() => {
                                    this.setState({ displayShow: !displayShow })
                                }}>
                                    <div className='row'>
                                        <div className='col-10 col-sm-11 col-md-11'>
                                            <h5 className='header-style' >{this.state.action} Workspace</h5>
                                        </div>
                                        <div className='col-2 col-sm-1 col-md-1'>
                                            <span style={{ float: "right", color: "#fff", }}>
                                                {displayShow ? <i class="fa fa-plus"></i> : <i class="fa fa-minus"></i>}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body" style={displayShow ? { display: "none" } : {}}>
                                    <div className={"row"}>
                                        <div className={"col-md-2"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Workspace</label></div>
                                        <div className={"col-md-7"}>
                                            <input type="text" placeholder={"Enter Workspace "} className={"form-control inputRadius"} name={"label"} value={workSpaceName} onChange={e => {
                                                this.setState({ workSpaceName: e.target.value })
                                            }} />
                                            <span className='error-txt'>{workSpaceNameValidate} </span>
                                        </div>
                                    </div>
                                    <br />
                                    <div className={"row"}>
                                        <div className={"col-md-2"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Description</label></div>
                                        <div className={"col-md-7 "}>
                                            <textarea placeholder={"Enter Description"} className={"form-control inputRadius"} name={"label"} value={statusDescription} onChange={e => {
                                                this.setState({ workSpaceDescription: e.target.value })
                                            }}
                                            />
                                            <span className='error-txt'>{workSpaceDescriptionValidate} </span>
                                        </div>
                                        <div className={"col-md-2"}>
                                            {
                                                this.state.action == "Add" ? <input type="button" className="btn mb-2  ml-auto addbuttonsty" value="Add" onClick={() => this.submit()} /> : <input type="button" className="btn  mb-2 ml-auto addbuttonsty" value="Update" onClick={() => this.Update()} />
                                            }
                                            {this.state.action == "Edit" && <input type="button" className="btn mb-1 ml-auto btn-cancel-sty" value="Cancel" onClick={() => {
                                                this.setState({ action: "Add", workSpaceName: "", workSpaceNameValidate: "" })
                                            }} />}

                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </div>

                        <div className="container mt-5">
                            <div className="card box-shadow" style={{ padding: 0 }}>
                                <div className="card-header border-btm-style" style={{ borderBottom: "none" }}>
                                    <h5 className='header-style' > Workspace List</h5>
                                </div>
                                <div className="card-body table-responsive" style={{ padding: 2 }}>
                                    <table className="table table-style light-border-clr" style={{ width: "100%", textAlign: "center" }} border={1} cellPadding={7}>
                                        <tr className='upperCase'>
                                            <th scope="col fw">Name</th>
                                            <th scope="col fw">Description</th>
                                            <th scope="col fw">Published</th>
                                            <th scope="col fw">Edit</th>
                                        </tr>
                                        <tbody>
                                            {tableData}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </div>
        )
    }
}

export default CreteWorkSpace;