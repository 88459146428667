import React, { useEffect, useState } from "react";
import { getUuidv4, readSessionData } from "../config/MyFunctions";
import { ZiFetchRequest } from "../protobuf/generated/ZiFetchService_grpc_web_pb";
import Modal from "./component/Modal";
import { toast, ToastContainer } from "react-toastify";
import DatatableFromData from "./component/DatatableFromData";

function Community(props) {
    let [data, setData] = useState([]);
    let [communityName, setCommunityName] = useState("");
    let [communityCode, setCommunityCode] = useState("");
    let [editData, setEditData] = useState(false);
    let [formModal, setFormModal] = useState(false);

    let resData = readSessionData();
    let { manage_session, manage_mUserId } = resData

    useEffect(() => {
        getCommunity()
    }, [])

    function getCommunity() {
        let postJson = { reqId: getUuidv4() };
        const request = new ZiFetchRequest();
        request.setDomain("adminUserManage");
        request.setEvent("getCommunityList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.warn("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                setData(Object.values(json.result))
            }
        });
    }

    function save() {
        let postJson = { reqId: getUuidv4(), mUserId: manage_mUserId, session: manage_session, communityCode, communityName };

        let action = "createCommunity";
        if(editData) {
            action = "editCommunity"
        }

        const request = new ZiFetchRequest();
        request.setDomain("adminUserManage");
        request.setEvent(action);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.warn("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response === "Success") {
                    let message = 'Added successfully.'
                    if(editData) {
                        message = "Updated successfully."
                    }
                    toast.success(message, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });

                    setFormModal(false)
                    setCommunityName("")
                    setCommunityCode("")
                    setEditData(false)
                    getCommunity()
                } else {
                    toast.error('Community code already exist.', {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            }
        });
    }


    return <div className="body-content left-rigt-padding cat-style" style={{ height: "auto" }}>
        <ToastContainer />
        <main className="app-main">
            <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>
                <div className="container">
                    <div className="card box-shadow" style={{ padding: 0 }}>
                        <div className="card-header border-btm-style" style={{ borderBottom: "none" }}>
                            <div className="row">
                                <div className='col-10 col-sm-11 col-md-11'>
                                    <h5 className='header-style' >Community</h5>
                                </div>
                                <div className='col-2 col-sm-1 col-md-1' onClick={() => {
                                    setFormModal(true)
                                }}>
                                    <span style={{ float: "right", color: "#fff", cursor: "pointer" }}>
                                        <i className="fa fa-plus"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <DatatableFromData
                                data={[
                                    {
                                        column: "Community Name",
                                        cell: (row, key) => {
                                            return row.communityName
                                        }
                                    },
                                    {
                                        column: "Community Code",
                                        cell: (row, key) => {
                                            return row.communityCode
                                        }
                                    },
                                    {
                                        column: "Edit",
                                        cell: (row, key) => {
                                            return <div className="btn btn-sm btn-success" onClick={() => {
                                                setEditData(true)
                                                setFormModal(true)
                                                setCommunityName(row.communityName)
                                                setCommunityCode(row.communityCode)
                                            }}>Edit</div>
                                        }
                                    },
                                ]}
                                result={data}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </main>
        {
            formModal && <Modal
                visible={formModal}
                closeModal={() => {
                    setEditData(false)
                    setFormModal(false)
                    setCommunityName("")
                    setCommunityCode("")
                }}
                heading={`${editData ? "Edit" : "Add"} Community`}
                body={<React.Fragment>
                    <div className="form-group">
                        <label>Community Name</label>
                        <input className="form-control" value={communityName} placeholder="Enter community name" onChange={(e) => {
                            setCommunityName(e.target.value)
                        }} />
                    </div>
                    <div className="form-group">
                        <label>Community Code</label>
                        <input className="form-control" value={communityCode} placeholder="Enter community code" onChange={(e) => {
                            setCommunityCode(e.target.value)
                        }} disabled={editData}/>
                    </div>
                </React.Fragment>
                }
                footer={<button className="btn theme-bg-manage white-bold" onClick={() => {
                    save()
                }}>{editData ? "Update" : "Save" }</button>}
            />
        }
    </div>;
}

export default Community;