import React from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import { dateConverter } from '../config/MyFunctions';
import { toast, ToastContainer } from "react-toastify";
import { pageNumbers } from '../config/MyFunctions';
import Pagination from "./Pagination"
import Select from './component/Select';
import { checkLogin, resetLocalData, readSessionData, getUuidv4 } from '../config/MyFunctions';
import Modal from "./component/Modal";
import DropDown from './component/DropDown';
import CountryCodeSelect from './component/CountryCodeSelect';
import MyConfig from '../config/MyConfig';

class ManageUserCreate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuActive: "",
            action: "Add",
            processing: false,
            prev: false,
            next: false,
            noPagesLimit: 1,
            pageLimit: 10,
            pagination: [],
            rollOption: [{ label: "ContentAdmin", value: "ContentAdmin" }],
            rollTypeSelect: [],
            rollTypeId: "",
            rollTypeValidate: "",
            subCatagory_id: "",
            userName: "",
            userNameValidate: "",
            userListJson: {},
            id: "",
            userPassword: "",
            userPasswordValidate: "",
            emailId: "",
            emailIdValidate: "",
            roleTypeList_Json: [],
            manage_session: "",
            mUserId: "",
            listJsonObj: {},
            modalShowRole: false,
            roleChange: "",
            roleUserName: "",
            roleChangeId: "",
            displayShow: true,
            mapping: false,
            mapCommunityAdmin: false,
            langaugeOption: [],
            langCodeArray: [],
            roleNameLang: "",
            mappingType: "language",
            communityName: "",
            countryCode: "",
            selectedUserId: "",
            selectedUserDetails: {},
            passwordVisible: false,
            communityOption: [],
            userStatus: "live"
        }
    }
    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        document.body.style.backgroundColor = "#f6f7fb";

        let resData = await readSessionData()
        let { manage_session, manage_mUserId } = resData
        this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
            this.getDatatableList()
            this.getRoleTypeList()
            this.getLanguageList()
            this.getCommunity()
        })

    }

    getCommunity() {
        let postJson = { reqId: getUuidv4() }
        const request = new ZiFetchRequest();
        request.setDomain("adminUserManage");
        request.setEvent("getCommunityList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request') {
                console.warn('error', 'Invalid Request. Please try again later', "");
            } else {
                let json = JSON.parse(response.getMessage());
                let communityOption = [];
                let data = json.result;
                let keys = Object.keys(data);
                for (let i = 0; i < keys.length; i++) {
                    let key = keys[i];
                    communityOption.push({
                        label: data[key].communityName,
                        value: key
                    })
                }
                this.setState({
                    communityOption
                })
            }
        })

    }

    getRoleTypeList() {
        let { mUserId, session } = this.state
        let postJson = { mUserId, session }
        const request = new ZiFetchRequest();
        request.setDomain("adminRoleType");
        request.setEvent("list");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response == "Success") {
                    let roleTypeList_Json = []
                    let resJson = json.result
                    Object.keys(resJson).map((key) => {
                        roleTypeList_Json.push({ value: key, label: resJson[key]["rollType"] })
                    })
                    this.setState({
                        roleTypeList_Json,
                        listJsonObj: resJson
                    });

                }
                else if (json.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }

    getDatatableList() {
        let { noPagesLimit, pageLimit, mUserId, session, userStatus } = this.state
        let postJson = { auth: "Teqbahn@321#", pageLimit: pageLimit, noOfPage: noPagesLimit, totalResult: noPagesLimit, mUserId, session, userStatus }
        const request = new ZiFetchRequest();
        request.setDomain("adminUserManage");
        request.setEvent("list");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response == "Success") {
                    let next = false;
                    let prev = false;
                    let totalResult = json.totalResult
                    let currentResult = noPagesLimit * 10;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noPagesLimit > 1) {
                        prev = true;
                    }

                    let pageCount = Math.ceil(totalResult / 10);
                    let pagination = pageNumbers(pageCount, noPagesLimit, 5);
                    this.setState({
                        prev,
                        next,
                        pagination: pagination,
                        processing: false,
                        userListJson: json.result
                    });
                }
                else if (json.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }




    submit() {

        let { rollTypeId, userName, userPassword, emailId, mUserId, session } = this.state

        let rollTypeValidate = ""
        let userNameValidate = ""
        let userPasswordValidate = ""
        let emailIdValidate = ""
        let passValidate = true
        let mailValidate = true
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (!rollTypeId) {
            rollTypeValidate = "Choose Role "
        }
        if (!userName) {
            userNameValidate = "Enter UserName"
        }

        if (!userPassword) {
            userPasswordValidate = "Enter Password"
            passValidate = false
        }
        else if (userPassword && userPassword.length < 6) {
            passValidate = false
            userPasswordValidate = "Please Enter Password Minimum 6 Letters"
        }

        if (!emailId) {
            mailValidate = false
            emailIdValidate = "Enter Email"
        }
        else if (reg.test(emailId) == false) {
            mailValidate = false
            emailIdValidate = "Please Enter Valid Email"
        }
        this.setState({ rollTypeValidate, userNameValidate, userPasswordValidate, emailIdValidate })
        if (!rollTypeId || !userName || !passValidate || !mailValidate) {
            return false
        }

        let postJson = {
            mUserName: userName,
            password: userPassword,
            rollType: rollTypeId,
            email: emailId,
            mUserId, session
        };

        const request = new ZiFetchRequest();
        request.setDomain('adminUserManage');
        request.setEvent('create');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg.response == "Success") {
                    toast.success("User Created successfully.", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({ userPassword: "", rollTypeSelect: [], userName: "", emailId: "", rollTypeId: "" }, () => {
                        this.getDatatableList()
                    })

                } else if (responseMsg.response === "exist") {
                    toast.error("User aleady exist.", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                } else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }



    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }
        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getDatatableList()
            })
        }
    }
    handleChange(e) {
        this.setState({ rollTypeSelect: e, rollTypeId: e.value }, () => {

        })
    }




    delete(data) {
        let { mUserId, session } = this.state
        let confirmMessage = window.confirm("Are you sure you want to deactivate this admin user?")
        let postJson = {
            mUserName: data["mUserName"],
            adminUserId: data["mUserId"],
            mUserId, session
        };

        if (confirmMessage) {
            console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain('adminUserManage');
            request.setEvent('deactivate');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.log("response", response)
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    // console.log(responseMsg)
                    if (responseMsg.response == "Success") {
                        toast.success("Manage Admin User Deleted.", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        this.getDatatableList()
                    }
                    else if (responseMsg.response == "Invalid Session Request") {
                        toast.warning("Your session got expired!", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        resetLocalData(this.props)
                    }

                }
            });
        }

    }

    deleteAdminUser(data) {
        let { mUserId, session } = this.state
        let confirmMessage = window.confirm("Are you sure you want to delete this admin user?")
        let postJson = {
            mUserName: data["mUserName"],
            adminUserId: data["mUserId"],
            mUserId, session
        };

        if (confirmMessage) {
            const request = new ZiFetchRequest();
            request.setDomain('adminUserManage');
            request.setEvent('deleteAdminUser');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.log("response", response)
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    if (responseMsg.response == "Success") {
                        toast.success("Manage Admin User Deleted.", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        this.getDatatableList()
                    }
                    else if (responseMsg.response == "Invalid Session Request") {
                        toast.warning("Your session got expired!", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        resetLocalData(this.props)
                    }
                }
            });
        }

    }

    activeFunction(data) {

        let { mUserId, session } = this.state
        let confirmMessage = window.confirm("Are you sure you want to Activate this admin user?")
        let postJson = {
            mUserName: data["mUserName"],
            adminUserId: data["mUserId"],
            mUserId, session
        };

        if (confirmMessage) {
            console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain('adminUserManage');
            request.setEvent('activate');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.log("response", response)
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    // console.log(responseMsg)
                    if (responseMsg.response == "Success") {
                        toast.success("Manage Admin User Deleted.", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        this.getDatatableList()
                    }
                    else if (responseMsg.response == "Invalid Session Request") {
                        toast.warning("Your session got expired!", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        resetLocalData(this.props)
                    }

                }
            });
        }

    }

    async roleSubmit() {
        let { mUserId, session, roleChangeId, id } = this.state
        let postJson = { mUserId, session, roleTypeId: roleChangeId, adminUserId: id }
        console.log("postJson", postJson)
        //adminUserId, roleTypeId, mUserId, session
        const request = new ZiFetchRequest();
        request.setDomain('adminUserManage');
        request.setEvent('changeRole');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Role Type Changed Successfully.", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.getDatatableList()
                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }
    mapLanguage(roleName, selectedUserId, userData) {
        console.log("userData", userData)
        this.setState({
            mapping: true,
            roleNameLang: roleName,
            mappingType: "language",
            selectedUserId,
            selectedUserDetails: userData
        }, () => {
            this.langListDataApi()
        })
    }

    getLanguageList() {
        let { grpcClient, } = this.props
        let postJson = { reqId: getUuidv4() };
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("getLanguagesData");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let langaugeOption = []
                if (Object.keys(json.uiMap).length > 0) {
                    Object.keys(json.uiMap).map((key => {
                        let lan = json.uiMap[key]
                        var obj = JSON.parse(lan)
                        langaugeOption.push({ label: obj["name"], value: key })
                        // let obj = { label: json.uiMap[key], value: key }
                        // langaugeOption.push(obj)
                    }))
                }
                this.setState({
                    langaugeOption
                });
            }
        });
    }
    langSubmit() {
        let { selectedUserId, roleNameLang, langCodeArray } = this.state
        let { grpcClient } = this.props
        // mUserId: String,rollType:String,languages:List[String]
        let postJson = { mUserId: selectedUserId, rollType: roleNameLang, languages: langCodeArray };
        console.log("postjson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("lanaguageMapToTranslator");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response == "Success") {
                    this.setState({ mapping: false, langCodeArray: [], roleNameLang: "" })
                    toast.success("Languages Mapped Successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                }
            }
        });
    }

    commnuityAdminSubmit() {
        let { selectedUserId, countryCode, communityName } = this.state;

        if (countryCode === "" || communityName === "") {
            toast.error("Please select country and commnuity!", {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            let postJson = { mUserId: selectedUserId, country: countryCode, community: communityName };
            const request = new ZiFetchRequest();
            request.setDomain("ui");
            request.setEvent("mapToCommunityAdmin");
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === "Invalid Request") {
                    console.log("Invalid Request. Please try again later")
                } else {
                    let json = JSON.parse(response.getMessage());
                    if (json.response == "Success") {
                        this.setState({ mapping: false, countryCode: "", communityName: "", mappingType: "language" })
                        toast.success("Mapped Successfully!", {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                    }
                }
            });
        }
    }
    langListDataApi() {
        let { selectedUserId, roleNameLang, } = this.state
        let { grpcClient } = this.props
        // mUserId: String,rollType:String
        let postJson = { mUserId: selectedUserId, rollType: roleNameLang, };
        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("getMappedLanaguageList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                console.log("json", json)
                let result = json.result
                this.setState({ langCodeArray: result })

            }
        });
    }
    getCommunityAdmin() {
        let { selectedUserId } = this.state;
        let postJson = { mUserId: selectedUserId };
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("getCommunityAdmin");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let { country, community } = json;
                console.log("json", json)
                this.setState({
                    communityName: community,
                    countryCode: country
                })
            }
        });
    }

    resendInvite(mUserId) {
        let postJson = { reqId: getUuidv4(), mUserId };
        const request = new ZiFetchRequest();
        request.setDomain("adminUserManage");
        request.setEvent("resendAdminUserMail");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response == "Success") {
                    toast.success("Resend Successfully!", {
                        position: "top-center",
                        autoClose: 1500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                }
            }
        });
    }

    restore(data) {
        let { mUserId, session } = this.state
        let postJson = {
            mUserName: data["mUserName"],
            adminUserId: data["mUserId"],
            mUserId, session
        };

        const request = new ZiFetchRequest();
        request.setDomain("adminUserManage");
        request.setEvent("restoreDeletedAdminUser");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response == "Success") {
                    toast.success("User moved to live Successfully!", {
                        position: "top-center",
                        autoClose: 1500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.getDatatableList()
                }
            }
        });
    }

    returnMapContent() {
        let { mappingType, langaugeOption, langCodeArray, communityName, countryCode, communityOption } = this.state;
        let content;
        switch (mappingType) {
            case "language":
                content = <div className='container'>
                    <div className='row'>
                        {langaugeOption && langaugeOption.map((checkdata, ind) => {
                            return (<div className='col-sm-4 mb-2'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" checked={langCodeArray.includes(checkdata.value)} id={ind} onChange={() => {
                                        let data = langCodeArray.includes(checkdata.value)
                                        if (!data) {
                                            langCodeArray.push(checkdata.value)
                                        }
                                        else {
                                            langCodeArray = langCodeArray.filter((e) => {
                                                return e !== checkdata.value
                                            })
                                        }
                                        this.setState({ langCodeArray })
                                    }} />
                                    <label className="form-check-label" for={ind}>
                                        {checkdata.label}
                                    </label>
                                </div>
                            </div>)
                        })
                        }
                    </div>
                    <div className='row my-3 mt-5'>
                        <div className='col-sm-5'></div>
                        <div className='col-sm-1'>
                            <button className='btn btn-sm btn-info' onClick={() => this.langSubmit()}>
                                Submit
                            </button>
                            <div className='col-sm-5'></div>
                        </div>
                    </div>
                </div>
                break;
            case "commnuityAdmin":
                content = <React.Fragment>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>Community Name</label>
                                <DropDown
                                    selectedOption={communityOption.filter(option => option.value === communityName)}
                                    onChange={(e) => {
                                        this.setState({
                                            communityName: e.value
                                        })
                                    }}
                                    options={communityOption}
                                    isDisabled={false}
                                    isMulti={false}
                                    placeholder={"Select Community name"}
                                />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>Country</label>
                                <CountryCodeSelect
                                    countryCode={countryCode}
                                    onChange={(obj) => {
                                        this.setState({
                                            countryCode: obj.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='text-center'>
                        <button className='btn btn-sm btn-info' onClick={() => this.commnuityAdminSubmit()}>
                            Submit
                        </button>
                    </div>
                </React.Fragment>
                break;
        }

        return content;
    }

    render() {
        let { selectedUserDetails, pagination, next, prev, noPagesLimit, rollTypeSelect, rollTypeValidate, userName, userNameValidate, userListJson, userPassword, userPasswordValidate, emailIdValidate, emailId, roleTypeList_Json, listJsonObj, modalShowRole, roleChange, roleUserName, displayShow, mapping, mappingType, passwordVisible, userStatus } = this.state
        import('../admin/style.css');
        let that = this
        let tableData = []
        Object.keys(userListJson).forEach(function (key, index) {
            let data = userListJson[key]
            let roleName = data['rollType']
            let role_id = ""
            if (listJsonObj[data['rollType']]) {
                roleName = listJsonObj[data['rollType']]["rollType"]
                role_id = data['rollType']
            }
            tableData.push(
                <tr style={{ width: "100%" }}>
                    <td className='tabletxt' style={{ width: "15%" }}>{data['mUserName']}</td>
                    <td className='tabletxt' style={{ width: "15%" }}>{data['email'] ? data['email'] : "-"}</td>
                    <td className='tabletxt' style={{ width: "15%" }}>{roleName} <span style={{ float: "right", color: "red", cursor: "pointer" }} onClick={() => {
                        that.setState({
                            modalShowRole: true, roleUserName: data['mUserName'], id: data["mUserId"],
                            roleChange: { label: roleName, value: role_id, roleChangeId: role_id }
                        })
                    }}><i className="fas fa-edit"></i> </span>  </td>
                    <td className='tabletxt' style={{ width: "5%", textTransform: "capitalize" }}>{data['status']}</td>
                    <td className='tabletxt' style={{ width: "10%" }}>{dateConverter(data['createdAt'])}</td>
                    <td className='tabletxt' style={{ width: "10%" }}>{data['lastLogin'] ? dateConverter(data['lastLogin']) : "-"}</td>

                    {
                        userStatus === "live" ? <React.Fragment>

                            <td className='tabletxt' style={{ width: "10%" }}>{<div className='editBtnstyle' onClick={() => { that.mapLanguage(roleName, data.mUserId, data) }}>Map</div>}</td>
                            <td className='tabletxt' style={{ width: "10%" }}>
                                {
                                    data['lastLogin'] ? "-" : <div className='editBtnstyle' onClick={() => {
                                        if (window.confirm("Please confirm to resend email?")) {
                                            that.resendInvite(data.mUserId)
                                        }
                                    }}>Resend</div>
                                }
                            </td>
                            <td className='tabletxt' style={{ width: "10%" }}>{data['lastResendAt'] ? dateConverter(data['lastResendAt']) : "-"}</td>
                            <td className='fofamily' style={{ width: "10%" }} >
                                <div className={data['status'] == "active" ? "deleteBtnstyle" : "activeBtnstyle"} onClick={() => {
                                    if (data['status'] == "active") {
                                        that.delete(data)
                                    } else {
                                        that.activeFunction(data)
                                    }
                                }}> {data['status'] == "active" ? "Deactivate" : "Activate"}</div>

                                {
                                    data['status'] !== "active" && <div className={"deleteBtnstyle mt-2"} onClick={() => {
                                        that.deleteAdminUser(data)
                                    }}>
                                        Delete
                                    </div>
                                }
                            </td>
                        </React.Fragment> : <td style={{ width: "10%" }}>
                            <div className='editBtnstyle' onClick={() => {
                                if (window.confirm("Please confirm to restore this user to live?")) {
                                    that.restore(data)
                                }
                            }}>Restore</div>
                        </td>
                    }
                </tr>
            )
        });

        return (
            <div className="body-content left-rigt-padding cat-style">

                <main className="app-main">
                    <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>
                        <div className="container">
                            <div className="card box-shadow" style={{ padding: 0 }}>
                                <div className="card-header border-btm-style cursor-style" onClick={() => {
                                    this.setState({ displayShow: !displayShow })
                                }}>
                                    <div className='row'>
                                        <div className='col-10 col-sm-11 col-md-11'>
                                            <h5 className='header-style' >{this.state.action} Manage Admin User</h5>
                                        </div>
                                        <div className='col-2 col-sm-1 col-md-1'>
                                            <span style={{ float: "right", color: "#fff", }}>
                                                {displayShow ? <i className="fa fa-plus"></i> : <i className="fa fa-minus"></i>}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div className="card-body" style={displayShow ? { display: "none" } : {}}>
                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' htmlFor="">Username</label></div>
                                        <div className={"col-md-6"}>
                                            <input type="text" placeholder={"Enter Username "} className={"form-control inputRadius"} value={userName} onChange={e => {
                                                this.setState({ userName: e.target.value })
                                            }} />
                                            <span className='error-txt'>{userNameValidate} </span>

                                        </div>
                                    </div>
                                    <br />

                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' htmlFor="">Email</label></div>
                                        <div className={"col-md-6"}>
                                            <input type="text" placeholder={"Enter Email"} className={"form-control inputRadius"} value={emailId} onChange={e => {
                                                this.setState({ emailId: e.target.value })
                                            }} />
                                            <span className='error-txt'>{emailIdValidate} </span>

                                        </div>
                                    </div>
                                    <br />

                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' htmlFor="">Password</label></div>
                                        <div className={"col-md-6 input-group"}>
                                            <input type={passwordVisible ? "text" : "password"} placeholder={"Enter Password"} className={"form-control inputRadius "} value={userPassword} onChange={e => {
                                                this.setState({ userPassword: e.target.value })
                                            }} />
                                            <div className="input-group-append" onClick={() => {
                                                this.setState({ passwordVisible: !passwordVisible })
                                            }}>
                                                <span className="input-group-text border-0 cursor-pointer "
                                                    style={{ borderTopRightRadius: 18, borderBottomRightRadius: 18 }}
                                                ><i className={`fa ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`} /></span>
                                            </div>
                                            <span className='error-txt'>{userPasswordValidate} </span>

                                        </div>
                                    </div>
                                    <br />

                                    <div className="row my-2">
                                        <div className="col-md-3">
                                            <label className='fw'>Role </label>
                                        </div>
                                        <br />
                                        <div className="col-md-6">
                                            <Select
                                                options={roleTypeList_Json}
                                                isSearchable={true}
                                                placeholder="Select Role "
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                                value={rollTypeSelect}
                                                isDisabled={this.state.action == "Add" ? false : true}
                                            />
                                            <span className='error-txt'>{rollTypeValidate} </span>
                                        </div>
                                    </div>
                                    <br />

                                    <div className={"row"}>
                                        <div className={"col-sm-4"}></div>
                                        <div className={"col-sm-4"}>
                                            {this.state.action == "Add" ? <input type="button" className="btn btn-primary ml-auto" value="Create" onClick={() => this.submit()} /> : <input type="button" className="btn btn-primary ml-auto" value="Update" onClick={() => this.update()} />}

                                        </div>
                                        <div className={"col-sm-4"}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-2">
                        <div className="card box-shadow" style={{ padding: 0 }}>
                            <div className="card-header border-btm-style" style={{ borderBottom: "none" }}>
                                <h5 className='header-style' >Manage Admin User</h5>
                            </div>
                            <div className="card-body table-responsive" style={{ padding: 2 }}>
                                <div className="row my-2">
                                    <div className="col-sm-12">
                                        <div>
                                            <label>Users from :</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="userStatus" value="live" checked={userStatus === "live"} onChange={(e) => {
                                                this.setState({
                                                    userStatus: "live"
                                                }, () => {
                                                    this.getDatatableList()
                                                })
                                            }} />
                                            <label className="form-check-label">Live</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="userStatus" value="deleted" checked={userStatus === "deleted"} onChange={(e) => {
                                                this.setState({
                                                    userStatus: "deleted"
                                                }, () => {
                                                    this.getDatatableList()
                                                })
                                            }} />
                                            <label className="form-check-label">Deleted</label>
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-style table-sm" style={{ width: "100%", textAlign: "center" }} border={1} cellPadding={7}>
                                    <tr className='upperCase'>
                                        <th scope="col fw">Usename</th>
                                        <th scope="col fw">Email</th>
                                        <th scope="col fw">Role </th>
                                        <th scope="col fw">Status</th>
                                        <th scope="col fw">created At</th>
                                        <th scope="col fw">Last Login</th>
                                        {
                                            userStatus === "live" ? <React.Fragment>
                                                <th scope="col fw">Mapping</th>
                                                <th scope="col fw">Resend</th>
                                                <th scope="col fw">Last Resent At</th>
                                                <th scope="col fw">Deactivate</th>
                                            </React.Fragment> : <th>
                                                Restore
                                            </th>
                                        }

                                    </tr>
                                    <tbody>
                                        {tableData}
                                    </tbody>
                                </table>

                                <Pagination
                                    pagination={pagination}
                                    currentPage={noPagesLimit}
                                    prev={prev}
                                    next={next}
                                    movePage={(type) => {
                                        this.movePage(type)
                                    }} />
                            </div>
                        </div>
                    </div>


                    {
                        modalShowRole && <Modal
                            visible={modalShowRole}
                            closeModal={() => this.setState({ modalShowRole: false, mappingType: "language" })}
                            heading={`Change Role`}
                            body={<React.Fragment>

                                <div className="form-group">
                                    <label>Username</label>
                                    <input type="text" placeholder={"Username"} className={"form-control inputRadius txt-disable-color"} name={"label"} value={roleUserName} onChange={e => {

                                    }}
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Role</label>
                                    <Select
                                        value={roleChange}
                                        onChange={(e) => {
                                            this.setState({
                                                roleChange: e,
                                                roleChangeId: e.value
                                            })
                                        }}
                                        options={roleTypeList_Json}
                                        isDisabled={false}
                                    />
                                </div>
                            </React.Fragment>
                            }
                            footer={<React.Fragment>
                                <button type="button" className="btn theme-bg-manage white-bold" onClick={() => this.roleSubmit()}>Save changes</button>
                            </React.Fragment>}
                        />
                    }
                </main>
                {
                    mapping && <Modal
                        visible={mapping}
                        size={"modal-lg"}
                        closeModal={() => this.setState({ mapping: false, langCodeArray: [] })}
                        heading={"Mapping to "+selectedUserDetails.mUserName}
                        body={<div className='umedme'>
                            <div className="tabs-container">
                                <div className="custom-tabs">
                                    <div className={`tab ${mappingType === "language" ? "active" : ""}`} onClick={() => {
                                        this.setState({
                                            mappingType: "language"
                                        })
                                    }}
                                    >
                                        Language
                                    </div>
                                    <div className={`tab ${mappingType === "commnuityAdmin" ? "active" : ""}`} onClick={() => {
                                        this.setState({
                                            mappingType: "commnuityAdmin"
                                        }, () => {
                                            this.getCommunityAdmin()
                                        })
                                    }}
                                    >
                                        Community Admin
                                    </div>
                                </div>
                                <div className="tabs-body">
                                    {this.returnMapContent()}
                                </div>
                            </div>
                        </div>
                        }
                    />
                }
            </div>
        )
    }
}

export default ManageUserCreate;