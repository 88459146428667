import React, { Component } from 'react';
// import './style.css';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import image1 from '../images/UMEDME_logo_white.png';
import image2 from '../images/UMEDME_new.png';
import image3 from '../images/Teqbahn_logo1.png';
import SideManuBar from './SideMenuBar';


export default class MedicationSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuActive: "",
            medicationSearchText: "",
            searchData: [],
            searchBoxDisable: false,
            medicationOveralldata: ""
        }
    }
    async componentDidMount() {
        var loginincheck = await localStorage.getItem("logedin")
        if (!loginincheck) {
            this.props.history.push("/admin/login")
        }

    }
    onMedicationSearch = async (e) => {
        let medicationSearchText = e.target.value;
        if (medicationSearchText.length > 3) {
            let postJson = { userId: 10, searchText: medicationSearchText, limit: 10 }
            console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain('admin');
            request.setEvent('medicationSearch');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            var data = ""
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                console.log("response-->" + response)
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {

                } else {

                    let responseMsg = JSON.parse(response.getMessage());
                    if (responseMsg && responseMsg.response) {
                        console.log("responseMsg", responseMsg)
                        // data = responseMsg
                        // await this.setState({ medicationSearchText, searchData: data });
                        data = []
                        responseMsg.response.map(key => {
                            console.log("key-->" + key)
                            var dataArr = key.split("|")
                            console.log(dataArr)
                            let medicationObj = {}
                            medicationObj.generic_name = dataArr[0]
                            medicationObj.brand_name = dataArr[1]
                            medicationObj.dosage_form = dataArr[2]
                            medicationObj.ingredientsBuilder = dataArr[3]
                            medicationObj.labeler_name = dataArr[4]
                            medicationObj.listing_expiration_date = dataArr[5]
                            medicationObj.spl_id = dataArr[6]
                            console.log(medicationObj)

                            if (dataArr.length > 4) {
                                var medicine = dataArr[0] + " - " + dataArr[2] + " - " + dataArr[3] + " - " + dataArr[4] + " - " + dataArr[5] + " - " + dataArr[4]

                                console.log(medicine)
                                data.push({ "label": medicine, "value": key, "overalldata": medicationObj })
                            }
                        })

                        // Object.keys(responseMsg.response).forEach(async key => {
                        //     console.log("key-->" + key)
                        //     var dataArr = key.split("|")
                        //     if (dataArr.length > 4) {
                        //         var medicine = dataArr[0] + " - " + dataArr[2] + " - " + dataArr[3] + " - " + dataArr[4]
                        //         data.push({ "label": medicine, "value": key })
                        //     }
                        // })

                        await this.setState({ medicationSearchText, searchData: data });
                        return false
                    }
                    else {
                    }
                }
            });
        }
        this.setState({ medicationSearchText });
    }
    render() {
        let { menuActive, searchData, searchBoxDisable, medicationSearchText, medicationOveralldata } = this.state
        import('./style.css');
        var medicationDeatils = []
        return (
            <>
                <div class="wrapper">
                    <SideManuBar menuActive={menuActive} typeofPage={"medicationsearch"} navigation={this.props.history} />
                    <div id="content">

                        <nav class="navbar navbar-expand-lg navbar-light bg-light">
                            <div class="container-fluid">
                                <button type="button" id="sidebarCollapse" class="btn btn-info" onClick={() => {
                                    if (!menuActive) {
                                        menuActive = "active"
                                    }
                                    else {
                                        menuActive = ""
                                    }
                                    this.setState({ menuActive })
                                }}>
                                    <i class="fas fa-align-left"></i>
                                    <span> {menuActive ? "Expand" : "Hide"} </span>
                                </button>
                                <img className="img-Teqlogo" src={image3} width="250px" height="70" />
                            </div>
                        </nav>

                        {/*body of text */}
                        <div className="body-content">

                            <div className="row">
                                <div className="col-sm-2" />
                                <div className="col-sm-8">
                                    <input type="text" placeholder="search" className="form-control input-sbox" value={medicationSearchText} onChange={async (e) => {
                                        await this.onMedicationSearch(e)
                                    }} />
                                    <span className="clearIcon" aria-hidden="true" onClick={() => {
                                        this.setState({ medicationSearchText: "", searchData: [], searchBoxDisable: false, medicationOveralldata: "" })
                                    }}>&times;</span>

                                    {!searchBoxDisable && searchData.length != 0 ? <div className="div-view"
                                        style={{ height: searchData.length > 4 ? 300 : "auto", position: "absolute", zIndex: 1 }} >
                                        {searchData && searchData.map(ival => {
                                            return (<>
                                                <p className={ival.label == this.state.medicationSearchText ? "text-design bg-select" : " text-design"} id={ival.label} onClick={(e) => {
                                                    console.log("e", e.target.id)
                                                    this.setState({ medicationSearchText: e.target.id, searchBoxDisable: true, medicationOveralldata: ival.overalldata })
                                                }} > {ival.label} </p>
                                            </>)
                                        })}
                                    </div>
                                        : null}
                                </div>
                                <div className="col-sm-2" />
                            </div>
                            <div className="row" style={{ marginTop: "30px" }}>

                                <div className="col-sm-12">
                                    {medicationOveralldata ?
                                        <table class="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S.No</th>
                                                    <th scope="col">Generic Name</th>
                                                    <th scope="col">Brand Name</th>
                                                    <th scope="col">Ingredients Builder</th>
                                                    <th scope="col">Labeler Name</th>
                                                    <th scope="col">Labeler Name</th>
                                                    <th scope="col">Expiration Date</th>
                                                    <th scope="col">Spl id</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{1}</td>
                                                    <td >{medicationOveralldata.generic_name}</td>
                                                    <td >{medicationOveralldata.brand_name}</td>
                                                    <td >{medicationOveralldata.dosage_form}</td>
                                                    <td >{medicationOveralldata.ingredientsBuilder}</td>
                                                    <td >{medicationOveralldata.labeler_name}</td>
                                                    <td >{medicationOveralldata.listing_expiration_date}</td>
                                                    <td >{medicationOveralldata.spl_id}</td>
                                                </tr>
                                            </tbody>
                                        </table> : null}
                                </div>
                            </div>
                        </div>
                        {/* <div className="row ">
                            {
                                JSON.stringify(this.state.searchData)
                            }
                        </div> */}
                    </div>
                </div>

            </>
        )
    }
}
