import React from 'react';
import Dropzone from 'react-dropzone';
import MyConstant from '../config/MyConstant';
import Dragplaceholder from '../images/Dragfile.png';
import { v4 as uuidv4 } from 'uuid';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import { toast, ToastContainer } from "react-toastify";
import Select from '../admin/component/Select';
import MyConfig from '../config/MyConfig';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { PDFViewer } from 'react-view-pdf';
import Modal from '../AppIssues/Modal';
import { readSessionData, checkLogin, resetLocalData } from '../config/MyFunctions';


class AdminEditIssues extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            successMsg: false,
            viewMode: "upload",
            document_Json: [],
            deviceWidth: window.innerWidth,
            loader: false,
            documentFiles: [],
            title: "",
            description: "",
            deviceOs: "",
            deviceOsVersion: "",
            deviceModel: "",
            deviceOtherOsVersion: "",
            deviceOsVersionOption: [...MyConfig.andriodOsVersion, ...MyConfig.iosOsVersion],
            completedStatus: "",
            reopenStatus: false,
            reOpenDescription: "",
            issueFrom: "Web",
            submitLoader: false,
            imagePreview: {},
            modalVisible: false,
            mUserId: "",
            editId: "",
            session: ""
        }
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        let appUserDeviceInfo = localStorage.getItem("appUserDeviceInfo");
        // if (appUserDeviceInfo) {
        //     appUserDeviceInfo = JSON.parse(appUserDeviceInfo);
        //     let deviceOs = appUserDeviceInfo.appPlatform
        //     let deviceOsVersionOption = []
        //     if (deviceOs === "android") {
        //         deviceOsVersionOption = MyConfig.andriodOsVersion
        //     } else if (deviceOs === "ios") {
        //         deviceOsVersionOption = MyConfig.iosOsVersion
        //     }
        //     this.setState({
        //         deviceOs,
        //         deviceOsVersionOption
        //     })
        // }

        let login_status = await checkLogin(this.props)

        let resData = await readSessionData()
        let { manage_session, manage_mUserId } = resData
        this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
            this.getIssueDetails()
        })


    }

    getIssueDetails() {
        let editId = ""
        // let editId = this.props.match.params['editId'];
        let currentPath = this.props.location.pathname
        editId = currentPath.split("/")[currentPath.split("/").length - 1]
        let { session } = this.state
        let userId = this.state.mUserId
        let postJson = { userId, issuesId: editId, mUserId: userId, session: session }
        console.log("**", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('adminIssues');
        request.setEvent('getAdminIssuesReportDetails');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid Request")
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                console.log("**", responseMsg)
                if (responseMsg.msg == "Success") {
                    if (responseMsg && responseMsg.response && responseMsg.response != null) {
                        let parseData = JSON.parse(responseMsg.response)
                        let { title, descrition, metaInfo, fileList, status, statusDescription } = parseData
                        let metaParse = JSON.parse(metaInfo)
                        let { deviceModel, deviceOs, deviceOsVersion, issueFrom } = metaParse
                        // console.log(metaParse)+
                        let documentFiles = []
                        let document_Json = []
                        fileList.map(kval => {
                            let parseImg = JSON.parse(kval)
                            // console.log(parseImg)
                            document_Json.push({ ...parseImg, progress: 100, status: 'active', processType: "issues", })
                            let { docsId, fileType, processType } = parseImg
                            documentFiles.push({ docsId, fileType, processType })
                        })

                        issueFrom = issueFrom === undefined ? "App" : issueFrom;
                        this.setState({ title, description: descrition, deviceModel, deviceOs, deviceOsVersion, documentFiles, document_Json, completedStatus: status, issueFrom, editId })
                        // console.log(parseData);

                    }
                }
                else if (responseMsg.msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }

        });
    }

    documentOnDrop(newFiles) {
        var documentFiles = this.state.documentFiles;
        var document_Json = this.state.document_Json;

        for (var i = 0; i < newFiles.length; i++) {
            let file = newFiles[i];
            let filename = file.name;
            let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
            let uid = uuidv4();

            documentFiles.push(newFiles[i]);
            document_Json.push({
                name: this.state.selectTypeVlaue,
                path: newFiles[i],
                status: 'active',
                progress: '0',
                origFileName: filename,
                fileName: uid + "." + ext,
                docsId: uid,
                processType: "issues",
                fileType: ext
            })
        }
        this.setState({
            documentFiles: documentFiles, document_Json: document_Json
        });
    }
    documentOnRemove(loopIndex) {
        var document_Json = this.state.document_Json;
        var documentFiles = this.state.documentFiles;
        document_Json.splice(loopIndex, 1);
        documentFiles.splice(loopIndex, 1);
        this.setState({
            document_Json: document_Json, documentFiles: documentFiles
        });

    }

    async Update_Documents(alertKey) {
        var that = this;
        this.setState({ viewMode: "progress", loader: true })
        let { title, description, document_Json, deviceOsVersion, deviceOs, deviceModel, deviceOtherOsVersion, issueFrom, session, mUserId } = this.state;

        if (title === "" || (issueFrom === "App" && (deviceModel === "" || deviceOsVersion === "" || (deviceOsVersion === "Others" && deviceOtherOsVersion === "")))) {
            let message = "";
            if (title === "") {
                message = "Please enter title";
            } else if (deviceOsVersion === "") {
                message = "Please select device OS Version";
            } else if (deviceOsVersion === "Others" && deviceOtherOsVersion === "") {
                message = "Please enter device OS Version";
            } else if (deviceModel === "") {
                message = "Please enter device model"
            }
            toast.error(message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        } else {
            this.setState({
                submitLoader: true
            }, async () => {
                let fileList = [];
                for (var i = 0; i < document_Json.length; i++) {
                    await that.sendPostJson(document_Json[i], i);
                    let fileDetails = document_Json[i];
                    fileList.push(JSON.stringify({
                        origFileName: fileDetails.origFileName,
                        fileName: fileDetails.fileName,
                        docsId: fileDetails.docsId,
                        processType: fileDetails.processType,
                        fileType: fileDetails.fileType
                    }));

                }
                deviceOsVersion = deviceOsVersion === "Others" ? deviceOtherOsVersion : deviceOsVersion;

                let userId = this.state.mUserId
                // let editId = this.props.match.params['editId'];
                let postJson = { userId, fileList, title, descrition: description, metaInfo: JSON.stringify({ deviceOsVersion, deviceOs, deviceModel, issueFrom }), issuesId: this.state.editId, session, mUserId }

                if (this.state.reopenStatus) {
                    this.updateStatus()
                }

                const request = new ZiFetchRequest();
                request.setDomain('adminIssues');
                request.setEvent('editAdminIssuesReport');
                request.setMessage(JSON.stringify(postJson));
                request.setDatetime(new Date().toString());
                this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                    if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                        console.warn("Invalid Request")
                    } else {
                        this.setState({
                            successMsg: true,
                            document_Json: [],
                            documentFiles: [],
                            loader: false,
                            title: "",
                            description: "",
                            deviceOsVersion: "",
                            deviceModel: "",
                            deviceOtherOsVersion: ""
                        }, () => {
                            let responseMsg = JSON.parse(response.getMessage());
                            if (responseMsg.msg === "Success") {
                                if (responseMsg.response === "Success") {
                                    toast.success("Feedback Updated Successfully.", {
                                        position: "top-right",
                                        autoClose: 1500,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true
                                    });
                                    setTimeout(() => {
                                        that.setState({ submitLoader: false, }, () => {
                                            window.location.href = MyConstant.keyList.projectUrl + "/admin/admin-feedback"
                                        })
                                    }, 3000)
                                }
                            }
                            else if (responseMsg.msg == "Invalid Session Request") {
                                toast.warning("Your session got expired!", {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                                resetLocalData(this.props)
                            }
                        });
                    }
                });
            })
        }

    }

    async sendPostJson(documentArray, i) {
        var that = this;
        var document_Json = this.state.document_Json;
        document_Json[i].progress = 50;
        that.setState({
            document_Json: document_Json
        })
        if (document_Json.length > 0) {
            await that.uploadDocumentFiles(documentArray, i);
            document_Json[i].progress = 100;
            that.setState({
                document_Json: document_Json
            })
        }
    }

    async uploadDocumentFiles(documentArray, i) {
        // console.log("****", documentArray.path)
        if (documentArray.path) {
            const postFileUpload = new FormData();
            postFileUpload.append('file' + (i + 1), documentArray.path);
            postFileUpload.append('fileName' + (i + 1), documentArray.fileName);
            postFileUpload.append('processType' + (i + 1), documentArray.processType);
            postFileUpload.append('docsId' + (i + 1), documentArray.docsId);

            let userId = localStorage.getItem("appUserLogin");
            let result = await fetch(MyConstant.keyList.apiURL + "uploads/" + documentArray.processType + "/" + documentArray.docsId + "/appissue", {
                method: "POST",
                mode: 'cors',
                redirect: 'follow',
                body: postFileUpload,
            }).then(
                function (response) {
                    return response.json();
                }
            ).then(json => {
                return json.response;
            }).catch(error => console.warn(error));
            return result
        }



    }

    handleChange(e) {
        this.setState({
            deviceOsVersion: e.value
        })
    }

    updateStatus() {

        let { reOpenDescription, completedStatus, session, mUserId } = this.state
        let editId = this.props.match.params['editId'];
        let postJson = {
            issuesId: editId, status: "Reopened", statusDescription: reOpenDescription,
            session, mUserId
            //reOpenDescription: reOpenDescription
        }
        const request = new ZiFetchRequest();
        request.setDomain('adminIssues');
        request.setEvent('updateAdminIssuesReportStatus');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid Request")
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg.response === "Success") {
                    this.setState({
                        reOpenDescription: "",
                        reopenStatus: false,
                    }, () => {

                    })
                }
                else if (responseMsg.response && responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });

    }

    issueFromChange(value) {
        this.setState({
            issueFrom: value
        })
    }

    render() {
        let { submitLoader, document_Json, documentFiles, title, description, deviceOs, deviceOsVersion, deviceOsVersionOption, deviceModel, deviceOtherOsVersion, issueFrom, imagePreview, modalVisible } = this.state
        var that = this

        // console.log("documentFiles", documentFiles)

        let videos = ["mp4", "3gp", "ogg", "mov"]
        let returnModelBody = ""
        if (imagePreview.fileType) {
            if (videos.includes(imagePreview.fileType.toLowerCase())) {
                returnModelBody = <>
                    <video width="400" controls>
                        <source src={imagePreview.filePath} />
                    </video>
                </>
            }
            else if (imagePreview.fileType == "pdf") {
                returnModelBody = <PDFViewer url={imagePreview.filePath} />

            }
            else {
                returnModelBody = <>
                    <img src={imagePreview.filePath} className={"img-thumbnail"} style={{ height: 400 }} />
                </>
            }
        }
        return <main>
            <ToastContainer />
            <div className="row mt-2">
                <div className="col-lg-12">
                    <div className="card cardstyle">
                        <div className="card-header" style={{ backgroundColor: "#ed3833", color: "#fff" }}>
                            Edit Feedback
                            <div className="float-right">
                                <Link className="btn btn-sm btn-primary" to={MyConstant.keyList.projectUrl + "/admin/admin-feedback"}>Back</Link>
                            </div>
                        </div>
                        <div className="card-body cardpad">
                            <div>
                                <div className="row my-2">
                                    <div className="col-sm-6 col-md-3">
                                        <label className='form-control lb1'>Title :</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control"
                                            placeholder="Title"
                                            value={title}
                                            onChange={(e) => this.setState({ title: e.target.value })} />
                                    </div>
                                </div>
                                <div className="row my-2">
                                    <div className="col-sm-6 col-md-3">
                                        <label className='form-control lb1'>Description :</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <textarea
                                            className="form-control"
                                            placeholder="Description"
                                            value={description}
                                            onChange={(e) => this.setState({ description: e.target.value })}></textarea>
                                    </div>
                                </div>
                                <div className="row my-2">
                                    <div className="col-sm-6 col-md-3">
                                        <label className='form-control lb1'>Feedback from :</label>
                                    </div>
                                    <div className="col-sm-6">
                                        {/* <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="issueFrom" value="App" checked={issueFrom === "App"} onChange={(e) => this.issueFromChange(e.currentTarget.value)} />
                                                    <label className="form-check-label">App</label>
                                                </div> */}
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="issueFrom" value="Web" checked={issueFrom === "Web"} onChange={(e) => this.issueFromChange(e.currentTarget.value)} />
                                            <label className="form-check-label">Web</label>
                                        </div>
                                    </div>
                                </div>
                                {
                                    issueFrom === "App" && <React.Fragment>
                                        <div className="row my-2">
                                            <div className="col-sm-6 col-md-3">
                                                <label className='form-control lb1'>Device OS :</label>
                                            </div>
                                            <div className="col-sm-6">
                                                <b style={{ textTransform: "uppercase" }}>{deviceOs}</b>
                                            </div>
                                        </div>
                                        <div className="row my-2">
                                            <div className="col-sm-6 col-md-3">
                                                <label className='form-control lb1'>Device OS Version:</label>
                                            </div>
                                            <div className="col-sm-6">
                                                <Select
                                                    options={deviceOsVersionOption}
                                                    isSearchable={true}
                                                    placeholder="Select"
                                                    onChange={this.handleChange}
                                                    value={deviceOsVersionOption.filter(option => option.value === deviceOsVersion)}
                                                />
                                            </div>
                                        </div>
                                        {
                                            deviceOsVersion === "Others" && <div className="row my-2">
                                                <div className="col-sm-6 col-md-3">
                                                    <label className='form-control lb1'>OS Version :</label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <input type="text" className="form-control"
                                                        placeholder="OS Version"
                                                        value={deviceOtherOsVersion}
                                                        onChange={(e) => this.setState({ deviceOtherOsVersion: e.target.value })} />
                                                </div>
                                            </div>
                                        }

                                        <div className="row my-2">
                                            <div className="col-sm-6 col-md-3">
                                                <label className='form-control lb1'>Device Model :</label>
                                            </div>
                                            <div className="col-sm-6">
                                                <input type="text" className="form-control"
                                                    placeholder="Device Model"
                                                    value={deviceModel}
                                                    onChange={(e) => this.setState({ deviceModel: e.target.value })} />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                <div className="row my-2">
                                    <div className="col-sm-6 col-md-3">
                                        <label className='form-control lb1'>Upload Image/Video/PDF :</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="dropback">
                                            <Dropzone accept=".png, .jpg, .mp4, .3gp, .ogg, .mov, .pdf" multiple={true}
                                                onDrop={acceptedFiles => that.documentOnDrop(acceptedFiles)}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                        <div {...getRootProps({ className: 'dropzone' })}>
                                                            <input {...getInputProps()} />
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <img src={Dragplaceholder} className='dragimgph' />
                                                                </div>
                                                                <div className="col-8">
                                                                    <p className='dragtext'>
                                                                        Try dropping some files here, or click to select files to upload.
                                                                        <br />Support file formats (.png, .jpg, .mp4, .3gp, .ogg, .mov, .pdf)
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                            {/* {
                                                        documentFiles.map((f, loopIndex) =>
                                                            <div className="my-2" key={loopIndex}>
                                                                <div className="row rowstructure">
                                                                    <div className="col-8">  {f.name}
                                                                        <div className="progress" key={loopIndex}>
                                                                            <div
                                                                                style={{ width: document_Json[loopIndex].progress + "%", background: '#673ab7' }} role="progressbar"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div className='roundbg' onClick={() => that.documentOnRemove(loopIndex)}>
                                                                            <p className='closex'>X</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        )
                                                    } */}

                                        </div>
                                    </div>
                                </div>

                                {this.state.completedStatus && this.state.completedStatus == "Completed" &&
                                    <div className="row my-2">
                                        <div className="col-sm-6 col-md-3">
                                            <label className='form-control lb1'>Reopen</label>
                                        </div>
                                        <div className="col-sm-6">
                                            <input className="form-check-input" type="checkbox"
                                                checked={this.state.reopenStatus}
                                                style={{ width: 75, height: 30 }}
                                                onChange={(e) => {
                                                    this.setState({ reopenStatus: e.target.checked })
                                                }} />
                                            <br />
                                            {this.state.reopenStatus && <textarea
                                                className="form-control mt-4"
                                                placeholder="Reopen Description"
                                                value={this.state.reOpenDescription}
                                                onChange={(e) => this.setState({ reOpenDescription: e.target.value })}></textarea>}

                                        </div>
                                    </div>}


                                <div className='row '>
                                    <div className='col-sm-6 col-md-3'></div>
                                    <div className='col-sm-6'>
                                        <div className='row my-2'>
                                            {
                                                documentFiles.map((f, loopIndex) => {

                                                    // console.log("**", f.path, typeof (f))
                                                    let filePath = ""
                                                    let fileName = ""
                                                    let fileType = ""
                                                    if (f.path && typeof (f.path) != undefined) {
                                                        filePath = URL.createObjectURL(f)
                                                        fileName = f.name
                                                        fileType = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
                                                    }
                                                    else {
                                                        let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + f.processType + "&key=" + f.docsId + "." + f.fileType + "&id=issues"
                                                        filePath = buildUrl
                                                        fileType = f.fileType
                                                    }

                                                    // console.log(fileType)
                                                    let returnData = ""
                                                    if (videos.includes(fileType.toLowerCase())) {
                                                        returnData = <i className="fa fa-video" aria-hidden="true"
                                                            style={{ paddingRight: 5, fontSize: 80, cursor: "pointer" }}
                                                            onClick={() => {
                                                                let imagePreview = { filePath, fileType }
                                                                this.setState({ imagePreview, modalVisible: true })
                                                            }}
                                                        ></i>
                                                    }
                                                    else if (fileType == "pdf") {
                                                        returnData = <i className="fa fa-file-pdf" aria-hidden="true"
                                                            style={{ paddingRight: 5, fontSize: 80, cursor: "pointer" }}
                                                            onClick={() => {
                                                                let imagePreview = { filePath, fileType }
                                                                this.setState({ imagePreview, modalVisible: true })
                                                            }}
                                                        ></i>
                                                    }
                                                    else {
                                                        returnData = <img src={filePath} alt="no img" style={{ width: 80, height: 80, cursor: "pointer" }}
                                                            onClick={() => {
                                                                let imagePreview = { filePath, fileType }
                                                                this.setState({ imagePreview, modalVisible: true })
                                                            }}
                                                        />

                                                    }
                                                    return (<>
                                                        <div className='col-sm-4 col-md-3 file-box' key={loopIndex}>
                                                            {/* <img src={"https://picsum.photos/200/300"} className='img-rounded' style={{ width: 80, height: 70 }} /> */}
                                                            {returnData}
                                                            <i className="fa fa-trash delete-upload-file"
                                                                onClick={() => that.documentOnRemove(loopIndex)}

                                                            ></i>
                                                        </div>
                                                    </>)
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>



                                {
                                    modalVisible && <Modal
                                        visible={modalVisible}
                                        size={"modal-xl"}
                                        closeModal={() => this.setState({ modalVisible: false, imagePreview: {} })}
                                        heading={"File View"}
                                        parentClass={"text-center"}
                                        body={<React.Fragment>
                                            {returnModelBody}
                                        </React.Fragment>}
                                    />}

                                <div className='row my-1'>
                                    <div className='col-12 text-center'>
                                        <button type="button"
                                            className="btn btn-success btntop "
                                            onClick={() => that.Update_Documents(true)}
                                            disabled={submitLoader}
                                        >
                                            {submitLoader && <i className="fa fa-spinner fa-spin"></i>}
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>;
    }
}

export default AdminEditIssues;