import { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../images/v3/logo.png';
import MyConstant from '../../../config/MyConstant';
// import updateImage from '../../../images/LightBg2.gif';

export default function Header(props) {
    let [mobileMenu, setMobileMenu] = useState(false);

    let { page } = props;
    return <div className="umedme-version-3-header py-2">
        <div className="nav-link-menu">
            <Link className={`${page === "about" ? "active" : ""}`} to={MyConstant.keyList.projectUrl + "/about"}>
                About Us
            </Link>
            <div className={`nav-dropdown-menu ${page === "media" ? "active" : ""}`}>
                <span>Media</span>
                <div className="nav-dropdown-content">
                    <Link to={MyConstant.keyList.projectUrl + "/videos"}>
                        Videos
                    </Link>
                    <Link to={MyConstant.keyList.projectUrl + "/research"}>
                        Research
                    </Link>
                    <Link to={MyConstant.keyList.projectUrl + "/press"}>
                        Press
                    </Link>
                </div>
            </div>
        </div>
        {!mobileMenu && <div className="nav-link-logo">
            <Link to={MyConstant.keyList.projectUrl + "/"}>
                <img alt="UmedMe" className='header-logo' src={logo} />
            </Link>
        </div>
        }
        {/* {!mobileMenu && <div className="nav-link-update-logo nav-link-menu">
            <a href={"https://teqbahn.com/mid-year-update-2024"}>
                <img alt="UmedMe" className='header-logo' src={updateImage} />
            </a>
        </div>
        } */}
        <div className="nav-link-migration">
            <Link to={MyConstant.keyList.projectUrl + "/migration"}>
                Migration
            </Link>
        </div>
        <div className='teqbahn-link mx-2 ml-5 cursor-pointer'>
            <a href={"https://teqbahn.com/"}>
                Teqbahn
            </a>
        </div>
        {!mobileMenu && <div className='header-mobile-link' onClick={() => {
            setMobileMenu(true)
        }}>
            <i className="fa fa-bars"></i>
        </div>
        }
        {
            mobileMenu && <div class="container view-mobile-container">
                <div class="phone">
                    <div className='d-flex flex-direction-row justify-content-between
'>
                        <div className='py-1'>
                            <img alt="UmedMe" className='header-logo' src={logo} style={{ height: 20 }} />
                        </div>
                        <div onClick={() => {
                            setMobileMenu(false)
                        }}>
                            <i className="fa fa-times"></i>
                        </div>
                    </div>
                    <div class="content my-3">
                        <nav>
                            <div className='d-flex flex-column'>
                                {/* <div className='p-2 d-flex align-items-center justify-content-center'>
                                    <a href={"https://teqbahn.com/mid-year-update-2024"}>
                                        <img alt="UmedMe" className='header-logo' src={updateImage} height={50} />
                                    </a>
                                </div> */}

                                <div className='p-2'>
                                    <Link className={`${page === "about" ? "active" : ""}`} to={MyConstant.keyList.projectUrl + "/about"}>
                                        About Us
                                    </Link>
                                </div>
                                <div className='p-2'>
                                    <div className={`nav-dropdown-menu ${page === "media" ? "active" : ""}`}>
                                        <span>Media</span>
                                        <div className="nav-dropdown-content">
                                            <Link to={MyConstant.keyList.projectUrl + "/videos"}>
                                                Videos
                                            </Link>
                                            <Link to={MyConstant.keyList.projectUrl + "/research"}>
                                                Research
                                            </Link>
                                            <Link to={MyConstant.keyList.projectUrl + "/press"}>
                                                Press
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className='p-2'>
                                    <Link to={MyConstant.keyList.projectUrl + "/migration"}>
                                        Migration
                                    </Link>
                                </div>
                                <div className='p-2'>
                                    <a href={"https://teqbahn.com/"}>
                                        Teqbahn
                                    </a>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        }
    </div>;
}
