import React from 'react';
import migracionLogo from '../../images/v3/migracionLogo.png'
import MyConstant from '../../config/MyConstant'
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'
import Slide1 from '../../images/overview/1.png';
import Slide2 from '../../images/overview/2.png';
import Slide3 from '../../images/overview/3.png';
import Slide4 from '../../images/overview/4.png';
import Slide5 from '../../images/overview/5.png';
import Slide6 from '../../images/overview/6.png';
import Slide7 from '../../images/overview/7.png';
import Slide8 from '../../images/overview/8.png';
import Slide9 from '../../images/overview/9.png';
import Slide10 from '../../images/overview/10.png';
import Slide11 from '../../images/overview/11.png';
import Slide12 from '../../images/overview/12.png';
import Footer from '../../common/Footer3';
import { userTrack } from '../../config/MyFunctions';
// import updateImage from '../../images/LightBg2.gif';

let imageList = [{ imagePath: Slide1 }, { imagePath: Slide2 }, { imagePath: Slide3 }, { imagePath: Slide4 }, { imagePath: Slide5 }, { imagePath: Slide6 }, { imagePath: Slide7 }, { imagePath: Slide8 }, { imagePath: Slide9 }, { imagePath: Slide10 }, { imagePath: Slide11 }, { imagePath: Slide12 }]




class MigrationOverView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            holdImage: true,
            oneTimeSet: false,
            mobileMenu: false
        }
    }

    componentDidMount() {
        userTrack("Migration Overview Page", "Landing");
    }

    setMobileMenu(mobileMenu) {
        this.setState({
            mobileMenu
        })
    }

    changeStatus(type) {
        this.setState({ holdImage: type })
    }
    render() {
        let { holdImage, mobileMenu } = this.state;
        let { slide } = this.props;
        return <>
            <div className="umedme-version-3 migration ">
                <div className="bg">
                    <div className='header' style={{ background: "#aec1c2" }}>
                        <div className='logo-container'>
                            <Link to={MyConstant.keyList.projectUrl + "/migration"}>
                                <img src={migracionLogo} className="logo" />
                            </Link>
                        </div>
                        {/* <div className='update-logo w-100 justify-content-center'>
                            <a href={"https://teqbahn.com/mid-year-update-2024"}>
                                <img alt="UmedMe" className='header-logo' src={updateImage} />
                            </a>
                        </div> */}
                        <div className='menu-link'>
                            <Link to={MyConstant.keyList.projectUrl + "/"}>
                                Home
                            </Link>
                            <Link to={MyConstant.keyList.projectUrl + "/migration-apps"}>
                                Apps
                            </Link>
                            <Link to={MyConstant.keyList.projectUrl + "/migration-media"}>
                                Media
                            </Link>
                            <Link to={MyConstant.keyList.projectUrl + "/migration-overview"}>
                                Overview
                            </Link>
                        </div>
                        <div className='mobile-link' onClick={() => {
                            this.setMobileMenu(true)
                        }}>
                            <i className="fa fa-bars"></i>
                        </div>
                        {
                            mobileMenu && <div className='mobile-menu'>
                                <div className='header d-flex'>
                                    <div className='main-logo'>
                                        <Link to={MyConstant.keyList.projectUrl + "/migration"}>
                                            <img alt="UmedMe" className='logo' src={migracionLogo} />
                                        </Link>
                                    </div>
                                    <div className='menu-close' onClick={() => {
                                        this.setMobileMenu(false)
                                    }}>
                                        <i className='fa fa-times' />
                                    </div>
                                </div>
                                <div className='mobile-menu-link'>
                                    {/* <div className='w-100 d-flex justify-content-center align-items-center mb-5'>
                                        <a href={"https://teqbahn.com/mid-year-update-2024"}>
                                            <img alt="UmedMe" className='header-logo' src={updateImage} />
                                        </a>
                                    </div> */}
                                    <div className='d-flex flex-column justify-content-center align-items-center mt-3'>
                                        <Link to={MyConstant.keyList.projectUrl + "/"}>
                                            Home
                                        </Link>
                                        <Link to={MyConstant.keyList.projectUrl + "/migration-apps"}>
                                            Apps
                                        </Link>
                                        <Link to={MyConstant.keyList.projectUrl + "/migration-media"}>
                                            Media
                                        </Link>
                                        <Link to={MyConstant.keyList.projectUrl + "/migration-overview"}>
                                            Overview
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='bg-setup'></div>
                </div>
            </div>
            <div className={`umedme-over-view-slider ${slide}`} style={{ paddingTop: 70 }}>
                <Carousel autoPlay={holdImage} interval={5000} width={"100%"} infiniteLoop={true} stopOnHover={false} showArrows={true} showStatus={false}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                            <span onClick={onClickHandler}>
                                <a href="javascript:void(0)" onClick={() => {
                                    this.changeStatus(true)
                                }} className='btn btn-sm  prevbtn'>
                                    <i className='fa fa-chevron-left' style={{ fontSize: "26px", color: "#e2915b" }} />
                                </a>
                            </span>
                        )
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                            <span onClick={onClickHandler}>
                                <a href="javascript:void(0)" onClick={() => {
                                    this.changeStatus(true)
                                }} title={label} className='btn btn-sm nextbtn' >
                                    <i className='fa fa-chevron-right' style={{ fontSize: "26px", color: "#e2915b" }} />
                                </a>
                            </span>
                        )
                    }
                    showThumbs={false}
                    animationHandler="fade"

                >
                    {imageList.map((e, i) => {
                        return <div className='' key={i} onClick={() => {
                            this.changeStatus(false)
                        }} >
                            <img src={e.imagePath} className='carosal_img' style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 70) : "auto" }}
                            />
                        </div>
                    })}


                </Carousel>
            </div>
            <Footer />
        </>
    }
}
export default MigrationOverView