import React, { Component } from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import SideManuBar from './SideMenuBar';
import image3 from '../images/Teqbahn_logo1.png';
import MyConfig from '../config/MyConfig';
import { dateConverter } from '../config/MyFunctions';
import { toast, ToastContainer } from "react-toastify";
import { pageNumbers, checkLogin, readSessionData, resetLocalData, getUuidv4 } from '../config/MyFunctions';
import Pagination from "./Pagination"
import Select from './component/Select';
import ManageSideMenu from '../manage/ManageSideMenu';
import whiteLogo from '../images/whiteLogo.png';
import ManageLogout from "../manage/ManageLogout"
import AdminLogout from "./AdminLogout"
import Modal from "./component/Modal";
import { v4 as uuidv4 } from 'uuid';
import MyConstant from '../config/MyConstant';




class AddArticle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuActive: "",
            action: "Add",
            subcategory_Options: [],
            subCategory_Validate: "",
            processing: false,
            prev: false,
            next: false,
            noPagesLimit: 1,
            pageLimit: 10,
            pagination: [],
            Catagory_Json: {},
            catagoryOption: [],
            catagorySelect: [],
            catgoryId: "",
            catagoryNameValidate: "",
            subCatagory_Select: [],
            subCatagory_id: "",
            linkText: "",
            linkTextValidate: "",
            AddArticleJson: {},
            id: "",
            visibleClass: "",
            mUserId: "",
            session: "",
            modalShow: false,
            selectStatusOption: "",
            selectStatusOptionId: "",
            statusDescription: "",
            statusId: "",
            forWhoOptions: [],
            forWhoSelect: "",
            forWhoSelectId: "",
            titleText: "",
            titleTextValidate: "",
            document_Json: [],
            documentFiles: [],
            validateImg: "",
            displayShow: true,
            coverPhoto: "",
            articleUserTypeOptions: [],
            selectArticleTypeId: "",
            selectArticleType: "",
            sourceText: "", sourceTextValidate: "",
            viewDetails: {},
            viewType: "",
            languageOption: [],
            languageSelect: "",
            workSpaceOptions: [],
            workSpaceSelect: ""


        }
        this.myRef = React.createRef()
    }
    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        if (login_status) {
            document.body.style.backgroundColor = "#f6f7fb";

            let resData = await readSessionData()
            let { manage_session, manage_mUserId } = resData
            this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
                // this.getAllCatagory()
                this.getArticle_DataList()
                // this.getforWhoList()
                this.getAllArticleUserTypeListData()
                // this.getArticleListByCategoryWise()
                this.getWorkSpaceList()
                this.getLanguageList()
            })
        }

    }


    getArticleListByCategoryWise() {
        let { mUserId, session, } = this.state
        let catagoryOption = []
        let postJson = { limitPerCategory: 5, userTypeId: "e524d2bec7554f9695b67a51d3272f73" }
        //auth: String, 
        const request = new ZiFetchRequest();
        request.setDomain("article");
        request.setEvent("getArticleListByCategoryWise");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                console.log("-->json stativ", json)

            }
        });
    }

    getArticleListByForWho(catId) {
        let { mUserId, session } = this.state
        let catagoryOption = []
        let postJson = { limitPerForWho: 5, catId: catId }
        console.log("postJson", postJson)
        //auth: String, 
        const request = new ZiFetchRequest();
        request.setDomain("article");
        request.setEvent("getArticleListByForWho");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                console.log("json***", json)

            }
        });
    }





    getAllCatagoryTypeBasedOn() {
        let { mUserId, session, selectArticleTypeId } = this.state
        let catagoryOption = []
        let postJson = { auth: "Teqbahn@321#", mUserId, session, userTypeId: selectArticleTypeId }
        //auth: String, 
        const request = new ZiFetchRequest();
        request.setDomain("article");
        request.setEvent("getArticleCategoryListBasedOnUserType");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let response_Msg = json.response
                if (response_Msg == "Success") {
                    let responseData = json.result
                    if (Object.keys(responseData).length > 0) {
                        Object.keys(responseData).map(key => {
                            let categoryName = responseData[key].categoryName
                            catagoryOption.push({ label: categoryName, value: key })
                        })
                    }
                    this.setState({
                        catagoryOption
                    }, () => {

                    });
                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }
    getAllCatagory() {
        let { mUserId, session } = this.state
        let catagoryOption = []
        let postJson = { auth: "Teqbahn@321#", mUserId, session }
        //auth: String, 
        const request = new ZiFetchRequest();
        request.setDomain("article");
        request.setEvent("getAllArticleCategoryList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                // console.log(json)
                let response_Msg = json.response
                if (response_Msg == "Success") {
                    let responseData = json.result
                    if (Object.keys(responseData).length > 0) {
                        Object.keys(responseData).map(key => {
                            let categoryName = responseData[key].categoryName
                            catagoryOption.push({ label: categoryName, value: key })
                        })
                    }
                    this.setState({
                        catagoryOption
                    });
                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }


    async submit() {

        let { subCatagory_id, catgoryId, linkText, mUserId, session, document_Json, titleText, forWhoSelectId, selectArticleTypeId, sourceText, languageSelect, workSpaceSelect } = this.state
        //catId: String,subCatId:String,articleData: String
        var that = this
        let fileList = [];
        let imgFilePath = "";
        for (var i = 0; i < document_Json.length; i++) {
            await that.sendPostJson(document_Json[i], i);
            let fileDetails = document_Json[i];
            fileList.push(JSON.stringify({
                origFileName: fileDetails.origFileName,
                fileName: fileDetails.fileName,
                docsId: fileDetails.docsId,
                processType: fileDetails.processType,
                fileType: fileDetails.fileType
            }));
            imgFilePath = fileDetails.fileName;
        }



        let subCategory_Validate = ""
        let catagoryNameValidate = ""
        let linkTextValidate = ""
        let titleTextValidate = ""
        let for_whoText_Validate = ""
        let selectArticleTypeValidate = ""
        let sourceTextValidate = ""
        let linkValidate = false
        let languageValidate = ""
        let workSpaceValidate = ""


        if (!workSpaceSelect) {
            workSpaceValidate = "Choose workspace"
        }

        if (!languageSelect) {
            languageValidate = "Choose language"
        }
        if (!subCatagory_id) {
            subCategory_Validate = "Choose subCategory Name"
        }
        if (!catgoryId) {
            catagoryNameValidate = "Choose Category Name"
        }
        if (!linkText) {
            linkTextValidate = "Please Enter Link"
            linkValidate = true
        }
        else if (linkText) {
            let res = await this.validateURL(linkText)
            if (!res) {
                linkTextValidate = "Please Enter Link"
                linkValidate = true
            }
        }
        if (!titleText) {
            titleTextValidate = "Please Enter Title"
        }

        if (!forWhoSelectId) {
            for_whoText_Validate = "Choose For Who Name"
        }

        if (!selectArticleTypeId) {
            selectArticleTypeValidate = "Choose Article User Type"
        }
        if (!sourceText) {
            sourceTextValidate = "Please Enter Source"
        }

        this.setState({
            subCategory_Validate, catagoryNameValidate, linkTextValidate, titleTextValidate, for_whoText_Validate, selectArticleTypeValidate, sourceTextValidate,
            workSpaceValidate, languageValidate
        })
        if (!subCatagory_id || !catgoryId || linkValidate || !titleText || !forWhoSelectId || !selectArticleTypeId || !sourceText || !workSpaceSelect || !languageSelect) {
            return false
        }

        let postJson = {
            catId: catgoryId,
            subCatId: subCatagory_id,
            forWhoId: forWhoSelectId, coverPhoto: imgFilePath,
            link: linkText, title: titleText,
            articleData: "",
            mUserId, session,
            userTypeId: selectArticleTypeId,
            source: sourceText,
            workspaceId: workSpaceSelect,
            langCode: languageSelect

        };

        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('article');
        request.setEvent('addArticleData');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Article Added successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({
                        subCatagory_Select: [], catagorySelect: [], linkText: "", forWhoSelectId: "", forWhoSelect: "", titleText: "", document_Json: [], documentFiles: [], selectArticleTypeId: "",
                        selectArticleType: "", sourceText: "",
                        workSpaceSelect: "", languageSelect: ""
                    }, () => {
                        this.getArticle_DataList()
                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }



    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }
        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getArticle_DataList()
            })
        }
    }
    handleChange(e) {
        this.setState({ catagorySelect: e, catgoryId: e.value }, () => {
            this.getCatagoryMapList(e.value)
            // this.getArticleListByForWho(e.value)
        })
    }

    subCat_handleChange(e) {
        this.setState({ subCatagory_Select: e, subCatagory_id: e.value }, () => {

        })
    }
    getCatagoryMapList(catId) {
        let { mUserId, session } = this.state
        let postJson = { catId: catId, mUserId, session }
        const request = new ZiFetchRequest();
        request.setDomain("article");
        request.setEvent("getAllArticleSubCategoryList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let response_Msg = json.response
                if (response_Msg == "Success") {
                    let subcategory_Options = []
                    let responseData = json.result
                    if (Object.keys(responseData).length != 0) {
                        Object.keys(responseData).map(key => {
                            let subCatDetails = responseData[key]
                            subcategory_Options.push({ label: subCatDetails.subCategoryName, value: key })
                        })
                    }
                    this.setState({
                        subcategory_Options
                    });
                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }

    getArticle_DataList() {
        let { noPagesLimit, pageLimit, mUserId, session } = this.state
        let postJson = { auth: "Teqbahn@321#", pageLimit: pageLimit, noOfPage: noPagesLimit, mUserId, session }
        //auth: String, pageLimit: Int, noOfPage:Int
        const request = new ZiFetchRequest();
        request.setDomain("article");
        request.setEvent("getArticleDataList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                // console.log("json", json)
                let response_Msg = json.response
                if (response_Msg == "Success") {
                    let responceResultData = json.resul
                    let next = false;
                    let prev = false;
                    let totalResult = json.totalResult
                    let currentResult = noPagesLimit * 10;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noPagesLimit > 1) {
                        prev = true;
                    }

                    let pageCount = Math.ceil(totalResult / 10);
                    let pagination = pageNumbers(pageCount, noPagesLimit, 5);


                    this.setState({
                        prev,
                        next,
                        pagination: pagination,
                        processing: false,
                        AddArticleJson: json.result
                    });
                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }

    async edit(data) {
        // console.log(data)
        this.scrollToTop()
        let { userTypeId } = data
        let { articleUserTypeOptions } = this.state
        let catagorySelect = []
        let subCatagory_Select = []
        let linkText = ""
        let coverImg = ""
        let document_Json = []
        let documentFiles = []
        if (data["coverPhoto"]) {
            coverImg = data["coverPhoto"]
            coverImg = coverImg.split(".")
            let imgObj = { docsId: coverImg[0], fileType: coverImg[1], processType: "coverPhoto" }
            document_Json.push({ ...imgObj, progress: 100, status: 'active' })
            documentFiles.push(imgObj)
        }

        catagorySelect.push({ label: data.subCategoryName, value: data.subCatId })
        subCatagory_Select.push({ label: data.categoryName, value: data.catId })
        if (data.articleData) {
            linkText = JSON.parse(data.articleData).link
        }
        let selectArticleTypeId = "", selectArticleType = ""
        let findUserUserTypeData = articleUserTypeOptions.filter((e) => { return e.value === userTypeId })
        if (findUserUserTypeData.length > 0) {
            selectArticleTypeId = userTypeId
            selectArticleType = findUserUserTypeData[0]
        }

        // console.log(selectArticleType)
        // console.log(selectArticleTypeId)
        await this.setState({
            id: data.id, linkText: data["link"], subCatagory_Select, catagorySelect, action: "Edit", visibleClass: "bgClrChg", forWhoSelect: { label: data["forWhoName"], value: data["forWhoId"] }, forWhoSelectId: data["forWhoId"], titleText: data["title"], documentFiles, document_Json, displayShow: false, coverPhoto: data["coverPhoto"], selectArticleTypeId, selectArticleType, sourceText: data["source"],
            languageSelect: data["langCode"], workSpaceSelect: data["workspaceId"]
        })

        setTimeout(() => {
            this.setState({ visibleClass: "" })
        }, 1000);
    }

    async update() {
        let { linkText, mUserId, session, titleText, document_Json, coverPhoto, sourceText,
        } = this.state
        //id: String, articleData: 
        let imgFilePath = coverPhoto
        let fileList = []
        var that = this
        for (var i = 0; i < document_Json.length; i++) {

            if (document_Json[i].path) {
                await that.sendPostJson(document_Json[i], i);
                let fileDetails = document_Json[i];
                fileList.push(JSON.stringify({
                    origFileName: fileDetails.origFileName,
                    fileName: fileDetails.fileName,
                    docsId: fileDetails.docsId,
                    processType: fileDetails.processType,
                    fileType: fileDetails.fileType
                }));
                imgFilePath = fileDetails.fileName;
            }

        }
        let postJson = {
            articleData: "",
            id: this.state.id,
            coverPhoto: imgFilePath,
            link: linkText, title: titleText,
            mUserId, session,
            source: sourceText
        };


        let linkTextValidate = ""
        let titleTextValidate = ""
        let sourceTextValidate = ""
        let linkValidate = false

        if (!linkText) {
            linkTextValidate = "Please Enter Link"
            linkValidate = true
        }
        else if (linkText) {
            let res = await this.validateURL(linkText)
            if (!res) {
                linkTextValidate = "Please Enter Link"
                linkValidate = true
            }
        }
        if (!titleText) {
            titleTextValidate = "Please Enter Title"
        }

        if (!sourceText) {
            sourceTextValidate = "Please Enter Source"
        }


        this.setState({ linkTextValidate, titleTextValidate, sourceTextValidate })
        if (linkValidate || !titleText || !sourceText) {
            return false
        }

        // console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('article');
        request.setEvent('updateArticleData');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                let response_Msg = responseMsg.response
                // console.log(responseMsg)
                if (response_Msg == "Success") {
                    toast.success("Article Data Updated!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({
                        action: "Add", catagorySelect: [], linkText: "", subCatagory_Select: [], forWhoSelectId: "", forWhoSelect: "", titleText: "", document_Json: [], documentFiles: [], selectArticleTypeId: "", selectArticleType: "", sourceText: "",
                    }, () => {
                        this.getArticle_DataList()
                    })

                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });

    }

    delete(data) {
        console.log(data)
        let { mUserId, session, } = this.state
        let confirmMessage = window.confirm("Are You Sure Delete Article Manager?")
        //id: String,catId: String,subCatId:String
        let postJson = {
            catId: data["catId"],
            subCatId: data["subCatId"],
            id: data["id"],
            forWhoId: data["forWhoId"],
            mUserId, session,
            userTypeId: data["userTypeId"],
            langCode:data["langCode"],
            workspaceId:data["workspaceId"]

        };
        // console.log(data)
        if (confirmMessage) {
            console.log("postJson", postJson)

            const request = new ZiFetchRequest();
            request.setDomain('article');
            request.setEvent('deleteArticleData');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.log("response", response)
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    // console.log(responseMsg)
                    if (responseMsg.response == "Success") {
                        toast.success("Article Manager Deleted.", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        this.getArticle_DataList()
                    }
                    else if (responseMsg.response == "Invalid Session Request") {
                        toast.warning("Your session got expired!", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        resetLocalData(this.props)
                    }

                }
            });
        }

    }
    scrollToTop() {
        this.myRef.current.scrollTo(0, 0);
    }
    async updateStatus() {
        let { mUserId, session, statusId, statusDescription, selectStatusOptionId } = this.state
        //id: String, status: String, mUserId: String, session: String
        let postJson = {
            id: statusId,
            mUserId, session,
            status: selectStatusOptionId,
            statusDescription: statusDescription ? statusDescription : ""
        };

        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('article');
        request.setEvent('updateArticleStatus');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Status Updated", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({ statusDescription: "", selectStatusOption: "", selectStatusOptionId: "" }, () => {
                        this.getArticle_DataList()
                    })
                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });

    }

    documentOnDrop(newFiles) {
        // console.log("newFiles", newFiles)

        let validateImg = ""
        var documentFiles = [];
        var document_Json = [];
        var _validFileExtensions = ["jpg", "jpeg", "png"];

        for (var i = 0; i < newFiles.length; i++) {
            let file = newFiles[i];
            let filename = file.name;
            let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);

            if (_validFileExtensions.includes(ext)) {
                let uid = uuidv4();
                documentFiles.push(newFiles[i]);
                document_Json.push({
                    name: filename,
                    path: newFiles[i],
                    status: 'active',
                    progress: '0',
                    origFileName: filename,
                    fileName: uid + "." + ext,
                    docsId: uid,
                    processType: "coverPhoto",
                    fileType: ext
                })
            }
            else {
                validateImg = "Please Upload  Image Files Only"
            }

        }
        this.setState({
            documentFiles: documentFiles, document_Json: document_Json, validateImg
        });
    }

    async sendPostJson(documentArray, i) {
        var that = this;
        var document_Json = this.state.document_Json;
        document_Json[i].progress = 50;
        that.setState({
            document_Json: document_Json
        })
        if (document_Json.length > 0) {
            await that.uploadDocumentFiles(documentArray, i);
            document_Json[i].progress = 100;
            that.setState({
                document_Json: document_Json
            })
        }
    }

    async uploadDocumentFiles(documentArray, i) {
        const postFileUpload = new FormData();
        postFileUpload.append('file' + (i + 1), documentArray.path);
        postFileUpload.append('fileName' + (i + 1), documentArray.fileName);
        postFileUpload.append('processType' + (i + 1), documentArray.processType);
        postFileUpload.append('docsId' + (i + 1), documentArray.docsId);
        let result = await fetch(MyConstant.keyList.apiURL + "fileUpload/" + documentArray.processType + "/" + documentArray.docsId + "/cover", {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        }).then(
            function (response) {
                return response.json();
            }
        ).then(json => {
            return json.response;
        }).catch(error => console.warn(error));

        return result
    }


    getforWhoListUserTypeBasedOn() {
        let { mUserId, session, selectArticleTypeId } = this.state
        let forWhoOptions = []
        let postJson = { mUserId, session, userTypeId: selectArticleTypeId }
        //auth: String, 
        const request = new ZiFetchRequest();
        request.setDomain("article");
        request.setEvent("getArticleForWhoListBasedOnUserType");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                console.log(json)
                let response_Msg = json.response
                if (response_Msg == "Success") {
                    let responseData = json.result
                    if (Object.keys(responseData).length > 0) {
                        Object.keys(responseData).map(key => {
                            let forWhoName = responseData[key].forWhoName
                            forWhoOptions.push({ label: forWhoName, value: key })
                        })
                    }
                    this.setState({
                        forWhoOptions
                    });
                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }


    getAllArticleUserTypeListData() {
        let { mUserId, session, } = this.state
        let articleUserTypeOptions = []
        let postJson = { session }
        //auth: String, 
        const request = new ZiFetchRequest();
        request.setDomain("article");
        request.setEvent("getAllArticleUserTypeList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                // console.log(json)
                let response_Msg = json.response
                if (response_Msg == "Success") {
                    let responseData = json.result
                    if (Object.keys(responseData).length > 0) {
                        Object.keys(responseData).map(key => {
                            let userType = responseData[key].userType
                            articleUserTypeOptions.push({ label: userType, value: key })
                        })
                    }
                    this.setState({
                        articleUserTypeOptions
                    });
                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }


    humanDateFormat(time) {
        return new Date(time).toLocaleString()
    }

    viewContent() {
        let { viewType, viewDetails, workSpaceOptions, languageOption } = this.state;
        // console.log(viewDetails)
        let languageName = ""
        let workSpaceName = ""
        if (viewDetails.workspaceId) {
            workSpaceName = workSpaceOptions.filter((e => { return e.value === viewDetails.workspaceId }))
            if (workSpaceName.length !== 0) {
                workSpaceName = workSpaceName[0].label
            }
            else {
                workSpaceName = ""
            }

        }
        // console.log(viewDetails.langCode)
        if (viewDetails.langCode) {
            languageName = languageOption.filter((e => { return e.value === viewDetails.langCode }))
            if (languageName.length !== 0) {
                languageName = languageName[0].label
            } else {
                languageName = ""
            }

        }

        let buildContent;
        switch (viewType) {
            case "articleusertype":
                buildContent = <div>{viewDetails.userType}</div>
                break;
            case "catagoryname":
                buildContent = <div>{viewDetails.categoryName}</div>
                break;
            case "subcatagoryname":
                buildContent = <div>{viewDetails.subCategoryName}</div>
                break;
            case "whotype":
                buildContent = <div>{viewDetails.forWhoName}</div>
                break;
            case "link":
                buildContent = <div style={{ color: "#47a8e6", cursor: "pointer" }} onClick={() => { window.open(viewDetails.link, "_blank"); }}>{viewDetails.link}</div>
                break;
            case "status":
                buildContent = <div>{viewDetails.status}</div>
                break;
            case "title":
                buildContent = <div>{viewDetails.title}</div>
                break;
            case "source":
                buildContent = <div>{viewDetails.source}</div>
                break;
            case "workspace":
                buildContent = <div>{viewDetails.workspaceId && <>
                    {workSpaceName}
                </>}</div>
                break;
            case "language":
                buildContent = <div>{viewDetails.langCode && <>
                    {languageName}
                </>}</div>
                break;
            case "createby":
                buildContent = <div>{viewDetails.info.email}</div>
                break;
            case "published":
                buildContent = <div>{this.humanDateFormat(viewDetails.createdAt)}</div>
                break;

        }

        return buildContent;
    }

    validateURL(textval) {
        var urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
        return urlregex.test(textval);
    }


    getWorkSpaceList() {
        let postJson = { auth: MyConfig.authKey }
        //auth: String, pageLimit: Int, noOfPage:Int       
        const request = new ZiFetchRequest();
        request.setDomain("workspace");
        request.setEvent("getWorkspaceList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.result && Object.keys(json.result).length != 0) {
                    let workSpaceOptions = [];
                    Object.keys(json.result).map((e) => {
                        let { name } = json.result[e]
                        workSpaceOptions.push({ label: name, value: e })
                    })
                    this.setState({
                        workSpaceOptions,
                    });
                }
                else if (json.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }

    getLanguageList() {
        let { grpcClient } = this.props
        let postJson = { reqId: getUuidv4(), };
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("getLanguagesData");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let languageOption = []
                if (json && json.uiMap && Object.keys(json.uiMap).length !== 0) {
                    Object.keys(json.uiMap).map((key) => {
                        let lan = json.uiMap[key]
                        var obj = JSON.parse(lan)
                        languageOption.push({ label: obj["name"], value: key })
                        // let lan = json.uiMap[e]
                        // languageOption.push({ label: lan, value: e })
                    })
                }
                this.setState({
                    languageOption
                });

            }
        });
    }



    render() {
        let { menuActive, subCategory_Validate, subcategory_Options, pagination, next, prev, noPagesLimit, catagoryOption, catagorySelect, catagoryNameValidate, subCatagory_Select, linkText, linkTextValidate, AddArticleJson, visibleClass, modalShow, selectStatusOption, statusDescription, forWhoSelect, forWhoOptions, titleText, documentFiles, titleTextValidate, for_whoText_Validate, displayShow, articleUserTypeOptions, selectArticleType, sourceText, sourceTextValidate, viewDetails, viewType, viewModal,
            workSpaceOptions, workSpaceSelect, languageOption, languageSelect, workSpaceValidate, languageValidate
        } = this.state
        import('../admin/style.css');
        let that = this

        var manageIsLogin = localStorage.getItem("managelogedin")
        var loginincheck = localStorage.getItem("logedin")
        var loginType = localStorage.getItem("login_RoleType")
        let tableData = []
        Object.keys(AddArticleJson).forEach(function (key, index) {
            let data = AddArticleJson[key]
            let info = data.info
            // console.log(data)
            let linkData = ""
            if (data.articleData) {
                linkData = JSON.parse(data.articleData)
                linkData = linkData.link
            }

            let CatName = data["categoryName"]

            let text = ""
            if (data["status"]) {
                text = data["status"].charAt(0).toUpperCase() + data["status"].slice(1);
            }
            linkData = data["link"]

            tableData.push(
                <tr style={{ width: "100%" }}>
                    <td className='tabletxt' >{data["userType"]}</td>
                    <td className='tabletxt' >{CatName}</td>
                    <td className='tabletxt' >{data['subCategoryName']}</td>
                    <td className='tabletxt' style={{ color: "#47a8e6", cursor: "pointer" }} onClick={() => { window.open(linkData, "_blank"); }}>
                        <span>{linkData.substring(0, 30)} {linkData.length > 30 && <span>...<a className="read-more-comp" ></a></span>}</span>
                    </td>
                    {/* <td className='tabletxt' >{text}</td> */}
                    {<td className='tabletxt'  >
                        <div className={"editBtnstyle"} style={{ backgroundColor: "#007bff", backgroundImage: "none" }} onClick={() => {
                            that.setState({ modalShow: true, statusId: data['id'], selectStatusOptionId: data["status"], selectStatusOption: { label: text, value: data["status"] }, statusDescription: data["statusDescription"] })
                        }}>{"Update Status"}</div>
                    </td>}
                    <td className='tabletxt' >{
                        info != null ? <p>{info.mUserName} - {info.email}</p> : null
                    }</td>
                    <td className='tabletxt' >{dateConverter(data['createdAt'])}</td>
                    <td className='tabletxt'>
                        <button className="btn btn-primary" onClick={() => {
                            that.setState({ viewDetails: data, viewModal: true, viewType: "articleusertype" })
                        }}>View</button>
                    </td>
                    <td className='fofamily' style={{ width: "10%" }} ><div className={"editBtnstyle"} onClick={() => that.edit(data)}>Edit</div></td>
                    <td className='fofamily' style={{ width: "10%" }} ><div className={"deleteBtnstyle"} onClick={() => that.delete(data)}>Delete</div></td>
                </tr>
            )
        });

        let compnayLogo = image3
        if (manageIsLogin) {
            compnayLogo = whiteLogo
        }


        return (
            <div className="body-content left-rigt-padding cat-style" ref={this.myRef}>
                <main className="app-main">
                    <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>
                        <div className="container">
                            <div className={(visibleClass ? visibleClass : "") + " card box-shadow"} style={{ padding: 0 }}>
                                <div className="card-header border-btm-style cursor-style" onClick={() => {
                                    this.setState({ displayShow: !displayShow })
                                }}>
                                    <div className='row'>
                                        <div className='col-10 col-sm-11 col-md-11'>
                                            <h5 className='header-style' >{this.state.action} Article</h5>
                                        </div>
                                        <div className='col-2 col-sm-1 col-md-1'>
                                            <span style={{ float: "right", color: "#fff", }}>
                                                {displayShow ? <i class="fa fa-plus"></i> : <i class="fa fa-minus"></i>}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body" style={displayShow ? { display: "none" } : {}}>
                                    <div className="row my-2">
                                        <div className="col-md-3">
                                            <label className='fw'>Article User Type</label>
                                        </div>
                                        <br />
                                        <div className="col-md-6">
                                            <Select
                                                options={articleUserTypeOptions}
                                                isSearchable={true}
                                                placeholder="Article User Type"
                                                onChange={(e) => {
                                                    this.setState({
                                                        selectArticleTypeId: e.value, selectArticleType: e,
                                                        subCatagory_Select: "", subCatagory_id: "",
                                                        catagorySelect: "", catgoryId: "",
                                                        forWhoSelect: "", forWhoSelectId: ""
                                                    }, () => {
                                                        this.getAllCatagoryTypeBasedOn()
                                                    })
                                                }}
                                                value={selectArticleType}
                                                isDisabled={this.state.action == "Add" ? false : true}
                                            />
                                            <span className='error-txt'>{catagoryNameValidate} </span>
                                        </div>
                                    </div>
                                    <br />

                                    <div className="row my-2">
                                        <div className="col-md-3">
                                            <label className='fw'>Category Name</label>
                                        </div>
                                        <br />
                                        <div className="col-md-6">
                                            <Select
                                                options={catagoryOption}
                                                isSearchable={true}
                                                placeholder="Select Category Name"
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                                value={catagorySelect}
                                                isDisabled={this.state.action == "Add" ? false : true}
                                            />
                                            <span className='error-txt'>{catagoryNameValidate} </span>
                                        </div>
                                    </div>
                                    <br />
                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' htmlFor="">Sub Category Name</label></div>
                                        <div className={"col-md-6"}>
                                            <Select
                                                options={subcategory_Options}
                                                isSearchable={true}
                                                placeholder="Select Sub Category Name"
                                                onChange={(e) => {
                                                    this.subCat_handleChange(e)
                                                    this.getforWhoListUserTypeBasedOn()
                                                }}
                                                value={subCatagory_Select}
                                                isDisabled={this.state.action == "Add" ? false : true}
                                            />

                                            <span className='error-txt'>{subCategory_Validate} </span>
                                        </div>
                                    </div>
                                    <br />
                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' htmlFor="">For Who ?</label></div>
                                        <div className={"col-md-6"}>
                                            <Select
                                                options={forWhoOptions}
                                                isSearchable={true}
                                                placeholder="Select For Who?"
                                                onChange={(e) => {
                                                    this.setState({ forWhoSelect: e, forWhoSelectId: e.value })
                                                }}
                                                value={forWhoSelect}
                                                isDisabled={this.state.action == "Add" ? false : true}
                                            />

                                            <span className='error-txt'>{for_whoText_Validate} </span>
                                        </div>
                                    </div>
                                    <br />
                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' htmlFor="">Link</label></div>
                                        <div className={"col-md-6"}>
                                            <input type="text" placeholder={"Enter Link "} className={"form-control inputRadius"} value={linkText} onChange={e => {
                                                this.setState({ linkText: e.target.value })
                                            }} />
                                            <span className='error-txt'>{linkTextValidate} </span>

                                        </div>
                                    </div>
                                    <br />
                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' htmlFor="">Title</label></div>
                                        <div className={"col-md-6"}>
                                            <input type="text" placeholder={"Enter Title "} className={"form-control inputRadius"} value={titleText} onChange={e => {
                                                this.setState({ titleText: e.target.value })
                                            }} />
                                            <span className='error-txt'>{titleTextValidate} </span>
                                        </div>
                                    </div>
                                    <br />

                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' htmlFor="">Source</label></div>
                                        <div className={"col-md-6"}>
                                            <input type="text" placeholder={"Enter Source "} className={"form-control inputRadius"} value={sourceText} onChange={e => {
                                                this.setState({ sourceText: e.target.value })
                                            }} />
                                            <span className='error-txt'>{sourceTextValidate} </span>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row my-2">
                                        <div className="col-md-3">
                                            <label className='fw'>Workspace</label>
                                        </div>
                                        <br />
                                        <div className="col-md-6">
                                            <Select
                                                options={workSpaceOptions}
                                                isSearchable={true}
                                                placeholder="Select WorkSpace"
                                                onChange={(e) => {
                                                    this.setState({ workSpaceSelect: e.value })
                                                }}
                                                value={workSpaceOptions.filter((e => { return e.value === workSpaceSelect }))}
                                                isDisabled={this.state.action == "Add" ? false : true}
                                            />
                                            <span className='error-txt'>{workSpaceValidate} </span>
                                        </div>
                                    </div>
                                    <br />

                                    <div className="row my-2">
                                        <div className="col-md-3">
                                            <label className='fw'>Choose language</label>
                                        </div>
                                        <br />
                                        <div className="col-md-6">
                                            <Select
                                                options={languageOption}
                                                isSearchable={true}
                                                placeholder="Select language"
                                                onChange={(e) => {
                                                    this.setState({ languageSelect: e.value })
                                                }}
                                                value={languageOption.filter((e => { return e.value === languageSelect }))}
                                                isDisabled={this.state.action == "Add" ? false : true}
                                            />
                                            <span className='error-txt'>{languageValidate} </span>
                                        </div>

                                        <div className={"col-md-2"}>
                                            {
                                                this.state.action == "Add" ? <input type="button" className="btn mb-2  ml-auto addbuttonsty" value="Add" onClick={() => this.submit()} /> : <input type="button" className="btn  mb-2 ml-auto addbuttonsty" value="Update" onClick={() => this.update()} />
                                            }
                                            {this.state.action == "Edit" && <input type="button" className="btn mb-1 ml-auto btn-cancel-sty" value="Cancel" onClick={() => {
                                                this.setState({
                                                    action: "Add", catagorySelect: [], linkText: "", subCatagory_Select: [], forWhoSelectId: "", forWhoSelect: "", titleText: "", document_Json: [], documentFiles: [], selectArticleTypeId: "", selectArticleType: "", sourceText: "",
                                                    languageSelect: "", workSpaceSelect: ""
                                                })
                                            }} />}

                                        </div>
                                    </div>
                                    <br />

                                    {/* <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' htmlFor="">Cover Page(optional)</label></div>
                                        <div className={"col-md-4"} style={{}}>
                                            <div className="image-upload-div inputRadius">
                                                <span style={{ padding: 5 }}>Upload</span>
                                                <input type="file" placeholder={"Upload"} className={"form-control inputRadius"} onChange={(event) => {
                                                    // console.log(event)
                                                    var files = event.target.files;
                                                    // console.log(files)
                                                    this.documentOnDrop(files)

                                                }} />

                                            </div>
                                            <br />
                                            <span className='error-txt'>{this.state.validateImg} </span>
                                        </div>


                                    </div>
                                    <br /> */}


                                    <div className='row '>
                                        <div className='col-sm-6 col-md-3'></div>
                                        <div className='col-sm-6'>
                                            <div className='row my-2'>
                                                {
                                                    this.state.document_Json.map((f, loopIndex) => {
                                                        // console.log("**", f, typeof (f))
                                                        let filePath = ""
                                                        let fileName = ""
                                                        let fileType = ""
                                                        if (f.path && typeof (f.path) != undefined) {
                                                            filePath = URL.createObjectURL(f.path)
                                                            fileName = f.path.name
                                                            fileType = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
                                                        }
                                                        else {
                                                            let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + f.processType + "&key=" + f.docsId + "." + f.fileType + "&id=cover"
                                                            filePath = buildUrl
                                                            fileType = f.fileType
                                                        }
                                                        // console.log("filePath", filePath)

                                                        let returnData = <img src={filePath} alt="no img" style={{ width: 80, height: 80, cursor: "pointer" }}
                                                            onClick={() => {
                                                                let imagePreview = { filePath, fileType }
                                                                this.setState({ imagePreview, modalVisible: true })
                                                            }}
                                                        />
                                                        return (<>
                                                            <div className='col-sm-4 col-md-3'>
                                                                {returnData}
                                                                {/* <i className="fa fa-trash delete-upload-file" aria-hidden="true"
                                                                onClick={() => that.documentOnRemove(loopIndex)}

                                                            ></i> */}
                                                            </div>
                                                        </>)
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    {/* <div className={"row"}>
                                        <div className={"col-sm-4"}></div>
                                        <div className={"col-sm-4"}>
                                            {this.state.action == "Add" ? <input type="button" className="btn btn-primary ml-auto" value="Create" onClick={() => this.submit()} /> : <input type="button" className="btn btn-primary ml-auto" value="Update" onClick={() => this.update()} />}

                                            {this.state.action == "Edit" && <input type="button" className="btn btn-danger ml-3" value="Cancel" onClick={() => {
                                                this.setState({ action: "Add", subCatagory_Select: [], catagorySelect: [], linkText: "", subCategory_Validate: "", catagoryNameValidate: "", linkTextValidate: "" })
                                            }} />}

                                        </div>
                                        <div className={"col-sm-4"}></div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-5">
                        <div className="card box-shadow" style={{ padding: 0 }}>
                            <div className="card-header border-btm-style" style={{ borderBottom: "none" }}>
                                <h5 className='header-style' >Article List</h5>
                            </div>
                            <div className='row col-md-12'>
                                <div className="card-body table-responsive" style={{ padding: 2 }}>
                                    <table className="table table-style table-sm light-border-clr" style={{ width: "100%", textAlign: "center" }} border={1} cellPadding={7}>
                                        <tr className='upperCase'>
                                            <th scope="col fw">Article User Type</th>
                                            <th scope="col fw">Category Name</th>
                                            <th scope="col fw">Sub Category Name</th>
                                            <th scope="col fw">Link</th>
                                            {/* <th scope="col fw">Status</th> */}
                                            {<th scope="col fw">pending/approval </th>}
                                            <th scope="col fw">Created By <br /> UserName - Email</th>
                                            <th scope="col fw">Published</th>
                                            <th scope="col fw">View</th>
                                            <th scope="col fw">Edit</th>
                                            <th scope="col fw">Delete</th>
                                        </tr>
                                        <tbody>
                                            {tableData}
                                        </tbody>
                                    </table>

                                    <Pagination
                                        pagination={pagination}
                                        currentPage={noPagesLimit}
                                        prev={prev}
                                        next={next}
                                        movePage={(type) => {
                                            this.movePage(type)
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>


                    {
                        viewModal && <Modal
                            visible={viewModal}
                            size={"modal-xl"}
                            closeModal={() => this.setState({ viewModal: false })}
                            heading={"View"}
                            body={<React.Fragment>
                                <div className="row">
                                    <div className="col-8">
                                        {
                                            this.viewContent()
                                        }
                                    </div>
                                    <div className="col-4">
                                        <div className="tabs-vertical">
                                            <div className={`tab ${viewType === "articleusertype" ? "active" : ""}`} onClick={() => this.setState({ viewType: "articleusertype" })}>User Type</div>
                                            <div className={`tab ${viewType === "catagoryname" ? "active" : ""}`} onClick={() => this.setState({ viewType: "catagoryname" })}>Category Name</div>
                                            <div className={`tab ${viewType === "subcatagoryname" ? "active" : ""}`} onClick={() => this.setState({ viewType: "subcatagoryname" })}>Sub Category Name</div>
                                            <div className={`tab ${viewType === "whotype" ? "active" : ""}`} onClick={() => this.setState({ viewType: "whotype" })}>Who</div>
                                            <div className={`tab ${viewType === "link" ? "active" : ""}`} onClick={() => this.setState({ viewType: "link" })}>Link</div>
                                            <div className={`tab ${viewType === "status" ? "active" : ""}`} onClick={() => this.setState({ viewType: "status" })}>Status</div>
                                            <div className={`tab ${viewType === "title" ? "active" : ""}`} onClick={() => this.setState({ viewType: "title" })}>Title</div>
                                            <div className={`tab ${viewType === "source" ? "active" : ""}`} onClick={() => this.setState({ viewType: "source" })}>Source</div>
                                            <div className={`tab ${viewType === "workspace" ? "active" : ""}`} onClick={() => this.setState({ viewType: "workspace" })}>Workspace</div>
                                            <div className={`tab ${viewType === "language" ? "active" : ""}`} onClick={() => this.setState({ viewType: "language" })}>Lanuguage</div>
                                            <div className={`tab ${viewType === "createby" ? "active" : ""}`} onClick={() => this.setState({ viewType: "createby" })}>created By</div>
                                            <div className={`tab ${viewType === "published" ? "active" : ""}`} onClick={() => this.setState({ viewType: "published" })}>Published</div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                            }
                        />
                    }


                    {
                        modalShow && <Modal
                            visible={modalShow}
                            closeModal={() => this.setState({ modalShow: false, statusDescription: "", selectStatusOption: "", selectStatusOptionId: "" })}
                            heading={`Update Status`}
                            body={<React.Fragment>

                                <div className="form-group">
                                    <label>Status</label>
                                    <Select
                                        value={selectStatusOption}
                                        onChange={(e) => {
                                            this.setState({
                                                selectStatusOption: e,
                                                selectStatusOptionId: e.value
                                            })
                                        }}
                                        options={MyConfig.contentOptions}
                                    />
                                </div>


                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea placeholder={"Description"} className={"form-control inputRadius"} name={"label"} value={statusDescription} onChange={e => {
                                        this.setState({ statusDescription: e.target.value })
                                    }}
                                    />
                                </div>
                            </React.Fragment>
                            }
                            footer={<React.Fragment>
                                <button type="button" className="btn theme-bg-manage white-bold" onClick={() => {
                                    that.updateStatus()
                                }}>Save changes</button>
                            </React.Fragment>}
                        />
                    }

                </main>
            </div>
        )
    }
}

export default AddArticle;