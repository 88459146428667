import React from "react";
import Pagination from "../Pagination";
import { pageNumbers } from "../../config/MyFunctions";


export default class DatatableFromData extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: [],
            pageLimit: 10,
            noOfPage: 1,
            pagination: [],
            prev: false,
            next: false,
            pageText: null
        }
    }

    componentDidMount() {
        this.getList()
    }

    componentDidUpdate(prevProps) {
        if (this.props.result !== prevProps.result) {
            this.getList()
        }
    }

    getList() {
        let { result, data } = this.props;
        let { pageLimit, noOfPage } = this.state;
        let value = [];
        result.map((keyData, index) => {
            value[index] = [];
            data.map((column, columnIndex) => {
                let input = "";
                if (typeof column.selector !== "undefined") {
                    input = keyData[column.selector];
                } else if (typeof column.cell !== "undefined") {
                    input = column.cell(keyData, index)
                }
                value[index][columnIndex] = input;
            })
        })

        let next = false;
        let prev = false;

        let totalResult = Object.keys(result).length
        let currentResult = noOfPage * pageLimit;
        if (currentResult < totalResult) {
            next = true;
        }
        if (noOfPage > 1) {
            prev = true;
        }
        let pageCount = Math.ceil(totalResult / pageLimit);
        let pagination = pageNumbers(pageCount, noOfPage, 5);
        this.setState({
            value,
            pagination,
            next,
            prev
        })
    }

    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noOfPage + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noOfPage - 1;
            }
        }

        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noOfPage: pageNo,
                processing: true
            })
        }
    }
    render() {
        let { data } = this.props;
        let { value, pagination, next, prev, noOfPage, pageLimit } = this.state;

        let endIndex = noOfPage * pageLimit;
        let startIndex = endIndex - pageLimit;
        return <div>
            <div className="table-responsive ">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            {
                                data.map((row, index) => {
                                    return <th key={index} data-tip={row.toolTip}>{row.column}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            value.length > 0 ? value.map((row, index) => {
                                return (index >= startIndex && index < endIndex) && <tr key={index}>
                                    {
                                        data.map((column, columnIndex) => {
                                            return <td key={columnIndex}>{value[index][columnIndex]}</td>
                                        })
                                    }
                                </tr>
                            }) : <tr>
                                <td className="text-center" colSpan={data.length} >{"No Records found."}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <Pagination
                pagination={pagination}
                currentPage={noOfPage}
                prev={prev}
                next={next}
                movePage={(type) => {
                    this.movePage(type)
                }} />
        </div>;
    }
}
