import React, { Component } from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import Logo from '../images/umedmeweblogo.png';
import Teqbahn_Logo from '../images/Teqbahn_logo1.png';

export default class AdminLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserName: "",
            Password: "",
            errors: {
                UserName: "",
                Password: "",
                deletedUser: ""
            }
        }
    }

    getLogin() {

        let { UserName, Password, errors } = this.state
        let postJson = { mUserName: UserName, password: Password }
        //loginId: String, password: String
        if (!UserName) {
            errors.UserName = "Please enter the Username"
        } else {
            errors.UserName = ""
        }

        if (!Password) {
            errors.Password = "Please enter the Password"

        } else {
            errors.Password = ""
        }

        this.setState({ errors })

        if (Password == "" || UserName == "") {
            return false
        }

        const request = new ZiFetchRequest();
        request.setDomain('adminUserReq');
        request.setEvent('login');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
            }
            else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg && responseMsg.response == "Success") {
                    localStorage.removeItem("managelogedin");
                    localStorage.removeItem("logedin");
                    localStorage.removeItem("manage_session");
                    localStorage.removeItem("manage_mUserId");
                    localStorage.removeItem("login_RoleType");
                    localStorage.setItem("login_RoleType", responseMsg.rollType)
                    localStorage.setItem("manage_session", responseMsg.session)
                    localStorage.setItem("manage_mUserId", responseMsg.mUserId)
                    localStorage.setItem("manageLoggedUser", responseMsg.mUserName)
                    localStorage.setItem("managelogedin", "manage")
                    if (responseMsg.redirect) {
                        this.props.history.push(responseMsg.redirect)
                    } else {
                        errors.deletedUser = "Unable to Redirect, Please contact to Admin !"
                        this.setState({ errors })
                    }

                }
                else if (responseMsg && responseMsg.response == "Deleted") {
                    errors.deletedUser = "Your Account is Deleted, Please contact to Admin !"
                    this.setState({ errors })
                }
                else {
                    errors.UserName = "Incorrect Username"
                    errors.Password = "Incorrect Password"
                    this.setState({ errors })
                }
            }
        });

    }
    render() {
        let { UserName, Password, errors } = this.state
        import('./style.css');


        return (

            <React.Fragment>
                <main className="login-body d-flex align-items-center min-vh-100 py-3 py-md-0">
                    <div className="container">
                        <div className="card login-card">
                            <div className="row no-gutters">
                                <div className="col-md-6 m-auto px-5">
                                    <img src={Logo} className="img-fluid" />
                                </div>
                                <div className="col-md-6" style={{ borderLeft: "1px solid #ccc" }}>
                                    <div className="card-body">
                                        <div className="my-2">
                                            <div style={{ width: 300, margin: "auto" }}>
                                                <img src={Teqbahn_Logo} className="img-fluid" />
                                            </div>
                                        </div>
                                        {errors.deletedUser && <div className="custom-invalid-feedback" style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>{errors.deletedUser}</div>
                                        }
                                        <p className="login-card-description">Admin Login</p>
                                        <form action="">
                                            <div className="form-group">
                                                <label className="sr-only">Username</label>
                                                <input type="email" name="email" id="email"
                                                    className={"form-control"}
                                                    placeholder="Username" value={UserName} onChange={(e) => {
                                                        this.setState({ UserName: e.target.value })
                                                    }} />
                                                <div className="custom-invalid-feedback">{errors.UserName}</div>
                                            </div>
                                            <div className="form-group mb-4">
                                                <label className="sr-only">Password</label>
                                                <input type="password" name="password" id="password"
                                                    className={"form-control"}
                                                    placeholder="***********" value={Password} onChange={(e) => {
                                                        this.setState({ Password: e.target.value })
                                                    }} />
                                                <div className="custom-invalid-feedback">{errors.Password}</div>
                                            </div>
                                            <button type="button" className="btn btn-block login-btn mb-4" onClick={() => this.getLogin()}>Login</button>
                                        </form>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}