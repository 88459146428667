import React, { Component } from "react";
import countries from "../../json/countries.json"
import DropDown from "./DropDown";

class CountryCodeSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryJson: countries.countries,
            selectedCountry: "",
            BoxHeight: 45,
            backgroundColor: "#fff",
            fontColor: "gray",
            borderColor: "#fff",

        };
    }


    componentDidMount() {
        let { countryCode, BoxHeight, backgroundColor, fontColor, borderColor } = this.props
        let propsCountryCode = this.state.selectedCountry;
        let propsBoxHeight = this.state.BoxHeight;
        let propsbackgroundColor = this.state.backgroundColor;
        let propsfontColor = this.state.fontColor;
        let propsborderColor = this.state.borderColor;

        if (countryCode) {
            propsCountryCode = countryCode;
        }
        if (BoxHeight) {
            propsBoxHeight = BoxHeight;
        }
        if (backgroundColor) {
            propsbackgroundColor = backgroundColor;
        }
        if (fontColor) {
            propsfontColor = fontColor;
        }
        if (borderColor) {
            propsborderColor = borderColor;
        }
        this.setState({ selectedCountry: propsCountryCode, BoxHeight: propsBoxHeight, backgroundColor: propsbackgroundColor, fontColor: propsfontColor, borderColor: propsborderColor })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.countryCode !== this.props.countryCode) {
            this.setState({ selectedCountry: this.props.countryCode })
        }
    }

    render() {
        let { countryJson, selectedCountry, } = this.state
        let { isDisabled } = this.props
        return <DropDown
            selectedOption={countryJson.filter(option => option.value === selectedCountry)}
            onChange={(e) => {
                this.setState({ selectedCountry: e ? e.value : "" })
                this.props.onChange(e ? e : "")
            }}
            options={countryJson}
            isMulti={false}
            placeholder={"Country"}
            background={"#fff"}
            getOptionLabel={e => e.label}
            isClearable={this.props.isClearable ? this.props.isClearable : false}
            isDisabled={typeof (isDisabled) !== "undefined" ? isDisabled : false}
        />
    }
}
export default CountryCodeSelect;