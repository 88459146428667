import React, { useState } from "react";
import Modal from "./Modal";
import { ZiFetchRequest } from "../../protobuf/generated/ZiFetchService_grpc_web_pb";
import { timeConverter } from "../../config/MyFunctions";
import countries from "../../json/countries.json"

const { default: Loader } = require("./Loader");

const countryJson = countries.countries
function UserCommunityHistory(props) {
    let [viewModal, setViewModal] = useState(false);
    let [loader, setLoader] = useState(false);
    let [logs, setLogs] = useState([]);

    function getHistory() {
        let postJson = { userId: props.userId }
        const request = new ZiFetchRequest();
        request.setDomain('userManage');
        request.setEvent('getUserCommunityAndCountryChangeLogs');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid request")
            } else {
                let json = JSON.parse(response.getMessage());
                setLogs(json.response)
                setLoader(false)
            }
        });
    }
    return <React.Fragment>
        <button className="btn btn-sm btn-primary" onClick={() => {
            setViewModal(true)
            setLoader(true)
            getHistory()
        }}>View</button>
        {
            viewModal && <Modal
                visible={viewModal}
                closeModal={() => {
                    setViewModal(false)
                }}
                heading={"View Community - History"}
                body={<div style={{minHeight: "50vh"}}>
                    {
                        loader ? <Loader /> : <div>
                            {
                                logs.length > 0 ? <ul className="timeline">
                                    {
                                        logs.map((log, index) => {
                                            let country = countryJson.filter(option => option.value === log.newCountry)
                                            let community = props.communityOption.filter(option => option.value === log.newCommunity)

                                            console.log("newCommunity", country, community)
                                            return <li key={index}>
                                                <span>Community : {community.length > 0 ? community[0].label : log.newCommunity} </span> <br />
                                                <span>Country : {country.length > 0 ? country[0].label : log.newCountry}</span>
                                                <span className="float-right">{timeConverter(log.createdAt)}</span>
                                            </li>
                                        })
                                    }
                                </ul> : "No Logs found."
                            }
                        </div>
                    }
                </div>
                } />
        }
    </React.Fragment>
}

export default UserCommunityHistory