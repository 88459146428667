import React from 'react';
import Umedme_Migartion from '../../video/poster/Umedme_Migartion.png'
import { userTrack } from '../../config/MyFunctions';
import Footer from '../../common/Footer3';
import MyConstant from '../../config/MyConstant';
import { Link } from 'react-router-dom';
import migracionLogo from '../../images/v3/migracionLogo.png';
// import updateImage from '../../images/LightBg2.gif';


export default class VideosPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mobileMenu: false
        }
    }

    componentDidMount() {
        userTrack("Migration Videos Page", "Landing");
    }

    setMobileMenu(mobileMenu) {
        this.setState({
            mobileMenu
        })
    }

    render() {
        let { mobileMenu } = this.state;
        return <React.Fragment>
            <div className="umedme-version-3 migration ">
                <div className="bg">
                    <div className='header' style={{ background: "#aec1c2" }}>
                        <div className='logo-container'>
                            <Link to={MyConstant.keyList.projectUrl + "/migration"}>
                                <img src={migracionLogo} className="logo" />
                            </Link>
                        </div>
                        {/* <div className='update-logo w-100 justify-content-center'>
                            <a href={"https://teqbahn.com/mid-year-update-2024"}>
                                <img alt="UmedMe" className='header-logo' src={updateImage} />
                            </a>
                        </div> */}
                        <div className='menu-link'>
                            <Link to={MyConstant.keyList.projectUrl + "/"}>
                                Home
                            </Link>
                            <Link to={MyConstant.keyList.projectUrl + "/migration-apps"}>
                                Apps
                            </Link>
                            <Link to={MyConstant.keyList.projectUrl + "/migration-media"}>
                                Media
                            </Link>
                            <Link to={MyConstant.keyList.projectUrl + "/migration-overview"}>
                                Overview
                            </Link>
                        </div>
                        <div className='mobile-link' onClick={() => {
                            this.setMobileMenu(true)
                        }}>
                            <i className="fa fa-bars"></i>
                        </div>
                        {
                            mobileMenu && <div className='mobile-menu'>
                                <div className='header d-flex'>
                                    <div className='main-logo'>
                                        <Link to={MyConstant.keyList.projectUrl + "/migration"}>
                                            <img alt="UmedMe" className='logo' src={migracionLogo} />
                                        </Link>
                                    </div>
                                    <div className='menu-close' onClick={() => {
                                        this.setMobileMenu(false)
                                    }}>
                                        <i className='fa fa-times' />
                                    </div>
                                </div>
                                <div className='mobile-menu-link'>
                                    {/* <div className='w-100 d-flex justify-content-center align-items-center mb-5'>
                                        <a href={"https://teqbahn.com/mid-year-update-2024"}>
                                            <img alt="UmedMe" className='header-logo' src={updateImage} />
                                        </a>
                                    </div> */}
                                    <div className='d-flex flex-column justify-content-center align-items-center mt-3'>
                                        <Link to={MyConstant.keyList.projectUrl + "/"}>
                                            Home
                                        </Link>
                                        <Link to={MyConstant.keyList.projectUrl + "/migration-apps"}>
                                            Apps
                                        </Link>
                                        <Link to={MyConstant.keyList.projectUrl + "/migration-media"}>
                                            Media
                                        </Link>
                                        <Link to={MyConstant.keyList.projectUrl + "/migration-overview"}>
                                            Overview
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='bg-setup'></div>
                </div>
                <div style={{ paddingTop: 100 }}>
                    <div style={{ height: '80vh' }}>
                        <div className="video-player">
                            <video
                                style={{ objectFit: "contain" }}
                                controls="controls"
                                preload="auto"
                                autobuffer="true"
                            >
                                <source src={"https://umedme-vid.s3.amazonaws.com/release/umedme-migration.mp4"} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    }
}
