import React, { Component } from 'react';
import SideManuBar from '../admin/SideMenuBar';
import image3 from '../images/Teqbahn_logo1.png';
import { Link } from "react-router-dom";
import MyConstant from '../config/MyConstant';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import { loginStatusCheck, checkLogin } from '../config/MyFunctions';
import AdminLogout from "../admin/AdminLogout"
import ManageSideMenu from '../manage/ManageSideMenu';
import ManageLogout from "../manage/ManageLogout"





class RulesList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            docsId: "",
            userId: "",
            responseData: []
        }
    }
    backPage() {
        this.props.history.push(MyConstant.keyList.projectUrl + "/admin/workspace")
    }
    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        var docsId = this.props.match.params['docsId'];
        this.setState({ docsId: docsId })
        this.getRoleList()

    }

    async getRoleList() {
        let { responseData } = this.state
        //sessionId:String,id: String, userId: String,docsId:String,ruleId:String,reqId:String
        let docsId = this.props.match.params['docsId']
        let postJson = { sessionId: "1234", id: "", userId: "admin", docsId: docsId, reqId: "", ruleId: "" }
        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('admin');
        request.setEvent('getRules');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                responseData = JSON.parse(responseMsg.response)
                console.log("responseData", responseData)
                this.setState({ responseData })

            }
        });

    }

    render() {
        let { menuActive, responseData } = this.state
        import('../admin/style.css');
        var that = this
        var docsList = [];
        var jsonData = responseData
        jsonData.map(function (key) {
            var rulesjson = key;
            var rule_id = rulesjson['id'];
            docsList.push(<tr>
                <td>{rulesjson.rule_name} </td>
                <td><Link to={MyConstant.keyList.projectUrl + "/admin/uploadbatchmultiplefile/" + that.state.docsId + "/" + rule_id}><a className="blue btn-link btn-flat m-b-10 m-l-5">Batch Multiple File Upload</a></Link></td>
                <td><Link to={MyConstant.keyList.projectUrl + "/admin/viewuploadmultipletestfile/" + that.state.docsId + "/" + rule_id}><a className="blue btn-link btn-flat m-b-10 m-l-5">View</a></Link></td>
                <td><Link to={MyConstant.keyList.projectUrl + "/admin/viewmultiplebatchsheet/" + that.state.docsId + "/" + rule_id}><a className="blue btn-link btn-flat m-b-10 m-l-5">View</a></Link></td>

            </tr>)
        })


        var manageIsLogin = localStorage.getItem("managelogedin")
        let returnMenu = <SideManuBar menuActive={menuActive} typeofPage={"workspace"} navigation={this.props.history} />
        let returnLogout = <AdminLogout navigation={this.props.history} />
        if (manageIsLogin == "manage") {
            returnMenu = <ManageSideMenu menuActive={menuActive} typeofPage={"workspace"} navigation={this.props.history} grpcClient={this.props.grpcClient} />
            returnLogout = <ManageLogout navigation={this.props.history} />
        }


        return (
            <>
                <div className={`wrapper ${manageIsLogin ? "manageBgColor" : ""}`}>
                    {returnMenu}
                    <div id="content">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <div className="container-fluid">

                                <button type="button" id="sidebarCollapse" className="btn btn-info" onClick={() => {

                                    if (!menuActive) {
                                        menuActive = "active"
                                    }
                                    else {
                                        menuActive = ""
                                    }

                                    this.setState({ menuActive })
                                }}>
                                    <i className="fas fa-align-left"></i>
                                    <span> {menuActive ? "Expand" : "Hide"} </span>
                                </button>
                                {returnLogout}
                            </div>
                        </nav>
                        <div className="content-wrap">
                            <div className="main">
                                <div className="container-fluid shad">
                                    <section id="main-content">
                                        <div className="card">
                                            <div className="col-sm-1">
                                                <button className="btn btn-primary btn-bg-styl" onClick={(e) => that.backPage()}>BACK</button>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="bootstrap-data-table-panel">
                                                        <div className="table-responsive">
                                                            {
                                                                docsList.length > 0 ?
                                                                    <table class="table table-striped  table-bordered tbl">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Spreadsheet Name</th>
                                                                                {/*<th>Batch File Upload</th>*/}
                                                                                <th>Batch Multiple File Upload</th>
                                                                                {/*<th>Processed Data</th>*/}
                                                                                <th>View Upload Test File</th>
                                                                                <th>View</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {docsList}
                                                                            {/* <tr>
                                                                        <td>Untitled spreadsheet</td>
                                                                        <td class="td-style"><Link to={MyConstant.keyList.projectUrl + "/admin/uploadbatchmultiplefile/" + that.state.docsId + "/" + rule_id}>Batch Multiple File Upload</Link></td>
                                                                        <td class="td-style"><Link to={MyConstant.keyList.projectUrl + "/admin/viewuploadmultipletestfile/" + that.state.docsId + "/" + rule_id}>View</Link></td>
                                                                        <td class="td-style"><Link to={MyConstant.keyList.projectUrl + "/admin/viewmultiplebatchsheet/" + that.state.docsId + "/" + rule_id}>View</Link></td>
                                                                    </tr> */}
                                                                        </tbody>
                                                                    </table>
                                                                    : <div className="text-center"> No Rules Available</div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default RulesList;
