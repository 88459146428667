import React, { Component } from 'react';
import SideManuBar from '../admin/SideMenuBar';
import image3 from '../images/Teqbahn_logo1.png';
import MyConstant from '../config/MyConstant';
import ReactExport from 'react-data-export';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import { checkNullAndReturnString, isJson, timeConverter } from '../config/MyFunctions';
import Table from "./viewExcel/Table";
import { loginStatusCheck, checkLogin } from '../config/MyFunctions';
import AdminLogout from "../admin/AdminLogout"
import ManageSideMenu from '../manage/ManageSideMenu';
import ManageLogout from "../manage/ManageLogout"




const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;




class ViewMultipleBatchSheet extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ruleId: "",
            docsId: "",
            userId: "admin",
            responseData: {},
            excelSheet: [],
            headerRow: [],
            valuesRow: [],
            jsonData: {},
            updateColorJson: {},
            action: {}

        }
    }
    backPage() {
        this.props.history.push(MyConstant.keyList.projectUrl + "/admin/rules-list/" + this.state.docsId)
    }


    async componentDidMount() {

        let login_status = await checkLogin(this.props)
        let { userId } = this.state
        var that = this;
        var ruleId = this.props.match.params['ruleId'];
        var docsId = this.props.match.params['docsId'];
        this.setState({ ruleId: ruleId, docsId: docsId })
        that.getTestDocumentList(userId, ruleId, docsId);
        that.getRulesList(userId, docsId);

    }

    async getTestDocumentList(userId, ruleId, docsId) {
        //userId:String,rulesId: String, docsId: String
        var that = this;
        var ExcelColumnSheetData = [];
        var sessionId = "1234";
        var postJson = { userId: userId, rulesId: ruleId, docsId: docsId }
        const request = new ZiFetchRequest();
        request.setDomain('admin');
        request.setEvent('getTestDocumentList');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                console.log("responseData", responseMsg)
                var data = responseMsg.response
                if (data != null) {
                    var responseData = data;
                    console.log(responseData)
                    Object.keys(responseData).forEach((key, index) => {
                        if (checkNullAndReturnString(responseData[key])) {
                            if (checkNullAndReturnString(responseData[key].rulesprocessed_json) && isJson(responseData[key].rulesprocessed_json)) {
                                var ruleProcessJson = JSON.parse(responseData[key].rulesprocessed_json)
                                var maxColumIndex = [];
                                Object.keys(ruleProcessJson).forEach((pageIndex) => {
                                    var rowIndexJson = ruleProcessJson[pageIndex]
                                    Object.keys(rowIndexJson).forEach((rowIndex) => {
                                        var columnIndexJson = rowIndexJson[rowIndex];
                                        Object.keys(columnIndexJson).forEach((columnIndex) => {
                                            maxColumIndex.push(columnIndex)
                                            if (columnIndexJson[columnIndex].celltype == "Key") {
                                                ExcelColumnSheetData.push({ title: columnIndexJson[columnIndex].value })
                                                that.updateJsonData(columnIndexJson[columnIndex], 1, columnIndex);
                                            }
                                            if (columnIndexJson[columnIndex].celltype == "Value") {
                                                that.updateJsonData(columnIndexJson[columnIndex], index + 2, columnIndex);
                                            }
                                        });
                                    });
                                })
                                var max = maxColumIndex.reduce(function (a, b) {
                                    return Math.max(a, b);
                                });
                                that.updateJsonData({ value: "Batch Name" }, 1, max + 1);
                                that.updateJsonData({ value: "Date" }, 1, max + 2);

                                that.updateJsonData({ value: responseData[key].document_parser_name }, index + 2, max + 1);
                                that.updateJsonData({ value: timeConverter(responseData[key].completed_at) }, index + 2, max + 2);
                            }
                        }

                        console.log(Object.keys(responseData).length + " -> " + index)

                        if (Object.keys(responseData).length == index + 1) {
                            setTimeout(function () {
                                that.totalCalculation();
                            }, 1000)
                        }
                    })
                }

                setTimeout(function () {
                    var jsonData = that.state.jsonData;
                    var ExcelSheetData = [];
                    var ExcelColumnSheetData = [];
                    Object.keys(jsonData).forEach(key => {

                        if (key != 0)
                            var ExcelSheetColumnData = [];
                        var indexJson = jsonData[key]
                        Object.keys(indexJson).forEach(key1 => {
                            if (key == 0) {
                                ExcelColumnSheetData.push({ title: indexJson[key1].value })
                            }
                            else {

                                var celldata = indexJson[key1].value + "";
                                celldata = celldata.replace(/^\s+|\s+$/gm, '')

                                ExcelSheetColumnData.push({ value: celldata })
                            }
                        });
                        if (key != 0) {
                            ExcelSheetData.push(ExcelSheetColumnData)
                        }
                    })
                    var excelSheet = [
                        {
                            columns: ExcelColumnSheetData,
                            data: ExcelSheetData
                        }
                    ];
                    that.setState({ excelSheet: excelSheet })
                }, 2000)

            }
        });
        return false


        // var data = await doGetConnect("/getTestDocumentList?sessionId=" + sessionId + "&userId=" + userId + "&ruleId=" + ruleId);

    }


    async getRulesList(userId, ruleId) {
        //sessionId:String,id: String, userId: String,docsId:String,ruleId:String,reqId:String
        var that = this;
        var sessionId = "1234";
        let postJson = { sessionId: sessionId, id: "", userId: userId, docsId: ruleId, ruleId: "", reqId: "" }
        // console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('admin');
        request.setEvent('getRules');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                let data = JSON.parse(responseMsg.response)
                var reulesJson = {}
                Object.keys(data).forEach(key => {
                    var columnJson = data[key].column_cal_json
                    if (columnJson) {
                        columnJson = JSON.parse(columnJson);
                        var keyIndex = Object.keys(columnJson);
                        that.setState({ action: columnJson })
                    }
                })

            }
        });



        // var data = await doGetConnect("/getRules?sessionId=" + sessionId + "&userId=" + userId + "&docsId=" + ruleId);
        // if (data != null) {
        //     var reulesJson = {}
        //     Object.keys(data).forEach(key => {
        //         var columnJson = data[key].column_cal_json
        //         if (columnJson) {
        //             columnJson = JSON.parse(columnJson);
        //             var keyIndex = Object.keys(columnJson);
        //             that.setState({ action: columnJson })
        //         }
        //     })
        // }
    }


    totalCalculation() {
        var action = this.state.action;
        var jsonData = this.state.jsonData;
        var keyIndex = parseInt(Object.keys(this.state.jsonData).length) + 1;
        Object.keys(action).forEach(key => {
            var total = 0;
            Object.keys(jsonData).forEach(key1 => {
                if (key1 != 0) {
                    if (!isNaN(jsonData[key1][key].value)) {
                        total = parseFloat(jsonData[key1][key].value) + parseFloat(total)
                    }
                }
            })
            var keyIndexMinus = parseInt(key) - 1;
            var totalDataPrint = { value: "total" }
            var totalData = { value: total.toString() }
            this.updateJsonData(totalDataPrint, keyIndex, keyIndexMinus);
            this.updateJsonData(totalData, keyIndex, key);
        })
    }

    updateJsonData(dataJSON, row, col) {
        var jsonData = this.state.jsonData;
        var addIndex = parseInt(Object.keys(jsonData).length) + 1;
        if (jsonData[row] == undefined)
            jsonData[row] = {};
        jsonData[row][col] = dataJSON;
        this.setState({
            jsonData: jsonData
        });
    }
    render() {
        let { menuActive, } = this.state
        import('../admin/style.css');
        var that = this;
        var row = 15;
        var col = 15;

        var manageIsLogin = localStorage.getItem("managelogedin")
        let returnMenu = <SideManuBar menuActive={menuActive} typeofPage={"workspace"} navigation={this.props.history} />
        let returnLogout = <AdminLogout navigation={this.props.history} />
        if (manageIsLogin == "manage") {
            returnMenu = <ManageSideMenu menuActive={menuActive} typeofPage={"workspace"} navigation={this.props.history} grpcClient={this.props.grpcClient} />
            returnLogout = <ManageLogout navigation={this.props.history} />
        }

        return (
            <>
                <div className={`wrapper ${manageIsLogin ? "manageBgColor" : ""}`}>
                    {returnMenu}
                    <div id="content">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <div className="container-fluid">
                                <button type="button" id="sidebarCollapse" className="btn btn-info" onClick={() => {
                                    if (!menuActive) {
                                        menuActive = "active"
                                    }
                                    else {
                                        menuActive = ""
                                    }

                                    this.setState({ menuActive })
                                }}>
                                    <i className="fas fa-align-left"></i>
                                    <span> {menuActive ? "Expand" : "Hide"} </span>
                                </button>
                                {returnLogout}
                            </div>
                        </nav>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="col-sm-2">
                                        <button className={"btn btn-primary btn-primary btn-bg-styl"} onClick={(e) => that.backPage()}>BACK</button>
                                    </div>
                                    <div className={"col-lg-12"}>
                                        <div style={{ with: "max-content", height: 700, overflowY: "auto", paddingTop: 10 }}>
                                            {Object.keys(that.state.jsonData).length > 0 ?
                                                <Table x={col} y={row} spreadData={that.state.jsonData} />
                                                : null
                                            }
                                        </div>
                                        <br />
                                        <br />
                                        <ExcelFile element={<button className={"btn btn-primary btn-bg-Ddstyl"}>Download Data</button>}>
                                            <ExcelSheet dataSet={that.state.excelSheet} name="Rule" />
                                        </ExcelFile>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ViewMultipleBatchSheet;