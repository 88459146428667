import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';

class Apps extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loader: true
        }
    }

    componentDidMount() {
        let { token } = this.props.match.params;

        let postJson = { token }
        const request = new ZiFetchRequest();
        request.setDomain('adminUserReq');
        request.setEvent('loginViaJwt');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
            }
            else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg && responseMsg.response == "Success") {
                    localStorage.removeItem("managelogedin");
                    localStorage.removeItem("logedin");
                    localStorage.removeItem("manage_session");
                    localStorage.removeItem("manage_mUserId");
                    localStorage.removeItem("login_RoleType");

                    localStorage.setItem("login_RoleType", responseMsg.rollType)
                    localStorage.setItem("manage_session", responseMsg.session)
                    localStorage.setItem("manage_mUserId", responseMsg.mUserId)
                    localStorage.setItem("manageLoggedUser", responseMsg.mUserName)
                    localStorage.setItem("managelogedin", "manage")
                    // this.props.history.push("/manage/articlecategory")
                    if (responseMsg.redirect) {
                        this.props.history.push(responseMsg.redirect)
                    }
                } else if (responseMsg.response == "Invalid Token") {
                    this.setState({
                        loader: false
                    })
                }
            }
        });
    }

    render() {
        let { loader } = this.state;
        return <div className='apps-page'>
            {
                loader ? <div className="spinner">
                    <div className="ball"></div>
                    <p>LOADING</p>
                </div> : <div className='alert-message'>
                    Please contact admin to view these apps.
                </div>
            }
        </div>
    }
}

export default withRouter(Apps)
