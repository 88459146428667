import React, { Component } from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import SideManuBar from './SideMenuBar';
import image3 from '../images/Teqbahn_logo1.png';
import MyConfig from '../config/MyConfig';
import { dateConverter } from '../config/MyFunctions';
import { toast, ToastContainer } from "react-toastify";
import { pageNumbers, checkLogin, readSessionData, resetLocalData } from '../config/MyFunctions';
import Pagination from "./Pagination"
import ManageSideMenu from '../manage/ManageSideMenu';
import whiteLogo from '../images/whiteLogo.png';
import ManageLogout from "../manage/ManageLogout"
import AdminLogout from "./AdminLogout"
import Select from './component/Select';





class AddArticleCategory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuActive: "",
            action: "Add",
            category_Name: "",
            category_Validate: "",
            processing: false,
            prev: false,
            next: false,
            noPagesLimit: 1,
            pageLimit: 10,
            catagory_Json: {},
            pagination: [],
            visibleClass: "",
            totalResultsCount: 0,
            mUserId: "",
            session: "",
            displayShow: true,
            articleUserTypeOptions: [],
            selectArticleTypeId: "",
            selectArticleType: ""
        }
        this.myRef = React.createRef()
    }
    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        if (login_status) {
            document.body.style.backgroundColor = "#f6f7fb";
            let resData = await readSessionData()
            let { manage_session, manage_mUserId } = resData
            this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
                this.getArticle_Catagory()
                this.getAllArticleUserTypeListData()
            })
        }

    }

    getAllArticleUserTypeListData() {
        let { mUserId, session, } = this.state
        let articleUserTypeOptions = []
        let postJson = { session }
        //auth: String, 
        const request = new ZiFetchRequest();
        request.setDomain("article");
        request.setEvent("getAllArticleUserTypeList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                // console.log(json)
                let response_Msg = json.response
                if (response_Msg == "Success") {
                    let responseData = json.result
                    if (Object.keys(responseData).length > 0) {
                        Object.keys(responseData).map(key => {
                            let userType = responseData[key].userType
                            articleUserTypeOptions.push({ label: userType, value: key })
                        })
                    }
                    this.setState({
                        articleUserTypeOptions
                    });
                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }

    getArticle_Catagory() {
        let { noPagesLimit, pageLimit, mUserId, session } = this.state
        let postJson = { auth: "Teqbahn@321#", pageLimit: pageLimit, noOfPage: noPagesLimit, mUserId, session }
        //auth: String, pageLimit: Int, noOfPage:Int       
        const request = new ZiFetchRequest();
        request.setDomain("article");
        request.setEvent("getArticleCategoryList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response == "Success") {
                    let next = false;
                    let prev = false;
                    let totalResult = json.totalResult
                    let currentResult = noPagesLimit * 10;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noPagesLimit > 1) {
                        prev = true;
                    }

                    let pageCount = Math.ceil(totalResult / 10);
                    let pagination = pageNumbers(pageCount, noPagesLimit, 5);
                    this.setState({
                        prev,
                        next,
                        pagination: pagination,
                        processing: false,
                        catagory_Json: json.result,
                        totalResultsCount: totalResult
                    });
                }
                else if (json.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }

    submit() {

        let { category_Name, mUserId, session, selectArticleTypeId } = this.state
        let postJson = { categoryName: category_Name, mUserId, session, userTypeId: selectArticleTypeId };
        let category_Validate = ""
        let selectArticleTypeValidate = ""

        if (!category_Name) {
            category_Validate = "Enter category Name"
        }

        if (!selectArticleTypeId) {
            selectArticleTypeValidate = "Choose Article User Type"
        }
        this.setState({ category_Validate, selectArticleTypeValidate })
        if (!category_Name || !selectArticleTypeId) {
            return false
        }
        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('article');
        request.setEvent('createArticleCategory');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Category Added successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({ category_Name: "", selectArticleType: "", selectArticleTypeId: "" }, () => {
                        this.getArticle_Catagory()
                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }

    Update() {
        let { category_Name, mUserId, session } = this.state
        //id: String,categoryName: String
        let postJson = {
            categoryName: category_Name,
            id: this.state.id,
            mUserId, session
        };

        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('article');
        request.setEvent('updateArticleCategory');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Category Name Updated successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({ action: "Add", category_Name: "", }, () => {
                        this.getArticle_Catagory()
                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });

    }
    async edit(data) {
        // console.log(data)
        let { articleUserTypeOptions } = this.state
        this.scrollToTop()

        let userTypeId = data["userTypeId"]
        let selectArticleTypeId = "", selectArticleType = ""
        let findUserUserTypeData = articleUserTypeOptions.filter((e) => { return e.value === userTypeId })
        if (findUserUserTypeData.length > 0) {
            selectArticleTypeId = userTypeId
            selectArticleType = findUserUserTypeData[0]
        }

        this.setState({ action: "Edit", id: data["id"], category_Name: data["categoryName"], visibleClass: "bgClrChg", displayShow: false, selectArticleTypeId, selectArticleType })
        setTimeout(() => {
            this.setState({ visibleClass: "" })
        }, 1000);
    }

    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }
        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getArticle_Catagory()
            })
        }
    }

    delete(data) {
        let { totalResultsCount, mUserId, session } = this.state

        let confirmMessage = window.confirm("Are You Sure Delete Catagory?")
        //id: String
        let postJson = {
            id: data["id"],
            mUserId, session
        };

        if (confirmMessage) {
            console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain('article');
            request.setEvent('deleteArticleCategory');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.log("response", response)
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    // console.log(responseMsg)
                    if (responseMsg.response == "Success") {
                        toast.success("Category Data Deleted.", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        this.getArticle_Catagory()
                    }
                    else if (responseMsg.response == "exist") {
                        toast.error("Please remove under the sub catagory mapping !", {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    else if (responseMsg.response == "Invalid Session Request") {
                        toast.warning("Your session got expired!", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        resetLocalData(this.props)
                    }
                }
            });
        }

    }

    scrollToTop() {
        this.myRef.current.scrollTo(0, 0);
    }
    render() {
        let { menuActive, category_Validate, catagory_Json, category_Name, pagination, next, prev, noPagesLimit, visibleClass, displayShow, articleUserTypeOptions, selectArticleType, catagoryNameValidate, selectArticleTypeValidate } = this.state
        import('../admin/style.css');
        let that = this
        var manageIsLogin = localStorage.getItem("managelogedin")
        var loginincheck = localStorage.getItem("logedin")
        var manageLoggedUser = localStorage.getItem("manageLoggedUser")

        let compnayLogo = image3
        if (manageIsLogin) {
            compnayLogo = whiteLogo
        }



        let tableData = []
        Object.keys(catagory_Json).forEach(function (key, index) {
            let fullData = catagory_Json[key]
            let info = fullData.info
            let data = fullData.category
            let userType = fullData.userType

            tableData.push(
                <tr >
                    <td style={{ width: "10%" }} className='tabletxt'>{userType}</td>
                    <td style={{ width: "30%" }} className='tabletxt'>{data['categoryName']}</td>
                    <td style={{ width: "20%" }} className='tabletxt'>
                        {
                            info != null ? <p>{info.mUserName} - {info.email}</p> : null
                        }
                    </td>
                    <td style={{ width: "20%" }} className='tabletxt' >{dateConverter(data['createdAt'])}</td>
                    <td style={{ width: "10%" }} className='fofamily' ><div className={" editBtnstyle"} onClick={() => that.edit(data)}>Edit</div></td>
                    <td style={{ width: "10%" }} className='fofamily'><div className={"deleteBtnstyle"} onClick={() => that.delete(data)}>Delete</div></td>
                </tr>
            )
        });

        return (
            <div className="body-content left-rigt-padding cat-style" ref={this.myRef}>
                <main className="app-main">
                    <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>

                        <div className="container">
                            <div className={(visibleClass ? visibleClass : "") + " card box-shadow"} style={{ padding: 0 }}>
                                <div className="card-header border-btm-style cursor-style" onClick={() => {
                                    this.setState({ displayShow: !displayShow })
                                }}>
                                    <div className='row'>
                                        <div className='col-10 col-sm-11 col-md-11'>
                                            <h5 className='header-style' >{this.state.action} Category</h5>
                                        </div>
                                        <div className='col-2 col-sm-1 col-md-1'>
                                            <span style={{ float: "right", color: "#fff", }}>
                                                {displayShow ? <i class="fa fa-plus"></i> : <i class="fa fa-minus"></i>}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body" style={displayShow ? { display: "none" } : {}}>

                                    <div className="row my-2">
                                        <div className="col-md-3">
                                            <label className='fw'>Article User Type</label>
                                        </div>
                                        <br />
                                        <div className="col-md-6">
                                            <Select
                                                options={articleUserTypeOptions}
                                                isSearchable={true}
                                                placeholder="Article User Type"
                                                onChange={(e) => {
                                                    this.setState({ selectArticleTypeId: e.value, selectArticleType: e })
                                                }}
                                                value={selectArticleType}
                                                isDisabled={this.state.action == "Add" ? false : true}
                                            />
                                            <span className='error-txt'>{selectArticleTypeValidate} </span>
                                        </div>
                                    </div>
                                    <br />

                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Category Name</label></div>
                                        <div className={"col-md-6"}>
                                            <input type="text" placeholder={"Enter Category "} className={"form-control inputRadius"} name={"label"} value={category_Name} onChange={e => {
                                                this.setState({ category_Name: e.target.value })
                                            }} />
                                            <span className='error-txt'>{category_Validate} </span>
                                        </div>
                                        <div className={"col-md-2"}>
                                            {
                                                this.state.action == "Add" ? <input type="button" className="btn mb-2  ml-auto addbuttonsty" value="Add" onClick={() => this.submit()} /> : <input type="button" className="btn  mb-2 ml-auto addbuttonsty" value="Update" onClick={() => this.Update()} />
                                            }
                                            {this.state.action == "Edit" && <input type="button" className="btn mb-1 ml-auto btn-cancel-sty" value="Cancel" onClick={() => {
                                                this.setState({
                                                    action: "Add", category_Name: "", category_Validate: "",
                                                    selectArticleType: "", selectArticleTypeId: ""
                                                })
                                            }} />}

                                        </div>
                                    </div>
                                    <br />

                                    {/* <div className={"row mt-3"}>
                                        <div className={"col-sm-4"}></div>
                                        <div className={"col-sm-4"}>
                                            {
                                                this.state.action == "Add" ? <input type="button" className="btn btn-primary ml-auto" value="Create" onClick={() => this.submit()} /> : <input type="button" className="btn btn-primary ml-auto" value="Update" onClick={() => this.Update()} />
                                            }
                                            {this.state.action == "Edit" && <input type="button" className="btn btn-danger ml-3" value="Cancel" onClick={() => {
                                                this.setState({ action: "Add", category_Name: "", category_Validate: "" })
                                            }} />}


                                        </div>
                                        <div className={"col-sm-4"}></div>
                                    </div> */}

                                </div>
                            </div>
                        </div>

                        <div className="container mt-5">
                            <div className="card box-shadow" style={{ padding: 0 }}>
                                <div className="card-header border-btm-style" style={{ borderBottom: "none" }}>
                                    <h5 className='header-style' > Category List</h5>
                                </div>
                                <div className="card-body table-responsive" style={{ padding: 2 }}>
                                    <table className="table table-style light-border-clr" style={{ width: "100%", textAlign: "center" }} border={1} cellPadding={7}>
                                        <tr className='upperCase'>
                                            <th scope="col fw">Article User Type</th>
                                            <th scope="col fw">Name</th>
                                            <th scope="col fw">Created By <br /> UserName - Email</th>
                                            <th scope="col fw">Published</th>
                                            <th scope="col fw">Edit</th>
                                            <th scope="col fw">Delete</th>
                                        </tr>
                                        <tbody>
                                            {tableData}
                                        </tbody>
                                    </table>

                                    <Pagination
                                        pagination={pagination}
                                        currentPage={noPagesLimit}
                                        prev={prev}
                                        next={next}
                                        movePage={(type) => {
                                            this.movePage(type)
                                        }} />

                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </div>
        )
    }
}

export default AddArticleCategory;