import React, { Component, useRef, useEffect } from 'react';
import Footer3 from './common/Footer3';
import Header3 from './common/Header3';
import image7 from './images/umedme-7_bg.png';
import image1 from './images/umedme-1-new.png';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ZiFetchRequest } from './protobuf/generated/ZiFetchService_grpc_web_pb';
import { Link } from 'react-router-dom';
import MyConstant from './config/MyConstant';

export default class ForgotPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            otpPage: false,
            userId: "",
            otp: "",
            password: "",
            passwordVisible: false
        }
    }


    submit() {
        let { email } = this.state;
        let validEmail = this.validateEmail(email);
        if (email === "" || !validEmail) {
            let message = "Please enter email.";

            if (email !== "" && !validEmail) {
                message = "Please enter valid email.";
            }
            toast.error(message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        } else {
            let postJson = {
                email: email,
                sessionId: "123"
            };
            const request = new ZiFetchRequest();
            request.setDomain('userManage');
            request.setEvent('sendForgotPassword');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request') {
                    console.warn("Invalid Request")
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    let json = responseData;
                    let responseMessage = json.responseMessage;
                    if (responseMessage == 'Success') {
                        toast.success("We sent PIN to your registered Email Address.", {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });

                        let userId = json.userId
                        this.setState({
                            otpPage: true,
                            userId
                        })
                    } else {
                        toast.error("Email ID not exist. Please sign up.", {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });
                    }
                }
            });
        }
    }

    validateEmail(email) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    resetPassword() {
        let { userId, otp, password } = this.state;

        let message = "";
        if (otp.length === 0) {
            message = "Please enter PIN";
        } else if (this.state.otp.length !== 6) {
            message = "Please enter valid PIN";
        } else if (password.length == 0) {
            message = "Please enter Password";
        }

        if (message !== "") {
            toast.error(message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        } else {
            let postJson = {
                sessionId: "123",
                userId: userId,
                otp: otp,
                password: password,
            };
            const request = new ZiFetchRequest();
            request.setDomain('userManage');
            request.setEvent('updateForgotPassword');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request') {
                    console.warn("Invalid Request")
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    var json = responseData;
                    var response = json.response;

                    if (response == 'Success') {
                        toast.success("Password update successfully. Please login.", {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });


                        setTimeout(() => {
                            this.props.history.push("/app-feedback")
                        }, 2000)
                    } else {
                        toast.error("Please enter valid PIN.", {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });
                    }
                }
            });
        }
    }
    render() {
        let { otpPage, passwordVisible } = this.state;
        return <React.Fragment>
            <div className="top-body">
                {/* <Header3 page="app-feedback" /> */}
                <ToastContainer />
                <main>
                    <div className="user-panel">
                        <div className="row">
                            <div className="col-sm-6 px-0 my-auto">
                                <img className="umedme-7" src={image7} />
                            </div>
                            <div className="col-sm-6 px-0 py-5" style={{ backgroundColor: "#FFF" }}>
                                <div className='offset-md-2 col-md-8'>
                                    <div className="login-logo my-3">
                                        <img className="img-fluid" src={image1} />
                                        <h3>Forgot Password</h3>
                                    </div>
                                    <div className="login-form px-5 py-2">
                                        {
                                            otpPage ? <React.Fragment>
                                                <div className="form-group">
                                                    <label>PIN</label>
                                                    <input type="text" className="form-control" placeholder="Enter PIN" onChange={(e) => {
                                                        this.setState({
                                                            otp: e.target.value
                                                        })
                                                    }} />
                                                </div>
                                                <div className="form-group">
                                                    <label>Password</label>
                                                    {/* <input type="Password" className="form-control" placeholder="Enter Password" onChange={(e) => {
                                                this.setState({
                                                    password: e.target.value
                                                })
                                            }} /> */}
                                                    <div className="input-group mb-3">
                                                        <input type={passwordVisible ? "text" : "password"} className="form-control" placeholder="Enter Password" onChange={(e) => {
                                                            this.setState({
                                                                password: e.target.value
                                                            })
                                                        }} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text c-pointer" onClick={() => {
                                                                this.setState({
                                                                    passwordVisible: !passwordVisible
                                                                })
                                                            }}>
                                                                <i className={`fas ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`}></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment> : <div className="form-group">
                                                <label>Email address</label>
                                                <input type="email" className="form-control" placeholder="Enter email" onChange={(e) => {
                                                    this.setState({
                                                        email: e.target.value
                                                    })
                                                }} />
                                            </div>
                                        }
                                        <div className="text-right mb-2">
                                            <Link to={MyConstant.keyList.projectUrl + "/app-feedback"}>Back to Login</Link>
                                        </div>
                                        <div>
                                            <button type="button" className="btn btn-block user-login-btn" onClick={() => {
                                                if (otpPage) {
                                                    this.resetPassword()
                                                } else {
                                                    this.submit()
                                                }
                                            }
                                            }>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                {/* <Footer3 /> */}
            </div>
        </React.Fragment>;
    }
}
