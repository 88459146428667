import React from 'react';
import Dropzone from 'react-dropzone';
import MyConstant from '../config/MyConstant';
import Dragplaceholder from '../images/Dragfile.png';
import { v4 as uuidv4 } from 'uuid';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import { toast, ToastContainer } from "react-toastify";
import Select from '../admin/component/Select';
import MyConfig from '../config/MyConfig';
import 'react-toastify/dist/ReactToastify.css';
import { PDFViewer } from 'react-view-pdf';
import Modal from '../AppIssues/Modal';







class IssuesPost extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            successMsg: false,
            viewMode: "upload",
            document_Json: [],
            deviceWidth: window.innerWidth,
            loader: false,
            documentFiles: [],
            title: "",
            description: "",
            deviceOs: "",
            deviceOsVersion: "",
            deviceModel: "",
            deviceOtherOsVersion: "",
            deviceOsVersionOption: [],
            issueFrom: "App",
            submitLoader: false,
            imagePreview: {},
            modalVisible: false
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        let appUserDeviceInfo = localStorage.getItem("appUserDeviceInfo");
        if (appUserDeviceInfo) {
            appUserDeviceInfo = JSON.parse(appUserDeviceInfo);
            let deviceOs = appUserDeviceInfo.appPlatform
            let deviceOsVersionOption = []
            if (deviceOs === "android") {
                deviceOsVersionOption = MyConfig.andriodOsVersion
            } else if (deviceOs === "ios") {
                deviceOsVersionOption = MyConfig.iosOsVersion
            }
            this.setState({
                deviceOs,
                deviceOsVersionOption
            })
        }
    }

    documentOnDrop(newFiles) {

        console.log("newFiles", newFiles)

        var documentFiles = this.state.documentFiles;
        var document_Json = this.state.document_Json;

        for (var i = 0; i < newFiles.length; i++) {
            let file = newFiles[i];
            let filename = file.name;
            let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
            let uid = uuidv4();

            documentFiles.push(newFiles[i]);
            document_Json.push({
                name: this.state.selectTypeVlaue,
                path: newFiles[i],
                status: 'active',
                progress: '0',
                origFileName: filename,
                fileName: uid + "." + ext,
                docsId: uid,
                processType: "issues",
                fileType: ext
            })
        }
        this.setState({
            documentFiles: documentFiles, document_Json: document_Json
        });
    }
    documentOnRemove(loopIndex) {
        var document_Json = this.state.document_Json;
        var documentFiles = this.state.documentFiles;
        document_Json.splice(loopIndex, 1);
        documentFiles.splice(loopIndex, 1);
        this.setState({
            document_Json: document_Json, documentFiles: documentFiles
        });

    }

    async submitDocuments(alertKey) {
        var that = this;
        this.setState({ viewMode: "progress", loader: true })
        let { title, description, document_Json, deviceOsVersion, deviceOs, deviceModel, deviceOtherOsVersion, issueFrom } = this.state;

        if (title === "" || (issueFrom === "App" && (deviceModel === "" || deviceOsVersion === "" || (deviceOsVersion === "Others" && deviceOtherOsVersion === "")))) {
            let message = "";
            if (title === "") {
                message = "Please enter title";
            } else if (deviceOsVersion === "") {
                message = "Please select device OS Version";
            } else if (deviceOsVersion === "Others" && deviceOtherOsVersion === "") {
                message = "Please enter device OS Version";
            } else if (deviceModel === "") {
                message = "Please enter device model"
            }
            toast.error(message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        } else {
            this.setState({
                submitLoader: true
            }, async () => {
                let fileList = [];
                for (var i = 0; i < document_Json.length; i++) {
                    await that.sendPostJson(document_Json[i], i);
                    let fileDetails = document_Json[i];
                    fileList.push(JSON.stringify({
                        origFileName: fileDetails.origFileName,
                        fileName: fileDetails.fileName,
                        docsId: fileDetails.docsId,
                        processType: fileDetails.processType,
                        fileType: fileDetails.fileType
                    }));

                }
                deviceOsVersion = deviceOsVersion === "Others" ? deviceOtherOsVersion : deviceOsVersion;

                let userId = localStorage.getItem("appUserLogin");
                let postJson = { userId, fileList, title, descrition: description, metaInfo: JSON.stringify({ deviceOsVersion, deviceOs, deviceModel, issueFrom }) }

                const request = new ZiFetchRequest();
                request.setDomain('appIssues');
                request.setEvent('createAppIssuesReport');
                request.setMessage(JSON.stringify(postJson));
                request.setDatetime(new Date().toString());
                this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                    if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                        console.warn("Invalid Request")
                    } else {
                        this.setState({
                            successMsg: true,
                            document_Json: [],
                            documentFiles: [],
                            loader: false,
                            title: "",
                            description: "",
                            deviceOsVersion: "",
                            deviceModel: "",
                            deviceOtherOsVersion: ""
                        }, () => {
                            let responseMsg = JSON.parse(response.getMessage());
                            if (responseMsg.response === "Success") {
                                toast.success("Feedback Created Successfully.", {
                                    position: "top-right",
                                    autoClose: 1500,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true
                                });

                                setTimeout(() => {
                                    that.setState({ submitLoader: false, }, () => {
                                        that.props.setPage("issue-list");
                                    })

                                }, 3000)
                            }
                        })
                    }
                });
            })
        }

    }

    async sendPostJson(documentArray, i) {
        var that = this;
        var document_Json = this.state.document_Json;
        document_Json[i].progress = 50;
        that.setState({
            document_Json: document_Json
        })
        if (document_Json.length > 0) {
            await that.uploadDocumentFiles(documentArray, i);
            document_Json[i].progress = 100;
            that.setState({
                document_Json: document_Json
            })
        }
    }

    async uploadDocumentFiles(documentArray, i) {
        const postFileUpload = new FormData();
        postFileUpload.append('file' + (i + 1), documentArray.path);
        postFileUpload.append('fileName' + (i + 1), documentArray.fileName);
        postFileUpload.append('processType' + (i + 1), documentArray.processType);
        postFileUpload.append('docsId' + (i + 1), documentArray.docsId);

        let userId = localStorage.getItem("appUserLogin");
        let result = await fetch(MyConstant.keyList.apiURL + "fileUpload/" + documentArray.processType + "/" + documentArray.docsId + "/" + userId, {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        }).then(
            function (response) {
                return response.json();
            }
        ).then(json => {
            return json.response;
        }).catch(error => console.warn(error));

        return result
    }

    handleChange(e) {
        this.setState({
            deviceOsVersion: e.value
        })
    }

    issueFromChange(value) {
        this.setState({
            issueFrom: value
        })
    }
    render() {
        let { submitLoader, documentFiles, title, description, deviceOs, deviceOsVersion, deviceOsVersionOption, deviceModel, deviceOtherOsVersion, issueFrom, imagePreview, modalVisible } = this.state
        var that = this

        // console.log("documentFiles", documentFiles)
        let videos = ["mp4", "3gp", "ogg", "mov"]
        let returnModelBody = ""
        if (imagePreview.fileType) {
            if (videos.includes(imagePreview.fileType.toLowerCase())) {
                returnModelBody = <>
                    <video width="400" controls>
                        <source src={imagePreview.filePath} />
                    </video>
                </>
            }
            else if (imagePreview.fileType == "pdf") {
                returnModelBody = <PDFViewer url={imagePreview.filePath} />

            }
            else {
                returnModelBody = <>
                    <img src={imagePreview.filePath} className={"img-thumbnail"} style={{ height: 400 }} />
                </>
            }
        }

        return <React.Fragment>
            <ToastContainer />
            <div className="row my-3">
                <div className="col-12 text-right">
                    <button className="btn btn-secondary" onClick={() => this.props.logout()}>Logout</button>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card cardstyle">
                        <div className="card-header">
                            Post Feedback
                            <div className="float-right">
                                <button className="btn btn-sm btn-primary" onClick={() => {
                                    this.props.setPage("issue-list")
                                }}>Back</button>
                            </div>
                        </div>
                        <div className="card-body cardpad">
                            <div>
                                <div className="row my-2">
                                    <div className="col-sm-6 col-md-3">
                                        <label className='form-control lb1'>Title :</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control"
                                            placeholder="Title"
                                            value={title}
                                            onChange={(e) => this.setState({ title: e.target.value })} />
                                    </div>
                                </div>
                                <div className="row my-2">
                                    <div className="col-sm-6 col-md-3">
                                        <label className='form-control lb1'>Description :</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <textarea
                                            className="form-control"
                                            placeholder="Description"
                                            value={description}
                                            onChange={(e) => this.setState({ description: e.target.value })}></textarea>
                                    </div>
                                </div>
                                <div className="row my-2">
                                    <div className="col-sm-6 col-md-3">
                                        <label className='form-control lb1'>Feedback from :</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="issueFrom" value="App" checked={issueFrom === "App"} onChange={(e) => this.issueFromChange(e.currentTarget.value)} />
                                            <label className="form-check-label">App</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="issueFrom" value="Web" checked={issueFrom === "Web"} onChange={(e) => this.issueFromChange(e.currentTarget.value)} />
                                            <label className="form-check-label">Web</label>
                                        </div>
                                    </div>
                                </div>
                                {
                                    issueFrom === "App" && <React.Fragment>
                                        <div className="row my-2">
                                            <div className="col-sm-6 col-md-3">
                                                <label className='form-control lb1'>Device OS :</label>
                                            </div>
                                            <div className="col-sm-6">
                                                <b style={{ textTransform: "uppercase" }}>{deviceOs}</b>
                                            </div>
                                        </div>
                                        <div className="row my-2">
                                            <div className="col-sm-6 col-md-3">
                                                <label className='form-control lb1'>Device OS Version:</label>
                                            </div>
                                            <div className="col-sm-6">
                                                <Select
                                                    options={deviceOsVersionOption}
                                                    isSearchable={true}
                                                    placeholder="Select"
                                                    onChange={this.handleChange}
                                                    value={deviceOsVersionOption.filter(option => option.value === deviceOsVersion)}
                                                />
                                            </div>
                                        </div>
                                        {
                                            deviceOsVersion === "Others" && <div className="row my-2">
                                                <div className="col-sm-6 col-md-3">
                                                    <label className='form-control lb1'>OS Version :</label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <input type="text" className="form-control"
                                                        placeholder="OS Version"
                                                        value={deviceOtherOsVersion}
                                                        onChange={(e) => this.setState({ deviceOtherOsVersion: e.target.value })} />
                                                </div>
                                            </div>
                                        }

                                        <div className="row my-2">
                                            <div className="col-sm-6 col-md-3">
                                                <label className='form-control lb1'>Device Model :</label>
                                            </div>
                                            <div className="col-sm-6">
                                                <input type="text" className="form-control"
                                                    placeholder="Device Model"
                                                    value={deviceModel}
                                                    onChange={(e) => this.setState({ deviceModel: e.target.value })} />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }

                                <div className="row my-2">
                                    <div className="col-sm-6 col-md-3">
                                        <label className='form-control lb1'>Upload Image/Video/PDF :</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="dropback">
                                            <Dropzone accept=".png, .jpg, .mp4, .jpeg, .3gp, .ogg, .mov, .pdf" multiple={true}
                                                onDrop={(acceptedFiles) => {
                                                    that.documentOnDrop(acceptedFiles)
                                                }} onDropRejected={(reject) => {
                                                    console.log("reject", reject)
                                                    toast.error("Please Choose Image / Video / Pdf File.", {
                                                        position: "top-right",
                                                        autoClose: 1500,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true
                                                    });

                                                }}  >
                                                {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                        <div {...getRootProps({ className: 'dropzone' })}>
                                                            <input {...getInputProps()} />
                                                            <div className="row">
                                                                <div className="col-3">
                                                                    <img src={Dragplaceholder} className='dragimgph' />
                                                                </div>
                                                                <div className="col-9">
                                                                    <p className='dragtext'>
                                                                        Try dropping some files here, or click to select files to upload.
                                                                        <br />Support file formats (.png, .jpg, .jpeg, .mp4, .3gp, .ogg, .mov, .pdf)
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>

                                            {/* {
                                                documentFiles.map((f, loopIndex) => {                                                
                                                    return (<div className="my-2">
                                                        <div className="row rowstructure">
                                                            <div className="col-8">
                                                                
                                                                {f.name}
                                                                <div className="progress" key={loopIndex}>
                                                                    <div
                                                                        style={{ width: document_Json[loopIndex].progress + "%", background: '#673ab7' }} role="progressbar"></div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className='roundbg' onClick={() => that.documentOnRemove(loopIndex)}>
                                                                    <a href="javascript:void(0)" ><p className='closex'>X</p></a>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>)
                                                }
                                                )
                                            } */}

                                        </div>
                                    </div>
                                </div>

                                <div className='row '>
                                    <div className='col-sm-6 col-md-3'></div>
                                    <div className='col-sm-6'>
                                        <div className='row my-2'>
                                            {
                                                documentFiles.map((f, loopIndex) => {
                                                    // console.log("**",f.path,typeof(f))
                                                    let filePath = URL.createObjectURL(f)
                                                    let fileName = f.name
                                                    let fileType = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
                                                    // console.log(fileType)

                                                    let returnData = ""
                                                    if (videos.includes(fileType.toLowerCase())) {
                                                        returnData = <i className="fa fa-video" aria-hidden="true"
                                                            style={{ paddingRight: 5, fontSize: 80, cursor: "pointer" }}

                                                            onClick={() => {
                                                                let imagePreview = { filePath, fileType }
                                                                this.setState({ imagePreview, modalVisible: true })
                                                            }}
                                                        ></i>
                                                    }
                                                    else if (fileType == "pdf") {
                                                        returnData = <i className="fa fa-file-pdf" aria-hidden="true"

                                                            style={{ paddingRight: 5, fontSize: 80, cursor: "pointer" }}
                                                            onClick={() => {
                                                                let imagePreview = { filePath, fileType }
                                                                this.setState({ imagePreview, modalVisible: true })
                                                            }}
                                                        ></i>
                                                    }
                                                    else {
                                                        returnData = <img src={filePath} alt="no img" style={{ width: 80, height: 80, cursor: "pointer" }}
                                                            onClick={() => {
                                                                let imagePreview = { filePath, fileType }
                                                                this.setState({ imagePreview, modalVisible: true })
                                                            }}
                                                        />

                                                    }
                                                    return (<>
                                                        <div className='col-sm-4 col-md-3 file-box'>
                                                            {/* <img src={"https://picsum.photos/200/300"} className='img-rounded' style={{ width: 80, height: 70 }} /> */}
                                                            {returnData}
                                                            <i className="fa fa-trash delete-upload-file" aria-hidden="true"
                                                                onClick={() => that.documentOnRemove(loopIndex)}

                                                            ></i>
                                                        </div>
                                                    </>)
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                                {modalVisible && <Modal
                                    visible={modalVisible}
                                    size={"modal-xl"}
                                    closeModal={() => this.setState({ modalVisible: false, imagePreview: {} })}
                                    heading={"File View"}
                                    parentClass={"text-center"}
                                    body={<React.Fragment>
                                        {returnModelBody}
                                    </React.Fragment>}
                                />}

                                <div className='row my-1'>
                                    <div className='col-12 text-center'>
                                        <button type="button"
                                            className="btn btn-success btntop "
                                            onClick={() => that.submitDocuments(true)} disabled={submitLoader}>
                                            {submitLoader && <i className="fa fa-spinner fa-spin"></i>} Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>;
    }
}

export default IssuesPost;