import React from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { getValidLabels } from "../../config/MyFunctions";

function MedicalHistoryForm(props) {
    let { pageText } = props;

    return <div className="py-2" style={{ borderBottom: "1px dashed #ddd", position: "relative" }}>
        <div className="delete-medical-history" onClick={() => props.delete()}>
            <i className="fa fa-trash"></i>
        </div>
        <div>
            <div><b>{getValidLabels(pageText, 'whenItHappenedLabelTxt')}</b></div>
            <div>
                <DatePicker
                    className={`form-control`}
                    dateFormat={"MM/dd/yyyy"}
                    selected={props.date ? new Date(props.date) : ""}
                    onChange={(date) => {
                        props.onChange("date", date)
                    }}
                />
            </div>
        </div>
        <div>
            <div><b>{getValidLabels(pageText, 'whatHappenedLabelTxt')}</b></div>
            <div>
                <textarea className="form-control" value={props.description} onChange={(e) => {
                    props.onChange("description", e.target.value)
                }}></textarea>
            </div>
        </div>
    </div>;
}

export default MedicalHistoryForm;