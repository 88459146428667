import React, { Component, useRef, useEffect } from 'react';
import Footer3 from './common/Footer3';
import Header3 from './common/Header3';
import image7 from './images/umedme-7_bg.png';
import image1 from './images/umedme-1-new.png';
import IssuesPost from './AppIssues/IssuesPost';
import IssuesList from './AppIssues/IssuesList';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ZiFetchRequest } from './protobuf/generated/ZiFetchService_grpc_web_pb';
import { Link } from 'react-router-dom';
import MyConstant from './config/MyConstant';

export default class AppIssues extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pagename: "",
            email: "",
            password: "",
            passwordVisible: false
        }
        this.setPage = this.setPage.bind(this)
        this.logout = this.logout.bind(this)
    }

    componentDidMount() {
        let appUserLogin = localStorage.getItem("appUserLogin");
        let pagename = "";
        if (appUserLogin) {
            pagename = "issue-list"
        } else {
            pagename = "login"
        }

        this.setState({
            pagename
        })
    }

    setPage(pagename) {
        this.setState({
            pagename
        })
    }
    logout() {
        this.setState({
            pagename: "login"
        }, () => {
            localStorage.removeItem("appUserLogin");
        })
    }

    login() {
        let { email, password } = this.state;

        if (email === "" || password === "") {
            toast.error('Please enter email/password.', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        } else {
            let postJson = { email, password }
            const request = new ZiFetchRequest();
            request.setDomain('admin');
            request.setEvent('userSignInFromWeb');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.warn("Invalid Request")
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    if (responseMsg.responseCode == 0) {
                        toast.error(responseMsg.response, {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });
                    } else {
                        localStorage.setItem("appUserDeviceInfo", responseMsg.deviceInfo)
                        localStorage.setItem("appUserLogin", responseMsg.id)
                        this.setPage("issue-list")
                    }
                }
            });
        }
    }
    buildContent() {
        let content;
        let { pagename, passwordVisible } = this.state;
        if (pagename === "login") {
            content = <div className="row">
                <div className="col-sm-6 px-0 my-auto">
                    <img className="umedme-7" src={image7} />
                </div>
                <div className="col-sm-6 px-0 py-5" style={{ backgroundColor: "#FFF" }}>
                    <div className='offset-md-2 col-md-8'>
                        <div className="login-logo my-3">
                            <img className="img-fluid" src={image1} />
                            <h3>Please login to Feedback.</h3>
                        </div>
                        <div className="login-form px-5 py-2">
                            <div className="form-group">
                                <label>Email address</label>
                                <input type="email" className="form-control" placeholder="Enter email" onChange={(e) => {
                                    this.setState({
                                        email: e.target.value
                                    })
                                }} />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                {/* <input type="password" className="form-control" placeholder="Enter Password" onChange={(e) => {
                                    this.setState({
                                        password: e.target.value
                                    })
                                }}/> */}
                                <div className="input-group mb-3">
                                    <input type={passwordVisible ? "text" : "password"} className="form-control" placeholder="Enter Password" onChange={(e) => {
                                        this.setState({
                                            password: e.target.value
                                        })
                                    }} />
                                    <div className="input-group-append">
                                        <span className="input-group-text c-pointer" onClick={() => {
                                            this.setState({
                                                passwordVisible: !passwordVisible
                                            })
                                        }}>
                                            <i className={`fas ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`}></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm-6 ">
                                    {/* <Link to={MyConstant.keyList.projectUrl + "/sign-up"}>Create an account</Link> */}
                                </div>
                                <div className="col-sm-6 col-xs-12 text-right">
                                    <Link to={MyConstant.keyList.projectUrl + "/forgot-password"}>Forgot Password?</Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 px-0">
                                    <button type="button" className="btn btn-block user-login-btn" onClick={() => this.login()}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        } else if (pagename === "issue-list") {
            content = <IssuesList grpcClient={this.props.grpcClient} setPage={this.setPage} logout={this.logout} />
        } else if (pagename === "issue-post") {
            content = <IssuesPost grpcClient={this.props.grpcClient} setPage={this.setPage} logout={this.logout} />
        }

        return content
    }

    render() {

        return (<React.Fragment>
            <div className="top-body feedback-top-body">
                {/* <Header3 page="app-feedback" /> */}
                <ToastContainer />
                <div className="user-panel">
                        {
                            this.buildContent()
                        }
                    </div>
                {/* <Footer3 /> */}
            </div>
        </React.Fragment>
        );
    }
}
