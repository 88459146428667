import React, { Component } from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import SideManuBar from './SideMenuBar';
import image3 from '../images/Teqbahn_logo1.png';
import MyConfig from '../config/MyConfig';
import { dateConverter } from '../config/MyFunctions';
import { toast, ToastContainer } from "react-toastify";
import { pageNumbers, checkLogin, readSessionData, resetLocalData } from '../config/MyFunctions';
import Pagination from "./Pagination"
import Select from './component/Select';
import ManageSideMenu from '../manage/ManageSideMenu';
import whiteLogo from '../images/whiteLogo.png';
import ManageLogout from "../manage/ManageLogout"
import AdminLogout from "./AdminLogout"







class AddArticleSubCategory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuActive: "",
            action: "Add",
            subcategory_Name: "",
            subCategory_Validate: "",
            processing: false,
            prev: false,
            next: false,
            noPagesLimit: 1,
            pageLimit: 10,
            subCatagory_Json: {},
            pagination: [],
            Catagory_Json: {},
            catagoryOption: [],
            catagorySelect: [],
            catgoryId: "",
            catagoryNameValidate: "",
            visibleClass: "",
            mUserId: "",
            session: "",
            displayShow: true,
            selectArticleTypeId: "",
            selectArticleType: "",
            articleUserTypeOptions: [],
        }
        this.myRef = React.createRef()
    }
    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        if (login_status) {
            document.body.style.backgroundColor = "#f6f7fb";
            let resData = await readSessionData()
            let { manage_session, manage_mUserId } = resData
            this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
                // this.getAllCatagory()
                this.getAllArticleUserTypeListData()
                this.getArticle_SubCatagory()
            })
        }

    }

    getAllArticleUserTypeListData() {
        let { mUserId, session, } = this.state
        let articleUserTypeOptions = []
        let postJson = {  session }
        //auth: String, 
        const request = new ZiFetchRequest();
        request.setDomain("article");
        request.setEvent("getAllArticleUserTypeList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                // console.log(json)
                let response_Msg = json.response
                if (response_Msg == "Success") {
                    let responseData = json.result
                    if (Object.keys(responseData).length > 0) {
                        Object.keys(responseData).map(key => {
                            let userType = responseData[key].userType
                            articleUserTypeOptions.push({ label: userType, value: key })
                        })
                    }
                    this.setState({
                        articleUserTypeOptions
                    });
                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }


    getAllCatagoryTypeBasedOn() {
        let { mUserId, session, selectArticleTypeId } = this.state
        let catagoryOption = []
        let postJson = { auth: "Teqbahn@321#", mUserId, session, userTypeId: selectArticleTypeId }
        //auth: String, 
        const request = new ZiFetchRequest();
        request.setDomain("article");
        request.setEvent("getArticleCategoryListBasedOnUserType");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let response_Msg = json.response
                if (response_Msg == "Success") {
                    let responseData = json.result
                    if (Object.keys(responseData).length > 0) {
                        Object.keys(responseData).map(key => {
                            let categoryName = responseData[key].categoryName
                            catagoryOption.push({ label: categoryName, value: key })
                        })
                    }
                    this.setState({
                        catagoryOption
                    }, () => {
                        this.getArticle_SubCatagory()
                    });
                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }
    getAllCatagory() {
        let { mUserId, session } = this.state
        let catagoryOption = []
        let postJson = { auth: "Teqbahn@321#", mUserId, session }
        //auth: String, 
        const request = new ZiFetchRequest();
        request.setDomain("article");
        request.setEvent("getAllArticleCategoryList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let response_Msg = json.response
                if (response_Msg == "Success") {
                    let responseData = json.result
                    if (Object.keys(responseData).length > 0) {
                        Object.keys(responseData).map(key => {
                            let categoryName = responseData[key].categoryName
                            catagoryOption.push({ label: categoryName, value: key })
                        })
                    }
                    this.setState({
                        catagoryOption
                    }, () => {
                        this.getArticle_SubCatagory()
                    });
                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }

    getArticle_SubCatagory() {
        let { noPagesLimit, pageLimit, mUserId, session } = this.state
        let postJson = { auth: "Teqbahn@321#", pageLimit: pageLimit, noOfPage: noPagesLimit, mUserId, session }
        //auth: String, pageLimit: Int, noOfPage:Int
        const request = new ZiFetchRequest();
        request.setDomain("article");
        request.setEvent("getArticleSubCategoryList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let response_Msg = json.response

                if (response_Msg == "Success") {
                    let next = false;
                    let prev = false;
                    let totalResult = json.totalResult
                    let currentResult = noPagesLimit * 10;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noPagesLimit > 1) {
                        prev = true;
                    }

                    let pageCount = Math.ceil(totalResult / 10);
                    let pagination = pageNumbers(pageCount, noPagesLimit, 5);
                    this.setState({
                        prev,
                        next,
                        pagination: pagination,
                        processing: false,
                        subCatagory_Json: json.result
                    });
                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }

    submit() {

        let { subcategory_Name, catgoryId, mUserId, session, selectArticleTypeId } = this.state
        //catId: String,subCategoryName: String
        let postJson = {
            subCategoryName: subcategory_Name,
            catId: catgoryId,
            mUserId, session,
            userTypeId: selectArticleTypeId
        };
        let subCategory_Validate = ""
        let catagoryNameValidate = ""
        let selectArticleTypeValidate = ""

        if (!subcategory_Name) {
            subCategory_Validate = "Enter subCategory Name"
        }
        if (!catgoryId) {
            catagoryNameValidate = "Choose Category Name"
        }

        if (!selectArticleTypeId) {
            selectArticleTypeValidate = "Choose Article User Type"
        }

        this.setState({ subCategory_Validate, catagoryNameValidate, selectArticleTypeValidate })
        if (!subcategory_Name || !catgoryId || !selectArticleTypeId) {
            return false
        }
        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('article');
        request.setEvent('createArticleSubCategory');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("SubCategory Added successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({ subcategory_Name: "", catagorySelect: [], selectArticleType: "", selectArticleTypeId: "" }, () => {
                        this.getArticle_SubCatagory()
                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }

    Update() {
        let { subcategory_Name, mUserId, session } = this.state
        //id: String,subcategoryName: String
        let postJson = {
            subcategoryName: subcategory_Name,
            id: this.state.id,
            mUserId, session
        };

        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('article');
        request.setEvent('updateArticleSubCategory');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("SubCategory Name Updated!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({ action: "Add", subcategory_Name: "", catagorySelect: [], selectArticleType: "", selectArticleTypeId: "" }, () => {
                        this.getArticle_SubCatagory()
                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });

    }
    async edit(data) {
        this.scrollToTop()
        let { catagoryOption, articleUserTypeOptions } = this.state
        // console.log(data)
        let userTypeId = data["userTypeId"]
        let selectArticleTypeId = "", selectArticleType = ""
        selectArticleTypeId = userTypeId
        selectArticleType = { label: data["userType"], value: userTypeId }

        this.setState({ action: "Edit", id: data["id"], subcategory_Name: data["subCategoryName"], catagorySelect: { label: data["categoryName"], value: data["catId"] }, visibleClass: "bgClrChg", displayShow: false, selectArticleTypeId, selectArticleType }, () => {
            this.getAllCatagoryTypeBasedOn()
        })

        setTimeout(() => {
            this.setState({ visibleClass: "" })
        }, 1000);
    }

    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }
        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getArticle_SubCatagory()
            })
        }
    }
    handleChange(e) {
        this.setState({ catagorySelect: e, catgoryId: e.value })
    }


    delete(data) {
        let confirmMessage = window.confirm("Are You Sure Delete Sub Catagory?")
        //catId: String,subCatId:String
        let { mUserId, session } = this.state
        let postJson = {
            catId: data["catId"],
            subCatId: data["id"],
            mUserId, session,
        };

        if (confirmMessage) {
            console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain('article');
            request.setEvent('deleteArticleSubCategory');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.log("response", response)
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    // console.log(responseMsg)
                    if (responseMsg.response == "Success") {
                        toast.success("Sub Category Data Deleted.", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        this.getArticle_SubCatagory()
                    }
                    else if (responseMsg.response == "exist") {
                        toast.error("Please remove under the article mapping !", {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    else if (responseMsg.response == "Invalid Session Request") {
                        toast.warning("Your session got expired!", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        resetLocalData(this.props)
                    }
                }
            });
        }

    }
    scrollToTop() {
        this.myRef.current.scrollTo(0, 0);
    }
    render() {
        let { menuActive, subCategory_Validate, subCatagory_Json, subcategory_Name, pagination, next, prev, noPagesLimit, catagoryOption, catagorySelect, catagoryNameValidate, visibleClass, displayShow, articleUserTypeOptions, selectArticleType, selectArticleTypeValidate, } = this.state
        import('../admin/style.css');
        let that = this

        var manageIsLogin = localStorage.getItem("managelogedin")
        var loginincheck = localStorage.getItem("logedin")
        var manageLoggedUser = localStorage.getItem("manageLoggedUser")
        let compnayLogo = image3
        if (manageIsLogin) {
            compnayLogo = whiteLogo
        }
        let tableData = []
        Object.keys(subCatagory_Json).forEach(function (key, index) {

            let fullData = subCatagory_Json[key]
            let info = fullData.info
            let data = fullData
            let findCatName = fullData.categoryName
            let userType = fullData.userType
            // console.log(fullData)
            tableData.push(
                <tr >
                    <td className='tabletxt'>{userType}</td>
                    <td className='tabletxt'>{findCatName}</td>
                    <td className='tabletxt'>{data['subCategoryName']}</td>
                    <td className='tabletxt'>
                        {
                            info != null ? <p>{info.mUserName} - {info.email}</p> : null
                        }
                    </td>
                    <td className='tabletxt'>{dateConverter(data['createdAt'])}</td>
                    <td style={{ width: "10%" }} className='fofamily' ><div className={"editBtnstyle"} onClick={() => that.edit(data)}>Edit</div></td>
                    <td style={{ width: "10%" }} className='fofamily'><div className={"deleteBtnstyle"} onClick={() => that.delete(data)}>Delete</div></td>
                </tr>
            )
        });
        return (
            <div className="body-content left-rigt-padding cat-style" ref={this.myRef}>

                <main className="app-main">
                    <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>

                        <div className="container">
                            <div className={(visibleClass ? visibleClass : "") + " card box-shadow"} style={{ padding: 0 }}>
                                <div className="card-header border-btm-style cursor-style" onClick={() => {
                                    this.setState({ displayShow: !displayShow })
                                }}>
                                    <div className='row'>
                                        <div className='col-10 col-sm-11 col-md-11'>
                                            <h5 className='header-style' >{this.state.action} Sub Category</h5>
                                        </div>
                                        <div className='col-2 col-sm-1 col-md-1'>
                                            <span style={{ float: "right", color: "#fff", }}>
                                                {displayShow ? <i class="fa fa-plus"></i> : <i class="fa fa-minus"></i>}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div className="card-body" style={displayShow ? { display: "none" } : {}}>

                                    <div className="row my-2">
                                        <div className="col-md-3">
                                            <label className='fw'>Article User Type</label>
                                        </div>
                                        <br />
                                        <div className="col-md-6">
                                            <Select
                                                options={articleUserTypeOptions}
                                                isSearchable={true}
                                                placeholder="Article User Type"
                                                onChange={(e) => {
                                                    this.setState({ selectArticleTypeId: e.value, selectArticleType: e }, () => {
                                                        this.getAllCatagoryTypeBasedOn()
                                                    })
                                                }}
                                                value={selectArticleType}
                                                isDisabled={this.state.action == "Add" ? false : true}
                                            />
                                            <span className='error-txt'>{selectArticleTypeValidate} </span>
                                        </div>
                                    </div>
                                    <br />

                                    <div className="row my-2">
                                        <div className="col-md-3">
                                            <label className='fw'>Category Name</label>
                                        </div>
                                        <br />
                                        <div className="col-md-6">
                                            <Select
                                                options={catagoryOption}
                                                isSearchable={true}
                                                placeholder="Select Category Name"
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                                value={catagorySelect}
                                                isDisabled={this.state.action == "Add" ? false : true}
                                            />

                                            <span className='error-txt'>{catagoryNameValidate} </span>
                                        </div>
                                    </div>
                                    <br />
                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' htmlFor="">Sub Category Name</label></div>
                                        <div className={"col-md-6"}>
                                            <input type="text" placeholder={"Enter Category "} className={"form-control inputRadius"} name={"label"} value={subcategory_Name} onChange={e => {
                                                this.setState({ subcategory_Name: e.target.value })
                                            }} />
                                            <span className='error-txt'>{subCategory_Validate} </span>
                                        </div>
                                        <div className={"col-md-2"}>
                                            {
                                                this.state.action == "Add" ? <input type="button" className="btn mb-2  ml-auto addbuttonsty" value="Add" onClick={() => this.submit()} /> : <input type="button" className="btn  mb-2 ml-auto addbuttonsty" value="Update" onClick={() => this.Update()} />
                                            }
                                            {this.state.action == "Edit" && <input type="button" className="btn mb-1 ml-auto btn-cancel-sty" value="Cancel" onClick={() => {
                                                this.setState({ action: "Add", subcategory_Name: "", catagorySelect: [], subCategory_Validate: "", catagoryNameValidate: "", selectArticleType: "", selectArticleTypeValidate: "" })
                                            }} />}

                                        </div>

                                    </div>
                                    <br />
                                    {/* <div className={"row"}>
                                        <div className={"col-sm-4"}></div>
                                        <div className={"col-sm-4"}>
                                            {
                                                this.state.action == "Add" ? <input type="button" className="btn btn-primary ml-auto" value="Create" onClick={() => this.submit()} /> : <input type="button" className="btn btn-primary ml-auto" value="Update" onClick={() => this.Update()} />
                                            }

                                            {this.state.action == "Edit" && <input type="button" className="btn btn-danger ml-3" value="Cancel" onClick={() => {
                                                this.setState({ action: "Add", subcategory_Name: "", catagorySelect: [], subCategory_Validate: "", catagoryNameValidate: "" })
                                            }} />}

                                        </div>
                                        <div className={"col-sm-4"}></div>
                                    </div> */}

                                </div>
                            </div>
                        </div>


                        <div className="container mt-5">
                            <div className="card box-shadow" style={{ padding: 0 }}>
                                <div className="card-header border-btm-style" style={{ borderBottom: "none" }}>
                                    <h5 className='header-style' >Sub Category List</h5>
                                </div>
                                <div className="card-body table-responsive " style={{ padding: 2 }}>
                                    <table className="table table-style table-sm light-border-clr" style={{ width: "100%", textAlign: "center" }} border={1} cellPadding={7}>
                                        <tr className='upperCase'>
                                            <th scope="col fw">Article User Type</th>
                                            <th scope="col fw">Category Name</th>
                                            <th scope="col fw">Sub Category Name</th>
                                            <th scope="col fw">Created By <br /> UserName - Email</th>
                                            <th scope="col fw">Published</th>
                                            <th scope="col fw">Edit</th>
                                            <th scope="col fw">Delete</th>
                                        </tr>
                                        <tbody>
                                            {tableData}
                                        </tbody>
                                    </table>

                                    <Pagination
                                        pagination={pagination}
                                        currentPage={noPagesLimit}
                                        prev={prev}
                                        next={next}
                                        movePage={(type) => {
                                            this.movePage(type)
                                        }} />
                                </div>

                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default AddArticleSubCategory;