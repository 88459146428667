import React, { Component } from 'react';
// import Footer3 from './common/Footer3';
// import Header3 from './common/Header3';
import image1 from './images/ResearchPage.jpg';
// import UmedMe_WP_Final from './Files/UmedMe_WP_Final.pdf';
// import UMedMe_WPES from './Files/UMedMe_WPES.pdf';
import MyConstant from './config/MyConstant';
import { userTrack } from './config/MyFunctions';

export default class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            left: false,
            right: false
        }
    }

    componentDidMount() {
        userTrack("Research Page", "Landing");
    }

    onHover(type) {
        let left = false,
            right = false;
        if (type === "left") {
            left = true;
        } else if (type === "right") {
            right = true;
        }

        this.setState({
            left,
            right
        })
    }
    onLeave() {
        let left = false,
            right = false;

        this.setState({
            left,
            right
        })
    }

    render() {
        let { left, right } = this.state;
        return (
            <React.Fragment>
                <div className='footerbg'>
                    {/* <Header3 page="research" /> */}
                    <main>
                        <div className="row" style={{ backgroundColor: "#161616", height: "auto" }}>
                            <div className="col-sm-12 px-0">
                                {/* <span className="researchcss"><a href={UmedMe_WP_Final} download="UmedMe_WP_Final">Research Paper</a></span>
                                <span className="executivecss"><a href={UMedMe_WPES} download="UMedMe_WPES">Executive Summary</a></span> */}
                                <div className="right-icon"
                                    onMouseOver={() => this.onHover("left")}
                                    onMouseOut={() => this.onLeave()}
                                    onClick={() => {
                                        userTrack("Research Page", "Research Paper Click");
                                        window.open(MyConstant.keyList.projectUrl + "/umedme/Files/Umed_Me_Study_2021.pdf", "_blank")
                                    }}></div>
                                <div className="left-icon"
                                    onMouseOver={() => this.onHover("right")}
                                    onMouseOut={() => this.onLeave()}
                                    onClick={() => {
                                        userTrack("Research Page", "Executive Summary Click");
                                        window.open(MyConstant.keyList.projectUrl + "/umedme/Files/UmedMe_Executive_Summary.pdf", "_blank")
                                    }}></div>
                                <span className={right ? "executivecss active" : "executivecss"}><a href={MyConstant.keyList.projectUrl + "/umedme/Files/UMedMe_Executive_Summary.pdf"} target="_blank">Executive Summary</a></span>
                                <span className={left ? "researchcss active" : "researchcss"}><a href={MyConstant.keyList.projectUrl + "/umedme/Files/Umed_Me_Study_2021.pdf"} target="_blank">Research Paper</a></span>
                                <img className="umedme-8" src={image1} />
                            </div>
                        </div>
                    </main>
                    {/* <Footer3 /> */}
                </div>
            </React.Fragment>

        )
    }
}