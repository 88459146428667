import React, { Component } from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import SideManuBar from './SideMenuBar';
import image3 from '../images/Teqbahn_logo1.png';
import MyConfig from '../config/MyConfig';
import { dateConverter } from '../config/MyFunctions';
import { toast, ToastContainer } from "react-toastify";
import { loginStatusCheck, checkLogin } from '../config/MyFunctions';
import AdminLogout from "./AdminLogout"
import ManageLogout from "../manage/ManageLogout"
import ManageSideMenu from '../manage/ManageSideMenu';





class UiList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            action: "Add",
            label: "",
            value: "",
            uiJson: {},
            labelValidate: "",
            valueValidate: "",
            routingUrl: "",
            validateRouting: ""

        }
    }
    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        this.getUiData()
    }

    getUiData() {
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("ui-list");
        request.setMessage("{}");
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                this.setState({ uiJson: json.uiMap })
            }
        });
    }

    submit() {
        let { routingUrl } = this.state;
        let postJson = {
            label: this.state.label,
            value: this.state.value,
            routing: routingUrl
        };

        let labelValidate = ""
        let valueValidate = ""
        let validateRouting = ""

        if (!this.state.label) {
            labelValidate = "Enter Page Name"
        }

        if (!this.state.value) {
            valueValidate = "Enter Value "
        }

        if (!this.state.routingUrl) {
            validateRouting = "Enter Routing "
        }

        this.setState({ valueValidate, labelValidate, validateRouting })
        if (!this.state.label || !this.state.value || !this.state.routingUrl) {
            return false
        }

        console.log("postJson", postJson)

        const request = new ZiFetchRequest();
        request.setDomain('ui');
        request.setEvent('ui-add');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg.response == "Success") {
                    toast.success("UI Added successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({ label: "", value: "", routingUrl: "" })
                    this.getUiData()
                }
            }
        });
    }

    Update() {
        let postJson = {
            label: this.state.label,
            value: this.state.value,
            id: this.state.id,
            routing: this.state.routingUrl
        };

        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('ui');
        request.setEvent('ui-edit');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("UI Updated successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    this.setState({ action: "Add", label: "", value: "", routingUrl: "" })
                    this.getUiData()
                }
            }
        });

    }
    async edit(data) {
        this.setState({ action: "Edit", id: data["id"], label: data["label"], value: data["value"], routingUrl: data["routing"] ? data["routing"] : "" })
    }

    async delete(id) {
        let postJson = {
            id: id
        };
        let confrimMessage = window.confirm("Delete Page !")
        if (confrimMessage) {
            console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain('ui');
            request.setEvent('ui-delete');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.log("response", response)
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    console.log(responseMsg)
                    if (responseMsg.response == "Success") {
                        toast.success("UI Delete successfully!", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        this.getUiData()
                    }
                }
            });
        }

    }
    render() {
        let { menuActive, labelValidate, valueValidate, validateRouting } = this.state


        import('../admin/style.css');
        let that = this
        let uiJson = this.state.uiJson;

        let tableData = []
        Object.keys(uiJson).forEach(function (key, index) {
            let data = uiJson[key]
            let getRouting = data['routing'] ? data['routing'] : "-"
            tableData.push(
                <tr ><td>{data['label']}</td><td>{data['value']}</td> <td>{getRouting}</td><td>{dateConverter(data['createdAt'])}</td><td ><span className={"btn-sm btn-success"} onClick={() => that.edit(data)}>Edit</span></td><td ><span onClick={() => that.delete(data['id'])} className={"btn-sm btn-warning"}>Delete</span></td></tr>
            )
        });
        return (
            <div className="uilist">
                <div className='container'>
                    <div className='row '>
                        <div className='uilistHeader mt-2'>
                            <h2>{this.state.action} UI</h2>
                        </div>
                    </div>
                </div>
                <div className='container mt-3'>
                    <div className='row'>
                        <div className='col-md-1'></div>
                        <div className='col-md-3'>
                            <div className=""><label htmlFor="">Name</label></div>
                            <div className="">
                                <input type="text" placeholder={"Enter screen name"} className={"form-control"} name={"label"} value={that.state.label} onChange={e => {
                                    this.setState({ label: e.target.value })
                                }} />
                                <span className='error-txt'>{labelValidate} </span>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className=""><label htmlFor="">Value</label></div>
                            <div className="">
                                <input type="text" placeholder={"Enter screen value"} className={"form-control"} name={"value"} value={that.state.value} onChange={e => {
                                    this.setState({ value: e.target.value })
                                }} />
                                <span className='error-txt'> {valueValidate} </span>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className=""><label htmlFor="">Routing</label></div>
                            <div className="">
                                <input type="text" placeholder={"Enter screen routing"} className={"form-control"} name={"value"} value={that.state.routingUrl} onChange={e => {
                                    this.setState({ routingUrl: e.target.value })
                                }} />
                                <span className='error-txt'> {validateRouting} </span>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className='btnnadd'>
                                {
                                    this.state.action == "Add" ? <input type="button" className="btn btn-primary ml-auto" value="Create" onClick={() => this.submit()} /> : <input type="button" className="btn btn-primary ml-auto" value="Update" onClick={() => this.Update()} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-2'>
                    <div className='row '>
                        <div className='uilistHeader mt-3'>
                            <h2>UI List</h2>
                        </div>
                    </div>
                    <div className='row '>
                        <div className='col-md-12'>
                            <table className="table table-bordered table-striped" style={{ width: "100%", textAlign: "center" }} border={1} cellPadding={5}>
                                <tr><th scope="col">Name</th><th scope="col">Value</th>
                                    <th scope="col">Routing</th>
                                    <th scope="col">Created At</th><th scope="col">Edit</th><th scope="col">Delete</th></tr>
                                <tbody>
                                    {tableData}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UiList;