module.exports = {
  issueBeingTested: "Issue being tested",
  issue_Being_Tested: "Issue_being_tested",
  status: [
    { value: 'Pending', label: 'Pending' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Processing', label: 'Processing' },
    { value: 'Feature Addition', label: 'Feature Addition' },
    { value: 'Issue Fixed', label: 'Issue Fixed' },
    { value: 'Working on this Issue', label: 'Working on this Issue' },
    { value: "Issue being tested", label: "Issue being tested" },
    { value: 'Not an App Issue', label: 'Not an App Issue' },
    { value: 'Duplicate', label: 'Duplicate' },
    { value: 'Clarification Required', label: 'Clarification Required' },
    { value: 'Notes From Dev Team', label: 'Notes From Dev Team' },
  ],
  urgency: [
    { value: 'Moderate', label: 'Moderate' },
    { value: 'Not Urgent', label: 'Not Urgent' },
    { value: 'Urgent', label: 'Urgent' },
    { value: 'Slightly Urgent', label: 'Slightly Urgent' },
    { value: 'In Process/Waiting', label: 'In Process/Waiting' },
  ],
  statusList: ["Pending", "Processing", "Reopened", "Issue_being_tested", "Feature_Addition", "Completed", "Not_an_App_Issue", "Duplicate", "Clarification_Required", "Notes_From_Dev_Team", "Issue_Fixed"],
  contentAdminStatus: [{ value: 'Pending', label: 'Pending' },
  { value: 'Completed', label: 'Completed' },
  { value: 'Processing', label: 'Processing' },
  { value: 'Feature Addition', label: 'Feature Addition' },
  { value: 'Issue Fixed', label: 'Issue Fixed' },
  { value: "Issue being tested", label: "Issue being tested" },
  { value: 'Not an App Issue', label: 'Not an App Issue' },
  { value: 'Duplicate', label: 'Duplicate' },
  { value: 'Clarification Required', label: 'Clarification Required' },
  ],
  contentAdminstatusList: ["Pending", "Processing", "Reopened", "Issue_being_tested", "Feature_Addition", "Completed", "Not_an_App_Issue", "Duplicate", "Clarification_Required",],
  andriodOsVersion: [
    { value: 'Android 11', label: 'Android 11' },
    { value: 'Android 10', label: 'Android 10' },
    { value: 'Pie', label: 'Pie' },
    { value: 'Oreo', label: 'Oreo' },
    { value: 'Nougat (7.1.0 - 7.1.2)', label: 'Nougat (7.1.0 - 7.1.2)' },
    { value: 'Nougat (7.0)', label: 'Nougat (7.0)' },
    { value: 'Marshmallow', label: 'Marshmallow' },
    { value: 'Lollipop', label: 'Lollipop' },
    { value: 'Others', label: 'Others' },
  ],
  iosOsVersion: [
    { value: '15.3.1', label: '15.3.1' },
    { value: '15.4 Beta 2', label: '15.4 Beta 2' },
    { value: '14.8.1', label: '14.8.1' },
    { value: '13.7', label: '13.7' },
    { value: '12.5.5', label: '12.5.5' },
    { value: '11.4.1', label: '11.4.1' },
    { value: '10.3.4', label: '10.3.4' },
    { value: '10.3.3', label: '10.3.3' },
    { value: '9.3.6', label: '9.3.6' },
    { value: '8.4.1', label: '8.4.1' },
    { value: 'Others', label: 'Others' },
  ],
  contentOptions: [
    { value: 'approved', label: 'Approved' },
    { value: 'rejected', label: 'Rejected' },
  ],
  languageOptions: [
    { value: "eng", label: "English" },
    { value: "esp", label: "Spanish" },
  ],
  umedmeWebAppUrl: "https://umed.me/app/",
  authKey: "Teqbahn@321#",
  communityOption: [
    { label: "Casa Del Migrante", value: "casa_del_migrante" },
    { label: "Embajadores de Jesus", value: "ambassadors_of_jesus" },
    { label: "Umedme", value: "umedme" }
  ],
  caseSummaryAdminType: "admin"
};


//https://www.javatpoint.com/android-versions
//https://www.gkgigs.com/list-apple-ios-version-history/