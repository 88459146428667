import React, { Component } from 'react';
// import Pagination from "./Pagination"
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import image3 from '../images/Teqbahn_logo1.png';
import SideManuBar from '../admin/SideMenuBar';
import { Link } from 'react-router-dom';
import MyConstant from '../config/MyConstant';
import Table from './table/Table'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, Menu, MenuProvider } from "react-contexify";
import { v4 as uuid } from 'uuid';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { loginStatusCheck, checkLogin } from '../config/MyFunctions';
import AdminLogout from "../admin/AdminLogout"
import ManageSideMenu from '../manage/ManageSideMenu';
import ManageLogout from "../manage/ManageLogout"








class TableSheet extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            docsList: [],
            userId: "",
            croppedText: "dd",
            fullScreen: false,
            pdfScreen: false,
            noOfPages: 0,
            selectedPage: 1,
            pdfResponseData: "",
            src: null,
            croppedSize: {},
            columncount: 5,
            currentRowIndex: "",
            currentRowType: "",
            headerRow: [],
            valuesRow: [],
            headercells: [],
            valuecells: [],
            data: [],
            spreadData: {},
            sendSpreadData: {},
            columnCalJSON: {},
            editName: false,
            ruleName: "Untitled spreadsheet",
            crop: { unit: "%", width: 30, height: 20, },
            visible: false,
            x: 0,
            y: 0
        }
        this.setCellData = this.setCellData.bind(this)
        this.setRowsType = this.setRowsType.bind(this)
        this.setColumnSum = this.setColumnSum.bind(this)
        this.handleEvent = this.handleEvent.bind(this)
        this.removeRowsType = this.removeRowsType.bind(this)
    }
    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        if (login_status) {
            var self = this;
            this.refs.Progress1.addEventListener('contextmenu', function (event) {
                event.preventDefault();
                const clickX = event.clientX;
                const clickY = event.clientY;
                // alert(clickX);
                self.setState({ visible: true, x: clickX, y: clickY });

            });
            this.refs.Progress1.addEventListener('click', function (event) {
                event.preventDefault();
                self.setState({ visible: false, x: 0, y: 0 });

            });

            var userId = "admin";
            var docsId = this.props.match.params['docsId'];
            await this.setState({
                docsId: docsId,
                userId: userId
            });

            this.getDocumentDetails();
            this.getPdfResponseData(1);
        }

    }

    async getDocumentDetails() {
        let { userId, docsId } = this.state
        //sessionId:String,id: String, userId: String,docsId:String,ruleId:String,reqId:String
        let postJson = { sessionId: "1234", userId: userId, docsId: docsId, ruleId: "", }
        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('admin');
        request.setEvent('getDocumentParserDetails');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg.response) {
                    let responseResult = JSON.parse(responseMsg.response)
                    console.log("res", responseResult)
                    this.setState({ noOfPages: parseInt(responseResult["no_of_pages"]) })

                }
            }
        });
    }

    async getPdfResponseData(selectedPage) {
        var actionSample = "admin/base"
        var subPath = this.state.docsId + "/" + selectedPage
        var fileName = "page.png"
        let imgPath = MyConstant.keyList.apiURL + "vp?action=" + actionSample + "&key=" + fileName + "&id=" + subPath
        this.setState({ src: imgPath, selectedPage: selectedPage, })
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);


    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        var that = this;
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        var selectedPage = that.state.selectedPage
        var croppedSize = {
            page: selectedPage,
            sx: crop.x * scaleX,
            sy: crop.y * scaleY,
            sw: crop.width * scaleX,
            sh: crop.height * scaleY,
        };

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                that.setState({
                    postBlobImage: blob,
                    croppedSize: croppedSize
                })
                resolve(this.fileUrl);
            }, "image/png");
        });
    }

    async convertImageToText() {
        var image = this.state.postBlobImage;
        let uid = uuid();
        var userId = "admin";
        var sessionId = "1234";
        const data = new FormData();
        var projectName = "umedme";
        data.append('projectName', projectName);
        data.append('docsTitle', projectName);
        var file = new File([image], "fileName.png");
        data.append('file' + (1), file);
        data.append('fileName' + (1), "fileName.png");
        data.append('docsTitle', this.state.docsTitle);
        data.append('userId', userId);
        data.append('sessionId', sessionId);
        var getUidString = uid + "." + "png";
        var documentArrayString = [];
        documentArrayString.push(getUidString)
        // data.append('namesArrayString', JSON.stringify(documentArrayString));
        // console.log("*219*", JSON.stringify(documentArrayString))
        // var responseData = await doFileConnect("getTextFromImage", "POST", data);
        // console.log("responseData", responseData)
        // if (responseData != null && responseData != "" && responseData != "{}") {
        //     await this.setState({
        //         croppedText: responseData['responseMsg']
        //     })
        // }



        let dirPath = "cropedText"
        var newFileName = "fileName.png"
        var subPath = "admin"
        let fileData = new FormData();
        fileData.append('fileName1', newFileName);
        fileData.append('processType', dirPath);
        fileData.append('docsId', uid);
        fileData.append('userId', subPath);
        fileData.append('file1', file);

        var res = await fetch(MyConstant.keyList.apiURL + "uploads/" + dirPath + "/" + uid + "/" + subPath, {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: fileData,
        }).then((response) => response.json())
            .then((responseData) => {
                return responseData.response;
            })
            .catch(error => console.warn(error));

        console.log("res**", res)

        if (res == "Success") {

            var subPath = ""
            var fileName = uid + ".png"
            //userId:String, docsId: String,filePath:String
            let postJson = { userId: userId, docsId: uid, fileName: fileName, }
            console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain('admin');
            request.setEvent('getTextFromImage');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.log("response", response)
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    console.log("res", responseMsg)
                    if (responseMsg.response) {
                        await this.setState({ croppedText: responseMsg.response })
                    }
                }
            });
        }

    }


    setCellData(value, type, r, c, dataType, pageNo) {
        if (value != "" && value != null && value != undefined) {
            var that = this;
            var spreadData = that.state.spreadData;
            var sendSpreadData = that.state.sendSpreadData;
            var rowData = spreadData[r];
            if (rowData === undefined || rowData == null) {
                rowData = {};
            }
            var setCellType = ""
            var closeStatus = "false"
            var startCount = ""

            if (rowData[c] != undefined) {
                if (rowData[c].celltype != undefined) {
                    setCellType = rowData[c].celltype

                }

                if (rowData[c].closeStatus != undefined) {
                    closeStatus = rowData[c].closeStatus

                }


                if (rowData[c].startCount != undefined) {
                    startCount = rowData[c].startCount

                }
            }

            if (type == "obj") {

                rowData[c] = {
                    value: this.state.croppedText,
                    obj: this.state.croppedSize,
                    dataType: dataType,
                    celltype: setCellType,
                    closeStatus: closeStatus,
                    startCount: startCount,
                };
                var croppedSize = this.state.croppedSize;
                if (croppedSize.page != undefined) {
                    pageNo = croppedSize.page
                }
            }
            else {
                rowData[c] = {
                    value: value,
                    obj: {},
                    dataType: dataType,
                    celltype: setCellType,
                    closeStatus: closeStatus,
                    startCount: startCount,
                };
                pageNo = 1;
                //  console.log(JSON.stringify(rowData))

            }

            var sendPostData = sendSpreadData[pageNo];
            if (sendPostData === undefined || sendPostData == null) {
                sendPostData = {};
            }
            if (sendPostData[r] === undefined || sendPostData[r] == null) {
                sendPostData[r] = {};
            }
            sendPostData[r][c] = rowData[c]

            //sendPostData[r] = rowData;
            sendSpreadData[pageNo] = sendPostData;

            spreadData[r] = rowData;
            this.setState({
                spreadData: spreadData, sendSpreadData: sendSpreadData
            });
            console.log(JSON.stringify(sendSpreadData))
            //console.log(value+" -> "+type +" -> "+r + " -> "+ c+ " -> "+ dataType + " -> "+ JSON.stringify(sendSpreadData))
        }
        else {
            var that = this;
            var spreadData = that.state.spreadData;
            var sendSpreadData = that.state.sendSpreadData;
            var rowData = spreadData[r];
            if (rowData[c] != undefined) {
                pageNo = 1;
                if (rowData[c] != undefined && rowData[c] != null && rowData[c] != "") {
                    if (rowData[c].obj != undefined && rowData[c].obj != null && rowData[c].obj != "") {
                        if (rowData[c].obj.page != undefined && rowData[c].obj.page != null && rowData[c].obj.page != "") {
                            pageNo = rowData[c].obj.page;
                        }
                    }
                }
                delete spreadData[r][c];
                delete sendSpreadData[pageNo][r][c];
                console.log(JSON.stringify(spreadData))

                // sendPostData = spreadData
                // sendSpreadData[pageNo] = sendPostData;
                this.setState({
                    spreadData: spreadData, sendSpreadData: sendSpreadData
                });
                console.log(JSON.stringify(sendSpreadData))
            }
        }
    }

    setRowsType(index, type) {
        this.setState({ currentRowIndex: index, currentRowType: type, tooldivDisplay: true });
    }


    setColumnSum(headerIndex, headerName, action) {
        var columnCalJSON = this.state.columnCalJSON;

        if (columnCalJSON[headerIndex] == undefined) {
            columnCalJSON[headerIndex] = {};
        }

        var setData = columnCalJSON[headerIndex];

        setData["headerIndex"] = headerIndex;
        setData["action"] = action;
        setData["headerName"] = headerName;

        columnCalJSON[headerIndex] = setData

        this.setState({ columnCalJSON: columnCalJSON });

        console.log(JSON.stringify(columnCalJSON))

        toast.success('Column as summation set succesfully', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
    }

    handleEvent(index) {
        this.setState({
            currentRowIndex: index
        });
    }


    removeRowsType(rowindex, startCount, end) {
        var spreadData = this.state.spreadData;
        if (spreadData != undefined && spreadData != null) {
            for (let j = 0; j < 2; j++) {
                var row = parseInt(rowindex) + j;

                if (spreadData[row] != undefined && spreadData[row] != null) {
                    var rowData = spreadData[row];

                    for (var i = startCount; i <= end; i++) {
                        var columnData = rowData[i];

                        columnData["celltype"] = "";
                        columnData["closeStatus"] = "false";

                        rowData[i] = columnData;

                    }
                }

                spreadData[row] = rowData;
            }
        }
        this.setState({ spreadData: spreadData })
    }

    async submitRule() {
        //sessionId:String,ruleName: String, ruleJson: String,userId:String,docsId:String, columnCalJSON:String

        var that = this;
        var userId = "admin";
        var sessionId = "1234"
        var docsId = this.props.match.params['docsId'];
        var sendSpreadData = that.state.sendSpreadData;
        var columnCalJSON = that.state.columnCalJSON;

        var postJson = {
            sessionId: sessionId,
            ruleName: that.state.ruleName,
            ruleJson: JSON.stringify(sendSpreadData),
            rulesId: "",
            userId: userId,
            docsId: docsId,
            columnCalJSON: JSON.stringify(columnCalJSON),
        };

        console.log("postJson", postJson)

        const request = new ZiFetchRequest();
        request.setDomain('admin');
        request.setEvent('createRule');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                console.log("responseMsg", responseMsg)

                toast.success('Spreadsheet type created!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });

                setTimeout(function () {
                    that.props.history.push(MyConstant.keyList.projectUrl + "/admin/workspace")
                }, 1000)
            }
        });

    }

    async updateState(key, value) {
        await this.setState({
            [key]: value
        });
    }


    async setHeaderColumn() {
        var columncount = await this.state.setcolumnCount;
        var rowindex = await this.state.currentRowIndex;
        var startCount = await this.state.startCount;
        var currentRowType = await this.state.currentRowType;
        var spreadData = this.state.spreadData;

        var checkCellData = await this.checkCellData();

        if (spreadData[rowindex] != undefined && spreadData[rowindex] != null) {


            var columncount = parseInt(columncount);


            //  alert(checkCellData)

            for (let j = 0; j < 2; j++) {

                var row = parseInt(rowindex) + j;


                var rowData = spreadData[row];

                var start = parseInt(startCount);


                if (rowData == undefined) {
                    rowData = {};
                }


                for (var i = start; i < columncount + start; i++) {
                    if (rowData[i] != undefined) {

                        var columnData = rowData[i];


                        if (checkCellData) {

                            toast.success('Header already  added', {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });


                            return false;

                        }
                        else {

                            if (j == 0) {
                                columnData["celltype"] = "Key";

                                var end = columncount + start;

                                if (i + 1 == end) {
                                    columnData["closeStatus"] = "true";

                                    columnData["startCount"] = start;
                                }


                            }
                            else if (j == 1) {
                                columnData["celltype"] = "Value";

                                columnData["closeStatus"] = "false";


                                columnData["startCount"] = "";

                            }

                            rowData[i] = columnData;

                        }
                    }
                    else {

                        rowData[i] = {};
                        var columnData = rowData[i];


                        if (checkCellData) {
                            toast.success('Header already  added', {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });

                            return false;

                        }
                        else {
                            if (j == 0) {
                                columnData["celltype"] = "Key";

                                var end = columncount + start;

                                if (i + 1 == end) {
                                    columnData["closeStatus"] = "true";

                                    columnData["startCount"] = start;
                                }

                            } else if (j == 1) {
                                columnData["celltype"] = "Value";

                                columnData["closeStatus"] = "false";

                                columnData["startCount"] = "";

                            }
                        }
                        rowData[i] = columnData;

                    }


                }

                // console.log(columnData);


                spreadData[row] = rowData;

            }


        }
        else {

            //console.log( parseInt(rowindex) +"-->"+columncount)

            var columncount = parseInt(columncount);
            for (let j = 0; j < 2; j++) {

                var row = parseInt(rowindex) + j;
                spreadData[row] = {};

                var rowData = spreadData[row];

                var start = parseInt(startCount);
                for (var i = start; i < columncount + start; i++) {
                    rowData[i] = {};
                    var columnData = rowData[i];

                    if (j == 0) {
                        columnData["celltype"] = "Key";


                        var end = columncount + start;



                        if (i + 1 == end) {
                            columnData["closeStatus"] = "true";

                            columnData["startCount"] = start;
                        }


                    }
                    else if (j == 1) {
                        columnData["celltype"] = "Value";

                        columnData["closeStatus"] = "false";

                        columnData["startCount"] = "";


                    }

                    rowData[i] = columnData;

                }
                spreadData[row] = rowData;


            }


        }



        var pageNo = 0;
        // var sendSpreadData = this.state.sendSpreadData;
        var croppedSize = this.state.croppedSize;

        if (croppedSize.page != undefined) {
            pageNo = croppedSize.page
        }

        // sendSpreadData[pageNo] = spreadData;

        // console.log(JSON.stringify(spreadData));

        this.setState({ spreadData: spreadData, tooldivDisplay: false });

    }


    async checkCellData() {

        var columncount = await this.state.setcolumnCount;
        var rowindex = await this.state.currentRowIndex;
        var startCount = await this.state.startCount;
        var currentRowType = await this.state.currentRowType;
        var spreadData = this.state.spreadData;

        var checkValue = 0;

        for (let j = 0; j < 2; j++) {
            var row = parseInt(rowindex) + j;

            if (spreadData[row] != undefined && spreadData[row] != null) {

                //console.log(JSON.stringify(spreadData[row]))

                var columncount = parseInt(columncount);

                var rowData = spreadData[row];
                var start = parseInt(startCount);

                if (rowData == undefined) {
                    rowData = {};
                }


                for (var i = start; i < columncount + start; i++) {


                    if (rowData[i] != undefined) {

                        var columnData = rowData[i];

                        if (columnData.celltype == "Key" || columnData.celltype == "Value") {
                            checkValue = 1;

                        }
                    }

                }


            }

        }


        if (checkValue == 1) {
            return true;
        }
        else {
            return false;
        }



    }


    render() {
        let { menuActive, } = this.state
        import('../admin/style.css');

        var that = this
        var selectBoxArray = [];
        var noOfPages = this.state.noOfPages;
        var selectedPage = this.state.selectedPage;
        if (noOfPages > 0) {
            for (var i = 0; i < noOfPages; i++) {
                if ((i + 1) == selectedPage)
                    selectBoxArray.push(<option value={i + 1} selected>Page {i + 1}</option>);
                else
                    selectBoxArray.push(<option value={i + 1}>Page {i + 1}</option>);
            }
        }

        let { crop, src } = this.state;
        var row = 20;
        var col = 30;

        var classname = "col-sm-6 ";
        var classnamepdf = classname;
        var spreadHeight = 600
        var cropClass = "ReactCropPage"
        var popupCardCenter = "card popupCard"


        var cropBtn = <div className="buttonDesignNormal" onClick={() => this.convertImageToText()} > <span >COPY TEXT</span></div>;


        if (that.state.fullScreen) {
            classname = "col-sm-12";
            spreadHeight = "unset";
            popupCardCenter = "card popupCardCenter"

        }

        if (that.state.pdfScreen) {
            classnamepdf = "col-sm-12";
            cropClass = "";
            cropBtn = <div className="buttonDesign  cropBtn" onClick={() => this.convertImageToText()} > <span >C<br />O<br />P<br />Y<br />T<br />E<br />X<br />T</span></div>;
            classname = "col-sm-12 hideSpreadSheet"

        }

        var cellContextmenuOption = [];
        var cellContextmenu = null
        // if(this.props.croppedText!="")
        // {
        cellContextmenuOption.push(<Item onClick={() => { this.convertImageToText() }} >Copy Text</Item>)
        cellContextmenu = <Menu id={'textcopymenu'}>{cellContextmenuOption}</Menu>;
        //  }




        var manageIsLogin = localStorage.getItem("managelogedin")
        let returnMenu = <SideManuBar menuActive={menuActive} typeofPage={"workspace"} navigation={this.props.history} />
        let returnLogout = <AdminLogout navigation={this.props.history} />
        if (manageIsLogin == "manage") {
            returnMenu = <ManageSideMenu menuActive={menuActive} typeofPage={"workspace"} navigation={this.props.history} grpcClient={this.props.grpcClient} />
            returnLogout = <ManageLogout navigation={this.props.history} />
        }

        return (
            <>
                <div className={`wrapper ${manageIsLogin ? "manageBgColor" : ""}`}>
                    {returnMenu}
                    <div id="content">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <div className="container-fluid">
                                <button type="button" id="sidebarCollapse" className="btn btn-info" onClick={() => {
                                    if (!menuActive) {
                                        menuActive = "active"
                                    }
                                    else {
                                        menuActive = ""
                                    }

                                    this.setState({ menuActive })
                                }}>
                                    <i className="fas fa-align-left"></i>
                                    <span> {menuActive ? "Expand" : "Hide"} </span>
                                </button>
                                {returnLogout}
                            </div>
                        </nav>
                        <div className="body-content left-rigt-padding">
                            <div className='card card-table'>
                                <div className='row mb-4'>
                                    <div className='col-lg-12'>
                                        <Link to='/admin/workspace'>
                                            <a class="btn btn-primary btn-bg-clr">Back</a>
                                        </Link>
                                    </div>
                                </div>



                                {
                                    this.state.tooldivDisplay ?
                                        <div className={popupCardCenter} >
                                            <div className="form-group" show={true}>
                                                <div className="col-sm-12">
                                                    <label style={{ color: "#373757" }}>column count : </label>
                                                    <input type="input" name="setcolumnCount" className={"form-control hs-input"}
                                                        onChange={(e) => this.setState({ setcolumnCount: e.target.value })} />
                                                </div>

                                                <div className="col-sm-12">
                                                    <label style={{ color: "#373757" }}>Start count : </label>
                                                    <input type="input" name="setcolumnCount" className={"form-control hs-input"}
                                                        onChange={(e) => this.setState({ startCount: e.target.value })} />
                                                </div>

                                                <div className="col-sm-12" style={{ padding: "0, 0, 0, 15" }}>
                                                    <button className={"btn btn-sm btn-success"} style={{ marginBottom: 5 }} onClick={(e) => that.setHeaderColumn()}>Confirm</button>
                                                </div>
                                                <div className="col-sm-12" style={{ padding: "0, 0, 0, 15" }}>
                                                    <button style={{ marginBottom: 5 }} className={"btn btn-sm btn-danger"}
                                                        onClick={(e) => this.setState({ tooldivDisplay: false })}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }




                                <div className="row">


                                    {this.state.fullScreen == false ?
                                        <div className={classnamepdf}>
                                            <div className="borderStyle" id="Progress1" ref="Progress1">
                                                <div style={{ float: "right", marginRight: 20, marginTop: 17, fontSize: 20 }}>
                                                    <a href="#" onClick={() => {
                                                        that.setState({ pdfScreen: !that.state.pdfScreen })
                                                    }}>
                                                        <i className="ti-arrows-corner"></i></a></div>

                                                <div style={{ padding: 20, backgroundColor: "#F5F5F5" }}>
                                                    <div className="row">
                                                        <div className="col-sm-3">PDF Content</div>
                                                        <div className="col-sm-2">Page</div>
                                                        <div className="col-sm-4" >
                                                            <select style={{ width: 200 }} name="noOfPages" id="noOfPages"
                                                                onChange={(e) => {
                                                                    this.updateState("selectedPage", e.target.value);
                                                                    this.getPdfResponseData(e.target.value)
                                                                }}>
                                                                <option value="">Select Page</option>
                                                                {
                                                                    selectBoxArray
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-sm-6" >
                                                    {
                                                        (that.state.croppedSize != null && that.state.croppedSize != "") ? <span style={{ color: "#1947BE", fontSize: 12 }}>
                                                            <b>Position : {parseInt(that.state.croppedSize['sx'])} - {parseInt(that.state.croppedSize['sy'])}  Size : {parseInt(that.state.croppedSize['sw'])} - {parseInt(that.state.croppedSize['sh'])} </b> <br />

                                                        </span> : null
                                                    }

                                                    {/*cropBtn*/}

                                                </div>




                                                {(that.state.croppedSize != null && that.state.croppedSize != "" && that.state.croppedSize['sx'] != "") ?
                                                    <div className="react-contexify"
                                                        style={{ top: that.state.y, left: that.state.x, opacity: '1', zIndex: '1', display: that.state.visible ? 'block' : 'none' }}>
                                                        <div>
                                                            <div className="react-contexify__item" role="presentation">
                                                                <div onClick={() => this.convertImageToText()} className="react-contexify__item__content">Copy Text
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : null}

                                                {/*<p style={{textAlign: "center"}}><h4><br/></h4></p>*/}
                                                {src && (
                                                    <div className={cropClass}>
                                                        <ReactCrop
                                                            src={this.state.src}
                                                            crop={crop}
                                                            onImageLoaded={this.onImageLoaded}
                                                            onComplete={this.onCropComplete}
                                                            onChange={this.onCropChange}
                                                            crossorigin="Anonymous"
                                                        />
                                                    </div>
                                                )}

                                            </div>
                                        </div> : null
                                    }


                                    <div className={classname} >
                                        <div className="borderStyle">
                                            <div style={{ float: "right", marginRight: 20, marginTop: 17, fontSize: 20 }}><a href="#" onClick={() => { that.setState({ fullScreen: !that.state.fullScreen }) }}>
                                                <i className="ti-arrows-corner"></i></a> </div>
                                            <div style={{ padding: 20, backgroundColor: "#F5F5F5" }}>
                                                <div className="row">
                                                    <div className={"col-sm-6"}>
                                                        {
                                                            that.state.editName ?
                                                                <input type="text" name="ruleName" onBlur={() => that.setState({ editName: false })} placeholder={"Enter RuleName"} className={"form-control spreadSheetText"} value={that.state.ruleName} onChange={(e) => this.setState({ ruleName: e.target.value })} />
                                                                :
                                                                <span style={{ fontSize: 17 }} className={"spreadSheetText"} onClick={() => that.setState({ editName: true })}>{that.state.ruleName}</span>
                                                        }
                                                    </div>
                                                    <div className={"col-sm-6"} >
                                                        <a href={"javascript:void(0)"} style={{ float: "right", padding: 5 }} className={"buttonDesignNormal"} onClick={(e) => that.submitRule()}>Submit</a>
                                                    </div>
                                                </div>
                                            </div>


                                            <div style={{ with: "max-content", height: spreadHeight, overflowY: "auto", paddingTop: 10 }}>
                                                <Table x={col} y={row} setCellData={this.setCellData} croppedText={that.state.croppedText} setRowsType={that.setRowsType}
                                                    headerRow={that.state.headerRow} valuesRow={that.state.valuesRow}
                                                    spreadData={that.state.spreadData}
                                                    setColumnSum={that.setColumnSum}
                                                    handleEvent={that.handleEvent}
                                                    removeRowsType={that.removeRowsType} />
                                            </div>
                                            <br />
                                            <div className="row" >
                                                <div className="col-sm-10"></div>
                                                <div className="col-sm-2" >
                                                    <a href={"javascript:void(0)"} style={{ float: "right", padding: 5 }} className={"buttonDesignNormal"} onClick={(e) => that.submitRule()}>Submit</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <ToastContainer
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                                <ToastContainer />


                            </div>



                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default TableSheet;