import React, { Component } from 'react';
import SpeakUpIssuesList from './issues/SpeakUpIssuesList';
import UmedmeIssuesList from './issues/UmedmeIssuesList';

class IssuesList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            platform: "umedme"
        }
    }

    typeChange(platform) {
        this.setState({
            platform
        })
    }
    render() {
        let { platform } = this.state;
        return (
            <div className="body-content p-2">
                <div className="tabs-container">
                    <div className="custom-tabs">
                        <div className={`tab ${platform === "umedme" ? "active" : ""}`} onClick={() => {
                            this.typeChange("umedme")
                        }}
                        >
                            UmedMe
                        </div>
                        <div className={`tab ${platform === "speakup" ? "active" : ""}`} onClick={() => {
                            this.typeChange("speakup")
                        }}
                        >
                            SpeakUp
                        </div>
                    </div>
                    <div className="tabs-body">
                        {
                            platform === "speakup" ? <SpeakUpIssuesList grpcClient={this.props.speakupGrpcClient} /> : <UmedmeIssuesList grpcClient={this.props.grpcClient}/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default IssuesList;