import React from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import image3 from '../images/Teqbahn_logo1.png';
import MyConfig from '../config/MyConfig';
import { dateConverter } from '../config/MyFunctions';
import { toast, } from "react-toastify";
import { pageNumbers, checkLogin, readSessionData, resetLocalData, getUuidv4 } from '../config/MyFunctions';
import Pagination from "./Pagination"
import Select from './component/Select';
import whiteLogo from '../images/whiteLogo.png';
import Modal from "./component/Modal";
import { v4 as uuidv4 } from 'uuid';
import MyConstant from '../config/MyConstant';




class AddResource extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuActive: "",
            action: "Add",
            processing: false,
            prev: false,
            next: false,
            noPagesLimit: 1,
            pageLimit: 10,
            pagination: [],
            catagoryOption: [],
            catagorySelect: [],
            catgoryId: "",
            catagoryNameValidate: "",
            linkText: "",
            linkTextValidate: "",
            ArticleResourceJson: {},
            id: "",
            visibleClass: "",
            mUserId: "",
            session: "",
            modalShow: false,
            selectStatusOption: "",
            selectStatusOptionId: "",
            statusDescription: "",
            statusId: "",
            forWhoOptions: [],
            forWhoSelect: "",
            forWhoSelectId: "",
            titleText: "",
            titleTextValidate: "",
            document_Json: [],
            documentFiles: [],
            validateImg: "",
            displayShow: true,
            coverPhoto: "",
            sourceText: "",
            sourceTextValidate: "",
            viewDetails: {},
            viewType: "",
            languageOption: [],
            languageSelect: "",
            workSpaceOptions: [],
            workSpaceSelect: "",
            subCatagoryOption: [],
            subCatagorySelect: "",
            subCatagorySelectData: [],


        }
        this.myRef = React.createRef()
    }
    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        if (login_status) {
            document.body.style.backgroundColor = "#f6f7fb";

            let resData = await readSessionData()
            let { manage_session, manage_mUserId } = resData
            this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
                this.getResourceListAPI()
                this.getWorkSpaceList()
                this.getLanguageList()
            })
        }

    }


    getAllCatagoryTypeBasedOn() {
        let { mUserId, session, workSpaceSelect } = this.state
        let catagoryOption = []
        let postJson = { auth: MyConfig.authKey, mUserId, session, workspaceId: workSpaceSelect }
        //auth: String, 
        const request = new ZiFetchRequest();
        request.setDomain("resource");
        request.setEvent("getResourceCategoryListBasedOnWorkspace");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let response_Msg = json.response
                // console.log(json.result)
                if (response_Msg == "Success") {
                    let responseData = json.result
                    if (Object.keys(responseData).length > 0) {
                        Object.keys(responseData).map(key => {
                            let categoryName = responseData[key].categoryName
                            catagoryOption.push({ label: categoryName, value: key })
                        })
                    }
                    this.setState({
                        catagoryOption, catagorySelect: [], catgoryId: "", subCatagoryOption: [],
                        subCatagorySelect: "",
                    }, () => {

                    });
                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }


    getAllSubCatagoryTypeBasedOn() {
        let { mUserId, session, catgoryId } = this.state
        let subCatagoryOption = []
        let postJson = { catId: catgoryId, mUserId, session, }
        //auth: String, 
        const request = new ZiFetchRequest();
        request.setDomain("resource");
        request.setEvent("getAllResourceSubCategoryListBasedOnCategory");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let response_Msg = json.response
                // console.log(json.result)
                if (response_Msg == "Success") {
                    let responseData = json.result
                    if (Object.keys(responseData).length > 0) {
                        Object.keys(responseData).map(key => {
                            let subCategoryName = responseData[key].subCategoryName
                            subCatagoryOption.push({ label: subCategoryName, value: key })
                        })
                    }
                    this.setState({
                        subCatagoryOption, subCatagorySelect: "", subCatagorySelectData: []
                    }, () => {

                    });
                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }

    async submit() {
        let { catgoryId, linkText, mUserId, session, document_Json, titleText, sourceText, languageSelect, workSpaceSelect, subCatagorySelect } = this.state
        //catId: String,subCatId:String,articleData: String
        var that = this
        let fileList = [];

        let catagoryNameValidate = ""
        let linkTextValidate = ""
        let titleTextValidate = ""

        let sourceTextValidate = ""
        let linkValidate = false
        let languageValidate = ""
        let workSpaceValidate = ""
        let validateImg = "";
        let subCatagoryNameValidate = ""


        if (!workSpaceSelect) {
            workSpaceValidate = "Choose workspace"
        }

        if (!languageSelect) {
            languageValidate = "Choose language"
        }

        if (!catgoryId) {
            catagoryNameValidate = "Choose Category Name"
        }
        if (!subCatagorySelect) {
            subCatagoryNameValidate = "Choose Sub Category Name"
        }
        if (!linkText) {
            linkTextValidate = "Please Enter Link"
            linkValidate = true
        }
        else if (linkText) {
            let res = await this.validateURL(linkText)
            if (!res) {
                linkTextValidate = "Please Enter Link"
                linkValidate = true
            }
        }
        if (!titleText) {
            titleTextValidate = "Please Enter Title"
        }

        if (!sourceText) {
            sourceTextValidate = "Please Enter Source"
        }

        if (document_Json.length === 0) {
            validateImg = "Please choose file";
        }
        this.setState({
            catagoryNameValidate, linkTextValidate, titleTextValidate, sourceTextValidate,
            workSpaceValidate, languageValidate, validateImg, subCatagoryNameValidate
        })
        if (!catgoryId || linkValidate || !titleText || !sourceText || !workSpaceSelect || !languageSelect || document_Json.length === 0 || !subCatagorySelect) {
            return false
        }

        let imgFilePath = "";
        for (var i = 0; i < document_Json.length; i++) {
            await that.sendPostJson(document_Json[i], i);
            let fileDetails = document_Json[i];
            fileList.push(JSON.stringify({
                origFileName: fileDetails.origFileName,
                fileName: fileDetails.fileName,
                docsId: fileDetails.docsId,
                processType: fileDetails.processType,
                fileType: fileDetails.fileType
            }));
            imgFilePath = fileDetails.fileName;
        }

        let postJson = {
            workspaceId: workSpaceSelect,
            catId: catgoryId,
            link: linkText,
            title: titleText,
            coverPhoto: imgFilePath,
            resourceData: "",
            mUserId,
            session,
            source: sourceText,
            langCode: languageSelect,
            subCatId: subCatagorySelect

        };

        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('resource');
        request.setEvent('addResourceData');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Resource Added successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({
                        catagorySelect: [], linkText: "", titleText: "", document_Json: [], documentFiles: [],
                        sourceText: "",
                        workSpaceSelect: "",
                        languageSelect: "", subCatagorySelect: "", subCatagorySelectData: []
                    }, () => {
                        this.getResourceListAPI()
                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }



    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }
        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getResourceListAPI()
            })
        }
    }
    handleChange(e) {
        this.setState({ catagorySelect: e, catgoryId: e.value }, () => {
            this.getAllSubCatagoryTypeBasedOn()
        })
    }



    getResourceListAPI() {
        let { noPagesLimit, pageLimit, mUserId, session } = this.state
        let postJson = { auth: MyConfig.authKey, pageLimit: pageLimit, noOfPage: noPagesLimit, mUserId, session }
        //auth: String, pageLimit: Int, noOfPage:Int
        const request = new ZiFetchRequest();
        request.setDomain("resource");
        request.setEvent("getResourceDataList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                // console.log("json", json)
                let response_Msg = json.response
                if (response_Msg == "Success") {
                    let next = false;
                    let prev = false;
                    let totalResult = json.totalResult
                    let currentResult = noPagesLimit * 10;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noPagesLimit > 1) {
                        prev = true;
                    }

                    let pageCount = Math.ceil(totalResult / 10);
                    let pagination = pageNumbers(pageCount, noPagesLimit, 5);


                    this.setState({
                        prev,
                        next,
                        pagination: pagination,
                        processing: false,
                        ArticleResourceJson: json.result
                    });
                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }

    async edit(data) {
        // console.log(data)
        this.scrollToTop()
        let catagorySelect = []
        let coverImg = ""
        let document_Json = []
        let documentFiles = []
        let subCatagorySelectData = []
        if (data["coverPhoto"]) {
            coverImg = data["coverPhoto"]
            coverImg = coverImg.split(".")
            let imgObj = { docsId: coverImg[0], fileType: coverImg[1], processType: "resourceCoverPhoto" }
            document_Json.push({ ...imgObj, progress: 100, status: 'active' })
            documentFiles.push(imgObj)
        }
        catagorySelect.push({ label: data.categoryName, value: data.catId })
        subCatagorySelectData.push({ label: data.subCategoryName, value: data.subCatId })

        await this.setState({
            id: data.id, linkText: data["link"], catagorySelect, catgoryId: data.catId, subCatagorySelect: data["subCatId"], subCatagorySelectData, action: "Edit", visibleClass: "bgClrChg", titleText: data["title"], documentFiles, document_Json, displayShow: false, coverPhoto: data["coverPhoto"], sourceText: data["source"],
            languageSelect: data["langCode"], workSpaceSelect: data["workspaceId"],
        })

        setTimeout(() => {
            this.setState({ visibleClass: "" })
        }, 1000);
    }

    async update() {
        let { linkText, mUserId, session, titleText, document_Json, coverPhoto, sourceText,
        } = this.state
        //id: String, articleData: 



        let linkTextValidate = ""
        let titleTextValidate = ""
        let sourceTextValidate = ""
        let linkValidate = false;
        let validateImg = "";

        if (!linkText) {
            linkTextValidate = "Please Enter Link"
            linkValidate = true
        }
        else if (linkText) {
            let res = await this.validateURL(linkText)
            if (!res) {
                linkTextValidate = "Please Enter Link"
                linkValidate = true
            }
        }
        if (!titleText) {
            titleTextValidate = "Please Enter Title"
        }

        if (!sourceText) {
            sourceTextValidate = "Please Enter Source"
        }
        if (document_Json.length === 0) {
            validateImg = "Please Choose Image"
        }


        this.setState({ linkTextValidate, titleTextValidate, sourceTextValidate, validateImg })
        if (linkValidate || !titleText || !sourceText || document_Json.length === 0) {
            return false
        }

        let imgFilePath = coverPhoto
        let fileList = []
        var that = this
        for (var i = 0; i < document_Json.length; i++) {

            if (document_Json[i].path) {
                await that.sendPostJson(document_Json[i], i);
                let fileDetails = document_Json[i];
                fileList.push(JSON.stringify({
                    origFileName: fileDetails.origFileName,
                    fileName: fileDetails.fileName,
                    docsId: fileDetails.docsId,
                    processType: fileDetails.processType,
                    fileType: fileDetails.fileType
                }));
                imgFilePath = fileDetails.fileName;
            }

        }
        let postJson = {
            articleData: "",
            id: this.state.id,
            coverPhoto: imgFilePath,
            resourceData: "",
            link: linkText,
            title: titleText,
            mUserId, session,
            source: sourceText,

        };

        // console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('resource');
        request.setEvent('updateResourceData');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                let response_Msg = responseMsg.response
                // console.log(responseMsg)
                if (response_Msg == "Success") {
                    toast.success("Article Data Updated!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({
                        action: "Add", catagorySelect: [], linkText: "", subCataforWhoSelectId: "", titleText: "", document_Json: [], documentFiles: [], sourceText: "",
                        workSpaceSelect: "", languageSelect: "", subCatagorySelect: "", subCatagorySelectData: []
                    }, () => {
                        this.getResourceListAPI()
                    })

                }
                else if (response_Msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });

    }

    delete(data) {
        console.log(data)
        let { mUserId, session, } = this.state
        let confirmMessage = window.confirm("Are You Sure Delete Resource ?")
        //id: String,catId: String,subCatId:String
        let postJson = {
            id: data["id"],
            workspaceId: data["workspaceId"],
            langCode: data["langCode"],
            catId: data["catId"],
            subCatId: data["subCatId"],
            mUserId, session,
        };
        // console.log(data)
        if (confirmMessage) {
            console.log("postJson", postJson)

            const request = new ZiFetchRequest();
            request.setDomain('resource');
            request.setEvent('deleteResourceData');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.log("response", response)
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    // console.log(responseMsg)
                    if (responseMsg.response == "Success") {
                        toast.success("Resource Data Deleted.", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        this.getResourceListAPI()
                    }
                    else if (responseMsg.response == "Invalid Session Request") {
                        toast.warning("Your session got expired!", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        resetLocalData(this.props)
                    }

                }
            });
        }

    }
    scrollToTop() {
        this.myRef.current.scrollTo(0, 0);
    }
    async updateStatus() {
        let { mUserId, session, statusId, statusDescription, selectStatusOptionId } = this.state
        //id: String, status: String, mUserId: String, session: String
        let postJson = {
            id: statusId,
            status: selectStatusOptionId,
            statusDescription: statusDescription ? statusDescription : "",
            mUserId, session,
        };

        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('resource');
        request.setEvent('updateResourceStatus');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Status Updated", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({ statusDescription: "", selectStatusOption: "", selectStatusOptionId: "" }, () => {
                        this.getResourceListAPI()
                    })
                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });

    }

    documentOnDrop(newFiles) {
        // console.log("newFiles", newFiles)

        let validateImg = ""
        var documentFiles = [];
        var document_Json = [];
        var _validFileExtensions = ["jpg", "jpeg", "png"];

        for (var i = 0; i < newFiles.length; i++) {
            let file = newFiles[i];
            let filename = file.name;
            let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);

            if (_validFileExtensions.includes(ext)) {
                let uid = uuidv4();
                documentFiles.push(newFiles[i]);
                document_Json.push({
                    name: filename,
                    path: newFiles[i],
                    status: 'active',
                    progress: '0',
                    origFileName: filename,
                    fileName: uid + "." + ext,
                    docsId: uid,
                    processType: "resourceCoverPhoto",
                    fileType: ext
                })
            }
            else {
                validateImg = "Please Upload  Image Files Only"
            }

        }
        this.setState({
            documentFiles: documentFiles, document_Json: document_Json, validateImg
        });
    }

    async sendPostJson(documentArray, i) {
        var that = this;
        var document_Json = this.state.document_Json;
        document_Json[i].progress = 50;
        that.setState({
            document_Json: document_Json
        })
        if (document_Json.length > 0) {
            await that.uploadDocumentFiles(documentArray, i);
            document_Json[i].progress = 100;
            that.setState({
                document_Json: document_Json
            })
        }
    }

    async uploadDocumentFiles(documentArray, i) {
        const postFileUpload = new FormData();
        postFileUpload.append('file' + (i + 1), documentArray.path);
        postFileUpload.append('fileName' + (i + 1), documentArray.fileName);
        postFileUpload.append('processType' + (i + 1), documentArray.processType);
        postFileUpload.append('docsId' + (i + 1), documentArray.docsId);
        let result = await fetch(MyConstant.keyList.apiURL + "fileUpload/" + documentArray.processType + "/" + documentArray.docsId + "/cover", {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        }).then(
            function (response) {
                return response.json();
            }
        ).then(json => {
            return json.response;
        }).catch(error => console.warn(error));

        return result
    }







    humanDateFormat(time) {
        return new Date(time).toLocaleString()
    }

    viewContent() {
        let { viewType, viewDetails, workSpaceOptions, languageOption, mUserId } = this.state;
        console.log(viewDetails)
        let languageName = ""
        let workSpaceName = ""
        if (viewDetails.workspaceId) {
            workSpaceName = workSpaceOptions.filter((e => { return e.value === viewDetails.workspaceId }))
            if (workSpaceName.length !== 0) {
                workSpaceName = workSpaceName[0].label
            }
            else {
                workSpaceName = ""
            }

        }
        // console.log(viewDetails.langCode)
        if (viewDetails.langCode) {
            languageName = languageOption.filter((e => { return e.value === viewDetails.langCode }))
            if (languageName.length !== 0) {
                languageName = languageName[0].label
            } else {
                languageName = ""
            }

        }
        let buildImageUrl = "";
        if (viewDetails.coverPhoto) {
            let split = viewDetails.coverPhoto.split(".");
            let ext = split[1];
            let docsId = split[0];
            buildImageUrl = MyConstant.keyList.apiURL + "vp?action=resourceCoverPhoto&key=" + docsId + "." + ext + "&id=cover"
        }

        let buildContent;
        switch (viewType) {
            case "catagoryname":
                buildContent = <div>{viewDetails.categoryName}</div>
                break;
            case "link":
                buildContent = <div style={{ color: "#47a8e6", cursor: "pointer" }} onClick={() => { window.open(viewDetails.link, "_blank"); }}>{viewDetails.link}</div>
                break;
            case "status":
                buildContent = <div>{viewDetails.status}</div>
                break;
            case "title":
                buildContent = <div>{viewDetails.title}</div>
                break;
            case "source":
                buildContent = <div>{viewDetails.source}</div>
                break;
            case "workspace":
                buildContent = <div>{viewDetails.workspaceId && <>
                    {workSpaceName}
                </>}</div>
                break;
            case "language":
                buildContent = <div>{viewDetails.langCode && <>
                    {languageName}
                </>}</div>
                break;
            case "coverphoto":
                buildContent = <div>{viewDetails.langCode && <>
                    <img src={buildImageUrl} alt="no img" style={{
                        width: 100, height: 100,
                    }} />
                </>}</div>
                break;
            case "createby":
                buildContent = <div>{viewDetails.info.email}</div>
                break;
            case "published":
                buildContent = <div>{this.humanDateFormat(viewDetails.createdAt)}</div>
                break;

        }

        return buildContent;
    }

    validateURL(textval) {
        var urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
        return urlregex.test(textval);
    }


    getWorkSpaceList() {
        let postJson = { auth: MyConfig.authKey }
        //auth: String, pageLimit: Int, noOfPage:Int       
        const request = new ZiFetchRequest();
        request.setDomain("workspace");
        request.setEvent("getWorkspaceList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.result && Object.keys(json.result).length != 0) {
                    let workSpaceOptions = [];
                    Object.keys(json.result).map((e) => {
                        let { name } = json.result[e]
                        workSpaceOptions.push({ label: name, value: e })
                    })
                    this.setState({
                        workSpaceOptions,
                    });
                }
                else if (json.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }

    getLanguageList() {
        let { grpcClient } = this.props
        let postJson = { reqId: getUuidv4(), };
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("getLanguagesData");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let languageOption = []
                if (json && json.uiMap && Object.keys(json.uiMap).length !== 0) {
                    Object.keys(json.uiMap).map((key) => {
                        let lan = json.uiMap[key]
                        var obj = JSON.parse(lan)
                        languageOption.push({ label: obj["name"], value: key })
                    })
                }
                this.setState({
                    languageOption
                });

            }
        });
    }



    render() {
        let { pagination, next, prev, noPagesLimit, catagoryOption, catagorySelect, catagoryNameValidate, linkText, linkTextValidate, ArticleResourceJson, visibleClass, modalShow, selectStatusOption, statusDescription, titleText, titleTextValidate, displayShow, sourceText, sourceTextValidate, viewType, viewModal, workSpaceOptions, workSpaceSelect, languageOption, languageSelect, workSpaceValidate, languageValidate, subCatagorySelect, subCatagoryOption, subCatagoryNameValidate, subCatagorySelectData } = this.state
        import('../admin/style.css');
        let that = this

        var manageIsLogin = localStorage.getItem("managelogedin")
        let tableData = []
        Object.keys(ArticleResourceJson).forEach(function (key, index) {
            let data = ArticleResourceJson[key]
            let info = data.info
            // console.log(data)
            let linkData = ""
            let CatName = data["categoryName"]

            let text = ""
            if (data["status"]) {
                text = data["status"].charAt(0).toUpperCase() + data["status"].slice(1);
            }
            linkData = data["link"]
            let workSpaceName = ""
            if (data.workspaceId && workSpaceOptions.length > 0) {
                workSpaceName = workSpaceOptions.filter((e => { return e.value === data.workspaceId }))
                if (workSpaceName.length !== 0) {
                    workSpaceName = workSpaceName[0].label
                }
                else {
                    workSpaceName = ""
                }

            }

            tableData.push(
                <tr style={{ width: "100%" }}>
                    <td className='tabletxt' >{workSpaceName}</td>
                    <td className='tabletxt' >{CatName}</td>
                    <td className='tabletxt' >{data.subCategoryName}</td>
                    <td className='tabletxt' style={{ color: "#47a8e6", cursor: "pointer" }} onClick={() => { window.open(linkData, "_blank"); }}>
                        <span>{linkData.substring(0, 30)} {linkData.length > 30 && <span>...<a className="read-more-comp" ></a></span>}</span>
                    </td>
                    {/* <td className='tabletxt' >{text}</td> */}
                    {<td className='tabletxt'  >
                        <div className={"editBtnstyle"} style={{ backgroundColor: "#007bff", backgroundImage: "none" }} onClick={() => {
                            that.setState({ modalShow: true, statusId: data['id'], selectStatusOptionId: data["status"], selectStatusOption: { label: text, value: data["status"] }, statusDescription: data["statusDescription"] })
                        }}>{"Update Status"}</div>
                    </td>}
                    <td className='tabletxt' >{
                        info != null ? <p>{info.mUserName} - {info.email}</p> : null
                    }</td>
                    <td className='tabletxt' >{dateConverter(data['createdAt'])}</td>
                    <td className='tabletxt'>
                        <button className="btn btn-primary" onClick={() => {
                            that.setState({ viewDetails: data, viewModal: true, viewType: "articleusertype" })
                        }}>View</button>
                    </td>
                    <td className='fofamily' style={{ width: "10%" }} ><div className={"editBtnstyle"} onClick={() => that.edit(data)}>Edit</div></td>
                    <td className='fofamily' style={{ width: "10%" }} ><div className={"deleteBtnstyle"} onClick={() => that.delete(data)}>Delete</div></td>
                </tr>
            )
        });

        let compnayLogo = image3
        if (manageIsLogin) {
            compnayLogo = whiteLogo
        }


        return (
            <div className="body-content left-rigt-padding cat-style" ref={this.myRef}>
                <main className="app-main">
                    <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>
                        <div className="container">
                            <div className={(visibleClass ? visibleClass : "") + " card box-shadow"} style={{ padding: 0 }}>
                                <div className="card-header border-btm-style cursor-style" onClick={() => {
                                    this.setState({ displayShow: !displayShow })
                                }}>
                                    <div className='row'>
                                        <div className='col-10 col-sm-11 col-md-11'>
                                            <h5 className='header-style' >{this.state.action} Resource</h5>
                                        </div>
                                        <div className='col-2 col-sm-1 col-md-1'>
                                            <span style={{ float: "right", color: "#fff", }}>
                                                {displayShow ? <i class="fa fa-plus"></i> : <i class="fa fa-minus"></i>}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body" style={displayShow ? { display: "none" } : {}}>
                                    <div className="row my-2">
                                        <div className="col-md-3">
                                            <label className='fw'>Workspace</label>
                                        </div>
                                        <br />
                                        <div className="col-md-6">
                                            <Select
                                                options={workSpaceOptions}
                                                isSearchable={true}
                                                placeholder="Select WorkSpace"
                                                onChange={(e) => {
                                                    this.setState({ workSpaceSelect: e.value }, () => {
                                                        this.getAllCatagoryTypeBasedOn()
                                                    })

                                                }}
                                                value={workSpaceOptions.filter((e => { return e.value === workSpaceSelect }))}
                                                isDisabled={this.state.action == "Add" ? false : true}
                                            />
                                            <span className='error-txt'>{workSpaceValidate} </span>
                                        </div>
                                    </div>
                                    <br />

                                    <div className="row my-2">
                                        <div className="col-md-3">
                                            <label className='fw'>Category Name</label>
                                        </div>
                                        <br />
                                        <div className="col-md-6">
                                            <Select
                                                options={catagoryOption}
                                                isSearchable={true}
                                                placeholder="Select Category Name"
                                                onChange={(e) => {
                                                    this.handleChange(e)

                                                }}
                                                value={catagorySelect}
                                                isDisabled={this.state.action == "Add" ? false : true}
                                            />
                                            <span className='error-txt'>{catagoryNameValidate} </span>
                                        </div>
                                    </div>
                                    <br />

                                    <div className="row my-2">
                                        <div className="col-md-3">
                                            <label className='fw'>Sub Category Name</label>
                                        </div>
                                        <br />
                                        <div className="col-md-6">
                                            <Select
                                                options={subCatagoryOption}
                                                isSearchable={true}
                                                placeholder="Select Sub Category Name"
                                                onChange={(e) => {
                                                    this.setState({ subCatagorySelect: e.value, subCatagorySelectData: e })
                                                }}
                                                value={subCatagorySelectData}
                                                isDisabled={this.state.action == "Add" ? false : true}
                                            />
                                            <span className='error-txt'>{subCatagoryNameValidate} </span>
                                        </div>
                                    </div>
                                    <br />
                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' htmlFor="">Link</label></div>
                                        <div className={"col-md-6"}>
                                            <input type="text" placeholder={"Enter Link "} className={"form-control inputRadius"} value={linkText} onChange={e => {
                                                this.setState({ linkText: e.target.value })
                                            }} />
                                            <span className='error-txt'>{linkTextValidate} </span>

                                        </div>
                                    </div>
                                    <br />
                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' htmlFor="">Title</label></div>
                                        <div className={"col-md-6"}>
                                            <input type="text" placeholder={"Enter Title "} className={"form-control inputRadius"} value={titleText} onChange={e => {
                                                this.setState({ titleText: e.target.value })
                                            }} />
                                            <span className='error-txt'>{titleTextValidate} </span>
                                        </div>
                                    </div>
                                    <br />



                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' htmlFor="">Source</label></div>
                                        <div className={"col-md-6"}>
                                            <input type="text" placeholder={"Enter Source "} className={"form-control inputRadius"} value={sourceText} onChange={e => {
                                                this.setState({ sourceText: e.target.value })
                                            }} />
                                            <span className='error-txt'>{sourceTextValidate} </span>
                                        </div>
                                    </div>
                                    <br />


                                    <div className="row my-2">
                                        <div className="col-md-3">
                                            <label className='fw'>Choose language</label>
                                        </div>
                                        <br />
                                        <div className="col-md-6">
                                            <Select
                                                options={languageOption}
                                                isSearchable={true}
                                                placeholder="Select language"
                                                onChange={(e) => {
                                                    this.setState({ languageSelect: e.value })
                                                }}
                                                value={languageOption.filter((e => { return e.value === languageSelect }))}
                                                isDisabled={this.state.action == "Add" ? false : true}
                                            />
                                            <span className='error-txt'>{languageValidate} </span>
                                        </div>

                                        <div className={"col-md-2"}>
                                            {
                                                this.state.action == "Add" ? <input type="button" className="btn mb-2  ml-auto addbuttonsty" value="Add" onClick={() => this.submit()} /> : <input type="button" className="btn  mb-2 ml-auto addbuttonsty" value="Update" onClick={() => this.update()} />
                                            }
                                            {this.state.action == "Edit" && <input type="button" className="btn mb-1 ml-auto btn-cancel-sty" value="Cancel" onClick={() => {
                                                this.setState({
                                                    action: "Add", catagorySelect: [], linkText: "", forWhoSelectId: "", forWhoSelect: "", titleText: "", document_Json: [], documentFiles: [], sourceText: "",
                                                    languageSelect: "", workSpaceSelect: "", subCatagorySelect: "", subCatagorySelectData: []
                                                })
                                            }} />}

                                        </div>
                                    </div>
                                    <br />

                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' htmlFor="">Cover Photo</label></div>
                                        <div className={"col-md-4"} style={{}}>
                                            <div className="image-upload-div inputRadius">
                                                <span style={{ padding: 5 }}>Upload</span>
                                                <input type="file" placeholder={"Upload"} className={"form-control inputRadius"} onChange={(event) => {
                                                    var files = event.target.files;
                                                    this.documentOnDrop(files)
                                                }} />

                                            </div>
                                            <br />
                                            <span className='error-txt'>{this.state.validateImg} </span>
                                        </div>
                                    </div>
                                    <br />

                                    <div className='row '>
                                        <div className='col-sm-6 col-md-3'></div>
                                        <div className='col-sm-6'>
                                            <div className='row my-2'>
                                                {
                                                    this.state.document_Json.map((f, loopIndex) => {
                                                        // console.log("**", f, typeof (f))
                                                        let filePath = ""
                                                        let fileName = ""
                                                        let fileType = ""
                                                        if (f.path && typeof (f.path) != undefined) {
                                                            filePath = URL.createObjectURL(f.path)
                                                            fileName = f.path.name
                                                            fileType = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
                                                        }
                                                        else {
                                                            let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + f.processType + "&key=" + f.docsId + "." + f.fileType + "&id=cover"
                                                            filePath = buildUrl
                                                            fileType = f.fileType
                                                        }
                                                        // console.log("filePath", filePath)

                                                        let returnData = <img src={filePath} alt="no img" style={{ width: 80, height: 80, cursor: "pointer" }}
                                                            onClick={() => {
                                                                let imagePreview = { filePath, fileType }
                                                                this.setState({ imagePreview, modalVisible: true })
                                                            }}
                                                        />
                                                        return (<>
                                                            <div className='col-sm-4 col-md-3'>
                                                                {returnData}
                                                                {/* <i className="fa fa-trash delete-upload-file" aria-hidden="true"
                                                                onClick={() => that.documentOnRemove(loopIndex)}

                                                            ></i> */}
                                                            </div>
                                                        </>)
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <br />

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-5">
                        <div className="card box-shadow" style={{ padding: 0 }}>
                            <div className="card-header border-btm-style" style={{ borderBottom: "none" }}>
                                <h5 className='header-style' >Resource List</h5>
                            </div>
                            <div className='row col-md-12'>
                                <div className="card-body table-responsive" style={{ padding: 2 }}>
                                    <table className="table table-style table-sm light-border-clr" style={{ width: "100%", textAlign: "center" }} border={1} cellPadding={7}>
                                        <tr className='upperCase'>
                                            <th scope="col fw">Workspace</th>
                                            <th scope="col fw">Category Name</th>
                                            <th scope="col fw">Sub Category Name</th>
                                            <th scope="col fw">Link</th>
                                            {/* <th scope="col fw">Status</th> */}
                                            {<th scope="col fw">pending/approval </th>}
                                            <th scope="col fw">Created By <br /> UserName - Email</th>
                                            <th scope="col fw">Published</th>
                                            <th scope="col fw">View</th>
                                            <th scope="col fw">Edit</th>
                                            <th scope="col fw">Delete</th>
                                        </tr>
                                        <tbody>
                                            {tableData}
                                        </tbody>
                                    </table>

                                    <Pagination
                                        pagination={pagination}
                                        currentPage={noPagesLimit}
                                        prev={prev}
                                        next={next}
                                        movePage={(type) => {
                                            this.movePage(type)
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>


                    {
                        viewModal && <Modal
                            visible={viewModal}
                            size={"modal-xl"}
                            closeModal={() => this.setState({ viewModal: false })}
                            heading={"View"}
                            body={<React.Fragment>
                                <div className="row">
                                    <div className="col-8">
                                        {
                                            this.viewContent()
                                        }
                                    </div>
                                    <div className="col-4">
                                        <div className="tabs-vertical">

                                            <div className={`tab ${viewType === "catagoryname" ? "active" : ""}`} onClick={() => this.setState({ viewType: "catagoryname" })}>Category Name</div>
                                            <div className={`tab ${viewType === "link" ? "active" : ""}`} onClick={() => this.setState({ viewType: "link" })}>Link</div>
                                            <div className={`tab ${viewType === "status" ? "active" : ""}`} onClick={() => this.setState({ viewType: "status" })}>Status</div>
                                            <div className={`tab ${viewType === "title" ? "active" : ""}`} onClick={() => this.setState({ viewType: "title" })}>Title</div>
                                            <div className={`tab ${viewType === "source" ? "active" : ""}`} onClick={() => this.setState({ viewType: "source" })}>Source</div>
                                            <div className={`tab ${viewType === "workspace" ? "active" : ""}`} onClick={() => this.setState({ viewType: "workspace" })}>Workspace</div>
                                            <div className={`tab ${viewType === "language" ? "active" : ""}`} onClick={() => this.setState({ viewType: "language" })}>Lanuguage</div>
                                            <div className={`tab ${viewType === "coverphoto" ? "active" : ""}`} onClick={() => this.setState({ viewType: "coverphoto" })}>Cover Photo</div>
                                            <div className={`tab ${viewType === "createby" ? "active" : ""}`} onClick={() => this.setState({ viewType: "createby" })}>created By</div>
                                            <div className={`tab ${viewType === "published" ? "active" : ""}`} onClick={() => this.setState({ viewType: "published" })}>Published</div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                            }
                        />
                    }


                    {
                        modalShow && <Modal
                            visible={modalShow}
                            closeModal={() => this.setState({ modalShow: false, statusDescription: "", selectStatusOption: "", selectStatusOptionId: "" })}
                            heading={`Update Status`}
                            body={<React.Fragment>

                                <div className="form-group">
                                    <label>Status</label>
                                    <Select
                                        value={selectStatusOption}
                                        onChange={(e) => {
                                            this.setState({
                                                selectStatusOption: e,
                                                selectStatusOptionId: e.value
                                            })
                                        }}
                                        options={MyConfig.contentOptions}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea placeholder={"Description"} className={"form-control inputRadius"} name={"label"} value={statusDescription} onChange={e => {
                                        this.setState({ statusDescription: e.target.value })
                                    }}
                                    />
                                </div>
                            </React.Fragment>
                            }
                            footer={<React.Fragment>
                                <button type="button" className="btn theme-bg-manage white-bold" onClick={() => {
                                    that.updateStatus()
                                }}>Save changes</button>
                            </React.Fragment>}
                        />
                    }

                </main>
            </div>
        )
    }
}

export default AddResource;