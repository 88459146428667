import React from 'react';
import Footer3 from './common/Footer3';
import Header3 from './common/Header3';
import image7 from './images/umedme-7_bg.png';
import image1 from './images/umedme-1-new.png';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ZiFetchRequest } from './protobuf/generated/ZiFetchService_grpc_web_pb';
import { Link } from 'react-router-dom';
import MyConstant from './config/MyConstant';

export default class Signup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            osType: "iOS"
        }
    }

    signup() {
        let { email, osType } = this.state;

        let validEmail = this.validateEmail(email);
        if (email === "" || !validEmail) {
            let message = "Please enter email.";

            if (email !== "" && !validEmail) {
                message = "Please enter valid email.";
            }
            toast.error(message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        } else {
            let postJson = { email, osType }
            const request = new ZiFetchRequest();
            request.setDomain('admin');
            request.setEvent('updateInviteData');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.warn("Invalid Request")
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    if (responseMsg.response === "Success") {
                        this.setState({
                            email: "",
                            osType: "iOS"
                        }, () => {
                            toast.success("SignUp Successfully.", {
                                position: "top-right",
                                autoClose: 1500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });
                        })
                    } else if (responseMsg.response === "exist") {
                        toast.error("Already exist.", {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });
                    }
                }
            });
        }
    }

    validateEmail(email) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    osTypeChange(value) {
        this.setState({
            osType: value
        })
    }

    render() {
        let { email, osType } = this.state;

        return <React.Fragment>
            <div className="top-body">
                <Header3 page="signup" />
                <ToastContainer />
                <main>
                    <div className="user-panel">
                        <div className="row">
                            <div className="col-sm-6 px-0 my-auto">
                                <img className="umedme-7" src={image7} />
                            </div>
                            <div className="col-sm-6 px-0 py-5" style={{ backgroundColor: "#FFF" }}>
                                <div className="login-logo my-3">
                                    <img className="img-fluid" src={image1} />
                                    <h3 className="mt-2">Signup to participate in user testing</h3>
                                </div>
                                <div className="login-form px-5 py-2">
                                    <div className="form-group">
                                        <label>Please Select Mobile Phone OS</label>
                                        <div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="osType" value="iOS" checked={osType === "iOS"} onChange={(e) => this.osTypeChange(e.currentTarget.value)} />
                                                <label className="form-check-label">iOS</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="osType" value="Android" checked={osType === "Android"} onChange={(e) => this.osTypeChange(e.currentTarget.value)} />
                                                <label className="form-check-label">Android</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>{ osType === "iOS" ? "Please use an Apple ID." : "Please use a Google Email ID that is used for Google Services on your Android phone."}</label>
                                        <input type="email" className="form-control" placeholder="Enter email" value={email} onChange={(e) => {
                                            this.setState({
                                                email: e.target.value
                                            })
                                        }} />
                                    </div>
                                    <div className="text-right mb-2">
                                        <Link to={MyConstant.keyList.projectUrl+"/app-feedback"}>Back to Login</Link>
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-block user-login-btn" onClick={() => this.signup()}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer3 />
            </div>
        </React.Fragment>;
    }
}
