import React, { Component } from 'react';
import { Link } from "react-router-dom";
// import Logo from '../images/UMEDME_logo_wht.png';
import Logo_mobile from '../images/newmobile.png';
import Logo from '../images/umedmeweblogo.png';
// import Logo_Text from '../images/logo/text-logo.png';
import MyConstant from '../config/MyConstant';


export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    menuClick() {
        window.scrollTo(0, 0);
    }
    render() {
        let {page} = this.props;
        return (
            <React.Fragment>
                <div id="header" className="fixed-top">
                    <nav className="navbar navbar-expand-md navbar-dark bg-dark navbar-container">
                        <Link className="navbar-brand" to={MyConstant.keyList.projectUrl+"/"} >
                            <div className="">
                                <img className="logo" src={Logo} />
                                {/* <img className="logo logo-text" src={Logo_Text} /> */}
                            </div>
                            <div className="logo-mobile">
                            <img className="img-fluid" src={Logo_mobile} width={200} style={{marginTop:10}}/>
                            </div>
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <ul className="navbar-nav ml-auto">
                                <li className={page === "home" ? "nav-item px-2 active" : "nav-item px-2"} >
                                    <Link to={MyConstant.keyList.projectUrl+"/"} className="nav-link" onClick={ () => this.menuClick() }>HOME</Link>
                                </li>
                                <li className={page === "speakup" ? "nav-item px-2 active" : "nav-item px-2"} >
                                    <a href="https://globalspeakupcoalition.org/" className="nav-link" target="_blank">SpeakUp</a>
                                </li>
                                <li className={page === "about" ? "nav-item px-2 active" : "nav-item px-2"} >
                                    <Link to={MyConstant.keyList.projectUrl+"/about"} className="nav-link" onClick={ () => this.menuClick() }>ABOUT</Link>
                                </li>
                                <li className={page === "videos" ? "nav-item px-2 active" : "nav-item px-2"} >
                                    <Link to={MyConstant.keyList.projectUrl+"/videos"} className="nav-link" onClick={ () => this.menuClick() }>VIDEOS</Link>
                                </li>
                                <li className={page === "research" ? "nav-item px-2 active" : "nav-item px-2"} >
                                    <Link to={MyConstant.keyList.projectUrl+"/research"} className="nav-link" onClick={ () => this.menuClick() }>RESEARCH</Link>
                                </li>
                                <li className={page === "press" ? "nav-item px-2 active" : "nav-item px-2"} >
                                    <Link to={MyConstant.keyList.projectUrl+"/press"} className="nav-link" onClick={ () => this.menuClick() }>PRESS</Link>
                                </li>
                                <li className={page === "app-feedback" ? "nav-item px-2 active" : "nav-item px-2"} >
                                    <Link to={MyConstant.keyList.projectUrl+"/app-feedback"} className="nav-link" onClick={ () => this.menuClick() }>FEEDBACK</Link>
                                </li>
                                <li className={page === "downloads" ? "nav-item px-2 active" : "nav-item px-2"} >
                                    <Link to={MyConstant.keyList.projectUrl+"/downloads"} className="nav-link" onClick={ () => this.menuClick() }>DOWNLOADS</Link>
                                </li>
                                {/* 
                                <li className={page === "contact" ? "nav-item px-2 active" : "nav-item px-2"} >
                                    <a className="nav-link" href="">CONTACT</a>
                                </li>
                                */}
                                {/* <li className={page === "signup" ? "nav-item px-2 active" : "nav-item px-2"}>
                                    <Link to={MyConstant.keyList.projectUrl+"/sign-up"}  className="nav-link" onClick={ () => this.menuClick() }>SIGNUP</Link>
                                </li>  */}
                            </ul>
                        </div>
                    </nav>
                </div>
            </React.Fragment>
        )
    }
}