import React, { component } from "react";
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import Pagination from "./Pagination"
import countries from "../json/countries.json"
import { getUuidv4, pageNumbers, readSessionData } from "../config/MyFunctions";
import { toast, ToastContainer } from "react-toastify";
import CountryCodeSelect from "./component/CountryCodeSelect";
import DropDown from "./component/DropDown";
import MyConfig from "../config/MyConfig";
import Modal from "./component/Modal";
import UserCommunityHistory from "./component/UserCommunityHistory";


let flowTypeOption = [
    { label: "Only Email", value: "email" },
    { label: "Only Pin", value: "pin" }
]

class UsersList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuActive: "",
            tableData: [],
            processing: true,
            search: "",
            resultArray: [],
            pagination: [],
            noPagesLimit: 1,
            userListData: [],
            communityName: "",
            countryCode: "",
            flowType: "",
            manage_session: "",
            manage_mUserId: "",
            mappingError: false,
            communityOption: [],
            updateUserModal: false,
            updateUserId: "",
            existUpdateCommunity: "",
            existUpdateCountry: "",
            updateCommunity: "",
            updateCountry: "",
        }
    }

    componentDidMount() {
        let resData = readSessionData()
        let { manage_session, manage_mUserId } = resData
        let loginRoleType = localStorage.getItem("login_RoleType")

        this.setState({
            manage_session,
            manage_mUserId
        }, () => {
            if (loginRoleType === "Community Admin") {
                this.getCommunityAdmin();
            } else {
                this.getAllUserList()
            }
            this.getCommunity()
        })
    }

    getCommunity() {
        let postJson = { reqId: getUuidv4() }
        const request = new ZiFetchRequest();
        request.setDomain("adminUserManage");
        request.setEvent("getCommunityList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request') {
                console.warn('error', 'Invalid Request. Please try again later', "");
            } else {
                let json = JSON.parse(response.getMessage());
                let communityOption = [];
                let data = json.result;
                let keys = Object.keys(data);
                for (let i = 0; i < keys.length; i++) {
                    let key = keys[i];
                    communityOption.push({
                        label: data[key].communityName,
                        value: key
                    })
                }
                this.setState({
                    communityOption
                })
            }
        })

    }

    getCommunityAdmin() {
        let { manage_mUserId } = this.state;
        let postJson = { mUserId: manage_mUserId };
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("getCommunityAdmin");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let { country, community } = json;
                if (country && community) {
                    this.setState({
                        communityName: community,
                        countryCode: country
                    }, () => {
                        this.getAllUserList()
                    })
                } else {
                    this.setState({
                        mappingError: true,
                        processing: false,
                    })
                }
            }
        });
    }
    getAllUserList() {
        let { tableData, totalPagesResults, noPagesLimit, communityName, countryCode, flowType } = this.state
        let DataResult = []
        let headerData = []
        let postJson = { pageLimit: 10, noOfPage: noPagesLimit, totalResult: 10, auth: "Teqbahn@321#", startDate: "", endDate: "" }
        if (communityName) {
            postJson.community = communityName
        }
        if (countryCode) {
            postJson.countryCode = countryCode
        }
        if (flowType) {
            postJson.flowType = flowType
        }

        const request = new ZiFetchRequest();
        request.setDomain('admin');
        request.setEvent('getUsersList');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid request")
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg && responseMsg.result) {
                    var responseData = responseMsg.result
                    totalPagesResults = responseMsg.totalResult
                    let pageCount = Math.ceil(totalPagesResults / 10);

                    let pagination = pageNumbers(pageCount, noPagesLimit, 5);
                    this.setState({
                        search: "",
                        pagination,
                        resultArray: [],
                        tableData: DataResult,
                        totalPagesResults,
                        processing: false,
                        headerData,
                        userListData: responseData
                    })
                }


            }
        });
    }

    onSearch = async (e) => {

        let { userListData } = this.state;
        let search = e.target.value.toString().toLowerCase();
        let resultArray = [];

        userListData.map(ival => {
            let isThere = false;
            Object.values(ival).map(jval => {
                if (jval !== null && jval.toString().toLowerCase().indexOf(search) !== -1) {
                    isThere = true;
                }
            })
            if (isThere) {
                resultArray.push(ival);
            }
        })

        await this.setState({ resultArray, search });


    }

    async movePage(page) {
        let pageNo = page;
        let { noPagesLimit } = this.state
        if (page === "next") {
            pageNo = noPagesLimit + 1;
        } else if (page === "prev") {
            pageNo = noPagesLimit - 1;
        }


        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getAllUserList()
            })
        }
    }

    liveUserMoveToTestUser(userId) {
        if (window.confirm("Are You Sure move to Test User?")) {
            let resData = readSessionData();
            let { manage_session, manage_mUserId } = resData;

            let postJson = { userId, mUserId: manage_mUserId, session: manage_session }
            const request = new ZiFetchRequest();
            request.setDomain('user');
            request.setEvent('liveUserMoveToTestUser');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.warn("Invalid request")
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    if (responseMsg.response === "Success") {
                        toast.success("Updated successfully.", {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });
                        this.getAllUserList()
                    }
                }
            });
        }
    }

    update() {
        let { updateUserId, updateCommunity, updateCountry } = this.state;
        let resData = readSessionData();
        let { manage_mUserId } = resData;

        let postJson = { userId: updateUserId, community: updateCommunity, countryCode: updateCountry, updateByUserId: manage_mUserId, updateByUserType: "admin" }
        const request = new ZiFetchRequest();
        request.setDomain('userManage');
        request.setEvent('updateUserCommunityAndCountry');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid request")
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg.response === "Success") {
                    toast.success("Updated successfully.", {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    this.setState({
                        updateUserModal: false,
                        updateUserId: "",
                        updateCommunity: "",
                        updateCountry: "",
                    }, () => {
                        this.getAllUserList()
                    })
                }
            }
        });
    }
    render() {
        let { mappingError, resultArray, search, pagination, noPagesLimit, totalPagesResults, userListData, processing, communityName, countryCode, flowType, communityOption, updateCommunity, updateCountry, updateUserModal, existUpdateCommunity, existUpdateCountry } = this.state
        import('./style.css');
        var SearchList = search.trim() || resultArray.length ? [...resultArray] : [...userListData];

        let pageCount = Math.ceil((totalPagesResults / 10))
        if (pageCount) {
        }
        else {
            pageCount = 1
        }
        let loginRoleType = localStorage.getItem("login_RoleType")

        return <div className="wrapper">
            <ToastContainer />
            <div id="content">

                <div className="body-content ">
                    {
                        mappingError ? <div className="m-5">
                            Please contact umed.me admin team to view this page.
                        </div> : <React.Fragment>
                            <div className="row">
                                {
                                    <React.Fragment>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Community Name</label>
                                                <DropDown
                                                    selectedOption={communityOption.filter(option => option.value === communityName)}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            communityName: e ? e.value : ""
                                                        }, () => {
                                                            this.getAllUserList()
                                                        })
                                                    }}
                                                    options={communityOption}
                                                    isDisabled={loginRoleType === "Community Admin"}
                                                    isMulti={false}
                                                    placeholder={"Select Community name"}
                                                    isClearable={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Country</label>
                                                <CountryCodeSelect
                                                    countryCode={countryCode}
                                                    onChange={(obj) => {
                                                        this.setState({
                                                            countryCode: obj ? obj.value : ""
                                                        }, () => {
                                                            this.getAllUserList()
                                                        })
                                                    }}
                                                    isClearable={true}
                                                    isDisabled={loginRoleType === "Community Admin"}

                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }

                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>Login Type</label>
                                        <DropDown
                                            selectedOption={flowTypeOption.filter(option => option.value === flowType)}
                                            onChange={(e) => {
                                                this.setState({
                                                    flowType: e ? e.value : ""
                                                }, () => {
                                                    this.getAllUserList()
                                                })
                                            }}
                                            options={flowTypeOption}
                                            isDisabled={false}
                                            isMulti={false}
                                            placeholder={"Select Login Type"}
                                            isClearable={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-2 ml-auto">
                                    <input type="text" placeholder="search" className="form-control" value={this.state.search} onChange={this.onSearch} />
                                </div>
                            </div>
                            <div className={`row ${processing && "table-processing"}`}>
                                {processing && <div className="processing"><span>Processing...</span></div>}
                                <div className="col-md-12">
                                    <table className="table table-bordered table-striped" id="scrolltop">
                                        <thead>
                                            <tr>
                                                <th scope="col">S.No</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">User Id</th>
                                                <th scope="col">Full Name</th>
                                                <th scope="col">Mobile Number</th>
                                                <th scope="col">Community</th>
                                                <th scope="col">Country</th>
                                                <th scope="col">Update Community/Country</th>
                                                <th scope="col">View History of Community Changed</th>
                                                <th scope="col">App Version</th>
                                                <th scope="col">Build Number</th>
                                                <th scope="col">Last Login</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                SearchList.length != 0 ? SearchList.map((data, index) => {
                                                    let appVersion = ""
                                                    let buildnumber = ""
                                                    let createAtDate = "";
                                                    let countryStr = "";
                                                    let communityStr = ""
                                                    if (data.deviceInfo) {
                                                        let dataParse = JSON.parse(data.deviceInfo)
                                                        appVersion = dataParse.appVersion
                                                        buildnumber = dataParse.buildNumber
                                                    }

                                                    if (data.createdAt) {
                                                        createAtDate = new Date(data.createdAt);
                                                    }

                                                    let { countryCode, community, lastLogin, loginId, userId } = data;
                                                    if (countryCode) {
                                                        let country = countries.countries.filter(option => option.value === countryCode)
                                                        countryStr = country.length > 0 ? country[0].label : "-"
                                                    }
                                                    if (community) {
                                                        let communityName = communityOption.filter(option => option.value === community)
                                                        communityStr = communityName.length > 0 ? communityName[0].label : "-"
                                                    }
                                                    return (
                                                        <tr key={index}>
                                                            <td>{((noPagesLimit - 1) * 10) + index + 1}</td>
                                                            {/* <td >{data.address}</td> */}
                                                            <td>{data.email}</td>
                                                            <td>{loginId ? loginId : "-"}</td>
                                                            <td>{data.fullName}</td>
                                                            <td>{data.mobileNumber}</td>
                                                            <td>{communityStr}</td>
                                                            <td>{countryStr}</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-success" onClick={() => {
                                                                    this.setState({
                                                                        updateUserModal: true,
                                                                        updateUserId: userId,
                                                                        updateCommunity: community,
                                                                        updateCountry: countryCode,
                                                                        existUpdateCommunity: community,
                                                                        existUpdateCountry: countryCode
                                                                    })
                                                                }}>Update</button>
                                                            </td>
                                                            <td>
                                                                <UserCommunityHistory communityOption={communityOption} coun userId={userId} {...this.props} />
                                                            </td>
                                                            <td>{appVersion}</td>
                                                            <td>{buildnumber}</td>
                                                            <td>{lastLogin ? new Date(lastLogin).toLocaleString() : "-"}</td>
                                                            <td>{createAtDate.toLocaleString()}</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-danger" onClick={() => {
                                                                    this.liveUserMoveToTestUser(data.userId)
                                                                }}>
                                                                    <i className="fa fa-trash" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : <tr>
                                                    <td colSpan={12} className="text-center">No users found.</td>
                                                </tr>
                                            }
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Pagination
                                        currentPage={noPagesLimit}
                                        pagination={pagination}
                                        prev={noPagesLimit == 1 ? false : true}
                                        next={noPagesLimit != pageCount ? true : false}
                                        movePage={(type) => {
                                            this.movePage(type)
                                        }} />
                                </div>
                            </div>
                        </React.Fragment>
                    }

                </div>
            </div>
            {
                updateUserModal && <Modal
                    visible={updateUserModal}
                    closeModal={() =>
                        this.setState({
                            updateUserModal: false,
                        })
                    }
                    heading={"Update Community/Country"}
                    body={<React.Fragment>
                        <div className="form-group">
                            <label>Community Name</label>
                            <DropDown
                                selectedOption={communityOption.filter(option => option.value === updateCommunity)}
                                onChange={(e) => {
                                    this.setState({
                                        updateCommunity: e ? e.value : ""
                                    })
                                }}
                                options={communityOption}
                                isMulti={false}
                                placeholder={"Select Community name"}
                                isClearable={false}
                            />
                        </div>
                        <div className="form-group">
                            <label>Country</label>
                            <CountryCodeSelect
                                countryCode={updateCountry}
                                onChange={(obj) => {
                                    this.setState({
                                        updateCountry: obj ? obj.value : ""
                                    })
                                }}
                                isClearable={false}
                            />
                        </div>
                    </React.Fragment>
                    }
                    footer={<React.Fragment>
                        <button type="button" className="btn theme-bg-manage white-bold" onClick={() => {
                            this.update()
                        }} disabled={updateCommunity === existUpdateCommunity && updateCountry === existUpdateCountry}>Save changes</button>
                    </React.Fragment>}
                />
            }
        </div>
    }
}

export default UsersList;

