import React, { Component } from 'react';
import Footer from '../../common/Footer3';
import Header from "./component/Header";
import VideosPage from '../../../src/VideosPage';

export default class NewVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            intervalId: "",
            sliderTransform: 0,
            sliderHover: false,
            NavResClass: "",
            pageLogin: false,
            showMedia: false,
            showDownload: false,
        }
        this.aboutImg = React.createRef()
        this.aboutImgParent = React.createRef()
    }

    async componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.body.style.backgroundColor = "#ffffff"


    }



    render() {
        return (
            <React.Fragment>
                <div className="umedme-version-3">
                    <Header page="media" />
                    <div className={""} >
                        <main>
                            <VideosPage grpcClient={this.props.grpcClient} history={this.props.history} />
                        </main>
                        <Footer />
                    </div>
                </div>

            </React.Fragment >
        )
    }
}