import React from "react";
import { pageNumbers } from '../config/MyFunctions';
import { ZiFetchRequest } from "../protobuf/generated/ZiFetchService_grpc_web_pb";
import Pagination from './Pagination';

export default class SpeakupUnsubscribeList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            project: "umedme",
            noPagesLimit: 1,
            resData: [],
            prev: false,
            next: false,
            processing: true,
            pagination: []
        }
        this.movePage = this.movePage.bind(this);
    }

    componentDidMount() {
        this.getSpeakupSubscribeList();
    }

    getSpeakupSubscribeList() {
        let { noPagesLimit } = this.state;
        let postJson = { pageLimit: 10, noOfPage: noPagesLimit };
        const request = new ZiFetchRequest();
        request.setDomain('speakUp');
        request.setEvent('getSpeakUpMailingRemovedList');
        request.setMessage(JSON.stringify(postJson));
        this.props.speakupGrpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() === 'Invalid Request' || response.getMessage() === 'Invalid request') {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg && responseMsg.response) {
                    let responseData = responseMsg.result
                    let next = false;
                    let prev = false;
                    let totalResult = responseMsg.totalResult
                    let currentResult = noPagesLimit * 10;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noPagesLimit > 1) {
                        prev = true;
                    }

                    let pageCount = Math.ceil(totalResult / 10);
                    let pagination = pageNumbers(pageCount, noPagesLimit, 5);
                    this.setState({ resData: responseData, prev, next, processing: false, pagination: pagination });
                }
            }
        });
    }

    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }

        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getSpeakupSubscribeList();
            })
        }
    }

    humanDateFormat(time) {
        return new Date(time).toLocaleString()
    }

    render() {
        let { project, pagination, prev, next, noPagesLimit, processing, resData } = this.state;
        return <div className="body-content p-top">
            <div className={`row mx-0 ${processing && "table-processing"}`}>
                {processing && <div className="processing"><span>Processing...</span></div>}
                <div className="col-sm-12">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Email</th>
                                <th scope="col">First Name</th>
                                <th scope="col">Last Name</th>
                                <th scope="col">Country</th>
                                <th scope="col">Organization</th>
                                <th scope="col">Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(resData).length === 0 ? <tr>
                                    <td className="text-center" colSpan={6}>No Record(s) Found.</td>
                                </tr> : Object.keys(resData).map((key, index) => {
                                    let data = resData[key];
                                    return <tr key={index}>
                                        <td>{data.email}</td>
                                        <td>{data.firstName}</td>
                                        <td>{data.lastName}</td>
                                        <td>{data.country}</td>
                                        <td>{data.organization}</td>
                                        <td>{this.humanDateFormat(data.createdAt)}</td>
                                    </tr>
                                })}
                        </tbody>

                    </table>
                    <Pagination
                        pagination={pagination}
                        prev={prev}
                        next={next}
                        currentPage={noPagesLimit}
                        movePage={(type) => {
                            this.movePage(type)
                        }} />
                </div>
            </div>
        </div>;
    }
}