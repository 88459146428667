import React, { Component } from 'react';
import Pagination from "../admin/Pagination"
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import Modal from '../AppIssues/Modal';
import MyConstant from '../config/MyConstant';
import Select from '../admin/component/Select';
import MyConfig from '../config/MyConfig';
import { toast, ToastContainer } from "react-toastify";
import { getIssueId, pageNumbers } from '../config/MyFunctions';
import { checkLogin, resetLocalData, readSessionData } from '../config/MyFunctions';


class AdminFeedbackStatus extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            issueList: [],
            menuActive: "",
            search: "",
            resultArray: [],
            noPagesLimit: 1,
            totalPagesResults: "",
            processing: true,
            pageLimit: 10,
            statusOption: MyConfig.status,
            selectedStatus: "",
            description: "",
            userId: "",
            viewModal: false,
            historyData: [],
            activeData: {},
            viewType: "",
            viewDetails: {},
            prev: false,
            next: false,
            pagination: [],
            editHistoryData: {},
            issueCountList: {},
            mUserId: "",
            session: ""
        }
        this.handleChange = this.handleChange.bind(this)
    }
    async componentDidMount() {
        var loginincheck = await checkLogin(this.props)
        let resData = await readSessionData()
        let { manage_session, manage_mUserId } = resData
        this.setState({ session: manage_session, mUserId: manage_mUserId })

        if (window.location.search) {
            let data_1 = window.location.search.split("?")
            let data_2 = data_1[1].split("&")
            let statusType = ""

            data_2.map((ival, i) => {
                let equalSplit = ival.split("=")
                if (equalSplit[1]) {
                    statusType = equalSplit[1]
                }
            })
            // console.log(statusType)

            if (statusType) {
                this.getIssueList(statusType)
            }


        }
    }

    getIssueList(statusType) {
        let { noPagesLimit, pageLimit, session, mUserId } = this.state
        let postJson = { auth: 'Teqbahn@321#', status: statusType, pageLimit, noOfPage: noPagesLimit, session, mUserId }
        console.log(postJson)
        const request = new ZiFetchRequest();
        request.setDomain('adminIssues');;
        request.setEvent('getAdminIssuesReportListBasedOnStatus');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid Request")
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                console.log(responseMsg)

                if (responseMsg.msg == "Success") {
                    if (responseMsg && responseMsg.response) {
                        let responseData = responseMsg.response
                        let next = false;
                        let prev = false;
                        let totalResult = responseMsg.totalResult
                        let currentResult = noPagesLimit * 10;
                        if (currentResult < totalResult) {
                            next = true;
                        }
                        if (noPagesLimit > 1) {
                            prev = true;
                        }

                        let pageCount = Math.ceil(totalResult / 10);
                        let pagination = pageNumbers(pageCount, noPagesLimit, 5);
                        this.setState({
                            issueList: responseData,
                            prev,
                            next,
                            pagination: pagination,
                            processing: false
                        });
                    }
                }

                else if (responseMsg.msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }


    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }
        let statusType = ""
        let data_1 = window.location.search.split("?")
        let data_2 = data_1[1].split("&")
        data_2.map((ival, i) => {
            let equalSplit = ival.split("=")
            if (equalSplit[1]) {
                statusType = equalSplit[1]
            }
        })

        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                if (statusType) {
                    this.getIssueList(statusType)
                }
            })
        }
    }

    humanDateFormat(time) {
        return new Date(time).toLocaleString()
    }
    viewHistory(issueId) {
        let { session, mUserId } = this.state
        let postJson = { issueId, session, mUserId }
        const request = new ZiFetchRequest();
        request.setDomain('adminIssues');;
        request.setEvent('getAdminIssuesReportLogsList');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid Request")
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg.msg == "Success") {
                    this.setState({
                        historyData: responseMsg.response.reverse()
                    })
                }
                else if (responseMsg.msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }
    viewEditHistory(issueId) {
        let { session, mUserId } = this.state
        let postJson = { issuesId: issueId, session, mUserId }
        const request = new ZiFetchRequest();
        request.setDomain('adminIssues');;
        request.setEvent('getAdminIssuesReportVersionData');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid Request")
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg.msg == "Success") {
                    this.setState({
                        editHistoryData: responseMsg.response
                    })
                }
                else if (responseMsg.msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }

    updateStatus() {
        let { selectedStatus, description, issueId, session, mUserId } = this.state;

        let data_1 = window.location.search.split("?")
        let data_2 = data_1[1].split("&")
        let statusType = ""

        data_2.map((ival, i) => {
            let equalSplit = ival.split("=")
            if (equalSplit[1]) {
                statusType = equalSplit[1]
            }
        })
        let postJson = { issuesId: issueId, status: selectedStatus, statusDescription: description, session, mUserId }
        const request = new ZiFetchRequest();
        request.setDomain('adminIssues');;
        request.setEvent('updateAdminIssuesReportStatus');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid Request")
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg.msg === "Success") {
                    toast.success(responseMsg.response, {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    this.setState({
                        selectedStatus: "",
                        description: "",
                    }, () => {
                        this.getIssueList(statusType);
                        this.viewHistory(issueId)
                    })
                }
                else if (responseMsg.msg == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }

    handleChange(e) {
        this.setState({
            selectedStatus: e.value
        })
    }

    viewModal(viewType, issueId) {
        let { issueList } = this.state
        this.setState({
            viewType,
            viewDetails: issueList[issueId],
            viewModal: true,
            historyData: [],
            editHistoryData: [],
            issueId,
            selectedStatus: issueList[issueId].adminIssuesReportData.status
        }, () => {
            this.viewHistory(issueId)
            // this.viewEditHistory(issueId)
        })
    }

    viewContent() {
        let { viewType, viewDetails, statusOption, selectedStatus, description, historyData, editHistoryData } = this.state;
        let dlist = viewDetails.adminIssuesReportData;
        let metaInfo = dlist.metaInfo ? JSON.parse(dlist.metaInfo) : ""
        let emailId = viewDetails.emailId;
        let deviceInfo = dlist.deviceInfo ? JSON.parse(dlist.deviceInfo) : {}

        let images = ["jpg", "png"];
        let videos = ["mp4", "3gp", "ogg", "mov"]
        let pdf = ["pdf"]

        let buildContent;
        switch (viewType) {
            case "refNo":
                buildContent = <div>{getIssueId(dlist.issuesNo)}</div>
                break;
            case "title":
                buildContent = <div>{dlist.title}</div>
                break;
            case "description":
                buildContent = <div style={{ whiteSpace: 'pre-wrap' }}>{dlist.descrition}</div>
                break;
            case "email":
                buildContent = <div>{emailId}</div>
                break;
            case "device":
                buildContent = <div>{metaInfo.issueFrom === "Web" ? "web" : deviceInfo.appPlatform}</div>
                break;
            case "osVersion":
                buildContent = <div>{metaInfo.issueFrom === "Web" ? "-" : (metaInfo ? `${metaInfo.deviceOsVersion} ${metaInfo.deviceModel ? "- " + metaInfo.deviceModel : ""}` : "-")}</div>
                break;
            case "version":
                buildContent = <div>{metaInfo.issueFrom === "Web" ? "-" : deviceInfo.appVersion}</div>
                break;
            case "imageVideo":
                let currentViewFiles = dlist.fileList;
                let userId = dlist.userId;
                buildContent = <div className="row " >
                    {
                        currentViewFiles.map((fileStr, fileIndex) => {
                            let file = JSON.parse(fileStr)
                            let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + file.processType + "&key=" + file.docsId + "." + file.fileType + "&id=" + userId
                            return <div className="col-6 my-2 px-2" key={fileIndex} >
                                <div style={{ border: "1px solid #ddd", textAlign: "center", background: "#9e9e9e" }}>
                                    {
                                        images.includes(file.fileType.toLowerCase()) && <img src={buildUrl} className="img-fluid" style={{ maxHeight: 400 }} />
                                    }
                                    {
                                        videos.includes(file.fileType.toLowerCase()) && <video width="100%" controls style={{ maxHeight: 400 }}>
                                            <source src={buildUrl} />
                                        </video>
                                    }
                                    {
                                        pdf.includes(file.fileType.toLowerCase()) && <React.Fragment>
                                            <object data={buildUrl} type="application/pdf" width="100%" height="250px"></object>
                                            <a className="btn btn-sm btn-success my-2" href={buildUrl} target="_blank">Preview</a>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
                break;
            case "status":
                buildContent = <div>{`${dlist.status} ${dlist.statusDescription ? " - " + dlist.statusDescription : ""}`}</div>
                break;
            case "action":
                buildContent = <React.Fragment>
                    <div className="form-group">
                        <label>Status</label>
                        <Select
                            options={statusOption}
                            isSearchable={true}
                            placeholder="Select"
                            onChange={this.handleChange}
                            value={statusOption.filter(option => option.value === selectedStatus)}
                        />
                    </div>
                    <div className="form-group">
                        <label>Description</label>
                        <textarea
                            className="form-control"
                            value={description}
                            onChange={(e) => this.setState({
                                description: e.target.value
                            })}
                        />
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary" onClick={() => this.updateStatus()}>Save</button>
                    </div>
                </React.Fragment>
                break;
            case "history":
                buildContent = <React.Fragment>
                    <ul className="timeline">
                        <li>
                            <span>Feedback created</span>
                            <span className="float-right">{this.humanDateFormat(dlist.createdAt)}</span>
                        </li>
                        {
                            historyData.map((history, index) => {
                                history = JSON.parse(history)
                                return <li key={index} className={history.status.toLowerCase()}>
                                    <span>{history.status}</span>
                                    <span className="float-right">{this.humanDateFormat(history.createdAt)}</span>
                                    <p className="status-description">{history.statusDescription}</p>
                                </li>
                            })
                        }
                    </ul>
                </React.Fragment>
                break;
            case "editHistory":
                buildContent = <React.Fragment>
                    {
                        Object.keys(editHistoryData).map((row, index) => {
                            return <div key={index}>{this.versionChanges(row)}</div>
                        })
                    }
                </React.Fragment>
                break;
            case "createdAt":
                buildContent = <div>{this.humanDateFormat(dlist.createdAt)}</div>
                break;
        }

        return buildContent;
    }

    versionChanges(version) {
        let { editHistoryData } = this.state;
        return editHistoryData[version]
    }

    render() {
        let { menuActive, issueList, pagination, prev, next, totalPagesResults, processing, pageLimit, viewModal, viewType, viewDetails, noPagesLimit, issueCountList } = this.state
        import('../admin/style.css');

        let statusType = ""
        let data_1 = window.location.search.split("?")
        let data_2 = data_1[1].split("&")
        data_2.map((ival, i) => {
            let equalSplit = ival.split("=")
            if (equalSplit[1]) {
                statusType = equalSplit[1].replace(/_/g, " ")
            }
        })


        return (
            <>

                <div className="body-content left-rigt-padding cat-style">
                    <main className="app-main">
                        <div className='row' style={{ paddingBottom: 15 }}>
                            <div className='col-sm-3 col-3'>
                                <button className="btn btn-md btn-info" onClick={() => {
                                    this.props.history.push(MyConstant.keyList.projectUrl + "/admin/admin-feedbacklist")

                                }}>Back</button>
                            </div>
                            <div className='col-sm-6 col-6'>

                                <h2 style={{ textAlign: "center", fontWeight: "bold", fontFamily: "system-ui", }}> {statusType} List</h2>
                            </div>
                            <div className='col-sm-3 col-3'></div>

                        </div>

                        <div className={`row ${processing && "table-processing"}`}>
                            {processing && <div className="processing"><span>Processing...</span></div>}
                            <div className="col-md-12">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Ref No</th>
                                            <th scope="col">Title</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Username</th>
                                            <th scope="col">RoleType</th>
                                            <th scope="col">Email</th>                                            
                                            <th scope="col">Image/Video</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                            <th scope="col">History</th>
                                            <th scope="col">Created At</th>
                                            <th scope="col">Modified At</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.keys(issueList).map((key, index) => {
                                                let dlist = issueList[key].adminIssuesReportData;
                                                let metaInfo = dlist.metaInfo ? JSON.parse(dlist.metaInfo) : ""
                                                let emailId = issueList[key].emailId;
                                                let roleType = issueList[key].roleType;
                                                let userName = issueList[key].userName;
                                                let deviceInfo = dlist.deviceInfo ? JSON.parse(dlist.deviceInfo) : {}
                                                return <tr key={index} className={`${dlist.status === "Feature Addition" ? "feature-addition" : ""}`}>
                                                    <td>{getIssueId(dlist.issuesNo)}</td>
                                                    {
                                                        dlist.status === "Duplicate" ? <td colSpan="8" className="text-center">
                                                            Duplicate
                                                        </td> : <React.Fragment>
                                                            <td>{dlist.title}</td>
                                                            <td>
                                                                <span>{dlist.descrition.substring(0, 50)} {dlist.descrition.length > 50 && <span>...<a className="read-more-comp" onClick={() => this.viewModal("description", key)}>Read More</a></span>}</span>
                                                            </td>
                                                            <td>{userName}</td>
                                                            <td>{roleType}</td>
                                                            <td>{emailId}</td>

                                                            <td><button className="btn btn-sm btn-info" onClick={() => this.viewModal("imageVideo", key)}>View</button></td>
                                                            <td>
                                                                {`${dlist.status} `}

                                                            </td>
                                                            <td><button className="btn btn-sm btn-success" onClick={() => this.viewModal("action", key)}>Update</button></td>
                                                        </React.Fragment>
                                                    }

                                                    <td><button className="btn btn-sm btn-success" onClick={() => this.viewModal("history", key)}>View</button></td>
                                                    {/* <td><button className="btn btn-sm btn-success" onClick={() => this.viewModal("editHistory", key)}>View</button></td> */}
                                                    <td>{this.humanDateFormat(dlist.createdAt)}</td>
                                                    <td>{dlist.lastUpdatedAt == 0 ? "-" : this.humanDateFormat(dlist.lastUpdatedAt)}</td>
                                                </tr>
                                            })}
                                    </tbody>

                                </table>
                                <Pagination
                                    pagination={pagination}
                                    currentPage={noPagesLimit}
                                    prev={prev}
                                    next={next}
                                    movePage={(type) => {
                                        this.movePage(type)
                                    }} />
                            </div>
                        </div>
                    </main>
                </div>
                {
                    viewModal && <Modal
                        visible={viewModal}
                        size={"modal-xl"}
                        closeModal={() => this.setState({ viewModal: false })}
                        heading={"Feedback - " + viewDetails.adminIssuesReportData.title}
                        body={<React.Fragment>
                            <div className="row">
                                <div className="col-8">
                                    {
                                        this.viewContent()
                                    }
                                </div>
                                <div className="col-4">
                                    <div className="tabs-vertical">
                                        <div className={`tab ${viewType === "refNo" ? "active" : ""}`} onClick={() => this.setState({ viewType: "refNo" })}>Ref No</div>
                                        <div className={`tab ${viewType === "title" ? "active" : ""}`} onClick={() => this.setState({ viewType: "title" })}>Title</div>
                                        <div className={`tab ${viewType === "description" ? "active" : ""}`} onClick={() => this.setState({ viewType: "description" })}>Description</div>
                                        <div className={`tab ${viewType === "email" ? "active" : ""}`} onClick={() => this.setState({ viewType: "email" })}>Email</div>
                                        <div className={`tab ${viewType === "device" ? "active" : ""}`} onClick={() => this.setState({ viewType: "device" })}>Device</div>
                                        <div className={`tab ${viewType === "osVersion" ? "active" : ""}`} onClick={() => this.setState({ viewType: "osVersion" })}>OS version</div>
                                        <div className={`tab ${viewType === "version" ? "active" : ""}`} onClick={() => this.setState({ viewType: "version" })}>Version</div>
                                        <div className={`tab ${viewType === "imageVideo" ? "active" : ""}`} onClick={() => this.setState({ viewType: "imageVideo" })}>Image/Video</div>
                                        <div className={`tab ${viewType === "status" ? "active" : ""}`} onClick={() => this.setState({ viewType: "status" })}>Status</div>
                                        <div className={`tab ${viewType === "action" ? "active" : ""}`} onClick={() => this.setState({ viewType: "action" })}>Action</div>
                                        <div className={`tab ${viewType === "history" ? "active" : ""}`} onClick={() => this.setState({ viewType: "history" })}>History</div>
                                        
                                        <div className={`tab ${viewType === "createdAt" ? "active" : ""}`} onClick={() => this.setState({ viewType: "createdAt" })}>Created At</div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                    />
                }
            </>
        )
    }
}

export default AdminFeedbackStatus;