import React from "react";
import Header from "./component/Header";
import bg1 from '../../images/v3/homeBg.jpeg';
import logoMiddle from '../../images/v3/middleLogo.png'
import box1 from '../../images/v3/box1.jpeg'
import box2 from '../../images/v3/box2.jpeg'
import box3 from '../../images/v3/box3.jpeg'
import box4 from '../../images/v3/box4.jpeg'
import box5 from '../../images/v3/box5.jpeg'
import box6 from '../../images/v3/box6.jpeg'
import incubator1 from '../../images/v3/incubator1.png'
import incubator2 from '../../images/v3/incubator2_new.png'
import incubator3 from '../../images/v3/incubator3.png'
import Footer from '../../common/Footer3';
import MyConstant from "../../config/MyConstant";
import { Link } from 'react-router-dom';
import { userTrack } from "../../config/MyFunctions";

class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            slidewidth: 0,
            sliderWidth: 0,
            NavResClass: "",
            pageLogin: false,
            slideContent: [
                {
                    "content": '“From a nurse practitioner perspective, this would be a beneficial application for patients to utilize.  Too often I see patients get lost in the system.  I can see this app helping patients stay on top of their medical care.”',
                    "writer": "Kristen, APN-C"
                }, {
                    "content": '“It would be nice to see this (app) bridge the communication between healthcare staff and patients to improve patient outcomes”',
                    "writer": "Jessica, RN"
                }, {
                    "content": '“When we were coordinating the transfer of care from his New York doctors to New Jersey doctors I used to bring around this huge accordion folder that contained all his printed medical records... If we had this app back then it would be alot easier to carry around all this information from doctor to doctor.”',
                    "writer": "Sandy, Pharm D, BCPS"
                },
                {
                    "content": '“I would personally use this with my sister to help manage the health of our parents as they continue to age”',
                    "writer": "FNP, APN"
                },
                {
                    "content": '“I would think that this would encourage more family members to be involved in their loved ones care”',
                    "writer": "FNP, APN"
                },
                {
                    "content": '“They (patients & family members) are not experts in the medical field...A lot of what you tell them is foreign...Then you have family members that are asked to make a life saving decision based on all the information that you just told them..this can be a lot of pressure”',
                    "writer": "Nurse Educator"
                },
                {
                    "content": '“If they were able to follow along with their loved ones health, easily like this (app) is promising to deliver. It would allow family and patients to take the time needed to understand their health and make better decisions”',
                    "writer": "Nurse Educator"
                },
                {
                    "content": '“I struggle remembering when to schedule my kid’s shots and annual check ups.  If I had a place that had all their updated vaccine records and remind me to schedule another appointment that would be one less thing I have to remember”',
                    "writer": "Critical Care Nurse"
                },
                {
                    "content": '“If the information provided to them was repetitive they would be able to absorb it more versus a one time visit hearing the information only once”',
                    "writer": "Danielle PT, DPT"
                },
                {
                    "content": '“The general population doesn’t really know their disease. They know about it but not the details of it. They should be given an education tool that they can refer back to”',
                    "writer": "Danielle PT, DPT"
                },
                {
                    "content": '“I think, knowledge deficit is huge because there is so much conflicting information out there. Healthcare is a mystery to a lot of people. They try to treat things at home by themselves. You can go on google and look stuff up...and get wrong information.”',
                    "writer": "Josh C. BSN, RN-Traveling Critical Care RN"
                },
                {
                    "content": '“I also work in ambulatory surgery center and a lot of patients just want to go home….I also think time is an issue, some people may not want to bother the provider because they have different cultural differences, so they pretend they understand and are too afraid to ask questions on the spot”',
                    "writer": "Marian, BSN, RN, ONC"
                },
                {
                    "content": '“I think it is more of a knowledge gap because doctors are using medical jargon and not really taking the time to explain to patients and their families in a way that they understand, or maybe they think they are but really they are just nodding and pretending they understand”',
                    "writer": "MSN, RN"
                },
                {
                    "content": '“Not everyone has the same level of education and dedication to their health so it’s hard for them to grasp information and retain it”',
                    "writer": "Sonia, BSN, RN, ONC, GRN"
                },
                {
                    "content": '“Not everyone has the level of education and ability to understand the medical information that is given to them. It must be presented in very simple terms which can be difficult for most healthcare professionals.”',
                    "writer": "Mariza, BSN, RN"
                },
                {
                    "content": '“If they are not relaying all the information or there is not a platform that says ‘here is what you can access...easily then there might be a disconnect between the two physicians……”',
                    "writer": "Dr. Giankos, D.O"
                },
                {
                    "content": '“The more complex things are, the easier it is to miss the smaller more detailed information that can cause significant things to happen”',
                    "writer": "Pharm D"
                },
                {
                    "content": '“The more complicated a patient’s medical condition is the more complicated their medication lists gets...depending on how many doctors they see the doses can all be different and if there is no communication and there is not one list a patient has, this can be missed”',
                    "writer": "Pharm D"
                },
                {
                    "content": '“Miscommunication and errors occur with acute illness...you do not need end stage renal disease or a chronic illness for these errors to occur”',
                    "writer": "BSN, RN"
                },
                {
                    "content": '“I deal with medically complex patients everyday and sometimes they have a month, two month, or three month long admissions. It’s like the whisper game. How many times does that information change hands? And it gets changed around and miscommunicated. I’m guilty of it too, I would get home and be like - I forgot to tell them this…...with everything we have to remember in the chart - something will get left out almost every day. Unless you keep rigorous notes like a running note sheet it can be helpful which some units do but I have not seen that everywhere”',
                    "writer": "Josh C. BSN, RN-Traveling Critical Care Nurse"
                },
                {
                    "content": '“When it gets more complex everyone defers to the consults of other professionals, hematologist will get this, or nephrologist will get this and cardiology will look at this. There is some communication among all of them. But they are not necessarily on the same page at the same time, so there can be some time frames missing”',
                    "writer": "Nurse Educator"
                },
                {
                    "content": '“With complex patients…...a lot of information can go missed…….unfortunately we are all on a time limit and we are forced to crunch so much information in a small amount of time, that yes there could definitely be information that is unintentionally missed”',
                    "writer": "Danielle, PT, DPT"
                },
                {
                    "content": '“For patients that are very sick...they have chronic conditions such as diabetes or hypertension. When they are discharged physicians don’t really address those situations, just the diagnosis or complaint that they initially came in for. People assume that if they had this disease for a long time that they no longer need further education on this disease…….they should already know what they are doing, which we have evidently seen at the bedside, that is not the case”',
                    "writer": "Marian, BSN, RN, ONC"
                },
                {
                    "content": `“They obviously know more about the disease then the patient does and having to break that down into what the provider believes in simple language is not simple for the patient to understand, there is still a lot of questions that the patient has because they are unable to connect the dots with the information that was provided to them”`,
                    "writer": "Sonia, BSN, RN, ONC, GRN"
                },
                {
                    "content": `“...It would be a nice way to communicate with a physician through an electronic comfortable environment”`,
                    "writer": "Dr. Giankos, D.O "
                },
                {
                    "content": `“...“It would be helpful, especially if it had functions where it can share or send things to different healthcare providers and everyone is able to see everything in one place….”`,
                    "writer": "Geena, MS-RD"
                },
                {
                    "content": `“I think it is very good for patients to have their records in one place. There are a lot of patient portals but they are all separated, so you have a different username and login for each place and it becomes too cumbersome to remember exactly where you got that one shot for example and then have to remember your username and password so i think it would be very beneficial just to have everything in one localized place.”`,
                    "writer": "Critical Care RN"
                },
                {
                    "content": `“...Because patients cannot remember everything themselves….”`,
                    "writer": "OBGYN"
                },
                {
                    "content": `“If everyone is on the same page on that patient’s medical history then the care can be delivered quickly and catered to that patient”`,
                    "writer": "Critical Care RN"
                },
                {
                    "content": `“...If I had access to all my lab work per say, I would be able to tell my doctor I just had this lab work done a month ago. Is it necessary to draw it again….”`,
                    "writer": "MSN, RN"
                },
                {
                    "content": `“If they had the accurate information in the beginning they would not need to repeat tests that were recently performed on an otherwise stable individual saving that patient expenses and time to treatment”`,
                    "writer": "Sonia, BSN, RN, ONC, GRN"
                },
                {
                    "content": `“If you have all your information and based on your current presenting symptoms and an accurate history…….This can save the patient from additional testing if they could present that information to the doctor”`,
                    "writer": "Willie, BSN, RN"
                },
                {
                    "content": `“....If the patient just had a lab drawn yesterday and then they go to the ER the first thing they do is draw the labs again, which can delay and increase costs for the patient”`,
                    "writer": "Dialysis RN"
                },
                {
                    "content": `“They are more likely to advocate for themselves...question things and again show that they understand what is going on with their body and they can even say like ‘oh no i just had that CT Scan done a month ago, give me a good reason why I need to sit through that again’”`,
                    "writer": "Allie, PT, DPT"
                },
                {
                    "content": `"Having their medical records would be easier for patients and office staff if the patient chooses to go to another office for a second opinion"`,
                    "writer": "Kristen, APN-FNP"
                },
                {
                    "content": `“...so patients can have more autonomy and go for a second opinion”`,
                    "writer": "NICU/Employee Health RN"
                },
                {
                    "content": `“If they have their own health care record they have a bit more knowledge about their disease in offering up the various options with good counseling they could be more involved in their treatment plan”`,
                    "writer": "Dr. Villamor D.O"
                },
                {
                    "content": `“If you can see notes and diagnoses you would know where to go to get health care…”`,
                    "writer": "Josh C. BSN, RN-Traveling Critical Care RN"
                },
                {
                    "content": `“A family member not in health care (and) not sure what questions to ask...if things are laid out for them it will be must easier for them”`,
                    "writer": "Allie, PT, DPT"
                },
                {
                    "content": `"Medical Records have many requests and may not get the patient requested records to the required place within a timely manner"`,
                    "writer": "Kristen, APN-FNP"
                }
            ],
            sliderHover: false,
            sliderActive: 1,
            sliderTransform: 0,
            showDownload: false,
            showMedia: false


        }
    }

    componentDidMount() {
        this.updateDimensions();
        userTrack("Home Page", "Landing");
        window.addEventListener('resize', this.updateDimensions);

        setInterval(() => {
            this.moveSlide('next')
        }, 10);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    moveSlide() {
        let { sliderHover, sliderTransform, slidewidth, slideContent } = this.state;
        if (!sliderHover) {
            if (slidewidth * slideContent.length <= -sliderTransform) {
                sliderTransform = 0
            } else {
                sliderTransform = sliderTransform - 1
            }
            this.setState({
                sliderTransform
            })
        }
    }

    onMouseEnterToSlider() {
        this.setState({
            sliderHover: true
        })
    }
    onMouseLeaveToSlider() {
        this.setState({
            sliderHover: false
        })
    }
    updateDimensions = () => {
        let deviceWidth = document.body.clientWidth;
        var width = deviceWidth / 2;
        let sliderWidth = this.state.slideContent.length * width;
        var sliderTransform = 0;
        this.setState({
            slidewidth: width,
            sliderWidth: sliderWidth,
            sliderTransform: sliderTransform
        });


    };
    render() {
        let { slidewidth, slideContent, sliderTransform } = this.state
        return <div className="umedme-version-3">
            <Header />
            <div className="bg">
                <img src={bg1} className="background" />
            </div>
            <div className="marquee font my-5">
                <div className="marquee__item">
                    HealthTech Incubator
                    <span className="marquee__seperator"></span>
                    HealthTech Incubator
                    <span className="marquee__seperator"></span>
                    HealthTech Incubator
                    <span className="marquee__seperator"></span>
                    HealthTech Incubator
                    <span className="marquee__seperator"></span>
                    HealthTech Incubator
                    <span className="marquee__seperator"></span>
                    HealthTech Incubator
                    <span className="marquee__seperator"></span>
                    HealthTech Incubator
                    <span className="marquee__seperator"></span>
                </div>
                <div className="marquee__item">
                    HealthTech Incubator
                    <span className="marquee__seperator"></span>
                    HealthTech Incubator
                    <span className="marquee__seperator"></span>
                    HealthTech Incubator
                    <span className="marquee__seperator"></span>
                    HealthTech Incubator
                    <span className="marquee__seperator"></span>
                    HealthTech Incubator
                    <span className="marquee__seperator"></span>
                    HealthTech Incubator
                    <span className="marquee__seperator"></span>
                    HealthTech Incubator
                    <span className="marquee__seperator"></span>
                </div>
            </div>
            <div className="d-flex justify-content-center my-5">
                <img className="img-fluid" src={logoMiddle} />
            </div>
            <div className="px-5">
                <div className="home-content text-center">
                    <p>Umed.Me is a health tech incubator. It was developed using public health expertise across medical specialties in both outpatient (chronic) and hospital (acute) settings including mental health and migration.</p>
                    <br />
                    <p>Umed.Me is from Teqbahn Labs, which specializes in carbon sensitive technology systems to help promote better health and wellbeing with a combination of technology that is better for the planet and solutions that are closely informed by the people who use them. An evidence based approach to public health innovation and implementation at scale.</p>
                </div>
                <div className="row pt-5">
                    <div className="col-sm-4">
                        <div className="img-box">
                            <img src={box1} alt={"UmedMe"} />
                            <div className="content">Understandable</div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="img-box">
                            <img src={box2} alt={"UmedMe"} />
                            <div className="content">Manageable</div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="img-box">
                            <img src={box3} alt={"UmedMe"} />
                            <div className="content">Environment</div>
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-sm-4">
                        <div className="img-box">
                            <img src={box4} alt={"UmedMe"} />
                            <div className="content">Dependable</div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="img-box">
                            <img src={box5} alt={"UmedMe"} />
                            <div className="content">Motivational</div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="img-box">
                            <img src={box6} alt={"UmedMe"} />
                            <div className="content">Ethical</div>
                        </div>
                    </div>
                </div>
                <div className="text-center pt-5 pb-3">
                    <h2>Umed.Me Healthtech Incubator</h2>
                </div>
                <div className="row">
                    <div className="col-sm-4 text-center">
                        <Link to={MyConstant.keyList.projectUrl+"/tech"} onClick={() => {
                            window.scrollTo(0, 0);
                            userTrack("Home Page", "Tech Click");
                        }}>
                            <img className="img-fluid" src={incubator2} />
                            <h4>Umed.Tech</h4>
                        </Link>
                        <p>An EdTech tool to help nursing students practice and master fundamental skills like medication administration, diet &amp; nutrition management, and health document storage &amp; tracking. This tool includes a live polling survey that delivers pre and post learning measurements to help increase learner engagement and promote high quality learning outcomes.</p>
                    </div>
                    <div className="col-sm-4 text-center">
                        <Link to={MyConstant.keyList.projectUrl+"/migration"} onClick={() => {
                            window.scrollTo(0, 0);
                            userTrack("Home Page", "Migration Click");
                        }}>
                            <img className="img-fluid" src={incubator1} />
                            <h4>Umed.Me Migration</h4>
                        </Link>
                        <p>Secure document storage, Continuity of Care, Medication and Diet resources to promote health and wellbeing in the migrant setting. Available in Spanish, English, Tamil, Haitian Creole, French, Arabic, Kurdish, Dari, Russian. The Teqbahn language template promotes translation of the entire app into any language within a two week timeline.</p>
                    </div>
                    <div className="col-sm-4 text-center">
                        <a href="https://globalspeakupcoalition.org/" onClick={() => {
                            userTrack("Home Page", "SpeakUp Click");
                        }}>
                            <img className="img-fluid" src={incubator3} />
                            <h4>SpeakUp</h4>
                        </a>
                        <p>A self-advocacy tool to help advance physician equity by providing crucial resources to transform communication and drive positive work culture.</p>
                    </div>
                </div>
            </div>
            <div className="row profinsight mt-5" >
                <div className="col-sm-12 px-0 py-5 text-center">
                    <p className="p-text">Professional Insight</p>
                </div>
            </div>
            <div className="slidebg">
                <div className="slider-outer">
                    <div className="slider" style={{ position: "relative", left: sliderTransform, transition: "none" }} >
                        {slideContent.map((value, index) => {
                            return <div key={index} className={`slide`} style={{ minWidth: slidewidth }}>
                                <div className="slideInner mo-slideInner"
                                    onTouchStart={() => this.onMouseEnterToSlider()}
                                    onMouseEnter={() => this.onMouseEnterToSlider()}
                                    onMouseLeave={() => this.onMouseLeaveToSlider()}
                                    onTouchEnd={() => this.onMouseLeaveToSlider()}
                                >
                                    <p className="content">{value.content}</p>
                                    <p className="px-2 pt-2 writer">{value.writer}</p>
                                </div>
                            </div>
                        })}
                        {slideContent.map((value, index) => {
                            return <div key={index} className={`slide clone`} style={{ minWidth: slidewidth }}>
                                <div className="slideInner mo-slideInner"
                                    onTouchStart={() => this.onMouseEnterToSlider()}
                                    onMouseEnter={() => this.onMouseEnterToSlider()}
                                    onMouseLeave={() => this.onMouseLeaveToSlider()}
                                    onTouchEnd={() => this.onMouseLeaveToSlider()}
                                >
                                    <p className="content">{value.content}</p>
                                    <p className="px-2 pt-2 writer">{value.writer}</p>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    }
}
export default Home