import React from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import image3 from '../images/Teqbahn_logo1.png';
import MyConfig from '../config/MyConfig';
import { dateConverter } from '../config/MyFunctions';
import { toast, } from "react-toastify";
import { checkLogin, readSessionData, resetLocalData, pageNumbers } from '../config/MyFunctions';
import whiteLogo from '../images/whiteLogo.png';
import Pagination from "./Pagination"




class ContinuityOfCareList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuActive: "",
            processing: false,
            prev: false,
            next: false,
            noPagesLimit: 1,
            pageLimit: 10,
            resourceListJson: {},
            pagination: [],
            visibleClass: "",
            totalResultsCount: 0,
            mUserId: "",
            session: "",
            displayShow: true,

        }
        this.myRef = React.createRef()
    }
    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        if (login_status) {
            document.body.style.backgroundColor = "#f6f7fb";
            let resData = await readSessionData()
            let { manage_session, manage_mUserId } = resData
            this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
                this.getContinuityAPI()
            })
        }

    }

    getContinuityAPI() {
        let { noPagesLimit, pageLimit, session, mUserId } = this.state;
        let postJson = { pageLimit: pageLimit, noOfPage: noPagesLimit, mUserId, session }
        //mUserId: String,session: String,pageLimit: Int, noOfPage: Int  
        const request = new ZiFetchRequest();
        request.setDomain("adminUserManage");
        request.setEvent("getAllContinuityOfCareList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let response_Msg = json.response
                if (response_Msg === "Success") {
                    let next = false;
                    let prev = false;
                    let totalResult = json.totalResult
                    let currentResult = noPagesLimit * pageLimit;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noPagesLimit > 1) {
                        prev = true;
                    }

                    let pageCount = Math.ceil(totalResult / pageLimit);
                    let pagination = pageNumbers(pageCount, noPagesLimit, 5);

                    this.setState({
                        prev,
                        next,
                        pagination: pagination,
                        processing: false,
                        resourceListJson: json.result
                    });
                }
                else if (json.response === "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }


    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }

        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getContinuityAPI();
            })
        }
    }

    render() {
        let { resourceListJson, pagination, noPagesLimit, prev, next, processing } = this.state
        import('../admin/style.css');
        let that = this
        var manageIsLogin = localStorage.getItem("managelogedin")

        let compnayLogo = image3
        if (manageIsLogin) {
            compnayLogo = whiteLogo
        }
        let tableData = []
        Object.keys(resourceListJson).forEach(function (key, index) {
            let fullData = resourceListJson[key]
            let { createdAt, additionalNotes, dateOfMeeting, doctorBecause, doctorOrClinicOrHospitalName, doctorPrescribed, doctorSaidConsultExpert, doctorSaidIShould, doctorTold, typeOfMeeting } = fullData
            tableData.push(
                <tr>
                    <td className='tabletxt'>{dateOfMeeting}</td>
                    <td className='tabletxt'>{typeOfMeeting}</td>
                    <td className='tabletxt'>{doctorOrClinicOrHospitalName}</td>
                    <td className='tabletxt'>{doctorBecause}  </td>
                    <td className='tabletxt'>{doctorTold} </td>
                    <td className='tabletxt'>{doctorSaidIShould} </td>
                    <td className='tabletxt'>{doctorPrescribed} </td>
                    <td className='tabletxt'>{doctorSaidConsultExpert} </td>
                    <td className='tabletxt'>{additionalNotes} </td>
                    <td className='tabletxt' >{dateConverter(createdAt)}</td>

                </tr>
            )
        });

        return (
            <div className="body-content left-rigt-padding cat-style" ref={this.myRef} style={{ height: "auto" }}>
                <main className="app-main">
                    <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>
                        <div className="container mt-5">
                            <div className="card box-shadow" style={{ padding: 0 }}>
                                <div className="card-header border-btm-style" style={{ borderBottom: "none" }}>
                                    <h5 className='header-style' > Continuity Of Care List</h5>
                                </div>
                                <div className={`row ${processing && "table-processing"}`}>
                                    {processing && <div className="processing"><span>Processing...</span></div>}
                                    <div className="card-body table-responsive" style={{ padding: 2 }}>
                                        <table className="table table-style light-border-clr" style={{ width: "100%", textAlign: "center" }} border={1} cellPadding={7}>
                                            <tr className='upperCase'>
                                                <th scope="col fw">Date of meeting</th>
                                                <th scope="col fw">Location/type of meeting (in person/online)</th>
                                                <th scope="col fw">Doctor/clinic or hospital name</th>
                                                <th scope="col fw">I went to the doctor because</th>
                                                <th scope="col fw">The doctor told me (diagnosis)</th>
                                                <th scope="col fw">The doctor said I should (treatment)</th>
                                                <th scope="col fw">The doctor prescribed medication</th>
                                                <th scope="col fw">The doctor said to consult an expert</th>
                                                <th scope="col fw">Additional Notes</th>


                                                <th scope="col fw">Published</th>
                                            </tr>
                                            <tbody>
                                                {tableData}
                                            </tbody>
                                        </table>
                                        <Pagination
                                            pagination={pagination}
                                            currentPage={noPagesLimit}
                                            prev={prev}
                                            next={next}
                                            movePage={(type) => {
                                                this.movePage(type)
                                            }} />

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </div>
        )
    }
}

export default ContinuityOfCareList;