import React from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import SideManuBar from './SideMenuBar';
import image3 from '../images/Teqbahn_logo1.png';
import MyConfig from '../config/MyConfig';
import Select from './component/Select';
import { toast, ToastContainer } from "react-toastify";
import MyConstant from '../config/MyConstant';
import { loginStatusCheck, checkLogin, getUuidv4 } from '../config/MyFunctions';
import AdminLogout from "./AdminLogout"
import ManageLogout from "../manage/ManageLogout"
import ManageSideMenu from '../manage/ManageSideMenu';
import Loader from './component/Loader';





class UILanguage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedLanguageStatus: "",
            menuActive: "",
            uiJson: {},
            ui_Json_Structure: [],
            ui_Json_Array: [],
            selectValue: {},
            selectPageStatus: "",
            selectPage: "",
            createFileLoader: false,
            downloadDisable: false,
            laguageValidate: "",
            pageValidate: "",
            languageStructure_Data: [],
            langaugeOption: [],
            ui_Json_Array_Eng: {},
            languageMappedOption: [],
            pageLoader: false,
            newTextShow: []
        }
        this.handleChange = this.handleChange.bind(this)
    }
    handleChange(e) {
        let { selectPage } = this.state;
        localStorage.setItem("mappingLanguage", e.value)
        this.setState({
            selectedLanguageStatus: e.value,
            selectValue: e,
            pageValidate: "",
            pageLoader: true,
            ui_Json_Array: [],
        }, () => {
            this.getUi_Structure()
            this.getTranslateInfo()
            this.props.onChangePage(selectPage)
        })
    }
    handleChange_page(e) {
        let { selectedLanguageStatus } = this.state
        localStorage.setItem("mappingPageName", e.value)
        this.setState({
            selectPageStatus: e.value,
            selectPage: e,
            // selectValue: "",
            // selectedLanguageStatus: "",
            ui_Json_Array: [],
            // laguageValidate: "",
            pageLoader: selectedLanguageStatus ? true : false
        }, () => {
            if (selectedLanguageStatus) {
                this.getUi_Structure()
                this.props.onChangePage(e)
            }
        }
        )
    }

    componentDidMount() {
        this.initialFunction()
    }

    async initialFunction() {
        let login_status = await checkLogin(this.props)
        this.getUiData()
        let langFunc = await this.langListDataApi()
        let getPageName = localStorage.getItem("mappingPageName")
        let getLanguage = localStorage.getItem("mappingLanguage")

        this.setState({ languageMappedOption: langFunc.result, selectPageStatus: getPageName, selectedLanguageStatus: getLanguage, pageLoader: (getPageName && getLanguage) ? true : false }, () => {
            this.getLanguageList()
            if (getLanguage && getPageName) {
                this.getUi_Structure()
                this.props.onChangePage(getLanguage)
                this.getTranslateInfo()
            }

        })

    }
    getUiData() {
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("ui-list");
        request.setMessage("{}");
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                // console.log("json",json)
                this.setState({ uiJson: json.uiMap })
            }
        });
    }

    getUi_Structure() {
        let { selectPageStatus } = this.state
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("getUIStructure");
        request.setMessage("{}");
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (selectPageStatus && json.uiMap && json.uiMap[selectPageStatus]) {

                    let parseData = JSON.parse(json.uiMap[selectPageStatus])
                    // console.log("parseData",parseData)
                    if (typeof (parseData) != undefined) {
                        let language_structure = []
                        parseData.map((val) => {
                            language_structure.push({ name: val })
                        })
                        //ui_Json_Array: ui_Json_Array,
                        this.setState({ languageStructure_Data: language_structure, pageLoader: false }, () => {
                            this.getLangUI_Data()
                            this.getEngTxt()
                        })
                    }
                }
                else {
                    this.setState({ ui_Json_Array: [], languageStructure_Data: [], pageLoader: false })
                }
            }
        });
    }


    getLangUI_Data() {
        let { selectedLanguageStatus, selectPageStatus, languageStructure_Data } = this.state
        let postJson = { key: selectPageStatus, langCode: selectedLanguageStatus }
        //key: String,langCode:String
        if (selectedLanguageStatus && selectedLanguageStatus) {
            const request = new ZiFetchRequest();
            request.setDomain("ui");
            request.setEvent("getUILanguageData");
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
                if (response == null || response.getMessage() == "Invalid Request") {
                    console.log("Invalid Request. Please try again later")
                } else {
                    let json = JSON.parse(response.getMessage());
                    // console.log("json", json)
                    let dummyObject = {}
                    if (json.response) {
                        let parseData = JSON.parse(json.response)
                        // console.log("languageStructure_Data", languageStructure_Data)
                        let ui_Json_Array = []                       
                        if (Object.keys(parseData).length != 0 && languageStructure_Data.length != 0) {
                                                      
                            languageStructure_Data.map((kval, k) => {
                                // Object.keys(parseData).map((jval) => {
                                //     let data = parseData[jval]
                                //     ui_Json_Array.push({ textField: data, name: jval })                                    
                                // }) 
                                if (parseData[kval.name]) {

                                    let languageTextField = parseData[kval.name]
                                    // languageTextField = dummyObject[kval.name]                                    
                                    ui_Json_Array.push({ textField: languageTextField, name: kval.name })
                                }
                                else {
                                    let languageTextField = ""
                                    //languageTextField = dummyObject[kval.name]
                                    ui_Json_Array.push({ textField: languageTextField, name: kval.name })
                                }

                            })
                            this.setState({ ui_Json_Array: ui_Json_Array })
                        }
                    }
                    else if (languageStructure_Data.length != 0) {
                        let ui_Json_Array = []
                        languageStructure_Data.map((kval, k) => {
                            let languageTextField = ""
                            //  languageTextField = dummyObject[kval.name]
                            ui_Json_Array.push({ textField:languageTextField, name: kval.name })
                        })
                        this.setState({ ui_Json_Array: ui_Json_Array })
                    }

                }
            });
        }

    }

    submitData() {
        let { selectPageStatus, ui_Json_Array, selectedLanguageStatus, } = this.state
        let lang_Structure = {}
        ui_Json_Array.map(ival => {
            lang_Structure[ival.name] = ival.textField
        })
        console.log("**", lang_Structure)
        let laguageValidate, pageValidate = ""
        if (!selectedLanguageStatus) {
            laguageValidate = "Please Choose Language"
        }

        if (!selectPageStatus) {
            pageValidate = "Please Choose Page"
        }

        this.setState({ laguageValidate, pageValidate })

        if (!selectPageStatus || !selectedLanguageStatus) {
            return false
        }

        if (ui_Json_Array.length == 0) {
            console.log(ui_Json_Array.length, "-->wait")
            return false
        }

        let emptyCount = ui_Json_Array.filter((item) => {
            return item.textField === ""
        }).length;


        let postJson = { key: selectPageStatus, langCode: selectedLanguageStatus, langJson: JSON.stringify(lang_Structure), isAllTranslated: emptyCount === 0 }
        //key: String,langCode:String, langJson :String
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("updateUILanguageData");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response == "Success") {
                    toast.success("UI Language Created!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.getTranslateInfo()
                }
            }
        });

    }
    generateFiles() {
        this.setState({ createFileLoader: true })
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("makeLanguagesDataZip");
        request.setMessage("{}");
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                console.log("json", json)
                if (json.response == "Success") {
                    this.setState({ createFileLoader: false, downloadDisable: true })
                }
            }
        });

    }

    // deleteLabel(index) {

    //     let askConfirm = window.confirm("Are You Sure Delete Label !")
    //     let { ui_Json_Array } = this.state
    //     if (askConfirm) {
    //         let removeLabel = ui_Json_Array.filter((data, j) => {
    //             return j != index
    //         })
    //         this.setState({ ui_Json_Array: removeLabel }, () => {
    //             this.deletePostApi("tam")
    //             this.deletePostApi("eng")
    //         })
    //     }

    // }


    deletePostApi(languageType) {
        let { ui_Json_Array, selectPageStatus, selectedLanguageStatus } = this.state
        let lang_Structure = {}
        ui_Json_Array.map(ival => {
            lang_Structure[ival.name] = ival.textField
        })
        // console.log("lang_Structure", lang_Structure)
        let postJson = { key: selectPageStatus, langCode: languageType, langJson: JSON.stringify(lang_Structure) }
        // console.log("postJson", postJson)

        //key: String,langCode:String, langJson :String
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("updateUILanguageData");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                console.log("json", json)
                if (json.response == "Success" && languageType == "eng") {
                    toast.success("Deleted Label!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        });
    }

    getLanguageList() {
        let { grpcClient } = this.props
        let { languageMappedOption } = this.state
        let postJson = { reqId: getUuidv4(), };
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("getLanguagesData");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let langaugeOption = []
                if (Object.keys(json.uiMap).length > 0) {
                    Object.keys(json.uiMap).map((key => {
                        if (languageMappedOption.includes(key) || languageMappedOption.length === 0) {
                            let lan = json.uiMap[key]
                            var obj = JSON.parse(lan)
                            langaugeOption.push({ label: obj["name"], value: key })
                        }
                    }))
                }
                this.setState({
                    langaugeOption
                });
            }
        });
    }
    getEngTxt() {
        let { selectPageStatus, languageStructure_Data } = this.state
        let selectedLanguageStatus = "eng"
        let postJson = { key: selectPageStatus, langCode: selectedLanguageStatus }
        //key: String,langCode:String
        if (selectedLanguageStatus && selectedLanguageStatus) {
            const request = new ZiFetchRequest();
            request.setDomain("ui");
            request.setEvent("getUILanguageData");
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
                if (response == null || response.getMessage() == "Invalid Request") {
                    console.log("Invalid Request. Please try again later")
                } else {
                    let json = JSON.parse(response.getMessage());
                    if (json.response) {
                        let parseData = JSON.parse(json.response)
                        this.setState({ ui_Json_Array_Eng: parseData })
                    }
                    else {
                        this.setState({ ui_Json_Array_Eng: {} })
                    }
                }
            });
        }
    }

    langListDataApi() {
        let roleType = localStorage.getItem("login_RoleType")
        let mUserId = localStorage.getItem("manage_mUserId");
        let { grpcClient } = this.props
        // mUserId: String,rollType:String
        let postJson = { mUserId, rollType: roleType, };
        const request = new ZiFetchRequest();
        request.setDomain("ui");
        request.setEvent("getMappedLanaguageList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        let result = new Promise(resolve => {
            grpcClient.sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage().toLowerCase() === "Invalid Request") {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    resolve(responseData);
                }
            })
        });
        return result;
    }

    getTranslateInfo() {
        let { selectedLanguageStatus } = this.state
        let postJson = { langCode: selectedLanguageStatus }
        //langCode:String
        // console.log("postJson", postJson)
        if (selectedLanguageStatus && selectedLanguageStatus) {
            const request = new ZiFetchRequest();
            request.setDomain("ui");
            request.setEvent("getTranslatedPagesInfo");
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
                if (response == null || response.getMessage() == "Invalid Request") {
                    console.log("Invalid Request. Please try again later")
                } else {
                    let json = JSON.parse(response.getMessage());
                    // console.log(json)
                    if (json.translatedList.length > 0) {
                        this.setState({ newTextShow: json.translatedList })
                    } else {
                        this.setState({ newTextShow: [] })
                    }
                }
            });
        }
    }

    getMissingTextCount() {
        let { ui_Json_Array } = this.state;
        let totalCount = ui_Json_Array.length
        let emptyCount = ui_Json_Array.filter((item) => {
            return item.textField === ""
        }).length;

        return ui_Json_Array.length > 0 && <div className='lang-count-details'>
            <div className='count d-flex'>
                <div className='text' style={{ backgroundColor: "#d0eff2" }}>Total</div>
                <div className='value'>{totalCount}</div>
            </div>
            <div className='count d-flex'>
                <div className='text' style={{ backgroundColor: "#aef6b1" }}>Filled</div>
                <div className='value'>{totalCount - emptyCount}</div>
            </div>
            <div className='count d-flex'>
                <div className='text' style={{ backgroundColor: "#f5ccc9" }}>Not Filled</div>
                <div className='value'>{emptyCount}</div>
            </div>
        </div>
    }

    render() {
        let { menuActive, selectedLanguageStatus, uiJson, ui_Json_Array, selectPageStatus, createFileLoader, laguageValidate, pageValidate, languageStructure_Data, langaugeOption, ui_Json_Array_Eng, pageLoader, newTextShow } = this.state
        import('../admin/style.css');
        let that = this
        let uiOption = []
        // let langaugeOption = [{ label: "english", value: "eng" }, { label: "tamil", value: "tam" }]

        Object.keys(uiJson).length != 0 && Object.keys(uiJson).forEach(function (key, index) {
            let data = uiJson[key]
            uiOption.push({ label: data['label'], value: data['value'], routing: data['routing'] ? data['routing'] : "" })
        });

        const pageFormatOptionLabel = ({ value, label, }) => (
            <div style={{ display: "flex" }}>
                <div>{label}</div>
                {!newTextShow.includes(value) && <span style={{ color: "red", paddingLeft: 5 }}>{'(New)'}</span>}
            </div>
        );


        let newTextMatchValue = [];
        let newTextNotMatchValue = [];

        newTextMatchValue = uiOption.filter((e) => newTextShow.includes(e.value))
        newTextNotMatchValue = uiOption.filter((e) => !newTextShow.includes(e.value))
        // console.log([...newTextNotMatchValue, ...newTextMatchValue,])
        uiOption = [...newTextNotMatchValue, ...newTextMatchValue,]

        return (
            <>
                <div className='row pt-2'>
                    <div className='col-sm-6'>
                        <h3>Language Mapping</h3>
                    </div>
                    <div className='col-sm-6 text-right'>
                        <button class="btn btn-sm btn-success" style={{ fontSize: 14 }} onClick={() => {
                            this.generateFiles()
                        }}
                            disabled={createFileLoader ? true : false}
                        >
                            {createFileLoader && <i className="fa fa-spinner fa-spin"></i>}Create Data Zip
                        </button>
                        <a class="btn btn-sm btn-primary mx-2" href={MyConstant.keyList.apiURL + "downloadLangZip"} download>Download Data Zip</a>
                    </div>
                </div>
                <div className="row mt-2" style={{}}>
                    <div className={"col-sm-3"}>
                        <label className='label-style'> Page  </label>
                        <Select
                            options={uiOption}
                            isSearchable={true}
                            placeholder="Select"
                            onChange={(e) => {
                                this.handleChange_page(e)
                            }}
                            value={uiOption.filter(option => option.value === selectPageStatus)}
                            formatOptionLabel={pageFormatOptionLabel}
                        />
                    </div>
                    <div className={"col-sm-3 "} >
                        <label className='label-style'> Language  </label>
                        <Select
                            options={langaugeOption}
                            isSearchable={true}
                            placeholder="Select"
                            onChange={this.handleChange}
                            value={langaugeOption.filter(option => option.value === selectedLanguageStatus)}
                        />
                        <span className='error-txt'> {laguageValidate} </span>
                    </div>
                    <div className={"col-sm-4 "} >
                        {this.getMissingTextCount()}
                    </div>
                </div>
                <hr />
                {pageLoader && <Loader />}


                <div className={"row mt-3 pl-3 pr-3 "} >
                    {ui_Json_Array.length != 0 && ui_Json_Array.map((ival, i) => {
                        return (<React.Fragment key={i.toString()}>
                            <div className={"col-lg-3 col-sm-4 col-4 pt-2 text-overlab"} >
                                <label className='label-style'>
                                    {ui_Json_Array_Eng[ival.name] ? ui_Json_Array_Eng[ival.name] : ival.name}</label>
                                <input className={`form-control ${(ui_Json_Array[i] && ui_Json_Array[i].textField) ? "" : "is-invalid"}`} type={"text"} placeholder={`Text - ${i + 1}`}
                                    value={ival.textField} onChange={(e) => {
                                        ui_Json_Array[i].textField = e.target.value
                                        this.setState({ ui_Json_Array })
                                    }} />
                            </div>
                        </React.Fragment>)
                    })}
                </div>
                {selectedLanguageStatus && selectPageStatus && !pageLoader &&
                    <div className={"row mt-3 pt-3 mb-5"} style={{}}>
                        <div className={"col-lg-4"} />
                        <div className={"col-lg-4"} >
                            <a href="javascript:void(0)" onClick={() => { this.submitData() }} className={"btn btn-success"}>Submit</a>
                        </div>

                    </div>
                }
            </>
        )
    }
}

export default UILanguage;