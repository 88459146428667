import React from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Cell,
    LineChart,
    Line,
} from "recharts";
import DataTable from "./component/DataTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MyConfig from "../config/MyConfig"
import { checkLogin, readSessionData } from '../config/MyFunctions';


const issueBeingTested = MyConfig.issueBeingTested
const issue_Being_Tested = MyConfig.issue_Being_Tested



class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sDate: new Date(),
            menuActive: "",
            eDate: new Date(),
            dateBasedVisitData: [],
            dateBasedPageData: [],
            dateBasedIp: {},
            columns: [
                {
                    name: 'Date',
                    selector: row => row.date,
                },
                {
                    name: 'IP address',
                    selector: row => row.ipaddress,
                },
                {
                    name: 'Visit count',
                    selector: row => row.visits,
                },
            ],
            ipData: [],
            platformType: "web",
            appRegisterUserData: [],
            counterJsonData: {
                audioData: [],
                videoData: [],
                calendarData: [],
                dietData: [],
                medicationData: []
            },
            issueCount: [],
            totalIssueCount: 0,
            appUserLoginCount: [],
            totalUserCount: 0,
            session: "",
            mUserId: "",
            counterMap: {}
        }
    }

    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        let resData = await readSessionData()
        let { manage_session, manage_mUserId } = resData

        var d = new Date();
        d.setDate(d.getDate() - 5);
        let ds = new Date()
        let yesterday = d
        this.setState({
            sDate: yesterday,
            eDate: ds,
            session: manage_session, mUserId: manage_mUserId
        }, () => {
            this.init()
        });
    }

    init() {
        this.getIssueCount();
        this.searchData();
        this.getAppRegisterData()
        this.getCountList()
        this.getUsersCountList()
    }

    formatDate(date) {
        var year = date.getFullYear().toString();
        var month = (date.getMonth() + 101).toString().substring(1);
        var day = (date.getDate() + 100).toString().substring(1);
        return year + "-" + month + "-" + day;
    }

    searchData() {
        let { sDate, eDate } = this.state;
        sDate = this.formatDate(sDate);
        eDate = this.formatDate(eDate);
        this.setState({ resLoaderView: true })
        let postJson = { sDate, eDate, id: "123" }
        const request = new ZiFetchRequest();
        request.setDomain('user');
        request.setEvent('fetchFilterUserAttemptAnalytics');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() === "Invalid request") {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response) {
                    let response = responseData.response;
                    let dateBasedVisitData = [],
                        dateBasedPageData = [],
                        ipData = [];
                    response.dateBasedUniqueIp.map(ival => {
                        dateBasedVisitData.push({
                            name: this.dateFormatChange(ival.x),
                            y: parseInt(ival.y)
                        });
                    })

                    let dateBasedIp = response.dateBasedIp;
                    let inc = 1;
                    Object.keys(dateBasedIp).map(date => {
                        let data = dateBasedIp[date];
                        Object.keys(data).map(ip => {
                            ipData.push({
                                id: inc,
                                date: this.dateFormatChange(date),
                                ipaddress: ip,
                                visits: dateBasedIp[date][ip]
                            })

                            inc++;
                        })
                    });

                    let dateBasedPageVist = response.dateBasedPageVist;
                    Object.keys(dateBasedPageVist).sort().map(ival => {
                        let data = dateBasedPageVist[ival]
                        dateBasedPageData.push({
                            name: this.dateFormatChange(ival),
                            home: typeof data["Home Page"] !== "undefined" ? parseInt(data["Home Page"]) : 0,
                            about: typeof data["About Page"] !== "undefined" ? parseInt(data["About Page"]) : 0,
                            research: typeof data["Research Page"] !== "undefined" ? parseInt(data["Research Page"]) : 0,
                            videos: typeof data["Videos Page"] !== "undefined" ? parseInt(data["Videos Page"]) : 0,
                            press: typeof data["Press Page"] !== "undefined" ? parseInt(data["Press Page"]) : 0,
                            executive_summary: typeof data["Research Page:Executive Summary Click"] !== "undefined" ? parseInt(data["Research Page:Executive Summary Click"]) : 0,
                            research_paper: typeof data["Research Page:Research Paper Click"] !== "undefined" ? parseInt(data["Research Page:Research Paper Click"]) : 0,
                            migration: typeof data["Migration Page"] !== "undefined" ? parseInt(data["Migration Page"]) : 0,
                            migrationApps: typeof data["Migration Apps Page"] !== "undefined" ? parseInt(data["Migration Apps Page"]) : 0,
                            migrationMedia: typeof data["Migration Videos Page"] !== "undefined" ? parseInt(data["Migration Videos Page"]) : 0,
                            migrationOverview: typeof data["Migration Overview Page"] !== "undefined" ? parseInt(data["Migration Overview Page"]) : 0,
                            tech: typeof data["Tech Page"] !== "undefined" ? parseInt(data["Tech Page"]) : 0,
                            techOverview: typeof data["Tech Overview Page"] !== "undefined" ? parseInt(data["Tech Overview Page"]) : 0,
                            press_release: typeof data["Press Page:Press Release Click"] !== "undefined" ? parseInt(data["Press Page:Press Release Click"]) : 0,
                            pressCityofTijuanaLaunchEvent: typeof data["Press Page:City of Tijuana Launch Event Click"] !== "undefined" ? parseInt(data["Press Page:City of Tijuana Launch Event Click"]) : 0,
                            pressTeatroCineLibertadTijuanaEvent: typeof data["Press Page:Teatro Cine Libertad Tijuana Click"] !== "undefined" ? parseInt(data["Press Page:Teatro Cine Libertad Tijuana Click"]) : 0,
                            pressTijuanaLaunchEnglishPressEvent: typeof data["Press Page:Tijuana Launch - English Press Click"] !== "undefined" ? parseInt(data["Press Page:Tijuana Launch - English Press Click"]) : 0,
                            pressTijuanaLaunchSpanishPressEvent: typeof data["Press Page:Tijuana Launch - Spanish Press Click"] !== "undefined" ? parseInt(data["Press Page:Tijuana Launch - Spanish Press Click"]) : 0,
                        });
                    })

                    ipData = ipData.sort((a,b) => (b.date > a.date) ? 1 : ((a.date > b.date) ? -1 : 0))
                    this.setState({
                        dateBasedVisitData,
                        dateBasedPageData,
                        ipData
                    })
                }
            }
        });
    }

    async typeChange(type) {
        this.setState({ platformType: type })
    }

    async getAppRegisterData() {
        let { sDate, eDate } = this.state
        sDate = this.formatDate(sDate);
        eDate = this.formatDate(eDate);
        // sDate: Option[String] = None, eDate: Option[String] = None, id: String
        let postJson = { sDate, eDate, id: "123" }
        let appRegisterUserData = []
        const request = new ZiFetchRequest();
        request.setDomain('user');
        request.setEvent('getUserAnalyticsBasedOnDate');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() === "Invalid request") {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response) {
                    responseData.response.dateBasedUserCountList.map(ival => {
                        appRegisterUserData.push({
                            name: this.dateFormatChange(ival.x),
                            y: parseInt(ival.y)
                        });
                    })

                    let appUserLoginCount = []
                    responseData.response.dateBasedUserLoginCountList && responseData.response.dateBasedUserLoginCountList.map((ival) => {
                        appUserLoginCount.push({
                            name: this.dateFormatChange(ival.x),
                            y: parseInt(ival.y)
                        });
                    })
                    this.setState({
                        appRegisterUserData,
                        appUserLoginCount
                    })
                }
            }
        });
    }

    getIssueCount() {
        let { session, mUserId } = this.state
        let postJson = { auth: "Teqbahn@321#", msg: "getcounter", mUserId: mUserId, session: session, statusList: MyConfig.statusList }
        const request = new ZiFetchRequest();
        request.setDomain('appIssues');
        request.setEvent('getAppIssuesStatusCounter');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() === "Invalid request") {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                let issueCount = {};
                let totalIssueCount = 0;
                Object.keys(responseData.response).map((key) => {
                    issueCount[key] = parseInt(responseData.response[key]);
                    totalIssueCount += parseInt(responseData.response[key]);
                })
                this.setState({
                    issueCount: [issueCount],
                    totalIssueCount
                })
            }
        });
    }


    async getCountList() {
        // id: String,ratingTypes:List[String]
        let postJson = { id: "123", ratingTypes: ["diet", "calendar", "video", "audio", "medication"] }
        let counterJsonData = []
        const request = new ZiFetchRequest();
        request.setDomain('user');
        request.setEvent('getUserRatingAnalytics');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() === "Invalid request") {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response) {
                    let response = responseData.response;
                    let audio = response.audio
                    let calendar = response.calendar
                    let diet = response.diet
                    let medication = response.medication
                    let video = response.video
                    for (let key = 0; key < 5; key++) {
                        let ival = audio[key].x;
                        counterJsonData.push({
                            name: ival,
                            audio: parseInt(audio[key].y),
                            calendar: parseInt(calendar[key].y),
                            diet: parseInt(diet[key].y),
                            medication: parseInt(medication[key].y),
                            video: parseInt(video[key].y),
                        });
                    }

                    this.setState({
                        counterJsonData
                    })
                }
            }
        });
    }

    dateFormatChange(date) {
        date = new Date(date);
        var year = date.getUTCFullYear().toString();
        var month = (date.getUTCMonth() + 101).toString().substring(1);
        var day = (date.getUTCDate() + 100).toString().substring(1);

        return month + "-" + day + "-" + year;
    }

    async getUsersCountList() {
        let postJson = { auth: "Teqbahn@321#" }
        let counterJsonData = []
        const request = new ZiFetchRequest();
        request.setDomain('admin');
        request.setEvent('getUsersCountDetails');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() === "Invalid request") {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                // console.log("responseData", responseData)                
                let totalUserCount = responseData.totalResult;
                let counterMap = responseData.counterMap

                this.setState({
                    totalUserCount,
                    counterMap: counterMap === undefined ? {} : counterMap
                })

            }
        });
    }


    render() {
        let { menuActive, sDate, eDate, dateBasedVisitData, dateBasedPageData, ipData, columns, platformType, appRegisterUserData, counterJsonData, issueCount, totalIssueCount, appUserLoginCount, counterMap } = this.state;
        import('./style.css');

        return <div className="body-content">
            <div className='d-flex h-100' style={{flexDirection: "column"}}>
                <div className='text-right p-2' style={{backgroundColor: "#fff", borderBottom: "1px solid #ddd"}}>
                    <button className='btn btn-sm btn-primary' onClick={() => this.init()}>Refresh</button>
                </div>
                <div style={{ flex: 1, overflow: "auto"}}>
                    <div className="row">
                        <div className="col-6">
                            <div className="card mx-0">
                                <div className="card-header">
                                    Feeback Analytics - Total Issues ({totalIssueCount})
                                </div>
                                <div className="card-body">
                                    <ResponsiveContainer width="100%" height={300}>
                                        <BarChart data={issueCount}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="Completed" name="Completed" fill="#ed3833" />
                                            <Bar dataKey="Feature_Addition" name="Feature Addition" fill="#9c3cb0" />
                                            {/* <Bar dataKey="Issue_Fixed" name="Issue Fixed" fill="#2196f3" /> */}
                                            <Bar dataKey={issue_Being_Tested} name={issueBeingTested} fill="#2196f3" />
                                            <Bar dataKey="Pending" name="Pending" fill="#8bc34a" />
                                            <Bar dataKey="Processing" name="Processing" fill="#607d8b" />
                                            <Bar dataKey="Reopened" name="Reopened" fill="#795548" />
                                            <Bar dataKey="Not_an_App_Issue" name="Not an App Issue" fill="#ffc107" />
                                            <Bar dataKey="Duplicate" name="Duplicate" fill="#673ab7" />
                                            <Bar dataKey="Clarification_Required" name="Clarification Required" fill="#3f51b5" />
                                        </BarChart>
                                    </ResponsiveContainer>

                                </div>
                            </div>
                        </div>


                        <div className="col-6">
                            <div className="card mx-0">
                                <div className="card-header">
                                    Total Users Count
                                </div>

                                <div className='row mx-0'>
                                    <div className="col-md-6 counter-body">
                                        <div className="counter-box">
                                            <i className="fa fa-users"></i>
                                            <span className="counter">
                                                Users<br />
                                                {this.state.totalUserCount}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card m-0">
                                <div className="card-header">
                                    Analytics by Day
                                </div>
                                <div className="card-body">
                                    <div className="row pt-2 pb-2">
                                        <div className="col-4 d-flex">
                                            <div style={{ lineHeight: '38px' }}>
                                                <label>Start Date</label>
                                            </div>
                                            <div style={{ flex: 1, paddingLeft: 10 }}>
                                                <DatePicker
                                                    className="form-control"
                                                    dateFormat="MM/dd/yyyy"
                                                    selected={sDate}
                                                    onChange={(date) => {
                                                        this.setState({ sDate: date })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4 d-flex">
                                            <div style={{ lineHeight: '38px' }}>
                                                <label>End Date</label>
                                            </div>
                                            <div style={{ flex: 1, paddingLeft: 10 }}>
                                                <DatePicker
                                                    className="form-control"
                                                    dateFormat="MM/dd/yyyy"
                                                    selected={eDate}
                                                    onChange={(date) => {
                                                        this.setState({ eDate: date })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <button className="btn btn-primary" onClick={() => {
                                                this.searchData()
                                                this.getAppRegisterData()
                                            }

                                            }>Search Data</button>
                                        </div>
                                    </div>

                                    <div className="tabs-container">
                                        <div className="custom-tabs">
                                            <div className={`tab ${platformType === "web" ? "active" : ""}`} onClick={() => {
                                                this.typeChange("web")
                                            }}
                                            >
                                                Web
                                            </div>
                                            <div className={`tab ${platformType === "app" ? "active" : ""}`} onClick={() => {
                                                this.typeChange("app")
                                            }}
                                            >
                                                App
                                            </div>
                                        </div>
                                        <div className="tabs-body">

                                            {
                                                platformType == "web" && <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="card mx-0">
                                                                <div className="card-header blue">
                                                                    Visits analytics by Day
                                                                </div>
                                                                <div className="card-body">
                                                                    <ResponsiveContainer width="100%" height={300}>
                                                                        <LineChart
                                                                            data={dateBasedVisitData}
                                                                        >
                                                                            <CartesianGrid strokeDasharray="3 3" />
                                                                            <XAxis dataKey="name" />
                                                                            <YAxis allowDecimals={false} />
                                                                            <Tooltip />
                                                                            <Legend />
                                                                            <Line type="linear" strokeWidth={3} dataKey="y" stroke="#ed3833" name={"Visits"} />
                                                                        </LineChart>
                                                                    </ResponsiveContainer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="card mx-0">
                                                                <div className="card-header blue">
                                                                    IP analytics by Day
                                                                </div>
                                                                <div className="card-body">
                                                                    <DataTable
                                                                        title=""
                                                                        columns={columns}
                                                                        data={ipData}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">

                                                        <div className="col-12">
                                                            <div className="card mx-0">
                                                                <div className="card-header blue">
                                                                    Page analytics by Day
                                                                </div>
                                                                <div className="card-body">
                                                                    <ResponsiveContainer width="100%" height={600}>
                                                                        <LineChart
                                                                            data={dateBasedPageData}
                                                                        >
                                                                            <CartesianGrid strokeDasharray="3 3" />
                                                                            <XAxis dataKey="name" />
                                                                            <YAxis allowDecimals={false} />
                                                                            <Tooltip />
                                                                            <Legend />
                                                                            <Line type="linear" strokeWidth={3} dataKey="home" stroke="#ed3833" name={"Home Page"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="about" stroke="#9c3cb0" name={"About Page"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="press" stroke="#2196f3" name={"Press Page"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="videos" stroke="#8bc34a" name={"Videos Page"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="research" stroke="#607d8b" name={"Research Page"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="executive_summary" stroke="#795548" name={"Executive Summary"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="research_paper" stroke="#ffc107" name={"Research Paper"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="press_release" stroke="#673ab7" name={"Press Release"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="pressCityofTijuanaLaunchEvent" stroke="#673ab7" name={"City of Tijuana Launch Event - Click"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="pressTeatroCineLibertadTijuanaEvent" stroke="#673ab7" name={"Teatro Cine Libertad Tijuana - Click"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="pressTijuanaLaunchSpanishPressEvent" stroke="#673ab7" name={"Tijuana Launch - Spanish Press - Click"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="pressTijuanaLaunchEnglishPressEvent" stroke="#673ab7" name={"Tijuana Launch - English Press - Click"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="migration" stroke="#9c3cb0" name={"Migration Page"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="migrationApps" stroke="#2196f3" name={"Migration Apps"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="migrationMedia" stroke="#8bc34a" name={"Migration Media"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="migrationOverview" stroke="#607d8b" name={"Migration Overview"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="tech" stroke="#795548" name={"Tech Page"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="techOverview" stroke="#ffc107" name={"Tech Overview Page"} />
                                                                        </LineChart>
                                                                    </ResponsiveContainer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            }

                                            {
                                                platformType === "app" && <>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="card mx-0">
                                                                <div className="card-header blue">
                                                                    Registered User by day
                                                                </div>
                                                                <div className="card-body">
                                                                    <ResponsiveContainer width="100%" height={300}>
                                                                        <LineChart
                                                                            data={appRegisterUserData}
                                                                        >
                                                                            <CartesianGrid strokeDasharray="3 3" />
                                                                            <XAxis dataKey="name" />
                                                                            <YAxis allowDecimals={false} />
                                                                            <Tooltip />
                                                                            <Legend />
                                                                            <Line type="linear" strokeWidth={3} dataKey="y" stroke="#ed3833" name={"Users"} />
                                                                        </LineChart>
                                                                    </ResponsiveContainer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="card mx-0">
                                                                <div className="card-header blue">
                                                                    User Login By Day
                                                                </div>
                                                                <div className="card-body">
                                                                    <ResponsiveContainer width="100%" height={300}>
                                                                        <LineChart
                                                                            data={appUserLoginCount}
                                                                        >
                                                                            <CartesianGrid strokeDasharray="3 3" />
                                                                            <XAxis dataKey="name" />
                                                                            <YAxis allowDecimals={false} />
                                                                            <Tooltip />
                                                                            <Legend />
                                                                            <Line type="linear" strokeWidth={3} dataKey="y" stroke="#ed3833" name={"Users"} />
                                                                        </LineChart>
                                                                    </ResponsiveContainer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="card mx-0">
                                                                <div className="card-header blue">
                                                                    Rating Analytics
                                                                </div>
                                                                <div className="card-body">
                                                                    <ResponsiveContainer width="100%" height={300}>
                                                                        <LineChart
                                                                            data={counterJsonData}
                                                                        >
                                                                            <CartesianGrid strokeDasharray="3 3" />
                                                                            <XAxis dataKey="name" />
                                                                            <YAxis allowDecimals={false} />
                                                                            <Tooltip />
                                                                            <Legend />
                                                                            <Line type="linear" strokeWidth={3} dataKey="audio" stroke="#ed3833" name={"Audio"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="video" stroke="#9c3cb0" name={"Video"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="calendar" stroke="#2196f3" name={"Calendar"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="diet" stroke="#8bc34a" name={"Diet"} />
                                                                            <Line type="linear" strokeWidth={3} dataKey="medication" stroke="#607d8b" name={"Medication"} />
                                                                        </LineChart>
                                                                    </ResponsiveContainer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default Dashboard;
