import React from 'react';
import whiteLogo from '../images/whiteLogo.png';
import image3 from '../images/Teqbahn_logo1.png';
import MyConstant from '../config/MyConstant';


class ManageLogout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logoChanged: false
        }
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps) {
        let { userProfilePhoto } = this.props;
        if (prevProps.userProfilePhoto !== userProfilePhoto) {
            let { logoChanged } = this.state
            this.setState({ logoChanged: !logoChanged })
        }

    }
    render() {
        var manageIsLogin = localStorage.getItem("managelogedin")
        var manageLoggedUser = localStorage.getItem("manageLoggedUser")
        var loginType = localStorage.getItem("login_RoleType")
        var profile = localStorage.getItem("manage_profile")
        var mUserId = localStorage.getItem("manage_mUserId")
        let compnayLogo = image3
        if (manageIsLogin) {
            compnayLogo = whiteLogo
        }
        let { navigation } = this.props

        let profileIcon = "";
        if (profile) {
            let split = profile.split(".")
            profileIcon = MyConstant.keyList.apiURL + "vp?action=aProfile&key=" + split[0] + "." + split[1] + "&id=" + mUserId
        }

        return (
            <div className="right-menu">
                <div style={{ width: 200, paddingRight: 10 }}>
                    <img className="img-fluid" src={compnayLogo} />
                </div>
                {manageIsLogin == "manage" &&
                    <div className="dropdown" style={{}}>
                        {
                            profileIcon ? <div className='profile-icon' style={{ backgroundImage: `url(${profileIcon})` }}>
                            </div> : <i className="fa fa-user dropbtn" style={{ color: "#fff", fontSize: 25, cursor: "pointer" }} aria-hidden="true"></i>
                        }

                        <div className="dropdown-content" >
                            <div>
                                <b className='first-upper-case'>{manageLoggedUser}</b><br />
                                <b>{"(" + loginType + ")"}</b>
                            </div>
                            <a onClick={() => {
                                navigation.push("/admin/myprofile")
                            }}>{"Profile"}</a>
                            <a onClick={() => {
                                navigation.push("/admin/reset-password")
                            }}>{"Reset password"}</a>
                            <a onClick={() => {
                                localStorage.removeItem("managelogedin");
                                localStorage.removeItem("manageLoggedUser");
                                navigation.push("/manage")
                            }}>Logout</a>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ManageLogout;