import React from 'react';

export default class Tabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0
        }
        this.scrollRef = React.createRef();
    }
    componentDidMount() {

    }
    componentDidUpdate(prevProps){
        if (this.props.tabs.length > prevProps.tabs.length ) {
            this.scrollRef.current.scrollLeft = this.scrollRef.current.scrollWidth;
        }
    }
    
    render() {
        let { tabs, activeIndex } = this.props;
        if(typeof activeIndex === "undefined") {
            activeIndex = this.state.activeIndex;
        }

        return <div className="tabs">
            <div className="tabs-header">
                <div className="tabs-left">
                    <i className="fas fa-chevron-left" onClick={(e) => {
                        this.scrollRef.current.scrollLeft -= 250;
                    } }></i>
                </div>
                <div className="tabs-header-content" ref={this.scrollRef}>
                {
                    tabs.map((tab, tabIndex) => {
                        return <div key={tabIndex} className={`tab ${tabIndex === activeIndex ? "active": ""}` } onClick={() => this.props.setActiveTab(tabIndex) }>
                            <span>{tab.title}</span>
                            <i className="fas fa-times" onClick={(e) => this.props.removeTab(e, tabIndex) }></i>
                        </div>
                    })
                }
                </div>
                <div className="tabs-right">
                    <i className="fas fa-chevron-right" onClick={(e) => {
                        this.scrollRef.current.scrollLeft += 250;
                    } }></i>
                </div>
            </div>
            <div className="tabs-body">
            {
                tabs.length > 0 && tabs[activeIndex].comp
            }
            </div>
        </div>;
    }
}
