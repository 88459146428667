import React, { Component } from 'react';
// import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
// import Logo1 from "../images/UMEDME_LP1.jpg"
import Logo2 from "../images/UMEDME_logo_wht.png"



class LoginLanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserName: "",
            Password: "",
            errors: {
                UserName: "",
                Password: ""
            }
        }
    }

    getLogin() {
        let { UserName, Password } = this.state;
        let errors = {}
        if (!UserName) {
            errors.UserName = "Please enter the username"
        }
        if (!Password) {
            errors.Password = "Please enter the password"
        }

        if (Object.keys(errors).length === 0) {
            if (UserName === "umedme" && Password === "LMqDJ95c") {
                localStorage.setItem("demo-user-login", true)
                // this.props.history.push("/videos")
                this.props.history.push("/v2home")
            } else {
                alert("Incorrect Username/Password")
            }
        } else {
            this.setState({ errors })
        }
    }

    render() {
        let { UserName, Password, errors } = this.state

        return (

            <React.Fragment>
                <main className="login-body d-flex align-items-center min-vh-100 py-3 py-md-0 bglanding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <img className="img-fluid" src={Logo2} />
                            </div>
                        </div>

                        <div className="card login-card">

                            <div className="row no-gutters">
                                {/* <img className="img-logo1" src={Logo1} width="200px" height="200px"/> */}
                                <div className="col-md-3" />
                                <div className="col-md-6">
                                    <div className="card-body">
                                        <p className="login-card-description"> Login</p>
                                        <form action="">
                                            <div className="form-group">
                                                <label className="sr-only">Username</label>
                                                <input type="email" name="email" id="email"
                                                    className={"form-control"}
                                                    placeholder="Username" value={UserName} onChange={(e) => {
                                                        this.setState({ UserName: e.target.value })
                                                    }} />
                                                <div className="custom-invalid-feedback">{errors.UserName}</div>
                                            </div>
                                            <div className="form-group mb-4">
                                                <label className="sr-only">Password</label>
                                                <input type="password" name="password" id="password"
                                                    className={"form-control"}
                                                    placeholder="***********" value={Password} onChange={(e) => {
                                                        this.setState({ Password: e.target.value })
                                                    }} />
                                                <div className="custom-invalid-feedback">{errors.Password}</div>
                                            </div>
                                            <button type="button" className="btn btn-block login-btn mb-4" onClick={() => this.getLogin()}>Login</button>
                                        </form>


                                    </div>
                                </div>
                                <div className="col-md-3" />
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}

export default LoginLanding;