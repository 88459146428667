import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history'
import Home from './pages/Home';
import HomeV3 from './pages/v3/Home';
import AboutV3 from './pages/v3/About';
import VideoV3 from './pages/v3/Video';
import ResearchV3 from './pages/v3/Research';
import PressV3 from './pages/v3/Press';
import MigrationV3 from './pages/v3/Migration';
import MigrationOverViewV3 from './pages/v3/MigrationOverView';
import UmedmeTechV3 from './pages/v3/UmedmeTech';


import About from './pages/About';
import About1 from './pages/About1';
import MyConstant from './config/MyConstant';
import AdminLogin from './admin/AdminLogin';
import LoginLanding from './pages/LoginLanding';
import { getMyIp } from './config/MyFunctions';

import UpdateIssueData from './admin/UpdateIssueData';
import Signup from './Signup';
import ManageLogin from './manage/ManageLogin';
import AdminRoute from './AdminRoute';
import ManageRoute from './ManageRoute';
import ManageForgotPassword from './manage/ManageForgotPassword';
import NoMatch from './pages/NoMatch';
import Apps from './manage/Apps';
import UpdateAdminIssueData from './adminIssues/UpdateAdminIssueData';

import Call from "./call/app/Call";
import JoinCall from "./call/app/JoinCall";
import './NewVersionTwo/versiontwo.css'
import Blog from "./NewVersionTwo/Blog"
import FeedBackNew from "./NewVersionTwo/FeedBackNew"
import NewEditIssue from "./NewVersionTwo/NewEditIssue"
import NewForgotPassword from "./NewVersionTwo/NewForgotPassword"
import NewPrivacyPolicy from "./NewVersionTwo/NewPrivacyPolicy"
import ManageJoin from './manage/ManageJoin';
import Tech2 from './pages/v3/Tech2';
// import WelcomeBanner from './pages/v3/component/WelcomeBanner';
import MigrationApps from './pages/v3/MigrationApps';
import MigrationMedia from './pages/v3/MigrationMedia';
import MigrationTest from './pages/v3/MigrationTest';




const {
  ZiFetchServiceClient,
} = require('./protobuf/generated/ZiFetchService_grpc_web_pb');
let grpcClient = new ZiFetchServiceClient(MyConstant.keyList.grpcURL, {}, {});

const speakupProto = require('./protobuf/speakup/speakup_grpc_web_pb');
let speakupGrpcClient = new speakupProto.ZiFetchServiceClient(MyConstant.keyList.speakupGrpcURL, {}, {});

const history = createBrowserHistory();
export default class RouterApp extends React.Component {
  componentDidMount() {
    getMyIp().then((data) => {
      localStorage.setItem("ipAddress", data);
    });
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path={MyConstant.keyList.projectUrl + "/about2"} history={history} render={(props) =>
            <About {...props} />
          } />
          <Route exact path={MyConstant.keyList.projectUrl + "/umedme/about"} history={history} render={(props) =>
            <About1 {...props} />
          } />

          {/* New landing page start*/}
          <Route exact path={MyConstant.keyList.projectUrl + "/login"} history={history} render={(props) =>
            <LoginLanding grpcClient={grpcClient}  {...props} />
          } />
          <Route exact path={MyConstant.keyList.projectUrl + "/sign-up"} history={history} render={(props) =>
            <Signup grpcClient={grpcClient}  {...props} />
          } />
          <Route exact path={"/umedme/update-feedback-data/:id"} history={history} render={(props) =>
            <UpdateIssueData grpcClient={grpcClient}  {...props} />
          } />
          <Route exact path={"/umedme/updateIssueData/:id"} history={history} render={(props) =>
            <UpdateIssueData grpcClient={grpcClient}  {...props} />
          } />

          <Route exact path={"/update-feedback-data/:id"} history={history} render={(props) =>
            <UpdateIssueData grpcClient={grpcClient}  {...props} />
          } />
          <Route exact path={"/updateIssueData/:id"} history={history} render={(props) =>
            <UpdateIssueData grpcClient={grpcClient}  {...props} />
          } />



          <Route exact path={MyConstant.keyList.projectUrl + "/admin"} history={history} render={(props) =>
            <AdminLogin grpcClient={grpcClient}  {...props} />
          } />
          <Route path={MyConstant.keyList.projectUrl + "/admin"} history={history} render={(props) =>
            <AdminRoute grpcClient={grpcClient} speakupGrpcClient={speakupGrpcClient} {...props} />
          } />

          <Route exact path={MyConstant.keyList.projectUrl + "/manage"} history={history} render={(props) =>
            <ManageLogin grpcClient={grpcClient}  {...props} />
          } />
          <Route exact path={MyConstant.keyList.projectUrl + "/manage-join/:inviteId"} history={history} render={(props) =>
            <ManageJoin grpcClient={grpcClient}  {...props} />
          } />
          <Route exact path={MyConstant.keyList.projectUrl + "/manage-forgot-password"} history={history} render={(props) =>
            <ManageForgotPassword grpcClient={grpcClient}  {...props} />
          } />

          <Route path={MyConstant.keyList.projectUrl + "/manage"} history={history} render={(props) =>
            <ManageRoute grpcClient={grpcClient}  {...props} />
          } />
          <Route path={MyConstant.keyList.projectUrl + "/auth/:token"} history={history} render={(props) =>
            <Apps grpcClient={grpcClient}  {...props} />
          } />
          <Route path={MyConstant.keyList.projectUrl + "/apps/:token"} history={history} render={(props) =>
            <Apps grpcClient={grpcClient}  {...props} />
          } />

          <Route exact path={MyConstant.keyList.projectUrl + "/update-admin-feedback/:id"} history={history} render={(props) =>
            <UpdateAdminIssueData grpcClient={grpcClient}  {...props} />
          } />

          <Route path={`${MyConstant.keyList.projectUrl}/umedme/app-call/:emailid/:roomId/:userId/:name`}>
            <Call grpcClient={grpcClient} history={history} />
          </Route>
          <Route path={`${MyConstant.keyList.projectUrl}/umedme/app-join-call/:emailid/:roomId/:userId/:name`}>
            <JoinCall grpcClient={grpcClient} history={history} />
          </Route>

          {/* ---NewVersion----- */}

          <Route exact path={MyConstant.keyList.projectUrl + "/downloads"} history={history} render={(props) =>
            <Blog grpcClient={grpcClient} {...props} />
          } />

          <Route exact path={MyConstant.keyList.projectUrl + "/app-feedback"} history={history} render={(props) =>
            <FeedBackNew grpcClient={grpcClient} {...props} />
          } />

          <Route exact path={MyConstant.keyList.projectUrl + "/edit-feedback/:editId"} history={history} render={(props) =>
            <NewEditIssue grpcClient={grpcClient}  {...props} />
          } />

          <Route exact path={MyConstant.keyList.projectUrl + "/forgot-password"} history={history} render={(props) =>
            <NewForgotPassword grpcClient={grpcClient}  {...props} />
          } />

          <Route exact path={MyConstant.keyList.projectUrl + "/privacy-policy"} history={history} render={(props) =>
            <NewPrivacyPolicy {...props} />
          } />

          {/* ---Version 3----- */}
          <Route exact path={MyConstant.keyList.projectUrl + "/"} history={history} render={(props) =>
            <div className='banner-v3'>
              {/* <WelcomeBanner page={"home"} /> */}
              <HomeV3 grpcClient={grpcClient} {...props} />
            </div>
          } />
          <Route exact path={MyConstant.keyList.projectUrl + "/migration/:focusId?"} history={history} render={(props) =>
            <div className='banner-v3'>
              {/* <WelcomeBanner page={"home"} /> */}
              <MigrationV3 grpcClient={grpcClient} {...props} />
            </div>
          } />
          <Route exact path={MyConstant.keyList.projectUrl + "/migration-apps"} history={history} render={(props) =>
            <div className='banner-v3'>
              {/* <WelcomeBanner page={"home"} /> */}
              <MigrationApps grpcClient={grpcClient} {...props} />
            </div>
          } />
          <Route exact path={MyConstant.keyList.projectUrl + "/migration-media"} history={history} render={(props) =>
            <div className='banner-v3'>
              {/* <WelcomeBanner page={"home"} /> */}
              <MigrationMedia grpcClient={grpcClient} {...props} />
            </div>
          } />
          <Route exact path={MyConstant.keyList.projectUrl + "/tech"} history={history} render={(props) =>
            <div className='banner-v3'>
              {/* <WelcomeBanner page={"tech"} /> */}
              <Tech2 grpcClient={grpcClient} {...props} />
            </div>
          } />
          <Route exact path={MyConstant.keyList.projectUrl + "/about"} history={history} render={(props) =>
            <div className='banner-v3'>
              {/* <WelcomeBanner page={"home"} /> */}
              <AboutV3 grpcClient={grpcClient} {...props} />
            </div>
          } />

          <Route exact path={MyConstant.keyList.projectUrl + "/videos"} history={history} render={(props) =>
            <div className='banner-v3'>
              {/* <WelcomeBanner page={"home"} /> */}
              <VideoV3 grpcClient={grpcClient} {...props} />
            </div>
          } />

          <Route exact path={MyConstant.keyList.projectUrl + "/research"} history={history} render={(props) =>
            <div className='banner-v3'>
              {/* <WelcomeBanner page={"home"} /> */}
              <ResearchV3 grpcClient={grpcClient} {...props} />
            </div>
          } />

          <Route exact path={MyConstant.keyList.projectUrl + "/press"} history={history} render={(props) =>
            <div className='banner-v3'>
              {/* <WelcomeBanner page={"home"} /> */}
              <PressV3 grpcClient={grpcClient} {...props} />
            </div>
          } />

          <Route exact path={MyConstant.keyList.projectUrl + "/migration-overview"} history={history} render={(props) =>
            <div className='banner-v3'>
              {/* <WelcomeBanner page={"home"} /> */}
              <MigrationOverViewV3 grpcClient={grpcClient} {...props} slide={"responsive"} />
            </div>
          } />
          <Route exact path={MyConstant.keyList.projectUrl + "/tech-overview"} history={history} render={(props) =>
            <div className='banner-v3'>
              {/* <WelcomeBanner page={"home"} /> */}
              <UmedmeTechV3 grpcClient={grpcClient} {...props} />
            </div>
          } />

          <Route path="*">
            <NoMatch />
          </Route>

        </Switch>
      </Router>
    );
  }
}
