import React, { Component } from 'react';
// import './style.css';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import Pagination from "./Pagination"
import image1 from '../images/UMEDME_logo_white.png';
import image2 from '../images/UMEDME_new.png';
import image3 from '../images/Teqbahn_logo1.png';
import SideManuBar from './SideMenuBar';

export default class ReviewList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuActive: "",
            tableData: [],
            processing: true,
            search: "",
            resultArray: [],
            pagination: [],
            noPagesLimit: 1,
            totalPagesResults: "",
            headerData: []
        }
    }

    async componentDidMount() {
        var loginincheck = await localStorage.getItem("logedin")
        if (!loginincheck) {
            this.props.history.push("/admin/login")
        }
        if (loginincheck)
            this.getAllUserReview()
    }

    getAllUserReview() {
        // pageLimit: Int, noOfPage:Int, totalResult: Long
        let { tableData, totalPagesResults, noPagesLimit } = this.state
        let DataResult = []
        let headerData = []
        let postJson = { pageLimit: 10, noOfPage: noPagesLimit, totalResult: 10 }
        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('admin');
        request.setEvent('getAllUserRatings');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {

            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg && responseMsg.response) {
                    console.log("responseMsg", responseMsg)
                    var responseData = responseMsg.response
                    totalPagesResults = responseMsg.totalResult

                    Object.keys(responseData).map((jva, j) => {
                        // console.log(responseData[jva])
                        if (j == 0) {
                            headerData.push({
                                userId: responseData[jva].userId,
                                ratingType: responseData[jva].ratingType,
                                rating: responseData[jva].rating, description: responseData[jva].descrition,
                                createdAt: new Date(responseData[jva].createAt).toLocaleString(),
                            })
                        }

                        DataResult.push({
                            userId: responseData[jva].userId,
                            ratingType: responseData[jva].ratingType,
                            rating: responseData[jva].rating, description: responseData[jva].descrition,
                            createdAt: new Date(responseData[jva].createAt).toLocaleString(),
                        })

                    })

                    // console.log("tableData", tableData)

                    await this.setState({ tableData: DataResult, totalPagesResults, processing: false, headerData })
                }
            }
        });
    }

    onSearch = e => {
        let { tableData } = this.state;
        let search = e.target.value.toString().toLowerCase();
        let resultArray = [];

        tableData.map(ival => {
            let isThere = false;
            Object.values(ival).map(jval => {
                if (jval.toString().toLowerCase().indexOf(search) !== -1) {
                    isThere = true;
                }
            })
            if (isThere) {
                resultArray.push(ival);
            }
        })
        this.setState({ resultArray, search });
    }

    async movePage(type) {
        let { noPagesLimit } = this.state

        if (type == "next") {
            noPagesLimit = noPagesLimit + 1

        }
        else if (type == "prev") {
            noPagesLimit = noPagesLimit - 1

        }
        await this.setState({ noPagesLimit, processing: true })
        await this.getAllUserReview()
    }

    render() {
        let { menuActive, processing, tableData, search, resultArray, pagination, totalPagesResults, noPagesLimit, headerData } = this.state
        import('./style.css');


        tableData = search.trim() || resultArray.length ? [...resultArray] : [...tableData];
        let pageCount = Math.ceil((totalPagesResults / 10))
        if (pageCount) {
        }
        else {
            pageCount = 1
        }

        return (
            <>
                <div class="wrapper">
                    <SideManuBar menuActive={menuActive} typeofPage={"reviewlist"} navigation={this.props.history} />
                    <div id="content">
                        <nav class="navbar navbar-expand-lg navbar-light bg-light">
                            <div class="container-fluid">

                                <button type="button" id="sidebarCollapse" class="btn btn-info" onClick={() => {

                                    if (!menuActive) {
                                        menuActive = "active"
                                    }
                                    else {
                                        menuActive = ""
                                    }

                                    this.setState({ menuActive })
                                }}>
                                    <i class="fas fa-align-left"></i>
                                    <span> {menuActive ? "Expand" : "Hide"} </span>
                                </button>

                                <img className="img-Teqlogo" src={image3} width="250px" height="70px" />
                            </div>
                        </nav>

                        {/*body of text */}
                        <div className="body-content">
                            <div className="row mb-2">
                                <div className="col-2 ml-auto">
                                    <input type="text" placeholder="search" className="form-control" value={this.state.search} onChange={this.onSearch} />
                                </div>
                            </div>
                            <div className={`row ${processing && "table-processing"}`}>
                                {processing && <div className="processing"><span>Processing...</span></div>}
                                <div className="col-sm-12">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                {
                                                    headerData.length != 0 && Object.keys(headerData[0]).map((value, index) => {
                                                        let str = value.charAt(0).toUpperCase() + value.substr(1);
                                                        str = str.match(/[A-Z][a-z]+|[0-9]+/g);
                                                        return <th key={index} >
                                                            {str != null && str.length > 0 ? str.join(" ") : value}
                                                        </th>
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.map((row, index) => {
                                                    return <tr key={index}   >
                                                        {
                                                            Object.keys(row).map((key, i) => {
                                                                return <td key={index + "_" + key} onClick={() => {

                                                                }}>{row[key]}</td>
                                                            })
                                                        }
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <Pagination pagination={pagination}
                                        prev={noPagesLimit != pageCount || noPagesLimit == 1 ? false : true}
                                        next={noPagesLimit != pageCount ? true : false} movePage={(type) => {
                                            this.movePage(type)
                                        }} />
                                </div>
                            </div>
                        </div>
                        {/* body of text*/}
                    </div>
                </div>
            </>
        )
    }
}
