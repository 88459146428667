import React from "react";
import { pageNumbers } from '../config/MyFunctions';
import Pagination from './Pagination';
import Modal from "./component/Modal";
import { ZiFetchRequest } from "../protobuf/generated/ZiFetchService_grpc_web_pb";

export default class SpeakupSubmit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            project: "umedme",
            noPagesLimit: 1,
            resData: [],
            prev: false,
            next: false,
            processing: true,
            pagination: [],
            viewStory: false,
            viewStoryData: {}
        }
        this.movePage = this.movePage.bind(this);
    }

    componentDidMount() {
        this.getSpeakupSubscribeList();
    }

    getSpeakupSubscribeList() {
        let { noPagesLimit } = this.state;
        let postJson = { pageLimit: 10, noOfPage: noPagesLimit };
        const request = new ZiFetchRequest();
        request.setDomain('speakUp');
        request.setEvent('getSpeakUpSubmissionList');
        request.setMessage(JSON.stringify(postJson));
        this.props.speakupGrpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() === 'Invalid Request' || response.getMessage() === 'Invalid request') {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg && responseMsg.response) {
                    let responseData = responseMsg.result
                    let next = false;
                    let prev = false;
                    let totalResult = responseMsg.totalResult
                    let currentResult = noPagesLimit * 10;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noPagesLimit > 1) {
                        prev = true;
                    }

                    let pageCount = Math.ceil(totalResult / 10);
                    let pagination = pageNumbers(pageCount, noPagesLimit, 5);
                    this.setState({ resData: responseData, prev, next, processing: false, pagination: pagination });
                }
            }
        });
    }

    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }

        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getSpeakupSubscribeList();
            })
        }
    }

    humanDateFormat(time) {
        return new Date(time).toLocaleString()
    }

    viewStory(data) {
        console.log(data)
        this.setState({
            viewStory: true,
            viewStoryData: JSON.parse(data)
        })
    }

    render() {
        let { project, pagination, prev, next, noPagesLimit, processing, resData, viewStory, viewStoryData } = this.state;
        return <div className="body-content p-top">
            <div className="main-content">
                <div className={`row mx-0 ${processing && "table-processing"}`}>
                    {processing && <div className="processing"><span>Processing...</span></div>}
                    <div className="col-sm-12">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Email</th>
                                    <th scope="col">Story</th>
                                    <th scope="col">Created At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(resData).length === 0 ? <tr>
                                        <td className="text-center" colSpan={3}>No Record(s) Found.</td>
                                    </tr> : Object.keys(resData).map((key, index) => {
                                        let data = resData[key];
                                        return <tr key={index}>
                                            <td>{data.email}</td>
                                            <td><button className="btn btn-sm btn-success" onClick={() => this.viewStory(data.json)}>View</button></td>
                                            <td>{this.humanDateFormat(data.createdAt)}</td>
                                        </tr>
                                    })}
                            </tbody>

                        </table>
                        <Pagination
                            pagination={pagination}
                            prev={prev}
                            next={next}
                            currentPage={noPagesLimit}
                            movePage={(type) => {
                                this.movePage(type)
                            }} />
                    </div>
                </div>
            </div>

            {
                viewStory && <Modal
                    visible={viewStory}
                    size={"modal-xl"}
                    closeModal={() => this.setState({ viewStory: false })}
                    heading={"Speak Up Submission"}
                    body={<React.Fragment>
                        <div className="question-box">
                            <label style={{ fontWeight: 'bold' }}>Submit how you yourself have overcome adversity or handled a difficult situation! Submit an incident where someone stood up for you and advocated for you!</label>
                            <div>{viewStoryData.question1}</div>
                        </div>
                        <div className="question-box">
                            <label style={{ fontWeight: 'bold' }}>Submit your story of how SpeakUpCoalition has had an impact on you, your residency, your training, or your workplace!</label>
                            <div>{viewStoryData.question2}</div>
                        </div>
                        <div className="question-box">
                            <label style={{ fontWeight: 'bold' }}>ONLY if you would like to disclose your identity to SpeakUpCoalition, please include your information below (Name, Email, Phone, Social Media Handles).</label>
                            <div>{viewStoryData.question3}</div>
                        </div>
                        <div className="question-box">
                            <label style={{ fontWeight: 'bold' }}>REPORTING EXPERIENCE: Please tell us your experience when you tried to report an abusive behavior. Was the situation resolved? Did you face retaliation? Help us understand the process you experienced.</label>
                            <div>{viewStoryData.question4}</div>
                        </div>
                        <div className="question-box">
                            <label style={{ fontWeight: 'bold' }}>AWARENESS: Please tell us your story that you'd like to submit for use on the SpeakUpCoalition social platforms. All stories will remain anonymous, and any identifying details of personal identity or institution will be removed. By submitting this story, you consent to its anonymous publication by SpeakUpCoalition. Your submission may be shortened to fit the platform (i.e. twitter, facebook, instagram), while maintaining the integrity of the message.</label>
                            <div>{viewStoryData.question5}</div>
                        </div>
                        <div className="question-box">
                            <label style={{ fontWeight: 'bold' }}>ADVOCACY: Please share instances in which you have witnessed or have directly been a part of active bystander advocacy when there has been bias, harassment or abuse.</label>
                            <div>{viewStoryData.question6}</div>
                        </div>
                        <div className="question-box">
                            <label style={{ fontWeight: 'bold' }}>ACTION: Share your ideas for creating a better work environment so that we can use your recommendations to implement change.</label>
                            <div>{viewStoryData.question7}</div>
                        </div>
                        <div className="question-box">
                            <label style={{ fontWeight: 'bold' }}>Region You Are From:</label>
                            <div>{viewStoryData.selectedCountry}</div>
                        </div>
                    </React.Fragment>
                    }
                />
            }
        </div>;
    }
}