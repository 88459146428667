import MyConstant from './MyConstant';
import { v4 as uuidv4 } from 'uuid';


const {
    ZiFetchServiceClient, ZiFetchRequest
} = require('../protobuf/generated/ZiFetchService_grpc_web_pb');

let grpcClient = new ZiFetchServiceClient(MyConstant.keyList.grpcURL, {}, {});

export async function userTrack(page, action, ipAddress) {
    if (!window.location.hostname.includes("localhost")) {
        ipAddress = typeof ipAddress === "undefined" ? localStorage.getItem("ipAddress") : ipAddress;
        if (!ipAddress) {
            ipAddress = await getMyIp();
        }
        let uniqId = localStorage.getItem("uniqId");
        if (!uniqId) {
            uniqId = 'id-' + (new Date()).getTime() + Math.random().toString(36).substr(2);
            localStorage.setItem("uniqId", uniqId)
        }
        let postJson = {
            reqId: uniqId,
            logsJson: {
                page,
                action,
                userAgent: navigator.userAgent,
                ipAddress: ipAddress,
                timestamp: new Date().getTime()
            }
        }
        const request = new ZiFetchRequest();
        request.setDomain('user');
        request.setEvent('captureLogs');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid Request");
            } else {
                console.log("Logged")
            }
        });
    }
    return {};
}


export async function getMyIp() {
    return await fetch(MyConstant.keyList.ipURL + "myIP").then((res) => res.json()).then((json) => {
        var ipAddress = json.ip
        return ipAddress;
    });
}

export async function doFileConnect(subUrl, method, postJson) {

    return fetch(MyConstant.keyList.apiURL + subUrl, {
        method: method,
        mode: 'cors',
        redirect: 'follow',
        body: postJson,
    }).then(
        function (response) {
            return response.json();
        }
    ).then(function (dataresponse) {
        return dataresponse;
    });
}

export function checkNullAndReturnString(str) {
    if (str != null && str != undefined && str != "") {
        return true;
    }
    return false;
}

export function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function timeConverter(UNIX_timestamp) {
    return new Date(parseInt(UNIX_timestamp)).toLocaleString();
}

export async function loginStatusCheck(navigation) {
    let loginres = false
    var loginincheck = await localStorage.getItem("logedin")
    if (!loginincheck) {
        navigation.history.push("/admin/login")
    }
    if (loginincheck) {
        loginres = true
    }

    return loginres
}

export function dateConverter(ts) {
    var date_ob = new Date(parseInt(ts));
    var year = date_ob.getFullYear();
    var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    var date = ("0" + date_ob.getDate()).slice(-2);
    var hours = ("0" + date_ob.getHours()).slice(-2);
    var minutes = ("0" + date_ob.getMinutes()).slice(-2);
    var seconds = ("0" + date_ob.getSeconds()).slice(-2);

    return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds
}

export function getOnlyDate(ts) {
    var date_ob = new Date(ts);
    var year = date_ob.getFullYear();
    var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    var date = ("0" + date_ob.getDate()).slice(-2);

    return year + "-" + month + "-" + date
}
export function getIssueId(id) {

    return "UMED" + String(id).padStart(5, '0');
}

export function getAdminIssueId(id) {
    return "UMEDA" + String(id).padStart(5, '0');
}

export function getSpeakUpIssueId(id) {
    return "SPKUP" + String(id).padStart(5, '0');
}

export function pageNumbers(totalPages, page, maxLength) {
    if (maxLength < 5) throw "maxLength must be at least 5";
    function range(start, end) {
        return Array.from(Array(end - start + 1), (_, i) => i + start);
    }
    let pagination = [];
    var sideWidth = maxLength < 9 ? 1 : 2;
    var leftWidth = (maxLength - sideWidth * 2 - 3) >> 1;
    var rightWidth = (maxLength - sideWidth * 2 - 2) >> 1;
    if (totalPages <= maxLength) {
        // no breaks in list
        pagination = range(1, totalPages);
    } else if (page <= maxLength - sideWidth - 1 - rightWidth) {
        // no break on left of page
        pagination = range(1, maxLength - sideWidth - 1)
            .concat(0, range(totalPages - sideWidth + 1, totalPages));
    } else if (page >= totalPages - sideWidth - 1 - rightWidth) {
        // no break on right of page
        pagination = range(1, sideWidth)
            .concat(0, range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages));
    } else {
        // Breaks on both sides
        pagination = range(1, sideWidth)
            .concat(0, range(page - leftWidth, page + rightWidth),
                0, range(totalPages - sideWidth + 1, totalPages));
    }

    return pagination;
}

export async function checkLogin(navigation) {
    let loginres = false
    var loginincheck = await localStorage.getItem("logedin")
    var manageLoggedUser = await localStorage.getItem("managelogedin")
    if (loginincheck && loginincheck == "admin") {
        loginres = true
    }
    if (manageLoggedUser && manageLoggedUser == "manage") {
        loginres = true
    }

    if (!loginres) {
        navigation.history.push("/manage")
    }
    return loginres

}


export function readSessionData() {
    var manage_session = ""
    var manage_mUserId = ""

    let sessionId = localStorage.getItem("manage_session")
    let mUserId = localStorage.getItem("manage_mUserId")

    if (sessionId) {
        manage_session = sessionId
    }
    if (mUserId) {
        manage_mUserId = mUserId
    }
    let obj = { manage_session, manage_mUserId }
    return obj
}


export async function resetLocalData(navigation) {

    setTimeout(() => {
        localStorage.removeItem("manage_session");
        localStorage.removeItem("manage_mUserId");
        localStorage.removeItem("manageLoggedUser");
        localStorage.removeItem("managelogedin");
        navigation.history.push("/manage")
    }, 3000);


}


export async function contentAdminLoginCheck(navigation) {
    let loginres = false
    var manageLoggedUser = await localStorage.getItem("managelogedin")
    var login_RoleType = await localStorage.getItem("login_RoleType")
    var manage_mUserId = await localStorage.getItem("manage_mUserId")

    if (manageLoggedUser && login_RoleType && manage_mUserId && manage_mUserId != "" && login_RoleType != "" && login_RoleType == "ContentAdmin") {
        loginres = true
    }

    if (!loginres) {
        navigation.history.push("/manage")
    }
    return loginres

}
export function getUuidv4() {
    return uuidv4()
}

export function isSuperadmin() {
    let loginRoleType = localStorage.getItem("login_RoleType")
    return loginRoleType === "Superadmin"
}

export function getValidLabels(pageText, labelName) {
    if (pageText != null) {
        if (labelName in pageText) {
            return pageText[labelName]
        }
    }
    return "";
}

export function getPageText(pageName) {
    var pageText = null;
    let language = localStorage.getItem("language");
    switch (pageName) {
        case 'casesummaryform':
            switch (language) {
                case 'tam':
                    pageText = require('../lang/tam/caseSummaryAddEdit.json');
                    break;
                case 'esp':
                    pageText = require('../lang/esp/caseSummaryAddEdit.json');
                    break;
                default:
                    pageText = require('../lang/eng/caseSummaryAddEdit.json');
                    break;
            }
            break;
        case 'migration':
            switch (language) {
                case 'esp':
                    pageText = require('../lang/esp/migration.json');
                    break;
                default:
                    pageText = require('../lang/eng/migration.json');
                    break;
            }
            break;
    }
    return pageText;
}