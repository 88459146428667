import React, { Component } from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import SideManuBar from './SideMenuBar';
import image3 from '../images/Teqbahn_logo1.png';
import MyConfig from '../config/MyConfig';
import { toast, ToastContainer } from "react-toastify";
import { checkLogin, readSessionData, resetLocalData } from '../config/MyFunctions';
import ManageSideMenu from '../manage/ManageSideMenu';
import whiteLogo from '../images/whiteLogo.png';
import ManageLogout from "../manage/ManageLogout"
import AdminLogout from "./AdminLogout"
import Dropzone from 'react-dropzone';
import Dragplaceholder from '../images/Dragfile.png';
import MyConstant from '../config/MyConstant';
import { v4 as uuidv4 } from 'uuid';
import Modal from "../AppIssues/Modal"
class MyProfile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuActive: "",
            mUserId: "",
            session: "",
            fullName: "",
            emailId: "",
            resUserId: "",
            action: "view",
            user_profilePhoto: "",
            documentFiles: [],
            document_Json: [],
            imagePreview: {},
            modalVisible: false,
            userName: "",
            profilePhotoValidate: "",
            btnLoader: false

        }
        this.myRef = React.createRef()
    }
    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        if (login_status) {
            document.body.style.backgroundColor = "#f6f7fb";
            let resData = await readSessionData()
            let { manage_session, manage_mUserId } = resData
            this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
                this.getProfileInfo()
            })
        }

    }

    getProfileInfo() {
        let { mUserId, session } = this.state
        let postJson = { mUserId, session }
        const request = new ZiFetchRequest();
        request.setDomain("adminUserManage");
        request.setEvent("getInfo");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response == "Success") {
                    let { email, mUserId, name, profile, mUserName } = json.info;

                    let documentFiles = [],
                        document_Json = [];
                    if (profile && profile != "") {
                        let split = profile.split(".")
                        documentFiles.push({ processType: "aProfile", fileType: split[1], docsId: split[0] })
                        document_Json.push({ processType: "aProfile", fileType: split[1], docsId: split[0], fileName: profile })
                    }

                    this.setState({
                        userName: mUserName,
                        fullName: name,
                        emailId: email,
                        resUserId: mUserId,
                        user_profilePhoto: profile,
                        documentFiles,
                        document_Json
                    })

                } else if (json.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }


    async submit() {
        let { mUserId, session, emailId, resUserId, fullName, document_Json } = this.state

        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        let emailIdValidate = "";
        let fullNameValidate = "";
        let profilePhotoValidate = "";
        let mailValidate = true
        if (!emailId) {
            mailValidate = false
            emailIdValidate = "Enter Email"
        } else if (reg.test(emailId) == false) {
            mailValidate = false
            emailIdValidate = "Please Enter Valid Email"
        }

        if (!fullName) {
            fullNameValidate = "Enter Full Name"
        }



        this.setState({ fullNameValidate, emailIdValidate, profilePhotoValidate })

        if (!mailValidate || !fullName) {
            return false
        }
        this.setState({ btnLoader: true })

        let fileList = { fileName: "" };
        for (var i = 0; i < document_Json.length; i++) {
            await this.sendPostJson(document_Json[i], i);
            let fileDetails = document_Json[i];
            fileList = {
                origFileName: fileDetails.origFileName,
                fileName: fileDetails.fileName,
                docsId: fileDetails.docsId,
                processType: fileDetails.processType,
                fileType: fileDetails.fileType
            };

        }

        //adminUserId, name, email, profilePhoto, mUserId, session
        let postJson = { mUserId, session, adminUserId: resUserId, name: fullName, email: emailId, profilePhoto: fileList.fileName }
        const request = new ZiFetchRequest();
        request.setDomain("adminUserManage");
        request.setEvent("update");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response == "Success") {

                    console.log(json)
                    toast.success("Profile Updated Successfully.", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    localStorage.setItem("manage_profile", fileList.fileName)
                    this.setState({ action: "view", btnLoader: false }, () => {
                        this.getProfileInfo()
                    })
                    let getFileStatus = fileList.fileName === "" ? "empty" : fileList.fileName
                    this.props.logoChange(getFileStatus);
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 2000)
                }
                else if (json.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }

    documentOnDrop(newFiles) {
        var documentFiles = [];
        var document_Json = [];

        let file_Name = ""
        for (var i = 0; i < newFiles.length; i++) {
            let file = newFiles[i];
            let filename = file.name;
            let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
            let uid = uuidv4();

            documentFiles.push(newFiles[i]);
            document_Json.push({
                name: this.state.selectTypeVlaue,
                path: newFiles[i],
                status: 'active',
                progress: '0',
                origFileName: filename,
                fileName: uid + "." + ext,
                docsId: uid,
                processType: "aProfile",
                fileType: ext
            })
            file_Name = uid + "." + ext
        }

        this.setState({
            documentFiles: documentFiles,
            document_Json: document_Json,
            user_profilePhoto: file_Name
        });
    }

    documentOnRemove(loopIndex) {
        var document_Json = this.state.document_Json;
        var documentFiles = this.state.documentFiles;
        document_Json.splice(loopIndex, 1);
        documentFiles.splice(loopIndex, 1);
        this.setState({
            document_Json: document_Json, documentFiles: documentFiles,
            user_profilePhoto: ""
        });

    }


    async uploadDocumentFiles(documentArray, i) {
        let { mUserId } = this.state
        const postFileUpload = new FormData();
        postFileUpload.append('file' + (i + 1), documentArray.path);
        postFileUpload.append('fileName' + (i + 1), documentArray.fileName);
        postFileUpload.append('processType' + (i + 1), documentArray.processType);
        postFileUpload.append('docsId' + (i + 1), documentArray.docsId);
        let { userId } = this.state;
        let result = await fetch(MyConstant.keyList.apiURL + "uploads/" + documentArray.processType + "/" + documentArray.docsId + "/" + mUserId, {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        }).then(
            function (response) {
                return response.json();
            }
        ).then(json => {
            return json.response;
        }).catch(error => console.warn(error));

        return result
    }

    async sendPostJson(documentArray, i) {
        var that = this;
        var document_Json = this.state.document_Json;
        document_Json[i].progress = 50;
        that.setState({
            document_Json: document_Json
        })
        if (document_Json.length > 0) {
            if (documentArray.path) {
                await that.uploadDocumentFiles(documentArray, i);
            }
            document_Json[i].progress = 100;
            that.setState({
                document_Json: document_Json
            })
        }
    }

    render() {
        let { mUserId, menuActive, fullNameValidate, fullName, userName, visibleClass, emailId, emailIdValidate, documentFiles, imagePreview, modalVisible, profilePhotoValidate, btnLoader } = this.state
        import('../admin/style.css');
        let that = this
        var manageIsLogin = localStorage.getItem("managelogedin")

        let compnayLogo = image3
        if (manageIsLogin) {
            compnayLogo = whiteLogo
        }


        let videos = ["mp4", "3gp", "ogg", "mov"]
        let returnModelBody = ""
        if (imagePreview.fileType) {
            returnModelBody = <>
                <img src={imagePreview.filePath} className={"img-thumbnail"} style={{ height: 400 }} />
            </>
        }

        let returnMenu = <SideManuBar menuActive={menuActive} typeofPage={""} navigation={this.props.history} />

        let returnLogout = <AdminLogout navigation={this.props.history} />
        if (manageIsLogin == "manage") {
            returnMenu = <ManageSideMenu menuActive={menuActive} typeofPage={""} navigation={this.props.history} grpcClient={this.props.grpcClient} />
            returnLogout = <ManageLogout navigation={this.props.history} />
        }

        return (

            <div className="body-content left-rigt-padding cat-style" ref={this.myRef}>
                <main className="app-main">
                    <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>

                        <div className="container">
                            <div className={(visibleClass ? visibleClass : "") + " card box-shadow"} style={{ padding: 0 }}>
                                <div className="card-header border-btm-style">
                                    <h5 className='header-style' >Profile {this.state.action == "view" ? <>
                                        <input type="button" className="btn btn-primary ml-auto"
                                            style={{ float: "right" }} value="Edit" onClick={() => {
                                                this.setState({ action: "edit" })
                                            }} />
                                    </> : <>  <input type="button" className="btn btn-success ml-auto" style={{ float: "right" }} value="View" onClick={() => {
                                        this.setState({ action: "view" })
                                    }} /></>}</h5>
                                </div>
                                <div className="card-body">
                                    <div className={"row mt-2"}>
                                        <div className={"col-md-2"}><label className='fw' style={{ color: "#373737" }} htmlFor="">User Name</label></div>
                                        <div className={"col-md-7"}>: {userName}
                                        </div>
                                    </div>
                                    <div className={"row mt-2"}>
                                        <div className={"col-md-2"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Full Name</label></div>
                                        <div className={"col-md-7"}>
                                            {
                                                this.state.action == "view" ? ": " + fullName : <input type="text" placeholder={"Enter Full Name"} className={
                                                    "form-control inputRadius"} name={"label"} value={fullName} onChange={e => {
                                                        this.setState({ fullName: e.target.value })
                                                    }}
                                                    disabled={this.state.action == "view" ? true : false}
                                                />
                                            }

                                            <span className='error-txt'>{fullNameValidate} </span>
                                        </div>
                                    </div>
                                    <div className={"row mt-2"}>
                                        <div className={"col-md-2"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Email</label></div>
                                        <div className={"col-md-7"}>
                                            {
                                                this.state.action == "view" ? ": " + emailId : <input type="text" placeholder={"Enter Mail "} className={"form-control inputRadius"} name={"label"} value={emailId} onChange={e => {
                                                    this.setState({ emailId: e.target.value })
                                                }}
                                                />
                                            }

                                            <span className='error-txt'>{emailIdValidate} </span>
                                        </div>
                                    </div>
                                    <div className={"row mt-2"}>
                                        <div className={"col-md-2"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Image</label></div>
                                        <div className={"col-md-7"}>

                                            {
                                                this.state.action !== "view" && <div className="dropback" >
                                                    <Dropzone accept=".png, .jpg, .jpeg," multiple={false}
                                                        onDrop={(acceptedFiles) => {
                                                            this.documentOnDrop(acceptedFiles)
                                                        }} onDropRejected={(reject) => {
                                                            toast.error("Please Choose Image.", {
                                                                position: "top-right",
                                                                autoClose: 1500,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true
                                                            });

                                                        }}  >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <section>
                                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                                    <input {...getInputProps()} />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <img src={Dragplaceholder} className='dragimgph' />
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="dragtext">Try dropping some files here, or click to select files to upload.
                                                                                <br />Support file formats (.png, .jpg, .jpeg)</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            }
                                            <span className='error-txt'>{profilePhotoValidate} </span>
                                            <div className='row my-2'>
                                                {
                                                    documentFiles.map((f, loopIndex) => {
                                                        let filePath = ""
                                                        let fileName = ""
                                                        let fileType = ""
                                                        if (f instanceof File) {
                                                            filePath = URL.createObjectURL(f)
                                                            fileName = f.name
                                                            fileType = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
                                                        } else {
                                                            let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + f.processType + "&key=" + f.docsId + "." + f.fileType + "&id=" + mUserId
                                                            filePath = buildUrl
                                                            fileType = f.fileType
                                                        }

                                                        let returnData = ""
                                                        if (videos.includes(fileType.toLowerCase())) {
                                                            returnData = <i className="fa fa-video" aria-hidden="true"
                                                                style={{ paddingRight: 5, fontSize: 80, cursor: "pointer" }}

                                                                onClick={() => {
                                                                    let imagePreview = { filePath, fileType }
                                                                    this.setState({ imagePreview, modalVisible: true })
                                                                }}
                                                            ></i>
                                                        }
                                                        else if (fileType == "pdf") {
                                                            returnData = <i className="fa fa-file-pdf" aria-hidden="true"

                                                                style={{ paddingRight: 5, fontSize: 80, cursor: "pointer" }}
                                                                onClick={() => {
                                                                    let imagePreview = { filePath, fileType }
                                                                    this.setState({ imagePreview, modalVisible: true })
                                                                }}
                                                            ></i>
                                                        }
                                                        else {
                                                            returnData = <img src={filePath} alt="no img" style={{ width: 80, height: 80, cursor: "pointer" }}
                                                                onClick={() => {
                                                                    let imagePreview = { filePath, fileType }
                                                                    this.setState({ imagePreview, modalVisible: true })
                                                                }}
                                                            />

                                                        }
                                                        return (<>
                                                            <div className='col-sm-4 col-md-3 file-box'>
                                                                {/* <img src={"https://picsum.photos/200/300"} className='img-rounded' style={{ width: 80, height: 70 }} /> */}
                                                                {returnData}
                                                                {
                                                                    this.state.action !== "view" && <i className="fa fa-trash delete-upload-file" aria-hidden="true"
                                                                        onClick={() => that.documentOnRemove(loopIndex)}

                                                                    ></i>
                                                                }
                                                            </div>
                                                        </>)
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    <div>
                                        {modalVisible && <Modal
                                            visible={modalVisible}
                                            size={"modal-xl"}
                                            closeModal={() => this.setState({ modalVisible: false, imagePreview: {} })}
                                            heading={"File View"}
                                            parentClass={"text-center"}
                                            body={<React.Fragment>
                                                {returnModelBody}
                                            </React.Fragment>}
                                        />}
                                    </div>

                                    {this.state.action == "edit" && <div className={"row mt-3"}>
                                        <div className={"col-sm-4"}></div>
                                        <div className={"col-sm-4"}>
                                            <button className="btn btn-primary ml-auto" onClick={() => this.submit()} >
                                                Update
                                                {btnLoader && <div className="spinner-border" role="status" style={{ width: "1rem", height: "1rem", color: "#fff", marginLeft: 10 }}>
                                                    <span className="sr-only"></span>
                                                </div>}
                                            </button>
                                        </div>
                                        <div className={"col-sm-4"}></div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </div >
        )
    }
}

export default MyProfile;