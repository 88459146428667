import React from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import image3 from '../images/Teqbahn_logo1.png';
import MyConfig from '../config/MyConfig';
import { dateConverter } from '../config/MyFunctions';
import { toast, } from "react-toastify";
import { pageNumbers, checkLogin, readSessionData, resetLocalData } from '../config/MyFunctions';
import Pagination from "./Pagination"
import whiteLogo from '../images/whiteLogo.png';
import Select from './component/Select';
import countries from "../json/countries.json"
import DatatableFromData from './component/DatatableFromData';





class AddTeacher extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuActive: "",
            action: "Add",
            processing: false,
            prev: false,
            next: false,
            noPagesLimit: 1,
            pageLimit: 10,
            techerJson: [],
            pagination: [],
            visibleClass: "",
            totalResultsCount: 0,
            mUserId: "",
            session: "",
            displayShow: true,
            errors: {},
            listObj: {
                teacherName: "",
                teacherEmail: "",
                countryCode: "",
                mobileNumber: ""
            },
            countryOptions: countries.countries

        }
        this.myRef = React.createRef()
    }
    async componentDidMount() {
        let login_status = await checkLogin(this.props)
        if (login_status) {
            document.body.style.backgroundColor = "#f6f7fb";
            let resData = await readSessionData()
            let { manage_session, manage_mUserId } = resData
            this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
                this.getTeacherListAPI()


            })
        }

    }



    getTeacherListAPI() {
        let { noPagesLimit, mUserId, session } = this.state
        let postJson = { mUserId, session }
        //auth: String, pageLimit: Int, noOfPage:Int       
        const request = new ZiFetchRequest();
        request.setDomain("techFlow");
        request.setEvent("getAllTeacher");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.response == "Success") {
                    console.log(json.result)

                    this.setState({
                        techerJson: json.result,
                    });
                }
                else if (json.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }

    async submit() {

        let { listObj, mUserId, session, } = this.state
        let { teacherName, teacherEmail, countryCode, mobileNumber } = listObj
        let errors = {};
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (teacherName === "") {
            errors.teacherName = "Please enter teacher name !";
        }
        if (teacherEmail.trim() === '') {
            errors.teacherEmail = "Please enter teacher email !";
        } else if (reg.test(teacherEmail) === false) {
            errors["teacherEmail"] = "Please enter valid email !";
        }
        if (countryCode === "") {
            errors.countryCode = "Please choose country code !";
        }
        if (mobileNumber === "") {
            errors.mobileNumber = "Please enter mobile number !";
        }

        // console.log(errors)
        this.setState({ errors })
        if (Object.keys(errors).length !== 0) {
            return false
        }

        let postJson = { mUserId, session, name: teacherName, email: teacherEmail.toLowerCase(), countryCode: countryCode, mobileNumber: mobileNumber };

        console.log("postJson", postJson)
        //name: String, email: String, countryCode: String, mobileNumber: String, mUserId: String, session: String
        const request = new ZiFetchRequest();
        request.setDomain('techFlow');
        request.setEvent('addTeacher');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response == "Success") {
                    toast.success("Teacher created successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({
                        listObj: {
                            teacherName: "",
                            teacherEmail: "",
                            countryCode: "",
                            mobileNumber: ""
                        }
                    }, () => {
                        this.getTeacherListAPI()
                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });
    }

    async Update() {
        let { mUserId, session, listObj } = this.state
        let { teacherName, teacherEmail, countryCode, mobileNumber } = listObj
        let errors = {};
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (teacherName === "") {
            errors.teacherName = "Please enter teacher name !";
        }
        if (teacherEmail.trim() === '') {
            errors.teacherEmail = "Please enter teacher email !";
        } else if (reg.test(teacherEmail) === false) {
            errors["teacherEmail"] = "Please enter valid email !";
        }
        if (countryCode === "") {
            errors.countryCode = "Please choose country code !";
        }
        if (mobileNumber === "") {
            errors.mobileNumber = "Please enter mobile number !";
        }

        this.setState({ errors })
        if (Object.keys(errors).length !== 0) {
            return false
        }

        let postJson = {
            mUserId, session, name: teacherName, email: teacherEmail.toLowerCase(), countryCode: countryCode, mobileNumber: mobileNumber, id: this.state.id, mUserId, session
        };


        console.log("postJson", postJson)

        //id: String, name: String, email: String, countryCode: String, mobileNumber: String, mUserId: String, session: String
        const request = new ZiFetchRequest();
        request.setDomain('techFlow');
        request.setEvent('updateTeacher');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.log("response", response)
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                // console.log(responseMsg)
                if (responseMsg.response === "Success") {
                    toast.success("Teacher updated successfully!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    this.setState({
                        action: "Add", errors: {},
                        listObj: {
                            teacherName: "",
                            teacherEmail: "",
                            countryCode: "",
                            mobileNumber: ""
                        },
                    }, () => {
                        this.getTeacherListAPI()
                    })

                }
                else if (responseMsg.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }
            }
        });

    }
    async edit(data) {
        //console.log(data)
        this.scrollToTop()
        let { name, email, countryCode, mobileNumber } = data;
        let listObj = {
            teacherName: name,
            teacherEmail: email,
            countryCode,
            mobileNumber
        };
        this.setState({ action: "Edit", id: data["id"], catvisibleClass: "bgClrChg", displayShow: false, listObj })
        setTimeout(() => {
            this.setState({ visibleClass: "" })
        }, 1000);
    }

    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }
        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getTeacherListAPI()
            })
        }
    }

    delete(data) {
        let { mUserId, session } = this.state

        let confirmMessage = window.confirm("Are you sure delete teacher ?")
        //id: String
        let postJson = {
            id: data["id"],
            mUserId, session
        };

        if (confirmMessage) {
            console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain('techFlow');
            request.setEvent('deleteTeacher');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                    console.log("response", response)
                } else {
                    let responseMsg = JSON.parse(response.getMessage());
                    // console.log(responseMsg)
                    if (responseMsg.response == "Success") {
                        toast.success("Teacher Data Deleted.", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        this.getTeacherListAPI()
                    }

                    else if (responseMsg.response == "Invalid Session Request") {
                        toast.warning("Your session got expired!", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        resetLocalData(this.props)
                    }
                }
            });
        }

    }

    scrollToTop() {
        this.myRef.current.scrollTo(0, 0);
    }
    getWorkSpaceList() {
        let postJson = { auth: MyConfig.authKey }
        //auth: String, pageLimit: Int, noOfPage:Int       
        const request = new ZiFetchRequest();
        request.setDomain("workspace");
        request.setEvent("getWorkspaceList");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                if (json.result && Object.keys(json.result).length != 0) {
                    let workSpaceOptions = [];
                    Object.keys(json.result).map((e) => {
                        let { name } = json.result[e]
                        workSpaceOptions.push({ label: name, value: e })
                    })
                    this.setState({
                        workSpaceOptions,
                    });
                }
                else if (json.response == "Invalid Session Request") {
                    toast.warning("Your session got expired!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    resetLocalData(this.props)
                }

            }
        });
    }









    render() {
        let { techerJson, pagination, next, prev, noPagesLimit, visibleClass, displayShow, mUserId, listObj, errors, countryOptions } = this.state
        import('../admin/style.css');
        let that = this
        var manageIsLogin = localStorage.getItem("managelogedin")

        let compnayLogo = image3
        if (manageIsLogin) {
            compnayLogo = whiteLogo
        }


        let tableData = []
        Object.keys(techerJson).forEach(function (key, index) {
            let fullData = techerJson[key]
            let { name, createdAt, email, mobileNumber, countryCode, status } = fullData
            tableData.push(
                <tr >
                    <td style={{ width: "10%" }} className='tabletxt'>{name}</td>
                    <td style={{ width: "10%" }} className='tabletxt' >{email}</td>
                    <td style={{ width: "10%" }} className='tabletxt' >{countryCode}</td>
                    <td style={{ width: "10%" }} className='tabletxt' >{mobileNumber}</td>
                    <td style={{ width: "10%" }} className='tabletxt' >{status}</td>
                    <td style={{ width: "10%" }} className='tabletxt' >{dateConverter(createdAt)}</td>
                    <td style={{ width: "10%" }} className='fofamily' ><div className={" editBtnstyle"} onClick={() => that.edit(fullData)}>Edit</div></td>
                    <td style={{ width: "10%" }} className='fofamily'><div className={"deleteBtnstyle"} onClick={() => that.delete(fullData)}>Delete</div></td>
                </tr>
            )
        });

        return (
            <div className="body-content left-rigt-padding cat-style" ref={this.myRef}>
                <main className="app-main">
                    <div className="wrapper row" style={{ padding: 10, paddingTop: 20 }}>

                        <div className="container">
                            <div className={(visibleClass ? visibleClass : "") + " card box-shadow"} style={{ padding: 0 }}>
                                <div className="card-header border-btm-style cursor-style" onClick={() => {
                                    this.setState({ displayShow: !displayShow })
                                }}>
                                    <div className='row'>
                                        <div className='col-10 col-sm-11 col-md-11'>
                                            <h5 className='header-style' >{this.state.action} Teacher</h5>
                                        </div>
                                        <div className='col-2 col-sm-1 col-md-1'>
                                            <span style={{ float: "right", color: "#fff", }}>
                                                {displayShow ? <i class="fa fa-plus"></i> : <i class="fa fa-minus"></i>}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body" style={displayShow ? { display: "none" } : {}}>

                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Teacher name</label></div>
                                        <div className={"col-md-6"}>
                                            <input type="text" placeholder={"Enter Teacher name "} className={"form-control inputRadius"} name={"label"} value={listObj.teacherName} onChange={e => {
                                                listObj.teacherName = e.target.value
                                                this.setState({ listObj })
                                            }} />
                                            <span className='error-txt'>{errors["teacherName"]} </span>
                                        </div>

                                    </div>
                                    <br />
                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Teacher email</label></div>
                                        <div className={"col-md-6"}>
                                            <input type="text" placeholder={"Enter Teacher email "} className={"form-control inputRadius"} name={"label"} value={listObj.teacherEmail} onChange={e => {
                                                listObj.teacherEmail = e.target.value
                                                this.setState({ listObj })
                                            }} />
                                            <span className='error-txt'>{errors["teacherEmail"]} </span>
                                        </div>
                                    </div>

                                    <br />
                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Country</label></div>
                                        <div className={"col-md-6"}>
                                            <Select
                                                options={countryOptions}
                                                isSearchable={true}
                                                placeholder="Select country code"
                                                onChange={(e) => {
                                                    listObj.countryCode = e.value;
                                                    this.setState({ listObj })
                                                }}
                                                value={countryOptions.filter((e) => e.value === listObj.countryCode)}

                                            />
                                            <span className='error-txt'>{errors["countryCode"]} </span>
                                        </div>
                                    </div>
                                    <br />
                                    <div className={"row"}>
                                        <div className={"col-md-3"}><label className='fw' style={{ color: "#373737" }} htmlFor="">Mobile number</label></div>
                                        <div className={"col-md-6"}>
                                            <input type="number" placeholder={"Enter mobile number "} className={"form-control inputRadius"} name={"label"} value={listObj.mobileNumber} onChange={e => {
                                                listObj.mobileNumber = e.target.value
                                                this.setState({ listObj })
                                            }} />
                                            <span className='error-txt'>{errors["mobileNumber"]} </span>
                                        </div>

                                        <div className={"col-md-2"}>
                                            {
                                                this.state.action == "Add" ? <input type="button" className="btn mb-2  ml-auto addbuttonsty" value="Add" onClick={() => this.submit()} /> : <input type="button" className="btn  mb-2 ml-auto addbuttonsty" value="Update" onClick={() => this.Update()} />
                                            }
                                            {this.state.action == "Edit" && <input type="button" className="btn mb-1 ml-auto btn-cancel-sty" value="Cancel" onClick={() => {
                                                this.setState({
                                                    action: "Add",
                                                    listObj: {
                                                        teacherName: "",
                                                        teacherEmail: "",
                                                        countryCode: "",
                                                        mobileNumber: ""
                                                    },
                                                    errors: {}
                                                })
                                            }} />}

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="container mt-5">
                            <div className="card box-shadow" style={{ padding: 0 }}>
                                <div className="card-header border-btm-style" style={{ borderBottom: "none" }}>
                                    <h5 className='header-style'> Teacher List</h5>
                                </div>
                                <div className="card-body table-responsive" style={{ padding: 2 }}>

                                    <DatatableFromData
                                        result={techerJson}
                                        data={
                                            [
                                                {
                                                    column: "Name",
                                                    selector: 'name',
                                                },
                                                {
                                                    column: "email",
                                                    selector: 'email',
                                                },
                                                {
                                                    column: "Country",
                                                    selector: 'countryCode',
                                                },
                                                {
                                                    column: "Mobile number",
                                                    selector: 'mobileNumber',
                                                },
                                                {
                                                    column: "Status",
                                                    selector: 'status',
                                                },
                                                {
                                                    column: "Published",
                                                    cell: (row, key) => {
                                                        return <div>{dateConverter(row.createdAt)}</div>
                                                    }
                                                },
                                                {
                                                    column: "Edit",
                                                    cell: (row, key) => {
                                                        return <div className={"editBtnstyle text-center"} onClick={() => that.edit(row)}>Edit</div>
                                                    }
                                                },
                                                {
                                                    column: "Delete",
                                                    cell: (row, key) => {
                                                        return <div className={"deleteBtnstyle text-center"} onClick={() => that.delete(row)}>Delete</div>
                                                    }
                                                }
                                            ]
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </div>
        )
    }
}

export default AddTeacher;