import React from 'react';
import { checkLogin, readSessionData } from '../config/MyFunctions';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import { toast, ToastContainer } from "react-toastify";


class InfraApps extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      updateAndroid: true,
      updateIos: true,
      androidVersion: "",
      iosVersion: "",
      forceLiveCheck: false
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    let login_status = await checkLogin(this.props)
    if (login_status) {
      document.body.style.backgroundColor = "#f6f7fb";
      let resData = await readSessionData()
      let { manage_session, manage_mUserId } = resData
      this.setState({ session: manage_session, mUserId: manage_mUserId }, () => {
        this.getAppForceUpdateInfraData()
      })
    }
  }

  getAppForceUpdateInfraData() {
    let { mUserId, session } = this.state
    let postJson = {
      platform: "ios"
    };

    const request = new ZiFetchRequest();
    request.setDomain('infraData');
    request.setEvent('GetAppForceUpdateInfraData');
    request.setMessage(JSON.stringify(postJson));
    request.setDatetime(new Date().toString());
    this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
      console.log("tesr", response.getMessage() == 'Invalid Request')
      if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
        console.warn("Invalid Request")
      } else {
        let responseStr = response.getMessage()
        let responseMsg = JSON.parse(responseStr);
        let data = responseMsg.data
        console.log("responseMsg MKM", responseMsg.data)
        this.setState({
          updateAndroid: data.isUpdateForAndroid,
          updateIos: data.isUpdateForIOS,
          androidVersion: data.updateAndroidVersion,
          iosVersion: data.updateIOSVersion,
          forceLiveCheck: data.forceLiveCheck ===
            "Yes"
        })
      }
    });
  }

  setAppForceUpdateInfraDataRequest() {
    let { mUserId, session, updateAndroid, updateIos, androidVersion, iosVersion, forceLiveCheck } = this.state

    let postJson = {
      isUpdateForAndroid: updateAndroid,
      isUpdateForIOS: updateIos,
      updateAndroidVersion: androidVersion,
      updateIOSVersion: iosVersion,
      forceLiveCheck: forceLiveCheck ? "Yes" : "No"
    };


    const request = new ZiFetchRequest();
    request.setDomain('infraData');
    request.setEvent('SetAppForceUpdateInfra');
    request.setMessage(JSON.stringify(postJson));
    request.setDatetime(new Date().toString());
    this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
      if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
        console.warn("Invalid Request")
      } else {
        let responseMsg = JSON.parse(response.getMessage());
        if (responseMsg.response === "Success") {
          toast.success("Updated successfully!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }

      }
    });

  }
  render() {
    let { updateAndroid, updateIos, androidVersion, iosVersion, forceLiveCheck } = this.state;
    return <div className="body-content left-rigt-padding cat-style">
      <div className="container mt-2">
        <div className="card box-shadow" style={{ padding: 0 }}>
          <div className="card-header border-btm-style" style={{ borderBottom: "none" }}>
            <h5 className='header-style' >Infra Apps</h5>
          </div>
          <div className="card-body" style={{ padding: 2 }}>
            <div className='row my-2'>
              <div className='col-sm-6'>
                <div className="form-group">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="updateAndroid" checked={updateAndroid} onChange={(e) => {
                      this.setState({
                        updateAndroid: e.target.checked
                      })
                    }} />
                    <label className="custom-control-label" htmlFor="updateAndroid">Update Android</label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="updateIos" checked={updateIos} onChange={(e) => {
                      this.setState({
                        updateIos: e.target.checked
                      })
                    }} />
                    <label className="custom-control-label" htmlFor="updateIos">Update IOS</label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="forceLiveCheck" checked={forceLiveCheck} onChange={(e) => {
                      this.setState({
                        forceLiveCheck: e.target.checked
                      })
                    }} />
                    <label className="custom-control-label" htmlFor="forceLiveCheck">Live Version Auto Fetch</label>
                  </div>
                </div>

                <div className="form-group">
                  <label>Android Version</label>
                  <input type="text" className="form-control" value={androidVersion} onChange={(e) => {
                    this.setState({
                      androidVersion: e.target.value
                    })
                  }} />
                </div>
                <div className="form-group">
                  <label>IOS Version</label>
                  <input type="text" className="form-control" value={iosVersion} onChange={(e) => {
                    this.setState({
                      iosVersion: e.target.value
                    })
                  }} />
                </div>
                <div className='text-center'>
                  <button className='btn btn-primary' onClick={() => this.setAppForceUpdateInfraDataRequest()}>Update</button>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='row'>
                  <div className='col-6'><b>Update Android</b></div>
                  <div className='col-6'>{this.state.updateAndroid ? "Yes" : "No"}</div>
                </div>
                <div className='row'>
                  <div className='col-6'><b>Update IOS</b></div>
                  <div className='col-6'>{this.state.updateIos ? "Yes" : "No"}</div>
                </div>
                <div className='row'>
                  <div className='col-6'><b>Android Version</b></div>
                  <div className='col-6'>{androidVersion ? androidVersion : "Nill"}</div>
                </div>
                <div className='row'>
                  <div className='col-6'><b>IOS Version</b></div>
                  <div className='col-6'>{iosVersion ? iosVersion : "Nill"}</div>
                </div>
                <div className='row'>
                  <div className='col-6'><b>Live Version Auto Fetch</b></div>
                  <div className='col-6'>{forceLiveCheck ? "Yes" : "No"}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

export default InfraApps