import React, { Component } from 'react';
import MyConstant from './config/MyConstant';
import { userTrack } from './config/MyFunctions';
import teqlogo from './images/teqbahn-white.png';
import migracionLogo from './images/v3/migracionLogo.png'
// import UmedMe_White_Paper_Press_Release from './Files/UmedMe_Press_Release.pdf';


export default class PressPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        userTrack("Press Page", "Landing");
    }

    render() {
        return (
            <React.Fragment>
                <div className=" footerwhitebg">
                    {/* <Header3 page="press" /> */}
                    <main>
                        <div style={{ backgroundColor: "#fff" }}>
                            <div className="row py-5">
                                <div className="col-sm-12 text-center">
                                    {/* <a href={MyConstant.keyList.projectUrl+"/umedme/Files/UmedMe_Press_Release.pdf"} target="_blank" onClick={() => {
                                        userTrack("Press Page", "Press Release Click");
                                    }}>
                                        <img className="img-fluid" src={image1} />
                                    </a> */}
                                    <div class="container">
                                        {/* <div class="row">
                                            <div className="col-sm-12">
                                                <p className="headertext">Press Release</p>
                                            </div>
                                        </div> */}
                                        <div class="row">
                                            <div className="box m-2 ">
                                                <a href={MyConstant.keyList.projectUrl + "/umedme/Files/Umed.Me_Migracion.png"} target="_blank" onClick={() => {
                                                    userTrack("Press Page", "City of Tijuana Launch Event Click");
                                                }}>
                                                    City of Tijuana Launch Event
                                                    <div className='redbox'>
                                                        <p className='redboxtext'>OCT. 27, 2023</p></div>
                                                </a>
                                            </div>
                                            <div className="box m-2 ">
                                                <a href={MyConstant.keyList.projectUrl + "/umedme/Files/Oct28CineLibertadRSVP.pdf"} target="_blank" onClick={() => {
                                                    userTrack("Press Page", "Teatro Cine Libertad Tijuana Click");
                                                }}>
                                                    Teatro Cine Libertad Tijuana
                                                    <div className='redbox'>
                                                        <p className='redboxtext'>OCT. 28, 2023</p></div>
                                                </a>
                                            </div>
                                            <div className="box m-2 ">
                                                <a href={MyConstant.keyList.projectUrl + "/umedme/Files/Migration_PressRelease_CityofTijuana_Spanish.pdf"} target="_blank" onClick={() => {
                                                    userTrack("Press Page", "Tijuana Launch - Spanish Press Click");
                                                }}>
                                                    Tijuana Launch - Spanish Press
                                                    <div className='redbox'>
                                                        <p className='redboxtext'>OCT. 27, 2023</p></div>
                                                </a>
                                            </div>
                                            <div className="box m-2 ">
                                                <a href={MyConstant.keyList.projectUrl + "/umedme/Files/TijuanaLaunchEnglishPress.pdf"} target="_blank" onClick={() => {
                                                    userTrack("Press Page", "Tijuana Launch - English Press Click");
                                                }}>
                                                    Tijuana Launch - English Press
                                                    <div className='redbox'>
                                                        <p className='redboxtext'>OCT. 27, 2023</p></div>
                                                </a>
                                            </div>
                                            <div className="box m-2">
                                                <a href={MyConstant.keyList.projectUrl + "/umedme/Files/UmedMe_Press_Release.pdf"} target="_blank" onClick={() => {
                                                    userTrack("Press Page", "Press Release Click");
                                                }}>
                                                    <img className="img-fluid teqlogowhite" src={teqlogo} />
                                                    <div className='redbox'>
                                                        <p className='redboxtext'>White Paper</p></div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-3">
                                <div className="col-sm-12 text-center">
                                    {/* <p className="press-content-header pb-3">Press Release 11.29.21</p> */}
                                    {/* <p className="press-content">
                                        What the pandemic exposed about how we<br/>
                                        manage our health<br />
                                        It turns out many of us don’t <br/>
                                        So, what can we do about it?
                                    </p> */}
                                </div>
                            </div>
                        </div>
                    </main>
                    {/* <Footer /> */}
                </div>
            </React.Fragment>
        )
    }
}