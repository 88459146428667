import React, { Component } from 'react';
import Footer from '../../common/Footer3';
import about1_collage from '../../images/about-collage.jpg';
import Header from "./component/Header";

import Vidya from '../../NewVersionTwo/VTwoImages/Vidya.jpg';
import Kau from '../../NewVersionTwo/VTwoImages/Kau.jpg';
import Mark from '../../NewVersionTwo/VTwoImages/Mark.jpg';
import Ravi from '../../NewVersionTwo/VTwoImages/Ravi.jpg';
import Ruben from '../../NewVersionTwo/VTwoImages/Ruben.jpg';
import { userTrack } from '../../config/MyFunctions';

export default class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            intervalId: "",
            sliderTransform: 0,
            sliderHover: false,
            NavResClass: "",
            pageLogin: false,
            showDownload: false,
            showMedia: false,
            deviceHeight: window.innerHeight,
            deviceWidth: window.innerWidth,
        }

    }

    componentDidMount() {
        userTrack("About Page", "Landing");
    }

    render() {
        let { NavResClass, deviceWidth } = this.state;
        let { version } = this.props;
        return (
            <React.Fragment>
                <div className={`umedme-version-3`}>
                    <Header page={"about"} />
                    <div className={"top-body-about v_two_about " + (NavResClass ? "showdiv" : "")} >
                        <main className="about">
                            <div className="about-slider">
                                <div className={`about-slider-row`} style={{ backgroundImage: `url(${about1_collage})` }} />
                            </div>

                            <div className="px-0 ">
                                <div>
                                    <div className='row prob-bg-color pb-4'>
                                        <div className='col-sm-1'></div>
                                        <div className='col-sm-10'>
                                            <div className="mt-5">
                                                <div className="heading-style">
                                                    <div>
                                                        <h3 className='v_two_about_h2'>What we do </h3>
                                                        <div style={{ backgroundColor: "#ff003b", width: "40%", height: 10, margin: "auto" }}>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 mt-4">
                                                    <h2 className='v2-h2-color'>The Problem</h2>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 " >
                                                    <p className="prob-ptag-txt">
                                                        1. The National Institutes of Health notes that the entire healthcare system relies on the assumption that
                                                        patients can understand complex written and spoken medical information. Research has shown this
                                                        approach to be grossly insufficient.
                                                    </p>
                                                    <h4 className='ml-4 prob-sub-ptag-txt mb-3'>  This means many of us do not understand our own health information. </h4>
                                                    <p className="prob-ptag-txt mt-2">
                                                        2. The Joint Commission on Accreditation of Healthcare Organizations describes communication error as the
                                                        cause of 60% to 70% of preventable hospital deaths.  <br />

                                                    </p>
                                                    <h4 className='ml-4 prob-sub-ptag-txt'>
                                                        This means people are dying due to this lack of understanding.
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-1'></div>
                                    </div>



                                    <div className="row pb-4">
                                        <div className='col-sm-1'></div>
                                        <div className='col-sm-10'>
                                            <div className="heading-style">
                                                <div>
                                                    <h3 className='v_two_about_h2'>How we work </h3>
                                                    <div style={{ backgroundColor: "#ff003b", width: "40%", height: 10, margin: "auto" }}>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12" style={{ paddingTop: "4%" }}>
                                                <h2 className='v2-h2-color'> The Solution</h2>
                                            </div>
                                            <div className="col-sm-12 ">
                                                <p className="solu-ptag-txt">
                                                    Change when and how personal health information is accessed using umed.me. <br />
                                                </p>
                                                <p className="solu-ptag-txt pt-4">
                                                    Umed.Me is a medical, health, and wellness app co-created by healthcare professionals and caregivers during the
                                                    covid pandemic. It makes it easy to collect, store, and track personal health information.<br />
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-sm-1'></div>
                                    </div>
                                    <div className="row benifites-bg-color" style={{ paddingTop: "2%", paddingBottom: "3%" }}>
                                        <div className='col-sm-1'></div>
                                        <div className='col-sm-10'>

                                            <div className="heading-style">
                                                <div>
                                                    <h3 className='v_two_about_h2 '>The Benefits </h3>
                                                    <div style={{ backgroundColor: "#ff003b", width: "40%", height: 10, margin: "auto" }}>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 mt-4" style={{}} >
                                                <h2 className='v2-h2-color'>Umed.Me</h2>
                                            </div>
                                            <div className="">
                                                <ul>
                                                    <li>
                                                        <span>
                                                            <b className='benifit-btag'>Reduce the stress  </b>
                                                            <span className="benifit-ptag-txt">
                                                                of accessing and managing health information.
                                                            </span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            <b className='benifit-btag'>  Saves time for busy people </b>
                                                            <span className="benifit-ptag-txt">
                                                                trying to manage their immediate, and often, extended family’s
                                                                healthcare (aging parents for example).   <br />
                                                            </span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            <b className='benifit-btag'>  Better coordination of care </b>
                                                            <span className="benifit-ptag-txt">
                                                                between patient and provider for
                                                            </span>
                                                            <b className='benifit-btag'> better health outcomes.</b>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-sm-1'></div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-1'></div>
                                    <div className='col-sm-10'>
                                        <div style={{ paddingTop: "2%" }}>
                                            <div className="heading-style">
                                                <div>
                                                    <h3 className='v_two_about_h2'>Meet the Team </h3>
                                                    <div style={{ backgroundColor: "#ff003b", width: "40%", height: 10, margin: "auto" }}>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='text-align-center ' style={{ paddingTop: "5%" }}>
                                                <div className='row' >
                                                    <div className='col-sm-3 col-12' style={{ margin: "auto" }}>
                                                        <img src={Vidya} className={"img-thumbnail"} alt="" />
                                                    </div>
                                                    <div className='col-sm-9 col-12' style={{ marginTop: "2%" }}>
                                                        <p className='sub-name text-left'>Vidya Sri</p>
                                                        <p className='pt-2 sub-text text-left'>Strategy & Operations</p>
                                                        <p className='mt-3 mob-font team-info-text text-justify'>
                                                            Vidya Sri is working to address structural inequities through transformative innovation. She is focused on addressing the disparities of gender, race, and class as Co-Founder of Teqbahn. During her time as a Human Rights Policy Fellow at the Harvard Kennedy School of Government, she led the Initiative on Violence Against Women and Girls, laying the groundwork for a global treaty. Vidya has deep expertise in organizational behavior and has led multi-disciplinary teams of over 100 people. Vidya’s story is shared in the book, “Social Justice and the Power of Compassion” by Marguerite Guzman Bouvard.                                                </p>
                                                    </div>
                                                </div>

                                                <div className='row' >
                                                    {deviceWidth <= 600 && <div className='col-sm-3  col-12' style={{ margin: "auto" }}>
                                                        <img src={Mark} className={"img-thumbnail"} alt="" />
                                                    </div>}

                                                    <div className='col-sm-9 col-12' style={{ marginTop: "2%" }}>
                                                        <p className='sub-name text-right'>Mark Ansay</p>
                                                        <p className='pt-2 sub-text text-right'>Head of Product</p>
                                                        <p className='mt-3 mob-font team-info-text text-justify'>
                                                            Mark is a hemodialysis registered nurse. He has worked with several hospital systems and the two largest hemodialysis companies globally. He has long term experience in both outpatient (chronic) and hospital (acute) settings. Mark has expertise as a staff nurse, nurse in charge, and clinical coordinator. He has deepened his knowledge base in the hospital setting by becoming very skilled in operations, compliance, finance, and training/onboarding which he has applied extensively in a role serving a multi-hospital setting. His strong leadership and deep commitment to the patient experience have made him an exceptional medical professional.
                                                        </p>
                                                    </div>
                                                    {deviceWidth >= 601 && <div className='col-sm-3  col-12' style={{ margin: "auto" }}>
                                                        <img src={Mark} className={"img-thumbnail"} alt="" />
                                                    </div>}

                                                </div>

                                                <div className='row' >
                                                    <div className='col-sm-3  col-12' style={{ margin: "auto" }}>
                                                        <img src={Kau} className={"img-thumbnail"} alt="" />
                                                    </div>
                                                    <div className='col-sm-9  col-12' style={{ marginTop: "2%" }}>
                                                        <p className='sub-name text-left'>Kathleen Ansay</p>
                                                        <p className='pt-2 sub-text text-left'>Research & Development </p>
                                                        <p className='mt-3 mob-font team-info-text text-justify'>
                                                            Kathleen Ansay is a nurse educator with extensive expertise in education across medical specialities, functional units, and departments at a level 2 trauma hospital. She has served as a mentor for early stage nursing students and mid-career nursing professionals. Kathleen has participated in the successful restructure of a nursing orientation program and has introduced gamification as an effective engagement tool. She helped pioneer the development of the first successful nurse residency program in her hospital system. This program was awarded the highest level of accreditation from the American Nursing Credential Center. Kathleen is an active member of the National Association of Orthopedic Nurses, American Nurses Association, and the Medical Surgical Nursing Board.
                                                        </p>
                                                    </div>
                                                </div>


                                                <div className='row'>
                                                    {deviceWidth <= 600 && <div className='col-sm-3  col-12' style={{ margin: "auto" }}>
                                                        <img src={Ruben} className={"img-thumbnail"} alt="" />
                                                    </div>}
                                                    <div className='col-sm-9  col-12' style={{ marginTop: "2%" }}>
                                                        <p className='sub-name text-right' >Ruben Barcenilla </p>
                                                        <p className='pt-2 sub-text text-right'>Creative Design</p>
                                                        <p className='mt-3 mob-font team-info-text text-justify'>
                                                            Ruben is a multi-disciplinary creative working as an art director at an ad agency in New York. He has been creating and designing interactive brand experiences for over a decade. Ruben refined his extensive skills by working for one of the world's largest luxury vehicle companies. He mastered his creative craft there, from photo editing to video composition, while also doing  freelance work. Ruben is obsessed with creating and continuous improvement. He is deeply skilled in the brand experience which elevates his brand delivery across sectors.
                                                        </p>
                                                    </div>
                                                    {deviceWidth >= 601 && <div className='col-sm-3  col-12' style={{ margin: "auto" }}>
                                                        <img src={Ruben} className={"img-thumbnail"} alt="" />
                                                    </div>}
                                                </div>

                                                <div className='row' >
                                                    <div className='col-sm-3  col-12' style={{ margin: "auto" }}>
                                                        <img src={Ravi} className={"img-thumbnail"} alt="" />
                                                    </div>
                                                    <div className='col-sm-9  col-12' style={{ marginTop: "2%" }}>
                                                        <p className='sub-name text-left'>Ravi Madhusudhan </p>
                                                        <p className='pt-2 sub-text text-left'>CTO</p>
                                                        <p className='mt-3 mob-font team-info-text text-justify'>
                                                            Ravi is an aeronautical engineer and senior technology expert with over 2 decades of experience at the forefront of information technology. He has deep expertise as an information technology architect for the Fortune 1000, social impact and startup ecosystems. Ravi has deployed systems in both traditional cloud native settings and decentralized cloud settings across sectors including healthcare, telecommunications, insurance, biotech, rural digital connectivity and government. Ravi has pioneered information solutions to address the digital divide with communication tools that perform complex business functions using sms based messaging. He has been building carbon sensitive information technology for the past 10 years. Ravi works through collaboration to raise awareness on digital technology as a primary carbon producer. Ravi has worked on the domestic and international level to promote gender equity by investing in transformative research and innovation.
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className='col-sm-1'></div>
                                </div>

                            </div>
                        </main>
                        <Footer />
                    </div>
                </div>

            </React.Fragment >
        )
    }
}