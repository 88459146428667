import React, { Component } from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import Logo from '../images/umedmeweblogo.png';
import Teqbahn_Logo from '../images/teqbahn-white.png';
import { Link } from 'react-router-dom';
import MyConstant from '../config/MyConstant';

export default class ManageLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserName: "",
            Password: "",
            errors: {
                UserName: "",
                Password: "",
                deletedUser: ""
            },
            passwordVisible: false

        }
    }

    getLogin() {

        let { UserName, Password, errors } = this.state
        //loginId: String, password: String
        if (!UserName) {
            errors.UserName = "Please enter the Username"
        } else {
            errors.UserName = ""
        }

        if (!Password) {
            errors.Password = "Please enter the Password"

        } else {
            errors.Password = ""
        }

        this.setState({ errors })

        if (Password == "" || UserName == "") {
            return false
        }
        let postJson = { mUserName: UserName, password: Password }

        const request = new ZiFetchRequest();
        request.setDomain('adminUserReq');
        request.setEvent('login');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
            }
            else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg && responseMsg.response == "Success") {
                    localStorage.removeItem("managelogedin");
                    localStorage.removeItem("logedin");
                    localStorage.removeItem("manage_session");
                    localStorage.removeItem("manage_mUserId");
                    localStorage.removeItem("login_RoleType");
                    localStorage.removeItem("manage_profile");
                    localStorage.setItem("login_RoleType", responseMsg.rollType)
                    localStorage.setItem("manage_session", responseMsg.session)
                    localStorage.setItem("manage_mUserId", responseMsg.mUserId)
                    localStorage.setItem("manage_profile", responseMsg.profile)
                    localStorage.setItem("manageLoggedUser", responseMsg.mUserName)
                    localStorage.setItem("managelogedin", "manage")
                    // this.props.history.push("/manage/articlecategory")
                    if (responseMsg.redirect) {
                        this.props.history.push(responseMsg.redirect)
                    } else {
                        errors.deletedUser = "Unable to Redirect, Please contact to Admin !"
                        this.setState({ errors })
                    }

                }
                else if (responseMsg && responseMsg.response == "Deleted") {
                    errors.deletedUser = "Your Account is Deleted, Please contact to Admin !"
                    this.setState({ errors })
                }
                else {
                    errors.UserName = "Incorrect Username"
                    errors.Password = "Incorrect Password"
                    this.setState({ errors })
                }
            }
        });

    }
    render() {
        let { UserName, Password, errors, passwordVisible } = this.state
        import('../admin/style.css');
        return (

            <React.Fragment>
                <main className="login-body d-flex justify-content-center align-items-center layout-green-login">
                    <div className='login-box' style={{ color: "#fff" }} >
                        <div className='d-flex align-items-center'>
                            <div style={{ flex: 1, padding: 10, borderRight: "1px solid #ddd" }}>
                                <img src={Logo} className="img-fluid" />
                            </div>
                            <div style={{ flex: 1, padding: 10 }}>
                                <img src={Teqbahn_Logo} className="img-fluid" />
                            </div>
                        </div>
                        <div className='text-center pt-5 pb-2' style={{ color: "#fff" }}>
                            <h4>Manage Admin Login</h4>
                        </div>
                        <div>
                            <form action="">
                                <div className='row'>
                                    <div className='offset-sm-2 col-sm-8'>
                                        <div className="form-group">
                                            <label>Username</label>
                                            <input type="email" name="email" id="email"
                                                className={"form-control"}
                                                placeholder="Username" value={UserName} onChange={(e) => {
                                                    this.setState({ UserName: e.target.value })
                                                }} />
                                            <div className="custom-invalid-feedback">{errors.UserName}</div>
                                        </div>
                                        <div className="form-group mb-4 ">
                                            <label>Password</label>
                                            <div className='input-group'>
                                                <input type={passwordVisible ? "text" : "password"} name="password" id="password"
                                                    className={"form-control password-radius"}
                                                    placeholder="***********" value={Password} onChange={(e) => {
                                                        this.setState({ Password: e.target.value })
                                                    }} />
                                                <div className="input-group-append" onClick={() => {
                                                    this.setState({ passwordVisible: !passwordVisible })
                                                }}>
                                                    <span className="input-group-text border-0 cursor-pointer password-hint-radius"><i className={`fa ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`} /></span>
                                                </div>
                                            </div>
                                            <div className="custom-invalid-feedback">{errors.Password}</div>
                                        </div>
                                        <div className='form-group text-right'>
                                            <Link className='forgot-password-link' to={MyConstant.keyList.projectUrl + "/manage-forgot-password"}>Forgot Password?</Link>
                                        </div>
                                        <button type="button" className="btn btn-block login-btn mb-4" onClick={() => this.getLogin()}>Login</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}