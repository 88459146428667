import React, { Component } from 'react';
import image3 from '../images/Teqbahn_logo1.png';


class ManageLogout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let compnayLogo = image3
        let { navigation } = this.props
        return (
            <div className="right-menu">
                <img className="img-Teqlogo" src={compnayLogo} width="250px" height="70" />
                <div class="dropdown" style={{}}>
                    <i class="fa fa-user dropbtn" style={{ color: "#fff", fontSize: 25, cursor: "pointer", backgroundColor: "#ed3833" }} aria-hidden="true"></i>
                    <div class="dropdown-content admin-lg-bg" >
                        <div> Logged in as <br />
                            <b>{"Admin"}</b>
                        </div>
                        <a onClick={() => {
                            localStorage.removeItem("logedin");
                            navigation.push("/admin/login")
                        }}>Logout</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default ManageLogout;